import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="10" fill="#F2F2F2" />
    <path
      d="M7.01309 14.5846L7.2853 14.3124C7.3601 14.2376 7.46528 14.2016 7.57023 14.2148C7.79206 14.2428 7.98075 14.0542 7.95273 13.8323C7.93949 13.7274 7.97549 13.6222 8.0503 13.5474L8.3225 13.2752C8.41772 13.18 8.57206 13.18 8.66723 13.2752C8.76245 13.3704 8.91679 13.3704 9.01196 13.2752L9.2075 13.0797C9.30271 12.9844 9.30271 12.8301 9.2075 12.7349C9.11229 12.6397 9.11229 12.4854 9.2075 12.3902L9.72764 11.87C9.82286 11.7748 9.9772 11.7748 10.0724 11.87C10.1676 11.9653 10.3219 11.9653 10.4171 11.87L11.2957 10.9915L9.34629 9.04211L4.90834 13.4801C4.73244 13.656 4.62712 13.8902 4.61226 14.1385L4.54636 15.2402C4.52772 15.552 4.78577 15.8101 5.09758 15.7914L6.19929 15.7255C6.4476 15.7107 6.68182 15.6053 6.85771 15.4294L7.01309 15.2741C7.1083 15.1788 7.1083 15.0245 7.01309 14.9293C6.91788 14.8341 6.91788 14.6798 7.01309 14.5846Z"
      fill="#7BC9EB"
    />
    <path
      d="M7.01308 14.5846L7.28529 14.3124C7.36009 14.2376 7.46527 14.2016 7.57022 14.2149C7.79205 14.2429 7.98074 14.0542 7.95272 13.8324C7.93948 13.7274 7.97548 13.6222 8.05028 13.5474L8.32249 13.2752C8.4177 13.18 8.57205 13.18 8.66722 13.2752C8.76243 13.3704 8.91678 13.3704 9.01195 13.2752L9.20749 13.0797C9.3027 12.9845 9.3027 12.8301 9.20749 12.735C9.11228 12.6398 9.11228 12.4854 9.20749 12.3902L9.72763 11.8701C9.82284 11.7749 9.97719 11.7749 10.0724 11.8701C10.1676 11.9653 10.3219 11.9653 10.4171 11.8701L11.2957 10.9915L10.321 10.0168C8.87007 11.4747 7.78892 12.5559 4.70251 15.6442C4.80338 15.7427 4.94395 15.8006 5.09757 15.7915L6.19928 15.7256C6.44759 15.7107 6.68181 15.6054 6.8577 15.4295L7.01308 15.2741C7.10829 15.1789 7.10829 15.0246 7.01308 14.9294C6.91787 14.8342 6.91787 14.6799 7.01308 14.5846Z"
      fill="#23A4DE"
    />
    <path
      d="M15.6605 4.80136C14.1072 3.24802 11.5886 3.24802 10.0353 4.80136C8.48188 6.35471 8.48192 8.87325 10.0353 10.4266C11.5886 11.98 14.1072 11.98 15.6605 10.4266C17.2139 8.87329 17.2139 6.35471 15.6605 4.80136ZM13.1188 7.34311C12.6784 6.90273 12.6781 6.18881 13.1188 5.74814C13.5592 5.30775 14.2731 5.30809 14.7135 5.74843C15.1538 6.18881 15.1541 6.90273 14.7138 7.34311C14.2731 7.78379 13.5591 7.78349 13.1188 7.34311Z"
      fill="#7BC9EB"
    />
    <path
      d="M15.6603 4.80139C15.8975 7.69071 15.6273 10.3936 9.45129 9.68613C9.61239 9.9497 9.80698 10.1986 10.035 10.4267C11.5884 11.98 14.1069 11.98 15.6603 10.4267C17.2137 8.87332 17.2137 6.35474 15.6603 4.80139Z"
      fill="#23A4DE"
    />
  </svg>
))
