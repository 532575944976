import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.9772 10H10.975C10.7291 10 10.4979 10.0976 10.3232 10.2749C10.1466 10.4543 10.0494 10.693 10.0494 10.9472V25.1503C10.0494 25.6711 10.4672 26.0958 10.9809 26.0972C13.1465 26.1024 16.7748 26.562 19.2778 29.2286V14.3647C19.2778 14.1881 19.2335 14.0223 19.1499 13.885C17.0956 10.5169 13.1478 10.0052 10.9772 10Z"
      fill="white"
    />
    <path
      d="M29.9507 25.1503V10.9472C29.9507 10.693 29.8535 10.4543 29.6769 10.2749C29.5023 10.0976 29.2708 10 29.0252 10C29.0244 10 29.0236 10 29.0229 10C26.8524 10.0053 22.9046 10.517 20.8502 13.8851C20.7666 14.0224 20.7224 14.1882 20.7224 14.3648V29.2286C23.2254 26.562 26.8537 26.1024 29.0193 26.0972C29.5329 26.0958 29.9507 25.6711 29.9507 25.1503Z"
      fill="white"
    />
    <path
      d="M32.0698 13.2751H31.3952V25.15C31.3952 26.4798 30.3309 27.5641 29.0227 27.5674C27.1858 27.5719 24.157 27.9375 22.0119 30.0044C25.7218 29.0796 29.6327 29.6808 31.8615 30.1979C32.1398 30.2624 32.4274 30.1957 32.6503 30.0148C32.8725 29.8343 33 29.5646 33 29.275V14.2222C33.0001 13.7 32.5827 13.2751 32.0698 13.2751Z"
      fill="#23A4DE"
    />
    <path
      d="M8.60483 25.15V13.2751H7.93023C7.4174 13.2751 7 13.7 7 14.2222V29.2747C7 29.5644 7.12746 29.8341 7.34964 30.0145C7.57244 30.1954 7.85975 30.2623 8.13849 30.1976C10.3673 29.6804 14.2783 29.0794 17.988 30.0041C15.843 27.9374 12.8142 27.5718 10.9773 27.5673C9.66917 27.5641 8.60483 26.4798 8.60483 25.15Z"
      fill="#23A4DE"
    />
  </svg>
))
