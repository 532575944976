import React, { memo } from 'react'

export default memo(() => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 264 350"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M241.503 36.3104C237.361 26.1157 225.946 21.2954 216.007 25.544C209.211 28.449 204.591 35.0329 204.061 42.5651C198.318 43.3798 194.306 48.8159 195.1 54.707C195.819 60.0375 200.264 64.0053 205.51 64H241.503C248.957 64 255 57.8014 255 50.1552C255 42.5091 248.957 36.3104 241.503 36.3104Z"
      fill="#7BC9EB"
    />
    <path
      d="M90.6805 55.0052C82.6036 35.1256 60.3447 25.7261 40.9637 34.0109C27.712 39.6755 18.7016 52.5141 17.6691 67.202C6.47001 68.7907 -1.35328 79.391 0.195476 90.8786C1.59701 101.273 10.2642 109.01 20.4941 109H90.6802C105.216 109 117 96.9128 117 82.0027C117 67.0926 105.217 55.0052 90.6805 55.0052Z"
      fill="#7BC9EB"
    />
    <path d="M165.406 249.591H105.512V233.479H165.406V249.591Z" fill="#10184F" />
    <path
      d="M228.973 238.739H42.577C36.3781 238.739 31.9607 234.322 31.9607 228.123V99.9806C31.9607 93.7805 36.3781 89.3643 42.577 89.3643H228.973C235.172 89.3643 239.589 93.7805 239.589 99.9806V228.123C239.589 234.322 235.172 238.739 228.973 238.739Z"
      fill="#262F69"
    />
    <mask id="mask0_560_271" maskUnits="userSpaceOnUse" x="40" y="96" width="193" height="132">
      <path
        d="M226.095 227.847H47.0101C43.2532 227.847 40.2085 224.725 40.2085 220.872V103.758C40.2085 99.9056 43.2532 96.7832 47.0101 96.7832H226.095C229.851 96.7832 232.897 99.9056 232.897 103.758V220.872C232.897 224.725 229.851 227.847 226.095 227.847Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_560_271)">
      <rect x="36.3125" y="92.7354" width="202.336" height="143.84" fill="white" />
    </g>
    <path
      d="M191.15 258.348H79.7673V256.946C79.7673 252.11 83.6883 248.191 88.524 248.191H182.395C187.23 248.191 191.15 252.11 191.15 256.946V258.348Z"
      fill="#262F69"
    />
    <g clipPath="url(#clip0_560_271)">
      <path
        d="M225.671 138.2V207.652C225.671 214.475 220.175 220.004 213.394 220.004H59.9037C53.1223 220.004 47.6223 214.475 47.6223 207.652V138.2H225.671Z"
        fill="#F2F2F2"
      />
      <path
        d="M225.671 116.591V133.255H47.6223V116.591C47.6223 109.768 53.1223 104.24 59.9037 104.24H213.394C220.175 104.24 225.671 109.768 225.671 116.591Z"
        fill="#23A4DE"
      />
      <ellipse cx="62.872" cy="118.627" rx="3.70936" ry="3.70936" fill="white" />
      <ellipse cx="74.4122" cy="118.627" rx="3.70936" ry="3.70936" fill="white" />
      <ellipse cx="85.9523" cy="118.627" rx="3.70936" ry="3.70936" fill="white" />
    </g>
    <rect x="66.5813" y="168.498" width="138.633" height="30.4992" rx="4" fill="#7BC9EB" />
    <path
      d="M104.762 176.815L106.125 179.159L107.107 182.284L108.252 179.103L109.561 176.871L112.179 178.322L110.761 180.722L108.252 183.4L111.961 182.73H114.579V185.855H112.07L108.579 185.186L111.088 187.864L112.343 190.04L109.779 191.603L108.361 189.259L107.107 185.855L106.071 189.092L104.707 191.435L101.98 189.873L103.453 187.529L105.743 185.186L102.58 185.855H99.853V182.73H102.58L105.962 183.4L103.507 180.945L102.089 178.378L104.762 176.815Z"
      fill="white"
    />
    <path
      d="M123.883 176.815L125.246 179.159L126.228 182.284L127.373 179.103L128.682 176.871L131.3 178.322L129.882 180.722L127.373 183.4L131.082 182.73H133.7V185.855H131.191L127.7 185.186L130.209 187.864L131.464 190.04L128.9 191.603L127.482 189.259L126.228 185.855L125.192 189.092L123.828 191.435L121.101 189.873L122.574 187.529L124.864 185.186L121.701 185.855H118.974V182.73H121.701L125.083 183.4L122.628 180.945L121.21 178.378L123.883 176.815Z"
      fill="white"
    />
    <path
      d="M143.004 176.815L144.367 179.159L145.349 182.284L146.494 179.103L147.803 176.871L150.421 178.322L149.003 180.722L146.494 183.4L150.203 182.73H152.821V185.855H150.312L146.822 185.186L149.33 187.864L150.585 190.04L148.021 191.603L146.603 189.259L145.349 185.855L144.313 189.092L142.949 191.435L140.222 189.873L141.695 187.529L143.985 185.186L140.822 185.855H138.095V182.73H140.822L144.204 183.4L141.749 180.945L140.331 178.378L143.004 176.815Z"
      fill="white"
    />
    <path
      d="M162.125 176.815L163.488 179.159L164.47 182.284L165.615 179.103L166.924 176.871L169.542 178.322L168.124 180.722L165.615 183.4L169.324 182.73H171.942V185.855H169.433L165.943 185.186L168.451 187.864L169.706 190.04L167.142 191.603L165.724 189.259L164.47 185.855L163.434 189.092L162.07 191.435L159.343 189.873L160.816 187.529L163.106 185.186L159.943 185.855H157.216V182.73H159.943L163.325 183.4L160.87 180.945L159.452 178.378L162.125 176.815Z"
      fill="white"
    />
    <g clipPath="url(#clip1_560_271)">
      <path
        d="M138.828 0.795898C116.141 0.823759 97.7564 19.2082 97.7285 41.8954V67.1872C97.7285 68.9332 99.1441 70.3488 100.89 70.3488H113.536C115.282 70.3488 116.698 68.9332 116.698 67.1872V41.8954C116.697 29.6729 126.606 19.7648 138.828 19.7648C151.05 19.7648 160.959 29.6729 160.959 41.8954V46.3937C160.959 48.1397 162.374 49.5553 164.12 49.5553H176.766C178.512 49.5553 179.927 48.1397 179.927 46.3937V41.8954C179.9 19.2082 161.515 0.823759 138.828 0.795898Z"
        fill="#DEDEDE"
      />
      <path
        d="M100.89 64.0254H176.766C185.496 64.0254 192.573 71.1026 192.573 79.8327V136.74C192.573 145.47 185.496 152.547 176.766 152.547H100.89C92.16 152.547 85.0828 145.47 85.0828 136.74V79.833C85.0828 71.1026 92.16 64.0254 100.89 64.0254Z"
        fill="#FFB71B"
      />
      <path
        d="M154.635 98.8024C154.672 90.0722 147.625 82.9651 138.895 82.9284C130.165 82.8916 123.057 89.9389 123.021 98.669C122.995 104.704 126.407 110.226 131.816 112.903L129.375 129.975C129.13 131.703 130.334 133.303 132.063 133.548C132.209 133.568 132.357 133.579 132.505 133.579H145.151C146.897 133.596 148.326 132.195 148.343 130.449C148.345 130.29 148.334 130.132 148.312 129.975L145.871 112.903C151.214 110.227 154.602 104.777 154.635 98.8024Z"
        fill="white"
      />
    </g>
    <rect x="21.2739" y="277" width="242.589" height="72.6697" fill="url(#pattern0)" />
    <defs>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_560_271" transform="translate(-0.258065 -0.771086) scale(0.00100607 0.00337169)" />
      </pattern>
      <clipPath id="clip0_560_271">
        <rect width="178.049" height="116.227" fill="white" transform="translate(47.6223 104.202)" />
      </clipPath>
      <clipPath id="clip1_560_271">
        <rect width="151.752" height="151.752" fill="white" transform="translate(62.9521 0.795898)" />
      </clipPath>
    </defs>
  </svg>
))
