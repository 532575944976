import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Modal, BodyText, Button, FlexContainer } from '@reapit/elements';

const title = 'Change in office group detected';

const OfficeGroupChangeNotifyModal = () => {
	const [isOpen, setIsOpen] = useState(true);

	const handleClick = () => {
		window.location.reload();
	};

	return (
		<Modal
			data-cy="abort-activity-info-modal"
			isOpen={isOpen}
			onModalClose={() => setIsOpen(false)}
			title={title}
			style={{ width: '540px' }}
		>
			<BodyText>
				Our system has detected that your AgencyCloud office group has changed
				recently
			</BodyText>
			<BodyText>
				AgencyCloud Identity Checks which you have run during this period may be
				affected
			</BodyText>
			<BodyText>
				From now, any Identity Checks which you run via the Thirdfort Reapit App
				will <span style={{ fontWeight: 'bold' }}>not</span> be affected
			</BodyText>

			<FlexContainer isFlexJustifyCenter style={{ marginTop: '2rem' }}>
				<Button intent="primary" onClick={handleClick}>
					Okay
				</Button>
			</FlexContainer>
		</Modal>
	);
};

OfficeGroupChangeNotifyModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func,
	onProceed: PropTypes.func,
};

export default OfficeGroupChangeNotifyModal;
