import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.6406 19.1797H18.3594C17.906 19.1797 17.5391 19.5466 17.5391 20V27.3828H15.8985C15.5876 27.3828 15.3041 27.5582 15.1647 27.8362C15.0261 28.1142 15.0557 28.4466 15.2424 28.695L19.3439 33.6715C19.4985 33.8782 19.7421 34 20 34C20.258 34 20.5015 33.8782 20.6561 33.6715L24.7577 28.695C24.9443 28.4466 24.974 28.1142 24.8354 27.8362C24.696 27.5582 24.4124 27.3828 24.1016 27.3828H22.461V20C22.461 19.5466 22.0941 19.1797 21.6406 19.1797Z"
      fill="#23A4DE"
    />
    <path
      d="M28.9409 14.2586C28.6037 12.6068 27.2618 11.3138 25.5259 11.0391C24.8097 8.15595 22.1894 6 19.1797 6C16.1764 6 13.5849 8.1239 12.8503 10.983C12.7734 10.979 12.6957 10.9766 12.6172 10.9766C8.99868 10.9766 6 13.9206 6 17.5391C6 21.1576 8.99868 24.1016 12.6172 24.1016H15.8984V20C15.8984 18.643 17.0024 17.5391 18.3594 17.5391H21.6406C22.9976 17.5391 24.1016 18.643 24.1016 20V24.1016H29.0234C31.7375 24.1016 34 21.8938 34 19.1797C34 16.4384 31.6998 14.1905 28.9409 14.2586Z"
      fill="white"
    />
  </svg>
))
