import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32.0989 62.5894H13.4382V64.8756H32.0989H46.3481H67.5601V62.5894H46.3481H32.0989Z" fill="#23A4DE" />
    <path d="M17.7112 66.8928H33.3835L39.1189 68.0359L45.064 66.8928H63.2878V62.5894H17.7112V66.8928Z" fill="#23A4DE" />
    <path
      d="M11.3933 65.0776V30.4736C11.3933 28.3136 13.1434 26.5627 15.3021 26.5627H65.3447C67.5035 26.5627 69.2536 28.3137 69.2536 30.4736V65.0776L40.3234 67.5218L11.3933 65.0776Z"
      fill="#23A4DE"
    />
    <path
      d="M15.9614 67.0777V31.8095C15.9614 31.4345 16.2654 31.1306 16.6403 31.1306H64.0069C64.382 31.1306 64.6859 31.4346 64.6859 31.8095V67.0776L40.3237 69.5218L15.9614 67.0777Z"
      fill="#23A4DE"
    />
    <path
      d="M9.49633 71.931H71.1526C74.001 71.931 73.9909 67.9891 74.0001 65.5095C73.9954 64.9218 73.694 64.4475 73.322 64.4475H48.3256C47.9507 64.4475 47.6467 64.9291 47.6467 65.5231V65.6885C47.6467 66.89 47.032 67.8638 46.2736 67.8638H33.7861C33.0277 67.8638 32.4129 66.89 32.4129 65.6885V65.5231C32.4129 64.9291 32.1089 64.4475 31.734 64.4475H7.32682C6.9548 64.4475 6.65342 64.9218 6.64868 65.5095C6.65802 67.9891 6.64789 71.931 9.49633 71.931Z"
      fill="#31448F"
    />
    <path
      d="M71.1518 69.544H9.49704C7.92389 69.544 6.64868 67.741 6.64868 65.5166V68.9727C6.64868 71.1968 7.92389 73.0001 9.49704 73.0001H71.1518C72.7249 73.0001 74.0001 71.197 74.0001 68.9727V65.5166C74.0001 67.741 72.7248 69.544 71.1518 69.544Z"
      fill="#262F69"
    />
    <rect x="19.4773" y="33.4446" width="25.6577" height="17.1051" rx="2" fill="#7BC9EB" />
    <rect x="48.3423" y="34.5137" width="12.8288" height="3.20721" rx="1.60361" fill="#7BC9EB" />
    <rect x="48.3423" y="39.8591" width="12.8288" height="3.20721" rx="1.60361" fill="#7BC9EB" />
    <rect x="48.3423" y="45.2043" width="12.8288" height="3.20721" rx="1.60361" fill="#7BC9EB" />
    <rect x="19.4773" y="58.0332" width="33.1412" height="1.06907" rx="0.534535" fill="#7BC9EB" />
    <rect x="19.4773" y="53.7568" width="41.6937" height="2.13814" rx="1.06907" fill="#7BC9EB" />
    <path
      d="M36.7769 18.6172C33.9466 11.481 26.1464 8.10681 19.3548 11.0808C14.711 13.1143 11.5535 17.723 11.1917 22.9956C7.26727 23.5659 4.52577 27.3711 5.0685 31.4949C5.55963 35.2263 8.59684 38.0037 12.1817 38H36.7768C41.8706 38 46 33.661 46 28.3087C46 22.9563 41.8708 18.6172 36.7769 18.6172Z"
      fill="white"
    />
    <path
      d="M39.5 28C45.299 28 50 23.299 50 17.5C50 11.701 45.299 7 39.5 7C33.701 7 29 11.701 29 17.5C29 23.299 33.701 28 39.5 28Z"
      fill="#A0C862"
    />
    <path
      d="M39.7652 19.8517C40.2289 20.3154 40.2289 21.1104 39.7652 21.5741L38.8046 22.5347C38.3409 22.9984 37.546 22.9984 37.0822 22.5347L32.8756 18.2949C32.4119 17.8312 32.4119 17.0362 32.8756 16.5725L33.8362 15.612C34.2999 15.1482 35.0949 15.1482 35.5586 15.612L39.7652 19.8517Z"
      fill="white"
    />
    <path
      d="M43.4422 12.5316C43.9059 12.0679 44.7009 12.0679 45.1646 12.5316L46.1252 13.4922C46.5889 13.9559 46.5889 14.7509 46.1252 15.2146L38.8381 22.4685C38.3744 22.9323 37.5795 22.9323 37.1157 22.4685L36.1552 21.508C35.6914 21.0442 35.6914 20.2493 36.1552 19.7856L43.4422 12.5316Z"
      fill="white"
    />
    <path
      d="M70.9508 40.6931C69.7082 37.6347 66.2838 36.1886 63.3021 37.4632C61.2634 38.3347 59.8772 40.3099 59.7183 42.5695C57.9954 42.8139 56.7918 44.4448 57.0301 46.2121C57.2457 47.8113 58.5791 49.0016 60.1529 49H70.9508C73.1871 49 75 47.1404 75 44.8466C75 42.5527 73.1872 40.6931 70.9508 40.6931Z"
      fill="white"
    />
  </svg>
))
