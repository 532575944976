export const Tasks = {
	document: 'document',
	documentsLite: 'documents:lite',
	documentsBankStatement: 'documents:bank-statement',
	documentsIdentity: 'documents:identity',
	documentsOther: 'documents:other',
	documentsSelfie: 'documents:selfie',
	documentsPoa: 'documents:poa',
	documentsPoo: 'documents:poo',
	reportBankStatement: 'report:bank-statement',
	reportBankSummary: 'report:bank-summary',
	reportCompanySanctions: 'company:sanctions',
	reportIdentity: 'report:identity',
	reportFacialSimilarityVideo: 'facial_similarity_video',
	reportFootprint: 'report:footprint',
	reportPeps: 'report:peps',
	reportPepsDismissals: 'peps:dismissals',
	reportPersonalInfo: 'report:personal-info',
	reportSanctionsDismissals: 'sanctions:dismissals',
	reportSofV1: 'report:sof-v1',
};

export const ReportTypeMapper = {
	'bank:statement': Tasks.reportBankStatement,
	'bank:summary': Tasks.reportBankSummary,
	'company:sanctions': Tasks.reportCompanySanctions,
	'documents:bank-statement': Tasks.documentsBankStatement,
	'documents:other': Tasks.documentsOther,
	'documents:poa': Tasks.documentsPoa,
	'documents:poo': Tasks.documentsPoo,
	'documents:selfie': Tasks.documentsSelfie,
	footprint: Tasks.reportFootprint,
	identity: Tasks.reportIdentity,
	peps: Tasks.reportPeps,
	'sof:v1': Tasks.reportSofV1,
};
