import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

import {
	Button,
	FlexContainer,
	Subtitle,
	Title,
	useSnack,
} from '@reapit/elements';

import {
	RegisterFormContainer,
	RegisterContainer,
	RegisterImageContainer,
	BallLockContainer,
	InputContainer,
	CheckBoxContentContainer,
	Link,
} from '../__styles__';

import { useNavigate } from 'react-router-dom';

import { useAPI } from 'components/hocs/api-provider';

import Checkbox from 'components/ui/checkbox';
import PDFViewer from 'components/ui/pdf-viewer';
import { BodyTextNoMargin } from 'components/ui/typography';

import { registerUser } from 'platform-api/auth-api';

import { Routes, SnackDuration } from '_constants';

import schema from './schemas/tenant-validation-schema';

import ballLock from 'assets/images/ball-lock.png';
import RegisterDataCapture from './register-data-capture';
import APIError from 'core/errors/api-error';

const initialInputs = {
	errors: {},
	data: {
		firstname: '',
		lastname: '',
		email: '',
		phone: '',
		companyName: '',
		jobTitle: '',
		approximateCheckCount: '0-50',
		termsOfUse: false,
	},
};

const RegisterTenantPage = ({ userStatus }) => {
	const { tenant, tenant_registered, user_name, user_email } = userStatus;
	const [firstname, lastname] = user_name.split(' ');

	const fetcher = useAPI();

	const navigate = useNavigate();

	const { success, error } = useSnack();

	const [inputs, setInputs] = useState({
		...initialInputs,
		data: {
			...initialInputs.data,
			email: user_email,
			firstname,
			lastname,
			companyName: tenant,
		},
	});
	const [loading, setLoading] = useState(false);

	const [touModalOpen, setTouModalOpen] = useState(false);

	const handleInput = (field, value, reset) => {
		setInputs((prevState) => {
			const data = {
				...prevState.data,
			};
			data[field] = value;

			return {
				...prevState,
				data: {
					...data,
					...reset,
				},
			};
		});
	};

	const handleRegister = async () => {
		setLoading(true);
		try {
			await registerUser(fetcher, inputs.data);
			success('User registered successfully', SnackDuration.LONG);
			navigate(Routes.HOME);
			window.location.reload();
		} catch (err) {
			if (!(err instanceof APIError)) {
				Sentry.captureException(err);
			}

			error("Couldn't register the user. Please try again", SnackDuration.LONG);
		} finally {
			setLoading(false);
		}
	};

	const onSubmit = ({ hasErrors, errors }) => {
		if (hasErrors) {
			setInputs((prevState) => ({ ...prevState, errors }));
		} else {
			handleRegister();
		}
	};

	const validator = async (field, value) => {
		return await schema()
			.validateAt(field, { [field]: value })
			.then(() => null)
			.catch((err) => err.message);
	};

	const validateOnSubmit = (data) => {
		let hasErrors = false;
		const errors = {};

		try {
			schema().validateSync(data, {
				abortEarly: false,
			});
		} catch (err) {
			hasErrors = true;
			const inner = err.inner;
			for (const error of inner) {
				errors[error.path] = error.message;
			}
		}

		return { hasErrors, errors };
	};

	const handleCheckbox = (event) => {
		setInputs((prevState) => {
			const data = {
				...prevState.data,
			};
			data.termsOfUse = event.target.checked;

			return {
				...prevState,
				data: {
					...data,
				},
			};
		});
	};

	return (
		<>
			<RegisterContainer>
				<RegisterImageContainer>
					<BallLockContainer src={ballLock} alt="ball-lock-image" />
				</RegisterImageContainer>
				<RegisterFormContainer>
					<Title>Welcome</Title>
					<Subtitle>
						Please complete this form to register
						{tenant_registered ? ` under ${tenant}` : ' your company'}
					</Subtitle>
					<RegisterDataCapture
						data={inputs.data}
						errors={inputs.errors}
						handleChange={handleInput}
						loading={loading}
						tenant_registered={tenant_registered}
						validator={validator}
						onValidate={(field, value) => {
							setInputs((prevState) => ({
								...prevState,
								errors: {
									...prevState.errors,
									[field]: value,
								},
							}));
						}}
					/>
					<InputContainer>
						<FlexContainer isFlexAlignCenter>
							<Checkbox
								checked={inputs.data.termsOfUse}
								disabled={loading}
								id="termsOfUse"
								onChange={handleCheckbox}
							/>
							<CheckBoxContentContainer>
								<BodyTextNoMargin hasBoldText>
									I understand and accept the&nbsp;
									<Link onClick={() => setTouModalOpen(true)}>
										Terms of Use
									</Link>
								</BodyTextNoMargin>
							</CheckBoxContentContainer>
						</FlexContainer>
					</InputContainer>
					<Button
						intent="primary"
						loading={loading}
						disabled={!inputs.data.termsOfUse}
						onClick={() => onSubmit(validateOnSubmit(inputs.data))}
					>
						Continue
					</Button>
				</RegisterFormContainer>
			</RegisterContainer>
			{touModalOpen && (
				<PDFViewer
					isOpen={touModalOpen}
					pdfData={
						process.env.PUBLIC_URL + '/assets/pdf/Thirdfort-Terms-Of-Use.pdf'
					}
					onClose={() => setTouModalOpen(false)}
					noCaptions
				/>
			)}
		</>
	);
};

RegisterTenantPage.propTypes = {
	userStatus: PropTypes.object.isRequired,
};

export default RegisterTenantPage;
