import { TF_BASE_HEADERS } from '_constants';
import * as Sentry from '@sentry/browser';
import APIError from '../core/errors/api-error';
import { redactHeaders, redactRequestBody } from 'utils/redact';

/**
 * This is a wrapper over the fetch library to
 * support reapit connect sessions
 */
class Fetcher {
	constructor(session) {
		this.session = session;
	}

	fetch = async (url, properties, meta) => {
		if (!this.session) return;

		const { method = 'GET', headers, body } = properties || {};
		const {
			returnResponse = false,
			skipBodyParsing = false,
			removeContentTypeHeader = false,
			useXAccessTokenHeader = false,
		} = meta || {};

		// format headers
		const _headers = {
			...TF_BASE_HEADERS,
			Authorization: `Bearer ${this.session.idToken}`,
			...headers,
		};

		if (useXAccessTokenHeader) {
			_headers['x-access-token'] = `Bearer ${this.session.accessToken}`;
		}

		if (removeContentTypeHeader) {
			delete _headers['Content-Type'];
		}

		// format body
		const _body = skipBodyParsing ? body : JSON.stringify(body);

		const response = await fetch(url, {
			method,
			headers: _headers,
			body: _body,
		});

		/**
		 * response.ok returns a Boolean stating whether the
		 * response status in the range 200-299 or not
		 *
		 * see more at: https://developer.mozilla.org/en-US/docs/Web/API/Response/ok
		 */
		if (!response.ok) {
			// Log on sentry
			Sentry.addBreadcrumb({
				type: 'request',
				level: 'error',
				data: {
					body: redactRequestBody(body),
					headers: redactHeaders(_headers),
					method,
					response: response.statusText,
					url,
				},
			});

			throw new APIError("Response wasn't successful", response.status);
		}

		if (returnResponse) {
			return response;
		}

		return response.json();
	};
}

export default Fetcher;
