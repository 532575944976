import { useEffect, useState } from 'react';

import { useReapitConnect } from '@reapit/connect-session';

import { reapitConnectBrowserSession } from 'core/connect-session';

import Fetcher from 'services/fetcher';

export const useAPI = () => {
	const [fetcher, setFetcher] = useState();

	const { connectSession } = useReapitConnect(reapitConnectBrowserSession);

	useEffect(() => {
		if (connectSession) {
			setFetcher(new Fetcher(connectSession));
		}
	}, [connectSession]);

	return fetcher;
};
