import { styled } from '@linaria/react';

export const Modal = styled.div`
	position: fixed;
	z-index: 20;
	padding: 100px;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.8);
`;

export const Spotlight = styled.div`
	margin: auto;
	display: flex;
	justify-content: center;

	-webkit-animation-name: zoom;
	-webkit-animation-duration: 0.6s;
	animation-name: zoom;
	animation-duration: 0.6s;

	@-webkit-keyframes zoom {
		from {
			-webkit-transform: scale(0);
		}
		to {
			-webkit-transform: scale(1);
		}
	}

	@keyframes zoom {
		from {
			transform: scale(0);
		}
		to {
			transform: scale(1);
		}
	}
`;

export const Caption = styled.div`
	margin: auto;
	display: block;
	text-align: center;
	color: #ccc;
	padding: 10px 0;
	height: 150px;

	-webkit-animation-name: zoom;
	-webkit-animation-duration: 0.6s;
	animation-name: zoom;
	animation-duration: 0.6s;

	@-webkit-keyframes zoom {
		from {
			-webkit-transform: scale(0);
		}
		to {
			-webkit-transform: scale(1);
		}
	}

	@keyframes zoom {
		from {
			transform: scale(0);
		}
		to {
			transform: scale(1);
		}
	}
`;

export const CloseButton = styled.div`
	position: absolute;
	top: 20px;
	right: 40px;
	color: #f1f1f1;
	font-size: 40px;
	transition: 0.3s;

	&:hover,
	&:focus {
		color: #bbb;
		cursor: pointer;
	}
`;

export const DownloadButton = styled.div`
	position: absolute;
	top: 20px;
	right: 80px;
	color: #f1f1f1;
	font-size: 40px;
	transition: 0.3s;

	&:hover,
	&:focus {
		color: #bbb;
		cursor: pointer;
	}
`;

export const LeftArrow = styled.div`
	position: absolute;
	left: 80px;
	top: 350px;
	transition: 0.3s;

	&:hover,
	&:focus {
		color: #bbb;
		cursor: pointer;
	}
`;

export const RightArrow = styled.div`
	position: absolute;
	right: 80px;
	top: 350px;
	transition: 0.3s;

	&:hover,
	&:focus {
		color: #bbb;
		cursor: pointer;
	}
`;
