export const Countries = {
	GBR: { name: 'United Kingdom', shortCode: 'GB' },
	ABW: { name: 'Aruba', shortCode: 'AW' },
	AFG: { name: 'Afghanistan', shortCode: 'AF' },
	AGO: { name: 'Angola', shortCode: 'AO' },
	AIA: { name: 'Anguilla', shortCode: 'AI' },
	ALA: { name: 'Aland Islands', shortCode: 'AX' },
	ALB: { name: 'Albania', shortCode: 'AL' },
	AND: { name: 'Andorra', shortCode: 'AD' },
	ANT: { name: 'Netherlands Antilles', shortCode: 'AN' },
	ARE: { name: 'United Arab Emirates', shortCode: 'AE' },
	ARG: { name: 'Argentina', shortCode: 'AR' },
	ARM: { name: 'Armenia', shortCode: 'AM' },
	ASM: { name: 'American Samoa', shortCode: 'AS' },
	ATA: { name: 'Antarctica', shortCode: 'AQ' },
	ATF: { name: 'French Southern Territories', shortCode: 'TF' },
	ATG: { name: 'Antigua and Barbuda', shortCode: 'AG' },
	AUS: { name: 'Australia', shortCode: 'AU' },
	AUT: { name: 'Austria', shortCode: 'AT' },
	AZE: { name: 'Azerbaijan', shortCode: 'AZ' },
	BDI: { name: 'Burundi', shortCode: 'BI' },
	BEL: { name: 'Belgium', shortCode: 'BE' },
	BEN: { name: 'Benin', shortCode: 'BJ' },
	BFA: { name: 'Burkina Faso', shortCode: 'BF' },
	BGD: { name: 'Bangladesh', shortCode: 'BD' },
	BGR: { name: 'Bulgaria', shortCode: 'BG' },
	BHR: { name: 'Bahrain', shortCode: 'BH' },
	BHS: { name: 'Bahamas', shortCode: 'BS' },
	BIH: { name: 'Bosnia and Herzegovina', shortCode: 'BA' },
	BLM: { name: 'Saint Barthélemy', shortCode: 'BL' },
	BLR: { name: 'Belarus', shortCode: 'BY' },
	BLZ: { name: 'Belize', shortCode: 'BZ' },
	BMU: { name: 'Bermuda', shortCode: 'BM' },
	BOL: { name: 'Bolivia, Plurinational State of', shortCode: 'BO' },
	BRA: { name: 'Brazil', shortCode: 'BR' },
	BRB: { name: 'Barbados', shortCode: 'BB' },
	BRN: { name: 'Brunei Darussalam', shortCode: 'BN' },
	BTN: { name: 'Bhutan', shortCode: 'BT' },
	BVT: { name: 'Bouvet Island', shortCode: 'BV' },
	BWA: { name: 'Botswana', shortCode: 'BW' },
	CAF: { name: 'Central African Republic', shortCode: 'CF' },
	CAN: { name: 'Canada', shortCode: 'CA' },
	CCK: { name: 'Cocos (Keeling) Islands', shortCode: 'CC' },
	CHE: { name: 'Switzerland', shortCode: 'CH' },
	CHL: { name: 'Chile', shortCode: 'CL' },
	CHN: { name: 'China', shortCode: 'CN' },
	CIV: { name: "Cote d'Ivoire", shortCode: 'CI' },
	CMR: { name: 'Cameroon', shortCode: 'CM' },
	COD: { name: 'Congo, the Democratic Republic of the', shortCode: 'CD' },
	COG: { name: 'Congo', shortCode: 'CG' },
	COK: { name: 'Cook Islands', shortCode: 'CK' },
	COL: { name: 'Colombia', shortCode: 'CO' },
	COM: { name: 'Comoros', shortCode: 'KM' },
	CPV: { name: 'Cape Verde', shortCode: 'CV' },
	CRI: { name: 'Costa Rica', shortCode: 'CR' },
	CUB: { name: 'Cuba', shortCode: 'CU' },
	CXR: { name: 'Christmas Island', shortCode: 'CX' },
	CYM: { name: 'Cayman Islands', shortCode: 'KY' },
	CYP: { name: 'Cyprus', shortCode: 'CY' },
	CZE: { name: 'Czech Republic', shortCode: 'CZ' },
	DEU: { name: 'Germany', shortCode: 'DE' },
	DJI: { name: 'Djibouti', shortCode: 'DJ' },
	DMA: { name: 'Dominica', shortCode: 'DM' },
	DNK: { name: 'Denmark', shortCode: 'DK' },
	DOM: { name: 'Dominican Republic', shortCode: 'DO' },
	DZA: { name: 'Algeria', shortCode: 'DZ' },
	ECU: { name: 'Ecuador', shortCode: 'EC' },
	EGY: { name: 'Egypt', shortCode: 'EG' },
	ERI: { name: 'Eritrea', shortCode: 'ER' },
	ESH: { name: 'Western Sahara', shortCode: 'EH' },
	ESP: { name: 'Spain', shortCode: 'ES' },
	EST: { name: 'Estonia', shortCode: 'EE' },
	ETH: { name: 'Ethiopia', shortCode: 'ET' },
	FIN: { name: 'Finland', shortCode: 'FI' },
	FJI: { name: 'Fiji', shortCode: 'FJ' },
	FLK: { name: 'Falkland Islands (Malvinas)', shortCode: 'FK' },
	FRA: { name: 'France', shortCode: 'FR' },
	FRO: { name: 'Faroe Islands', shortCode: 'FO' },
	FSM: { name: 'Micronesia, Federated States of', shortCode: 'FM' },
	GAB: { name: 'Gabon', shortCode: 'GA' },
	GEO: { name: 'Georgia', shortCode: 'GE' },
	GGY: { name: 'Guernsey', shortCode: 'GG' },
	GHA: { name: 'Ghana', shortCode: 'GH' },
	GIB: { name: 'Gibraltar', shortCode: 'GI' },
	GIN: { name: 'Guinea', shortCode: 'GN' },
	GLP: { name: 'Guadeloupe', shortCode: 'GP' },
	GMB: { name: 'Gambia', shortCode: 'GM' },
	GNB: { name: 'Guinea-Bissau', shortCode: 'GW' },
	GNQ: { name: 'Equatorial Guinea', shortCode: 'GQ' },
	GRC: { name: 'Greece', shortCode: 'GR' },
	GRD: { name: 'Grenada', shortCode: 'GD' },
	GRL: { name: 'Greenland', shortCode: 'GL' },
	GTM: { name: 'Guatemala', shortCode: 'GT' },
	GUF: { name: 'French Guiana', shortCode: 'GF' },
	GUM: { name: 'Guam', shortCode: 'GU' },
	GUY: { name: 'Guyana', shortCode: 'GY' },
	HKG: { name: 'Hong Kong', shortCode: 'HK' },
	HMD: { name: 'Heard Island and McDonald Islands', shortCode: 'HM' },
	HND: { name: 'Honduras', shortCode: 'HN' },
	HRV: { name: 'Croatia', shortCode: 'HR' },
	HTI: { name: 'Haiti', shortCode: 'HT' },
	HUN: { name: 'Hungary', shortCode: 'HU' },
	IDN: { name: 'Indonesia', shortCode: 'ID' },
	IMN: { name: 'Isle of Man', shortCode: 'IM' },
	IND: { name: 'India', shortCode: 'IN' },
	IOT: { name: 'British Indian Ocean Territory', shortCode: 'IO' },
	IRL: { name: 'Ireland', shortCode: 'IE' },
	IRN: { name: 'Iran, Islamic Republic of', shortCode: 'IR' },
	IRQ: { name: 'Iraq', shortCode: 'IQ' },
	ISL: { name: 'Iceland', shortCode: 'IS' },
	ISR: { name: 'Israel', shortCode: 'IL' },
	ITA: { name: 'Italy', shortCode: 'IT' },
	JAM: { name: 'Jamaica', shortCode: 'JM' },
	JEY: { name: 'Jersey', shortCode: 'JE' },
	JOR: { name: 'Jordan', shortCode: 'JO' },
	JPN: { name: 'Japan', shortCode: 'JP' },
	KAZ: { name: 'Kazakhstan', shortCode: 'KZ' },
	KEN: { name: 'Kenya', shortCode: 'KE' },
	KGZ: { name: 'Kyrgyzstan', shortCode: 'KG' },
	KHM: { name: 'Cambodia', shortCode: 'KH' },
	KIR: { name: 'Kiribati', shortCode: 'KI' },
	KNA: { name: 'Saint Kitts and Nevis', shortCode: 'KN' },
	KOR: { name: 'Korea, Republic of', shortCode: 'KR' },
	KWT: { name: 'Kuwait', shortCode: 'KW' },
	LAO: { name: "Lao People's Democratic Republic", shortCode: 'LA' },
	LBN: { name: 'Lebanon', shortCode: 'LB' },
	LBR: { name: 'Liberia', shortCode: 'LR' },
	LBY: { name: 'Libyan Arab Jamahiriya', shortCode: 'LY' },
	LCA: { name: 'Saint Lucia', shortCode: 'LC' },
	LIE: { name: 'Liechtenstein', shortCode: 'LI' },
	LKA: { name: 'Sri Lanka', shortCode: 'LK' },
	LSO: { name: 'Lesotho', shortCode: 'LS' },
	LTU: { name: 'Lithuania', shortCode: 'LT' },
	LUX: { name: 'Luxembourg', shortCode: 'LU' },
	LVA: { name: 'Latvia', shortCode: 'LV' },
	MAC: { name: 'Macao', shortCode: 'MO' },
	MAF: { name: 'Saint Martin (French part)', shortCode: 'MF' },
	MAR: { name: 'Morocco', shortCode: 'MA' },
	MCO: { name: 'Monaco', shortCode: 'MC' },
	MDA: { name: 'Moldova, Republic of', shortCode: 'MD' },
	MDG: { name: 'Madagascar', shortCode: 'MG' },
	MDV: { name: 'Maldives', shortCode: 'MV' },
	MEX: { name: 'Mexico', shortCode: 'MX' },
	MHL: { name: 'Marshall Islands', shortCode: 'MH' },
	MKD: { name: 'Macedonia, the former Yugoslav Republic of', shortCode: 'MK' },
	MLI: { name: 'Mali', shortCode: 'ML' },
	MLT: { name: 'Malta', shortCode: 'MT' },
	MMR: { name: 'Myanmar', shortCode: 'MM' },
	MNE: { name: 'Montenegro', shortCode: 'ME' },
	MNG: { name: 'Mongolia', shortCode: 'MN' },
	MNP: { name: 'Northern Mariana Islands', shortCode: 'MP' },
	MOZ: { name: 'Mozambique', shortCode: 'MZ' },
	MRT: { name: 'Mauritania', shortCode: 'MR' },
	MSR: { name: 'Montserrat', shortCode: 'MS' },
	MTQ: { name: 'Martinique', shortCode: 'MQ' },
	MUS: { name: 'Mauritius', shortCode: 'MU' },
	MWI: { name: 'Malawi', shortCode: 'MW' },
	MYS: { name: 'Malaysia', shortCode: 'MY' },
	MYT: { name: 'Mayotte', shortCode: 'YT' },
	NAM: { name: 'Namibia', shortCode: 'NA' },
	NCL: { name: 'New Caledonia', shortCode: 'NC' },
	NER: { name: 'Niger', shortCode: 'NE' },
	NFK: { name: 'Norfolk Island', shortCode: 'NF' },
	NGA: { name: 'Nigeria', shortCode: 'NG' },
	NIC: { name: 'Nicaragua', shortCode: 'NI' },
	NIU: { name: 'Niue', shortCode: 'NU' },
	NLD: { name: 'Netherlands', shortCode: 'NL' },
	NOR: { name: 'Norway', shortCode: 'NO' },
	NPL: { name: 'Nepal', shortCode: 'NP' },
	NRU: { name: 'Nauru', shortCode: 'NR' },
	NZL: { name: 'New Zealand', shortCode: 'NZ' },
	OMN: { name: 'Oman', shortCode: 'OM' },
	PAK: { name: 'Pakistan', shortCode: 'PK' },
	PAN: { name: 'Panama', shortCode: 'PA' },
	PCN: { name: 'Pitcairn', shortCode: 'PN' },
	PER: { name: 'Peru', shortCode: 'PE' },
	PHL: { name: 'Philippines', shortCode: 'PH' },
	PLW: { name: 'Palau', shortCode: 'PW' },
	PNG: { name: 'Papua New Guinea', shortCode: 'PG' },
	POL: { name: 'Poland', shortCode: 'PL' },
	PRI: { name: 'Puerto Rico', shortCode: 'PR' },
	PRK: { name: "Korea, Democratic People's Republic of", shortCode: 'KP' },
	PRT: { name: 'Portugal', shortCode: 'PT' },
	PRY: { name: 'Paraguay', shortCode: 'PY' },
	PSE: { name: 'Palestinian Territory, Occupied', shortCode: 'PS' },
	PYF: { name: 'French Polynesia', shortCode: 'PF' },
	QAT: { name: 'Qatar', shortCode: 'QA' },
	REU: { name: 'Réunion', shortCode: 'RE' },
	ROU: { name: 'Romania', shortCode: 'RO' },
	RUS: { name: 'Russian Federation', shortCode: 'RU' },
	RWA: { name: 'Rwanda', shortCode: 'RW' },
	SAU: { name: 'Saudi Arabia', shortCode: 'SA' },
	SDN: { name: 'Sudan', shortCode: 'SD' },
	SEN: { name: 'Senegal', shortCode: 'SN' },
	SGP: { name: 'Singapore', shortCode: 'SG' },
	SGS: {
		name: 'South Georgia and the South Sandwich Islands',
		shortCode: 'GS',
	},
	SHN: {
		name: 'Saint Helena, Ascension and Tristan da Cunha',
		shortCode: 'SH',
	},
	SJM: { name: 'Svalbard and Jan Mayen', shortCode: 'SJ' },
	SLB: { name: 'Solomon Islands', shortCode: 'SB' },
	SLE: { name: 'Sierra Leone', shortCode: 'SL' },
	SLV: { name: 'El Salvador', shortCode: 'SV' },
	SMR: { name: 'San Marino', shortCode: 'SM' },
	SOM: { name: 'Somalia', shortCode: 'SO' },
	SPM: { name: 'Saint Pierre and Miquelon', shortCode: 'PM' },
	SRB: { name: 'Serbia', shortCode: 'RS' },
	STP: { name: 'Sao Tome and Principe', shortCode: 'ST' },
	SUR: { name: 'Suriname', shortCode: 'SR' },
	SVK: { name: 'Slovakia', shortCode: 'SK' },
	SVN: { name: 'Slovenia', shortCode: 'SI' },
	SWE: { name: 'Sweden', shortCode: 'SE' },
	SWZ: { name: 'Swaziland', shortCode: 'SZ' },
	SYC: { name: 'Seychelles', shortCode: 'SC' },
	SYR: { name: 'Syrian Arab Republic', shortCode: 'SY' },
	TCA: { name: 'Turks and Caicos Islands', shortCode: 'TC' },
	TCD: { name: 'Chad', shortCode: 'TD' },
	TGO: { name: 'Togo', shortCode: 'TG' },
	THA: { name: 'Thailand', shortCode: 'TH' },
	TJK: { name: 'Tajikistan', shortCode: 'TJ' },
	TKL: { name: 'Tokelau', shortCode: 'TK' },
	TKM: { name: 'Turkmenistan', shortCode: 'TM' },
	TLS: { name: 'Timor-Leste', shortCode: 'TL' },
	TON: { name: 'Tonga', shortCode: 'TO' },
	TTO: { name: 'Trinidad and Tobago', shortCode: 'TT' },
	TUN: { name: 'Tunisia', shortCode: 'TN' },
	TUR: { name: 'Turkey', shortCode: 'TR' },
	TUV: { name: 'Tuvalu', shortCode: 'TV' },
	TWN: { name: 'Taiwan, Province of China', shortCode: 'TW' },
	TZA: { name: 'Tanzania, United Republic of', shortCode: 'TZ' },
	UGA: { name: 'Uganda', shortCode: 'UG' },
	UKR: { name: 'Ukraine', shortCode: 'UA' },
	UMI: { name: 'United States Minor Outlying Islands', shortCode: 'UM' },
	URY: { name: 'Uruguay', shortCode: 'UY' },
	USA: { name: 'United States of America', shortCode: 'US' },
	UZB: { name: 'Uzbekistan', shortCode: 'UZ' },
	VAT: { name: 'Holy See (Vatican City State)', shortCode: 'VA' },
	VCT: { name: 'Saint Vincent and the Grenadines', shortCode: 'VC' },
	VEN: { name: 'Venezuela, Bolivarian Republic of', shortCode: 'VE' },
	VGB: { name: 'Virgin Islands, British', shortCode: 'VG' },
	VIR: { name: 'Virgin Islands, U.S.', shortCode: 'VI' },
	VNM: { name: 'Vietnam', shortCode: 'VN' },
	VUT: { name: 'Vanuatu', shortCode: 'VU' },
	WLF: { name: 'Wallis and Futuna', shortCode: 'WF' },
	WSM: { name: 'Samoa', shortCode: 'WS' },
	YEM: { name: 'Yemen', shortCode: 'YE' },
	ZAF: { name: 'South Africa', shortCode: 'ZA' },
	ZMB: { name: 'Zambia', shortCode: 'ZM' },
	ZWE: { name: 'Zimbabwe', shortCode: 'ZW' },
};
