import React, { memo } from 'react'

export default memo(() => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.494 32.1284C17.2272 30.3952 17.0365 27.3998 15.0577 25.4692C13.1073 23.5664 10.1259 23.4401 8.44468 25.1864C7.5629 26.1024 7.2028 27.354 7.34433 28.6127C7.34345 28.6048 7.34208 28.5969 7.34126 28.589C7.34472 29.6933 7.79881 29.913 7.00202 30.7098C6.76561 30.9462 6.50581 31.1467 6.22845 31.3117C6.04585 31.4203 6.03261 31.6845 6.19963 31.8531C6.215 31.8686 6.23048 31.8841 6.24602 31.8995C8.88192 34.5169 12.9165 34.7051 15.2533 32.3688C15.4099 32.2124 15.3292 32.2932 15.494 32.1284Z"
      fill="#23A4DE"
    />
    <path
      d="M33.0537 6.86381C31.9789 5.78894 30.2631 5.70713 29.0909 6.67489L17.358 16.361C16.2007 17.3164 15.2479 18.4955 14.5568 19.8277L12.6788 23.4476L16.4699 27.2387L20.0898 25.3607C21.422 24.6696 22.6011 23.7168 23.5566 22.5595L33.2426 10.8266C34.2103 9.65442 34.1285 7.93868 33.0537 6.86381Z"
      fill="white"
    />
    <path
      d="M13.3016 21.0508L12.4945 22.7529C12.2774 23.2109 12.3717 23.7559 12.7301 24.1143L15.8031 27.1874C16.1615 27.5458 16.7066 27.6401 17.1645 27.423L18.8667 26.6159C19.2884 26.4159 19.3849 25.8585 19.0548 25.5285L14.389 20.8626C14.059 20.5326 13.5016 20.6291 13.3016 21.0508Z"
      fill="#23A4DE"
    />
    <path
      d="M15.3899 12.9249C17.0792 12.9249 18.4487 11.5554 18.4487 9.86599C18.4487 8.17663 17.0792 6.80713 15.3899 6.80713C13.7005 6.80713 12.331 8.17663 12.331 9.86599C12.331 11.5554 13.7005 12.9249 15.3899 12.9249Z"
      fill="white"
    />
    <path
      d="M10.1077 19.4749C11.3621 19.4749 12.379 18.458 12.379 17.2036C12.379 15.9492 11.3621 14.9324 10.1077 14.9324C8.85336 14.9324 7.83649 15.9492 7.83649 17.2036C7.83649 18.458 8.85336 19.4749 10.1077 19.4749Z"
      fill="white"
    />
    <path
      d="M28.4021 22.4409C26.0929 22.4465 24.1674 24.0837 23.7165 26.2606C23.6377 26.6411 23.3598 26.951 22.9924 27.0777C21.7908 27.4922 20.9283 28.6339 20.9317 29.9772C20.936 31.6384 22.295 33.0096 23.9562 33.0278C24.8019 33.0371 25.5694 32.7029 26.1283 32.1563C26.4048 31.8859 26.8049 31.7764 27.1786 31.8757C27.5728 31.9805 27.9869 32.0363 28.414 32.0363C31.0637 32.0363 33.2117 29.8883 33.2117 27.2387C33.2118 24.5868 31.054 22.4345 28.4021 22.4409Z"
      fill="#23A4DE"
    />
  </svg>
))
