import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M25.9286 4.46338H8.5V33.6208H25.9286C26.5203 33.6208 27 33.1212 27 32.5051V5.5791C27 4.96292 26.5203 4.46338 25.9286 4.46338Z"
        fill="#7BC9EB"
      />
      <path
        d="M35.2895 18.6897H34.4211V15.431C34.4211 12.4331 31.7684 10 28.5 10C25.2316 10 22.5789 12.4331 22.5789 15.431V18.6897H21.7105C21.3158 18.6897 21 18.9793 21 19.3414V30.3483C21 30.7031 21.3158 31 21.7105 31H35.2895C35.6842 31 36 30.7031 36 30.3483V19.3414C36 18.9793 35.6842 18.6897 35.2895 18.6897ZM26.5263 24.1207C26.5263 23.1214 27.4105 22.3103 28.5 22.3103C29.5895 22.3103 30.4737 23.1214 30.4737 24.1207C30.4737 24.7 30.1816 25.2286 29.6842 25.569V27.0172C29.6842 27.6183 29.1553 28.1034 28.5 28.1034C27.8447 28.1034 27.3158 27.6183 27.3158 27.0172V25.569C26.8184 25.2286 26.5263 24.7 26.5263 24.1207ZM24.9474 15.431C24.9474 13.6352 26.5421 12.1724 28.5 12.1724C30.4579 12.1724 32.0526 13.6352 32.0526 15.431V18.6897H24.9474V15.431Z"
        fill="#262F69"
      />
      <path
        d="M17.9848 0.21065C17.7054 0.000895559 17.3463 -0.0561548 17.0195 0.0572764L4.16233 4.52014C3.72483 4.67203 3.42969 5.09838 3.42969 5.57859V32.5046C3.42969 32.9848 3.72476 33.4111 4.16233 33.563L17.0195 38.0259C17.349 38.1403 17.708 38.0802 17.9848 37.8725C18.2641 37.6629 18.4297 37.3261 18.4297 36.9674V1.11572C18.4297 0.757129 18.2641 0.420331 17.9848 0.21065ZM13.0725 20.1573C12.4808 20.1573 12.0011 19.6577 12.0011 19.0416C12.0011 18.4254 12.4808 17.9259 13.0725 17.9259C13.6643 17.9259 14.144 18.4254 14.144 19.0416C14.144 19.6577 13.6643 20.1573 13.0725 20.1573Z"
        fill="#0061A8"
      />
    </g>
    <defs>
      <rect width="1em" height="1em" fill="white" />
    </defs>
  </svg>
))
