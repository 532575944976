import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.4192 14.0993L11.3203 10.0007L18.4182 5.90272C18.734 5.72042 18.8421 5.31666 18.6598 5.00092C18.4775 4.68519 18.0738 4.57692 17.758 4.7593L10.6596 8.85755V0.660171C10.6596 0.29558 10.364 0 9.99946 0C9.63487 0 9.33929 0.29558 9.33929 0.660171V8.85694L2.24193 4.7593C1.92619 4.57683 1.52243 4.68519 1.34013 5.00092C1.15784 5.31666 1.26602 5.72042 1.58176 5.90272L8.67965 10.0007L1.58079 14.0993C1.26505 14.2816 1.15687 14.6854 1.33916 15.0011C1.46143 15.2129 1.68333 15.3313 1.91149 15.3313C2.02354 15.3313 2.137 15.3028 2.24096 15.2427L9.33938 11.1445V19.3398C9.33938 19.7044 9.63496 20 9.99955 20C10.3641 20 10.6597 19.7044 10.6597 19.3398V11.1439L17.7591 15.2427C17.8631 15.3028 17.9766 15.3313 18.0886 15.3313C18.3167 15.3313 18.5386 15.2128 18.6609 15.0011C18.8432 14.6853 18.735 14.2816 18.4192 14.0993Z"
      fill="currentColor"
    />
  </svg>
))
