import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M12.5391 0.585938C12.5391 0.262344 12.2767 0 11.9531 0H8.04688C7.72328 0 7.46094 0.262344 7.46094 0.585938V1.5625H12.5391V0.585938Z"
        fill="#0061A8"
      />
      <path
        d="M19.4141 18.8281C17.9457 18.8281 19.8064 18.8281 16.25 18.8281V10.0391C16.25 9.84312 16.1521 9.6602 15.9891 9.55156L12.4734 7.20781C12.2937 7.08793 12.0625 7.07676 11.872 7.17871C11.6814 7.28066 11.5625 7.47922 11.5625 7.69531V18.8281C7.23026 18.8281 11.5459 18.8281 7.85156 18.8281V5.74219C7.85156 5.41859 7.58922 5.15625 7.26562 5.15625H3.75C3.42641 5.15625 3.16406 5.41859 3.16406 5.74219V13.5547H4.53125C5.50051 13.5547 6.28906 14.3432 6.28906 15.3125V18.8281C4.0486 18.8281 3.4502 18.8281 0.585938 18.8281C0.262344 18.8281 0 19.0905 0 19.4141C0 19.7377 0.262344 20 0.585938 20H19.4141C19.7377 20 20 19.7377 20 19.4141C20 19.0905 19.7377 18.8281 19.4141 18.8281ZM13.3203 11.9922C13.3203 11.6686 13.5827 11.4062 13.9062 11.4062C14.2298 11.4062 14.4922 11.6686 14.4922 11.9922V13.1641C14.4922 13.4877 14.2298 13.75 13.9062 13.75C13.5827 13.75 13.3203 13.4877 13.3203 13.1641V11.9922ZM13.3203 15.5078C13.3203 15.1842 13.5827 14.9219 13.9062 14.9219C14.2298 14.9219 14.4922 15.1842 14.4922 15.5078V16.6797C14.4922 17.0033 14.2298 17.2656 13.9062 17.2656C13.5827 17.2656 13.3203 17.0033 13.3203 16.6797V15.5078ZM6.09375 11.9922C6.09375 12.3158 5.83141 12.5781 5.50781 12.5781C5.18422 12.5781 4.92188 12.3158 4.92188 11.9922V10.8203C4.92188 10.4967 5.18422 10.2344 5.50781 10.2344C5.83141 10.2344 6.09375 10.4967 6.09375 10.8203V11.9922ZM6.09375 8.47656C6.09375 8.80016 5.83141 9.0625 5.50781 9.0625C5.18422 9.0625 4.92188 8.80016 4.92188 8.47656V7.30469C4.92188 6.98109 5.18422 6.71875 5.50781 6.71875C5.83141 6.71875 6.09375 6.98109 6.09375 7.30469V8.47656Z"
        fill="#7BC9EB"
      />
      <path
        d="M19.4141 18.8281H19.1797V13.1641C19.1797 12.9421 19.0543 12.7392 18.8558 12.64L17.4219 11.923V18.8281C12.8833 18.8281 12.7634 18.8281 10.3906 18.8281V7.69531C10.3906 7.04582 10.7464 6.45195 11.319 6.14547C11.5732 6.00941 11.86 5.9375 12.1484 5.9375C12.4967 5.9375 12.834 6.03961 13.1237 6.23289L13.7109 6.62437V3.32031C13.7109 2.99672 13.4486 2.73438 13.125 2.73438H6.875C6.55141 2.73438 6.28906 2.99672 6.28906 3.32031V3.98438H7.26562C8.23488 3.98438 9.02344 4.77293 9.02344 5.74219V18.8281C6 18.8281 6.28906 18.8281 5.11719 18.8281V15.3125C5.11719 14.9889 4.85484 14.7266 4.53125 14.7266H1.40625C1.08266 14.7266 0.820312 14.9889 0.820312 15.3125V18.8281H0.585938C0.262344 18.8281 0 19.0905 0 19.4141C0 19.7377 0.262344 20 0.585938 20H19.4141C19.7377 20 20 19.7377 20 19.4141C20 19.0905 19.7377 18.8281 19.4141 18.8281ZM2.38281 17.4609C2.38281 17.1373 2.64516 16.875 2.96875 16.875C3.29234 16.875 3.55469 17.1373 3.55469 17.4609V18.8281H2.38281V17.4609Z"
        fill="#23A4DE"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="1em" height="1em" fill="white" />
      </clipPath>
    </defs>
  </svg>
))
