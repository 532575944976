import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './core/app';

import * as Sentry from '@sentry/browser';

// Initialise sentry
Sentry.init({
	dsn: $configs.sentry.dsn,
	...$configs?.env?.sentry,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
