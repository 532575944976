import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.5436 11.8906C28.1144 7.46804 24.3759 4 19.8425 4C15.3093 4 11.5711 7.4681 11.1414 11.8906C8.23879 12.3034 6 14.8051 6 17.8198C6 21.1222 8.68674 23.8089 11.9891 23.8089H18.931V17.1381L17.725 18.344C17.3691 18.6999 16.792 18.6999 16.436 18.344C16.0801 17.9881 16.0801 17.411 16.436 17.0551L19.198 14.293C19.5539 13.9371 20.1311 13.9371 20.487 14.293L23.2491 17.0551C23.6051 17.411 23.6051 17.9881 23.2491 18.344C23.0711 18.522 22.8379 18.611 22.6046 18.611C22.3713 18.611 22.138 18.522 21.9601 18.344L20.7539 17.1378V23.809H27.6959C30.9983 23.809 33.6849 21.1222 33.6849 17.8199C33.6849 14.8051 31.4462 12.3034 28.5436 11.8906Z"
      fill="white"
    />
    <path
      d="M22.2288 25.6318H17.4564C17.4674 26.9386 18.5334 27.9983 19.8426 27.9983C21.1518 27.9983 22.2178 26.9386 22.2288 25.6318Z"
      fill="#23A4DE"
    />
    <path
      d="M19.8425 29.7301C17.5588 29.7301 15.6952 27.9015 15.6358 25.6318H9.112C9.15125 25.986 9.20503 26.3382 9.27588 26.6859C9.36241 27.1105 9.73574 27.4154 10.169 27.4154H11.8173C11.865 27.5382 11.9155 27.6599 11.9688 27.7804L10.8029 28.9462C10.4965 29.2526 10.4481 29.7324 10.6874 30.0937C11.1267 30.7576 11.6361 31.3809 12.2015 31.9462C12.7674 32.5121 13.3907 33.0216 14.0542 33.4603C14.4154 33.6995 14.8951 33.651 15.2015 33.3447L16.3672 32.1789C16.4877 32.2322 16.6094 32.2827 16.7323 32.3304V33.9787C16.7323 34.4119 17.0373 34.7852 17.4618 34.8718C18.2412 35.0305 19.0422 35.1111 19.8425 35.1111C20.6428 35.1111 21.4438 35.0306 22.2233 34.8718C22.6479 34.7852 22.9529 34.4119 22.9529 33.9787L22.9528 32.3304C23.0757 32.2827 23.1974 32.2322 23.3179 32.179L24.4834 33.3445C24.7897 33.6507 25.2693 33.6991 25.6306 33.4603C26.2938 33.0217 26.9173 32.5122 27.4835 31.9459C28.0494 31.38 28.5588 30.7568 28.9976 30.0934C29.2366 29.7321 29.1883 29.2524 28.8819 28.9461L27.7163 27.7805C27.7696 27.6601 27.8202 27.5383 27.8679 27.4154H29.5158C29.949 27.4154 30.3224 27.1104 30.4089 26.6858C30.4797 26.3382 30.5336 25.986 30.5728 25.6318H24.0493C23.99 27.9016 22.1264 29.7301 19.8425 29.7301Z"
      fill="#23A4DE"
    />
  </svg>
))
