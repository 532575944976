import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M20.875 18.1953C18.4324 18.1953 16.4453 20.1825 16.4453 22.625C16.4453 25.0675 18.4324 27.0547 20.875 27.0547C23.3176 27.0547 25.3047 25.0675 25.3047 22.625C25.3047 20.1825 23.3176 18.1953 20.875 18.1953ZM20.875 25.1953C19.4577 25.1953 18.3047 24.0423 18.3047 22.625C18.3047 21.2077 19.4577 20.0547 20.875 20.0547C22.2923 20.0547 23.4453 21.2077 23.4453 22.625C23.4453 24.0423 22.2923 25.1953 20.875 25.1953Z"
        fill="#23A4DE"
      />
      <path
        d="M18 11.0002L10.66 18.2503L14.8093 22.3996C14.9257 19.2315 18 15.0002 23.5 17.0002L18 11.0002Z"
        fill="white"
      />
      <path
        d="M33.81 9.09985L24.8602 18.0496C25.4447 18.5594 25.9309 19.179 26.2864 19.8761L34 27.5896V10.3747C34 9.93138 33.9332 9.50339 33.81 9.09985Z"
        fill="#23A4DE"
      />
      <path d="M19.125 29.0354L14.16 34.0003H24.0899L19.125 29.0354Z" fill="#23A4DE" />
      <path
        d="M20.875 21.6953C20.3624 21.6953 19.9453 22.1124 19.9453 22.625C19.9453 23.1376 20.3624 23.5547 20.875 23.5547C21.3876 23.5547 21.8047 23.1376 21.8047 22.625C21.8047 22.1124 21.3876 21.6953 20.875 21.6953Z"
        fill="#23A4DE"
      />
      <path
        d="M15.4636 25.374L9.5 19.4104L6 22.9104V29.6253C6 32.0377 7.96263 34.0003 10.375 34.0003H11.8399L17.9162 27.924C16.868 27.3364 16.0108 26.447 15.4636 25.374Z"
        fill="white"
      />
      <path
        d="M29.625 6H22.91L17.66 11.25L23.4999 17.0899L32.9981 7.59179C32.1949 6.62021 30.981 6 29.625 6Z"
        fill="white"
      />
      <path
        d="M26.9407 22.8506C26.8243 26.0186 24.2688 28.5741 21.1008 28.6906L26.4101 33.9998H29.625C31.9448 33.9998 33.8477 32.1848 33.9904 29.9003L26.9407 22.8506Z"
        fill="white"
      />
      <path
        d="M10.375 6C7.96263 6 6 7.96263 6 10.375V20.59L8.91987 17.6701C8.91993 17.67 8.91993 17.67 8.91998 17.67C8.92004 17.6699 8.92004 17.6699 8.92009 17.6699L20.5899 6H10.375Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="28" height="28" fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
))
