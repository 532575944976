import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.97 11.55C23.4 11.55 22.83 11.46 22.32 11.28C21.48 11.01 20.73 10.53 20.13 9.93C19.2 8.97 18.63 7.71 18.63 6.3L18.6 5.49L18.54 3.96L18.48 3H9.6C7.62 3 6 4.62 6 6.6V26.4C6 28.38 7.62 30 9.6 30H23.4C25.38 30 27 28.38 27 26.4V11.55H23.97Z"
      fill="#23A4DE"
    />
    <path
      d="M27.0005 9.9V10.05H23.9705C23.4605 10.05 22.9805 9.96 22.5305 9.78C21.1205 9.21 20.1305 7.86 20.1305 6.3V6.27L20.0405 4.2L20.0105 3.33L19.9805 3H20.1005L20.2205 3.12L27.0005 9.9Z"
      fill="#262F69"
    />
    <path
      d="M13.4406 25.5908C12.922 25.5908 12.415 25.437 11.9838 25.1488C11.5525 24.8607 11.2164 24.4511 11.018 23.972C10.8195 23.4928 10.7676 22.9656 10.8687 22.4569C10.9699 21.9482 11.2197 21.481 11.5864 21.1142C11.9531 20.7475 12.4204 20.4978 12.9291 20.3966C13.4377 20.2954 13.965 20.3473 14.4441 20.5458C14.9233 20.7443 15.3329 21.0804 15.621 21.5116C15.9091 21.9428 16.0629 22.4498 16.0629 22.9685C16.0622 23.6637 15.7856 24.3303 15.294 24.8219C14.8024 25.3135 14.1359 25.59 13.4406 25.5908ZM13.4406 22.0944C13.2678 22.0944 13.0988 22.1456 12.955 22.2417C12.8113 22.3377 12.6992 22.4743 12.6331 22.634C12.5669 22.7937 12.5496 22.9694 12.5833 23.139C12.6171 23.3086 12.7003 23.4643 12.8226 23.5866C12.9448 23.7088 13.1006 23.792 13.2701 23.8258C13.4397 23.8595 13.6154 23.8422 13.7751 23.776C13.9349 23.7099 14.0714 23.5978 14.1674 23.4541C14.2635 23.3104 14.3147 23.1414 14.3147 22.9685C14.3144 22.7367 14.2222 22.5146 14.0584 22.3507C13.8945 22.1869 13.6724 22.0947 13.4406 22.0944Z"
      fill="#F2F2F2"
    />
    <path
      d="M15.154 14.3931C13.8217 14.1306 12.4467 14.1798 11.1365 14.5369C11.0443 14.5635 10.9633 14.6195 10.9059 14.6964C10.8485 14.7733 10.8177 14.8668 10.8184 14.9628V15.8885C10.8192 15.9561 10.8361 16.0226 10.8677 16.0825C10.8992 16.1424 10.9445 16.1939 10.9998 16.2329C11.0551 16.2719 11.1189 16.2971 11.1859 16.3067C11.2529 16.3162 11.3212 16.3097 11.3852 16.2877C12.5997 15.9117 13.8938 15.8744 15.128 16.1797C16.3621 16.4851 17.4895 17.1216 18.3885 18.0206C19.2875 18.9196 19.924 20.047 20.2294 21.2811C20.5347 22.5153 20.4974 23.8094 20.1214 25.0239C20.0994 25.0879 20.0929 25.1562 20.1024 25.2232C20.1119 25.2902 20.1372 25.3539 20.1762 25.4093C20.2152 25.4646 20.2667 25.5099 20.3266 25.5414C20.3864 25.573 20.4529 25.5899 20.5206 25.5907H21.4463C21.5422 25.5914 21.6358 25.5606 21.7127 25.5032C21.7896 25.4458 21.8456 25.3648 21.8722 25.2726C22.2292 23.9624 22.2784 22.5874 22.016 21.2551C21.6782 19.5612 20.8462 18.0055 19.6249 16.7842C18.4036 15.5629 16.8478 14.7309 15.154 14.3931Z"
      fill="#F2F2F2"
    />
    <path
      d="M14.5058 17.3859C13.3437 17.1648 12.1415 17.3132 11.068 17.8104C10.9926 17.8468 10.9291 17.9039 10.8849 17.975C10.8407 18.0461 10.8176 18.1284 10.8184 18.2121V19.2011C10.8191 19.279 10.8406 19.3554 10.8807 19.4222C10.9208 19.4891 10.9781 19.544 11.0465 19.5813C11.115 19.6185 11.1922 19.6369 11.2701 19.6343C11.348 19.6317 11.4238 19.6084 11.4897 19.5667C12.2366 19.1324 13.1061 18.9574 13.9629 19.0689C14.8196 19.1804 15.6154 19.5721 16.2263 20.183C16.8372 20.7939 17.2289 21.5897 17.3404 22.4465C17.4519 23.3032 17.2769 24.1727 16.8426 24.9196C16.8009 24.9855 16.7776 25.0613 16.775 25.1392C16.7725 25.2171 16.7908 25.2943 16.8281 25.3628C16.8653 25.4312 16.9202 25.4885 16.9871 25.5286C17.0539 25.5687 17.1303 25.5902 17.2082 25.5909H18.1972C18.2809 25.5917 18.3632 25.5686 18.4343 25.5244C18.5054 25.4802 18.5625 25.4167 18.5989 25.3413C18.9608 24.5583 19.1395 23.7032 19.1214 22.8408C19.1032 21.9785 18.8887 21.1316 18.4941 20.3646C18.0994 19.5976 17.5351 18.9307 16.8441 18.4145C16.153 17.8984 15.3533 17.5466 14.5058 17.3859Z"
      fill="#F2F2F2"
    />
  </svg>
))
