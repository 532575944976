import { styled } from '@linaria/react';

// layouting elements
export const PageContainer = styled.div`
	padding: 7.5rem 1.5rem;
	background-color: #fff;
	display: block;
	width: 100%;
	min-height: 100%;
`;

// horizontal spacing
export const SmallSpacerHorizontal = styled.div`
	width: 0.5rem;
`;

export const SpacerHorizontal = styled.div`
	width: 1rem;
`;

export const LargeSpacerHorizontal = styled.div`
	width: 2rem;
`;

export const CustomSpacerHorizontal = styled.div`
	width: ${({ size }) => {
		return `${size}rem`;
	}};
`;

// vertical spacing
export const SmallSpacerVertical = styled.div`
	height: 0.5rem;
`;

export const SpacerVertical = styled.div`
	height: 1rem;
`;

export const LargeSpacerVertical = styled.div`
	height: 2rem;
`;
