import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0286 6.25C15.0914 6.25 14.249 5.83506 13.6756 5.17915L6.91693 9.0322C7.01647 9.33703 7.07031 9.66234 7.07031 10C7.07031 10.3376 7.0165 10.6628 6.91701 10.9676L13.6757 14.8207C14.2491 14.1649 15.0915 13.75 16.0286 13.75C17.7511 13.75 19.1536 15.1517 19.1536 16.875C19.1536 18.5983 17.7511 20 16.0286 20C14.3062 20 12.9036 18.5983 12.9036 16.875C12.9036 16.5371 12.9575 16.2116 13.0572 15.9065L6.29887 12.0536C5.72549 12.7098 4.88282 13.125 3.94531 13.125C2.22275 13.125 0.820312 11.7233 0.820312 10C0.820312 8.27667 2.22275 6.875 3.94531 6.875C4.88275 6.875 5.72538 7.29013 6.29875 7.94631L13.0571 4.09329C12.9575 3.78831 12.9036 3.46284 12.9036 3.125C12.9036 1.40167 14.3062 0 16.0286 0C17.7511 0 19.1536 1.40167 19.1536 3.125C19.1536 4.84833 17.7511 6.25 16.0286 6.25ZM16.0286 1.25C14.9952 1.25 14.1536 2.09076 14.1536 3.125C14.1536 4.15924 14.9952 5 16.0286 5C17.0619 5 17.9036 4.15924 17.9036 3.125C17.9036 2.09076 17.0619 1.25 16.0286 1.25ZM16.0286 15C14.9952 15 14.1536 15.8408 14.1536 16.875C14.1536 17.9092 14.9952 18.75 16.0286 18.75C17.0619 18.75 17.9036 17.9092 17.9036 16.875C17.9036 15.8408 17.0619 15 16.0286 15ZM2.07031 10C2.07031 8.96576 2.91199 8.125 3.94531 8.125C4.97864 8.125 5.82031 8.96576 5.82031 10C5.82031 11.0342 4.97864 11.875 3.94531 11.875C2.91199 11.875 2.07031 11.0342 2.07031 10Z"
      fill="currentColor"
    />
  </svg>
))
