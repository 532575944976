import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0498 9.82632L6.00274 4.44338L0.956931 9.82492C0.738006 10.0584 0.383056 10.0584 0.164132 9.82489C-0.0547151 9.59138 -0.0547118 9.21287 0.164149 8.97937L5.60635 3.17506C5.82528 2.94164 6.18021 2.94165 6.39913 3.17507L11.8413 8.97936L11.8426 8.98079C12.0577 9.21834 12.0516 9.59689 11.8288 9.82631C11.6116 10.0501 11.2671 10.0501 11.0498 9.82632Z"
      fill="currentColor"
    />
  </svg>
))
