import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M65 35.4285L38 26L11 35.4285L38 45L65 35.4285Z" fill="#23A4DE" />
    <path d="M65 35.4285L38 26V45L65 35.4285Z" fill="#0061A8" />
    <path
      d="M50.564 12.2517C49.9575 5.9453 44.676 1 38.2717 1C31.8676 1 26.5864 5.94539 25.9794 12.2517C21.8788 12.8404 18.7161 16.4077 18.7161 20.7066C18.7161 25.4157 22.5117 29.2468 27.177 29.2468C53.1412 29.2468 25.0325 29.2469 49.3663 29.2469C54.0317 29.2469 57.8272 25.4157 57.8272 20.7067C57.8272 16.4077 54.6644 12.8404 50.564 12.2517Z"
      fill="#FFB71B"
    />
    <path
      d="M45.8486 24.4714C45.7921 24.3332 45.6601 24.2429 45.5137 24.2429H41.5415L41.5415 15C39.5391 15 37.7646 15 35.0166 15L35.0166 24.243H31.0291C30.8827 24.243 30.7507 24.3332 30.6942 24.4707C30.6383 24.609 30.6688 24.768 30.7725 24.8737L38.0042 32.274C38.0724 32.3435 38.1645 32.3827 38.2609 32.3827C38.3573 32.3827 38.4494 32.3435 38.5175 32.2747L45.7703 24.8745C45.874 24.7687 45.9052 24.6097 45.8486 24.4714Z"
      fill="white"
    />
    <path
      d="M38.2717 34.4135C35.497 34.4135 33.2329 32.1083 33.1607 29.2468H25.2345C25.2822 29.6934 25.3475 30.1374 25.4336 30.5757C25.5387 31.1109 25.9923 31.4954 26.5187 31.4954H28.5213C28.5792 31.6502 28.6406 31.8036 28.7053 31.9555L27.2889 33.4253C26.9166 33.8115 26.8579 34.4164 27.1485 34.872C27.6823 35.709 28.3012 36.4947 28.9881 37.2074C29.6757 37.9209 30.4329 38.5631 31.239 39.1163C31.6779 39.4178 32.2607 39.3566 32.6329 38.9704L34.0493 37.5008C34.1957 37.568 34.3436 37.6317 34.4929 37.6918V39.7697C34.4929 40.3159 34.8634 40.7866 35.3792 40.8957C36.3262 41.0959 37.2993 41.1974 38.2717 41.1974C39.244 41.1974 40.2171 41.0959 41.1642 40.8957C41.6801 40.7866 42.0506 40.3159 42.0506 39.7697L42.0505 37.6918C42.1998 37.6317 42.3477 37.568 42.4941 37.5008L43.9101 38.9702C44.2823 39.3563 44.865 39.4174 45.304 39.1162C46.1098 38.5633 46.8672 37.9209 47.5552 37.2071C48.2427 36.4936 48.8615 35.7079 49.3947 34.8716C49.6851 34.4161 49.6264 33.8114 49.2542 33.4251L47.838 31.9557C47.9028 31.8038 47.9642 31.6503 48.0221 31.4954H50.0243C50.5507 31.4954 51.0043 31.1109 51.1094 30.5756C51.1955 30.1373 51.2609 29.6934 51.3086 29.2468H43.3828C43.3107 32.1083 41.0465 34.4135 38.2717 34.4135Z"
      fill="white"
    />
    <path d="M38 44.566L11 35V69.401L38 78V44.566Z" fill="#31448F" />
    <path d="M65 69.401V35L38 44.566V78L65 69.401Z" fill="#262F69" />
    <path d="M11.2576 35L2 46.0304L29.2457 56L38 44.4747L11.2576 35Z" fill="#7BC9EB" />
    <path d="M64.7401 35L74 46.0304L46.7566 56L38 44.4747L64.7401 35Z" fill="#23A4DE" />
  </svg>
))
