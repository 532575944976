import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '@reapit/elements';

const CHECKBOX_BG_COLOR = 'lightgrey';

const Checkbox = ({ id, checked, disabled, onChange }) => (
	<Input
		id={id}
		type="checkbox"
		style={{ backgroundColor: !checked && CHECKBOX_BG_COLOR, flexShrink: 0 }}
		checked={checked}
		disabled={disabled}
		onChange={onChange}
	/>
);

Checkbox.propTypes = {
	id: PropTypes.string.isRequired,
	checked: PropTypes.bool.isRequired,
	disabled: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
};

export default Checkbox;
