import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M2.55227 16.6724C0.965508 14.9025 0 12.5641 0 10C0 4.47715 4.47715 0 10 0C15.5229 0 20 4.47715 20 10C20 12.5641 19.0345 14.9025 17.4477 16.6724H2.55227Z"
        fill="#F2F2F2"
      />
      <path
        d="M5.893 3.89702C6.17187 2.81245 7.21511 2.69526 7.21511 2.69526C7.21511 2.69526 7.4414 0.768188 10 0.768188C12.5586 0.768188 12.7849 2.69526 12.7849 2.69526C12.7849 2.69526 13.8281 2.81245 14.107 3.89702C14.3859 4.98159 13.6653 6.52475 13.6653 6.52475L10 8.01803L6.33472 6.52475C6.33472 6.52475 5.61418 4.98163 5.893 3.89702Z"
        fill="#31448F"
      />
      <path
        d="M14.107 3.89707C14.3859 4.98164 13.6653 6.52481 13.6653 6.52481L11.8966 7.24539L11.4463 6.69922V4.30418C12.544 4.07637 12.7849 3.32031 12.7849 2.69531C12.7849 2.69531 13.8281 2.8125 14.107 3.89707Z"
        fill="#262F69"
      />
      <path
        d="M7.30172 7.17455C7.30172 7.17455 6.95418 6.32838 6.33469 6.52482C5.7152 6.72127 6.34965 8.5549 7.55859 8.67045L7.30172 7.17455Z"
        fill="#F5B555"
      />
      <path
        d="M12.6981 7.17455C12.6981 7.17455 13.0457 6.32838 13.6652 6.52482C14.2846 6.72127 13.6502 8.5549 12.4413 8.67045L12.6981 7.17455Z"
        fill="#F5B555"
      />
      <path
        d="M2.55229 16.6724C2.93151 15.564 3.35752 14.5308 3.71096 14.2188C4.57033 13.46 8.29318 12.8469 8.29318 12.8469L10 14.1667L11.7069 12.8469C11.7069 12.8469 15.4297 13.46 16.2891 14.2188C16.6425 14.5308 17.0685 15.564 17.4478 16.6724C15.6169 18.7147 12.9587 20 10 20C7.04131 20 4.38315 18.7147 2.55229 16.6724Z"
        fill="#7BC9EB"
      />
      <path
        d="M5.91326 18.6328C9.66717 18.6328 12.9374 16.564 14.6475 13.5046C15.3552 13.7125 15.9926 13.9569 16.2891 14.2187C16.6425 14.5308 17.0685 15.564 17.4478 16.6724C15.6169 18.7147 12.9587 20 10 20C8.11881 20 6.3592 19.4801 4.85627 18.5768C5.20365 18.6134 5.55619 18.6328 5.91326 18.6328Z"
        fill="#23A4DE"
      />
      <path
        d="M9.41976 15.9485L9.99992 15.0675L10.5801 15.9485L11.084 19.9418C10.7279 19.9802 10.3662 20 9.99992 20C9.63359 20 9.27195 19.9802 8.91586 19.9418L9.41976 15.9485Z"
        fill="#262F69"
      />
      <path
        d="M9.38938 16.0831H10.6107C10.6996 16.0831 10.7773 16.023 10.7997 15.9369L11.2891 14.0519H8.71094L9.20031 15.9369C9.22266 16.023 9.30039 16.0831 9.38938 16.0831Z"
        fill="#31448F"
      />
      <path
        d="M10.0184 15.9369L10.5078 14.0519H11.2891L10.7997 15.9369C10.7773 16.023 10.6996 16.0831 10.6106 16.0831H9.82937C9.91836 16.0831 9.99609 16.023 10.0184 15.9369Z"
        fill="#262F69"
      />
      <path
        d="M11.7069 8.46924V12.847C11.7069 13.5124 10.9427 14.0519 10.0001 14.0519C9.05741 14.0519 8.29323 13.5124 8.29323 12.847V8.46924H11.7069Z"
        fill="#F5B555"
      />
      <path
        d="M11.7069 8.46924V12.847C9.87363 12.847 8.8457 11.44 8.8457 11.44L8.29323 8.46928H11.7069V8.46924Z"
        fill="#F5B555"
      />
      <path
        d="M11.7068 12.3047L9.99997 14.0519C9.99997 14.0519 10.664 15.2528 11.0937 15.2528C11.5234 15.2528 12.2659 12.9477 12.2659 12.9477L11.7068 12.3047Z"
        fill="#EBEAEC"
      />
      <path
        d="M8.29316 12.3047L10 14.0519C10 14.0519 9.33594 15.2528 8.90625 15.2528C8.47656 15.2528 7.7341 12.9477 7.7341 12.9477L8.29316 12.3047Z"
        fill="#EBEAEC"
      />
      <path
        d="M4.40709 17.0409L4.85693 18.5772C4.56979 18.4047 4.29182 18.2184 4.02436 18.0188V17.0958C4.02436 16.87 4.34365 16.8242 4.40709 17.0409Z"
        fill="#0061A8"
      />
      <path
        d="M15.9757 17.0957V18.0187C15.7082 18.2184 15.4303 18.4046 15.1431 18.5772L15.593 17.0408C15.6564 16.8242 15.9757 16.87 15.9757 17.0957Z"
        fill="#0061A8"
      />
      <path
        d="M7.21512 6.1068C7.38598 5.69012 7.39832 4.41356 7.85156 4.2318C8.3048 4.05004 8.68488 4.5443 10 4.5443C11.3151 4.5443 11.6952 4.05004 12.1484 4.2318C12.6017 4.41356 12.614 5.69012 12.7849 6.1068C12.9441 6.49501 13.1441 6.57411 13.1707 6.58313C13.1726 6.64383 13.1738 6.70489 13.1738 6.76649C13.1738 9.1036 11.7529 11.8446 10 11.8446C8.24719 11.8446 6.82625 9.1036 6.82625 6.76649C6.82625 6.70485 6.82746 6.64379 6.82941 6.58313C6.85598 6.57415 7.05594 6.49501 7.21512 6.1068Z"
        fill="#FFE5C7"
      />
      <path
        d="M12.0824 6.76648C12.0824 5.81304 11.8454 4.96952 11.4463 4.30421C11.7353 4.21132 11.9338 4.1457 12.1484 4.23179C12.6017 4.41355 12.614 5.69011 12.7849 6.10679C12.9441 6.49499 13.1441 6.57409 13.1707 6.58312C13.1726 6.64382 13.1738 6.70488 13.1738 6.76648C13.1738 9.10359 11.7529 11.8446 10 11.8446C9.81395 11.8446 9.63164 11.8134 9.45434 11.7553C10.9471 11.2668 12.0824 8.85546 12.0824 6.76648Z"
        fill="#F5B555"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="1em" height="1em" fill="white" transform="matrix(-1 0 0 1 20 0)" />
      </clipPath>
    </defs>
  </svg>
))
