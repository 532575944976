import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="33.0004" cy="35.3998" rx="28.6" ry="28.6" fill="#F2F2F2" />
    <mask id="mask0_8061_91447" maskUnits="userSpaceOnUse" x="0" y="0" width="48" height="32">
      <path
        d="M36.9396 9.72518C33.6494 1.67142 24.582 -2.13661 16.687 1.21979C11.2888 3.5147 7.61828 8.71598 7.19768 14.6665C2.63562 15.3101 -0.551272 19.6046 0.0796292 24.2585C0.650556 28.4696 4.18121 31.6042 8.34847 31.6H36.9395C42.8609 31.6 47.6611 26.7031 47.6611 20.6626C47.6611 14.6222 42.8611 9.72518 36.9396 9.72518Z"
        fill="#7BC9EB"
      />
    </mask>
    <g mask="url(#mask0_8061_91447)">
      <rect x="-6.79102" y="-5.09277" width="56.9322" height="44.005" fill="#0061A8" />
      <path d="M36.5662 48.9276L25.9006 22.8573L0 12.0215V48.9276H36.5662Z" fill="#262F69" />
      <path d="M51.8397 48.9271V34.5697V33.6409L25.875 22.8696L36.5664 48.9271H51.8397Z" fill="#0061A8" />
      <path d="M0 12.0212L25.8749 22.8698L15.1964 -3.14893L0 12.0212Z" fill="#7BC9EB" />
      <path
        d="M51.8397 12.0213L25.952 -13.9717L21.9347 -9.94697L15.1836 -3.13591L25.875 22.8699L51.8397 33.6412V12.0213Z"
        fill="#23A4DE"
      />
    </g>
    <path
      d="M20 45.3509V20.4873C20.0089 20.0504 20.3625 19.6978 20.8006 19.689H58.6026C59.0407 19.6978 59.3943 20.0504 59.4031 20.4873V45.3536L20 45.3509Z"
      fill="#262F69"
    />
    <path
      d="M59.4031 45.3506V48.5438C59.3943 48.9807 59.0406 49.3333 58.6026 49.3421H20.8006C20.3618 49.3333 20.0089 48.9807 20 48.5438V45.3506H59.4031Z"
      fill="#31448F"
    />
    <path d="M48.1372 54.9863H31.2676V55.7846H48.1372V54.9863Z" fill="#31448F" />
    <path d="M45.3054 54.9902H34.0996L34.9002 49.3423H44.5048L45.3054 54.9902Z" fill="#262F69" />
    <path d="M57.8004 21.2856H21.6016V43.7537H57.8004V21.2856Z" fill="#7BC9EB" />
    <path
      d="M61.5755 55.839H51.0687C49.9493 55.8363 49.0418 54.9313 49.0391 53.8151V31.7173C49.0418 30.601 49.9493 29.6961 51.0687 29.6934H61.5755C62.6949 29.6961 63.6024 30.601 63.6051 31.7173V53.8158C63.6017 54.932 62.6949 55.8363 61.5755 55.839Z"
      fill="#262F69"
    />
    <path
      d="M61.5774 55.8389H51.0707C49.9513 55.8362 49.0437 54.9312 49.041 53.815V51.2515C55.7336 51.2515 57.6514 51.2515 63.6071 51.2515V53.8156C63.6037 54.9319 62.6968 55.8362 61.5774 55.8389Z"
      fill="#31448F"
    />
    <path d="M62.6428 31.7583H49.998V52.375H62.6428V31.7583Z" fill="#F2F2F2" />
    <path
      d="M57.7193 30.9413H55.135C55.0185 30.9406 54.9245 30.8468 54.9238 30.7306C54.9245 30.6145 55.0185 30.5207 55.135 30.52H57.7241C57.8406 30.5207 57.9346 30.6145 57.9353 30.7306C57.9346 30.8475 57.8399 30.9419 57.7227 30.9413C57.7213 30.9413 57.7207 30.9413 57.7193 30.9413Z"
      fill="#F2F2F2"
    />
    <path
      d="M56.3218 55.1028C55.7699 55.1028 55.3223 54.6564 55.3223 54.1061C55.3223 53.5558 55.7699 53.1094 56.3218 53.1094C56.8737 53.1094 57.3213 53.5558 57.3213 54.1061C57.3206 54.6557 56.8737 55.1014 56.3218 55.1028Z"
      fill="#F2F2F2"
    />
    <ellipse cx="22.7416" cy="40.8471" rx="10.5483" ry="10.5483" fill="white" />
    <path
      d="M22.4008 27.5874C14.8685 27.5874 8.80078 33.6551 8.80078 41.1874C8.80078 48.7197 14.8685 54.7874 22.4008 54.7874C29.9331 54.7874 36.0008 48.7197 36.0008 41.1874C36.0008 33.6551 29.9331 27.5874 22.4008 27.5874V27.5874ZM28.6179 42.1738L19.5612 47.4644C19.3819 47.5839 19.1726 47.6139 18.9933 47.6139C18.8139 47.6139 18.6046 47.5841 18.4253 47.4644C18.0965 47.2851 17.8573 46.8964 17.8573 46.478V35.8969C17.8573 35.4785 18.0965 35.0898 18.4253 34.9105C18.7541 34.7312 19.2324 34.7312 19.5612 34.9105L28.6478 40.2011C28.9766 40.3804 29.1857 40.7691 29.1857 41.1875C29.1859 41.606 28.9467 41.9946 28.6179 42.1739V42.1738Z"
      fill="#FFB71B"
    />
  </svg>
))
