import React from 'react';
import PropTypes from 'prop-types';

import { Button, FlexContainer } from '@reapit/elements';

import {
	LargeSpacerVertical,
	PageContainer,
} from 'components/ui/sized-components';

import { BodyTextNoMargin } from 'components/ui/typography';

import logo from 'assets/images/brand/tf-logo.svg';

import { TFLogo, VerticalDivider } from './__styles__';

const Message = ({ status }) => {
	const getErrorMessageFromStatus = (status) => {
		switch (status) {
			case 403:
				return 'Forbidden';
		}
	};

	if (!status) {
		return (
			<BodyTextNoMargin hasBoldText>
				Please try reloading the window.
			</BodyTextNoMargin>
		);
	}

	return (
		<FlexContainer isFlexAlignCenter>
			<BodyTextNoMargin hasBoldText>Error Code: {status}</BodyTextNoMargin>
			<VerticalDivider />
			<BodyTextNoMargin hasBoldText>
				{getErrorMessageFromStatus(status)}
			</BodyTextNoMargin>
		</FlexContainer>
	);
};

Message.propTypes = {
	status: PropTypes.number.isRequired,
};

const ErrorPage = ({ status }) => {
	return (
		<PageContainer>
			<FlexContainer isFlexColumn isFlexAlignCenter>
				<TFLogo src={logo} alt="thirdfort logo" />
				<LargeSpacerVertical />
				<BodyTextNoMargin>
					Sorry.. Something appears to have gone wrong.
				</BodyTextNoMargin>
				<LargeSpacerVertical />
				<Message status={status} />
				<LargeSpacerVertical />
				<Button intent="low" onClick={() => window.location.replace('/')}>
					Home
				</Button>
			</FlexContainer>
		</PageContainer>
	);
};

ErrorPage.propTypes = {
	status: PropTypes.number,
};

export default ErrorPage;
