import { ReapitConnectBrowserSession } from '@reapit/connect-session';

const connectClientId = $configs?.env?.reapit?.connectClientId;
const connectOAuthUrl = $configs?.reapit?.connectOAuthUrl;
const connectUserPoolId = $configs?.reapit?.connectUserPoolId;

export const reapitConnectBrowserSession = new ReapitConnectBrowserSession({
	connectClientId,
	connectOAuthUrl,
	connectUserPoolId,
});
