import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M65 35.4285L38 26L11 35.4285L38 45L65 35.4285Z" fill="#23A4DE" />
    <path d="M65 35.4285L38 26V45L65 35.4285Z" fill="#0061A8" />
    <path
      d="M38.942 20.9344C39.083 20.0421 39.1709 19.0638 39.1931 17.9668C39.1846 13.2629 36.9328 11.5984 36.4996 10.8181C37.1377 7.25402 36.3939 5.63283 36.048 5.07785C34.7721 4.62498 31.6089 6.24672 29.8806 7.38836C27.0628 6.56351 21.1079 6.64402 18.8747 7.60186C14.7543 4.6515 12.5751 5.10136 12.5751 5.10136C12.5751 5.10136 11.1662 7.62774 12.2026 11.3237C10.8469 13.0513 9.83647 14.2727 9.83647 17.5126C9.83647 18.2885 9.88466 19.0217 9.96644 19.7271C11.134 25.8659 15.9982 28.5142 20.7762 28.9709C20.0572 29.5172 19.194 30.5511 19.0746 31.7491C18.1711 32.3333 16.3539 32.5264 14.9407 32.0821C12.9606 31.4564 12.2026 27.5354 9.23742 28.0947C8.59582 28.2148 8.72333 28.6379 9.27895 28.9987C10.1823 29.5847 11.0331 30.313 11.6889 31.8717C12.1926 33.0683 13.252 35.2049 16.6014 35.2049C17.9311 35.2049 18.8624 35.0477 18.8624 35.0477C18.8624 35.0477 18.8877 38.097 18.8877 39.2863C18.8877 40.6549 17.0406 41.0392 17.0406 41.6958C17.0406 41.9569 17.652 41.9817 18.1433 41.9817C19.1142 41.9817 21.1344 41.173 21.1344 39.7504C21.1344 38.6201 21.1524 34.8215 21.1524 34.1562C21.1524 32.7027 21.9313 32.2399 21.9313 32.2399C21.9313 32.2399 22.0267 39.999 21.7439 41.0393C21.4123 42.2626 20.8115 42.0888 20.8115 42.6332C20.8115 43.4457 23.2401 42.8319 24.0457 41.0535C24.6688 39.6645 24.3898 32.0555 24.3898 32.0555L25.0395 32.0413C25.0395 32.0413 25.0469 35.5262 25.0245 37.1182C25.0011 38.7672 24.831 40.8518 25.8104 41.8357C26.4533 42.4828 28.4228 43.6182 28.4228 42.5806C28.4228 41.9793 27.0436 41.4823 27.0436 39.8531V32.348C27.8815 32.348 28.0546 34.8149 28.0546 34.8149L28.3566 39.3982C28.3566 39.3982 28.1561 41.0707 30.1634 41.7687C30.872 42.0169 32.3879 42.0844 32.459 41.6678C32.5302 41.2502 30.6336 40.6326 30.6156 39.3381C30.6058 38.5497 30.6509 38.0883 30.6509 34.659C30.6509 31.2316 30.1905 29.9638 28.5861 28.9534C33.2388 28.4759 38.0714 26.398 38.942 20.9344Z"
      fill="#262F69"
    />
    <path
      d="M51.564 29.2517C50.9575 22.9453 45.676 18 39.2717 18C32.8676 18 27.5864 22.9454 26.9794 29.2517C22.8788 29.8404 19.7161 33.4077 19.7161 37.7066C19.7161 42.4157 23.5117 46.2468 28.177 46.2468C54.1412 46.2468 26.0325 46.2469 50.3663 46.2469C55.0317 46.2469 58.8272 42.4157 58.8272 37.7067C58.8272 33.4077 55.6644 29.8404 51.564 29.2517Z"
      fill="#FFB71B"
    />
    <path
      d="M31.6945 36.7756C31.751 36.9138 31.883 37.004 32.0294 37.004H36.0016L36.0016 46.247C38.0041 46.247 39.7786 46.247 42.5266 46.247L42.5266 37.004H46.514C46.6604 37.004 46.7924 36.9138 46.8489 36.7762C46.9048 36.638 46.8743 36.479 46.7706 36.3732L39.5389 28.973C39.4707 28.9035 39.3787 28.8643 39.2822 28.8643C39.1858 28.8643 39.0937 28.9035 39.0256 28.9722L31.7728 36.3725C31.6691 36.4783 31.638 36.6373 31.6945 36.7756Z"
      fill="white"
    />
    <path
      d="M42.5308 46.2468H36.0123C36.0274 48.0466 37.4834 49.5061 39.2715 49.5061C41.0598 49.5061 42.5158 48.0466 42.5308 46.2468Z"
      fill="white"
    />
    <path d="M38 44.566L11 35V69.401L38 78V44.566Z" fill="#31448F" />
    <path d="M65 69.401V35L38 44.566V78L65 69.401Z" fill="#262F69" />
    <path d="M11.2576 35L2 46.0304L29.2457 56L38 44.4747L11.2576 35Z" fill="#7BC9EB" />
    <path d="M64.7401 35L74 46.0304L46.7566 56L38 44.4747L64.7401 35Z" fill="#23A4DE" />
  </svg>
))
