import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 79 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_7486_88784" maskUnits="userSpaceOnUse" x="5" y="7" width="72" height="72">
      <circle cx="41.0144" cy="42.6966" r="35.6309" transform="rotate(-2.75697 41.0144 42.6966)" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0_7486_88784)">
      <rect
        x="-2.50684"
        y="3.1748"
        width="88.3349"
        height="80.9118"
        transform="rotate(-2.75697 -2.50684 3.1748)"
        fill="white"
      />
      <path
        d="M70.3574 81.0422C70.3574 81.0422 61.8307 81.4528 55.2964 76.9368C45.476 74.0857 48.9105 53.9161 49.6294 53.1428C50.3481 52.3692 52.0831 52.8454 55.9758 54.4604C61.3294 56.6814 62.6238 61.2989 64.5359 64.6455C67.2517 69.3986 69.9646 72.8862 69.9646 72.8862C71.4065 72.8168 69.0303 77.493 70.3574 81.0422Z"
        fill="#23A4DE"
      />
      <path
        d="M33.2762 57.9805C32.9622 60.1558 25.8632 68.9556 25.404 80.8675C34.4689 89.199 47.9697 90.0308 57.9981 83.7437C56.6795 73.9172 52.516 58.8145 50.3053 54.4072C47.2325 48.2814 41.1839 47.1559 41.1839 47.1559C41.1839 47.1559 33.6878 55.1303 33.2762 57.9805Z"
        fill="#23A4DE"
      />
      <ellipse
        cx="42.9394"
        cy="53.038"
        rx="6.69342"
        ry="5.29479"
        transform="rotate(-2.75697 42.9394 53.038)"
        fill="#0076AB"
      />
      <path
        d="M23.7949 94.0608C23.7949 94.0608 22.9216 79.8757 29.8486 74.1749C36.7756 68.4745 36.7222 54.2479 36.1497 53.451C35.5773 52.6537 28.8097 53.9496 25.717 56.8097C19.9565 62.137 15.8876 70.3815 13.7234 77.8244C10.4348 76.0227 24.7845 90.6383 23.7949 94.0608Z"
        fill="#23A4DE"
      />
      <path
        d="M25.3927 78.941C26.3442 75.5509 30.0652 67.94 30.0652 67.94C30.0652 67.94 27.5278 76.9802 29.6135 81.7104C28.1128 81.4111 26.5585 79.9996 25.3927 78.941Z"
        fill="#0076AB"
      />
      <path
        d="M60.7921 77.5352C59.5073 74.2212 55.0303 66.9349 55.0303 66.9349C55.0303 66.9349 58.4567 75.7722 56.8198 80.7273C58.2987 80.2809 59.7235 78.712 60.7921 77.5352Z"
        fill="#0076AB"
      />
    </g>
    <path
      d="M30.7224 35.1932C30.8665 38.1844 28.72 40.7183 25.9283 40.8528C23.1363 40.9872 20.7563 38.6712 20.6122 35.68C20.4682 32.6885 22.6147 30.1546 25.4067 30.0201C28.1984 29.8857 30.5784 32.2017 30.7224 35.1932Z"
      fill="#FFE5C7"
    />
    <path
      d="M64.4129 33.2671C64.5569 36.2586 62.4104 38.7925 59.6188 38.927C56.8267 39.0614 54.4467 36.7454 54.3026 33.7539C54.1586 30.7627 56.3051 28.2284 59.0971 28.094C61.8888 27.9596 64.2688 30.2759 64.4129 33.2671Z"
      fill="#FFE5C7"
    />
    <path
      d="M24.6042 32.8781C24.6042 32.8781 26.4272 56.2812 43.2951 55.4689C60.1631 54.6567 61.3687 36.3279 60.6508 29.6805C59.9332 23.033 56.4599 12.8643 56.4599 12.8643L42.2006 12.0892L29.8192 15.1914L24.1354 19.0149L23.9441 23.3044L24.6042 32.8781Z"
      fill="#FFE5C7"
    />
    <path
      d="M32.717 25.6259C32.6896 26.1091 33.2962 26.0049 33.7978 25.922C34.8411 25.7497 34.5297 24.9804 36.728 24.9601C37.5256 24.9527 38.8279 26.2169 39.1959 25.5339C40.2414 23.5932 32.9055 22.3065 32.717 25.6259Z"
      fill="#262F69"
    />
    <path
      d="M55.3808 25.0609C54.8804 24.1237 53.2226 23.7212 52.0745 23.8482C51.1133 23.9547 49.5039 24.4366 49.5731 25.4207C49.5943 25.722 50.0455 25.6677 50.3964 25.5874C50.9416 25.4626 51.1613 25.2402 51.6884 25.1921C52.2009 25.1451 52.7287 25.2329 53.1794 25.4368C53.7252 25.6842 54.1131 26.0653 54.8017 25.8719C55.4199 25.6981 55.6315 25.5305 55.3808 25.0609Z"
      fill="#262F69"
    />
    <path
      d="M53.7598 28.1333C53.6767 28.086 53.5886 28.0447 53.4955 28.0106C50.4199 26.8814 49.3762 31.7553 51.6212 32.5464C54.8624 33.6884 55.6849 29.2332 53.7598 28.1333Z"
      fill="#262F69"
    />
    <path
      d="M53.3779 28.6731C53.0369 28.3496 52.3879 28.6373 52.4806 29.0775C52.5277 29.302 52.5727 29.4815 52.78 29.6344C53.0962 29.8681 53.7297 29.5944 53.704 29.2219C53.6871 28.9794 53.5556 28.8417 53.3779 28.6731Z"
      fill="white"
    />
    <path
      d="M36.058 43.5956C36.058 43.5956 43.6302 48.1135 49.4022 44.7856C46.2714 49.3031 37.817 48.611 36.058 43.5956Z"
      fill="#262F69"
    />
    <path
      d="M26.7106 7.6701C30.9503 1.53414 39.8927 0.103319 47.9804 1.61918C52.5585 2.47723 67.5929 7.66878 63.2938 16.4786C63.5443 19.1175 63.6857 21.7793 63.6882 24.4301C63.6895 26.1153 63.7213 27.9269 63.3048 29.5738C62.7828 31.6377 60.7663 31.0265 60.0744 29.3956C58.8181 26.4341 60.0073 23.1506 57.9824 20.2383C53.3814 13.6217 49.9386 17.1144 42.13 18.1329C36.0189 18.93 29.9078 15.7577 29.9078 15.7577C29.9078 15.7577 25.5288 22.1067 26.192 32.069C26.4009 35.2119 20.9426 34.2216 21.42 27.3008C21.897 20.38 20.2442 8.2906 26.7106 7.6701Z"
      fill="#262F69"
    />
    <path
      d="M20.5116 43.1799C19.8492 43.0716 19.2068 42.9195 18.602 42.7278C14.1686 41.3225 11.6143 37.7649 10.7136 33.1435C10.6281 32.7057 9.99455 32.7167 9.92438 33.1572C9.06569 38.5607 5.96136 42.4254 0.51012 43.5359C0.0765863 43.6242 0.0876459 44.251 0.524112 44.3239C5.96009 45.2316 9.25276 48.9775 10.2955 54.3047C10.3611 54.6403 10.7167 54.6358 10.6963 54.6284C10.8906 54.6248 11.0544 54.482 11.0847 54.2901C11.5199 51.5259 12.8118 47.7583 16.4944 45.5075C17.6709 44.7891 19.0275 44.2713 20.5262 43.9683C20.7159 43.93 20.8511 43.7616 20.8475 43.5681C20.8439 43.3745 20.7025 43.2112 20.5116 43.1799Z"
      fill="#FFB71B"
    />
    <path
      d="M18.1247 12.3463C17.8906 12.308 17.6635 12.2542 17.4497 12.1864C15.8826 11.6897 14.9796 10.4321 14.6612 8.79842C14.631 8.64365 14.407 8.64754 14.3822 8.80329C14.0787 10.7134 12.9813 12.0795 11.0543 12.4721C10.9011 12.5033 10.905 12.7249 11.0593 12.7506C12.9809 13.0715 14.1448 14.3956 14.5134 16.2788C14.5366 16.3974 14.6623 16.3958 14.6551 16.3932C14.7238 16.3919 14.7817 16.3415 14.7924 16.2736C14.9463 15.2965 15.4029 13.9647 16.7047 13.169C17.1206 12.9151 17.6001 12.732 18.1299 12.6249C18.197 12.6114 18.2448 12.5519 18.2435 12.4835C18.2422 12.415 18.1922 12.3573 18.1247 12.3463Z"
      fill="#FFB71B"
    />
    <path
      d="M72.5496 49.7267C72.2025 49.67 71.8658 49.5902 71.5488 49.4898C69.2254 48.7533 67.8867 46.8888 67.4146 44.4668C67.3698 44.2373 67.0378 44.2431 67.001 44.474C66.551 47.3058 64.9241 49.3313 62.0672 49.9133C61.8399 49.9596 61.8457 50.2881 62.0745 50.3263C64.9234 50.802 66.649 52.7651 67.1955 55.5571C67.2299 55.733 67.4163 55.7306 67.4056 55.7267C67.5074 55.7248 67.5933 55.65 67.6091 55.5494C67.8372 54.1008 68.5143 52.1262 70.4443 50.9466C71.0609 50.5701 71.7718 50.2987 72.5573 50.1399C72.6567 50.1199 72.7276 50.0316 72.7257 49.9302C72.7238 49.8287 72.6497 49.7431 72.5496 49.7267Z"
      fill="#FFB71B"
    />
    <path
      d="M36.7832 28.1338C36.7001 28.0865 36.6121 28.0452 36.5189 28.011C33.4433 26.8819 32.3996 31.7558 34.6447 32.5469C37.8859 33.6889 38.7083 29.2337 36.7832 28.1338Z"
      fill="#262F69"
    />
    <path
      d="M36.4031 28.6735C36.062 28.3501 35.4131 28.6378 35.5057 29.078C35.5533 29.3023 35.5978 29.482 35.8052 29.6349C36.1214 29.8686 36.755 29.5952 36.7292 29.2224C36.7123 28.9799 36.5808 28.8421 36.4031 28.6735Z"
      fill="white"
    />
  </svg>
))
