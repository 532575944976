import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M33.8424 15.4924L29.4674 9.3675C29.2924 9.14 29.0299 9 28.7499 9H11.2501C10.9701 9 10.7076 9.14 10.5326 9.3675L6.15764 15.4924C6.10514 15.6499 6.05264 15.8074 6.00014 15.9649C5.98264 17.9074 7.55763 19.4999 9.50011 19.4999C10.5501 19.4999 11.4776 19.0449 12.1251 18.3099C12.7726 19.0449 13.7001 19.4999 14.7501 19.4999C15.8 19.4999 16.7275 19.0449 17.375 18.3099C18.0225 19.0449 18.95 19.4999 20 19.4999C21.05 19.4999 21.9775 19.0449 22.625 18.3099C23.2725 19.0449 24.2 19.4999 25.2499 19.4999C26.2999 19.4999 27.2274 19.0449 27.8749 18.3099C28.5224 19.0449 29.4499 19.4999 30.4999 19.4999C32.4424 19.4999 34.0174 17.9074 33.9999 15.9649C33.9474 15.8074 33.8949 15.6499 33.8424 15.4924Z"
      fill="white"
    />
    <path
      d="M30.4999 21.25C29.5706 21.25 28.6659 21.0015 27.8749 20.543C26.2929 21.4582 24.2069 21.4582 22.625 20.543C21.043 21.4582 18.9553 21.4582 17.375 20.543C15.7948 21.4582 13.7053 21.4582 12.1251 20.543C10.8108 21.306 9.18336 21.404 7.75012 20.9087V29.9999C7.75012 30.9658 8.53411 31.7498 9.5001 31.7498H23.5V22.9999H28.7499V31.7498H30.4999C31.4659 31.7498 32.2499 30.9658 32.2499 29.9999V20.9297C31.6934 21.1275 31.1071 21.25 30.4999 21.25ZM18.25 28.2499H11.2501V22.9999H18.25V28.2499Z"
      fill="#23A4DE"
    />
  </svg>
))
