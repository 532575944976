import { styled } from '@linaria/react';

export const InputContainer = styled.div`
	margin: 2rem 0;
`;

export const CheckBoxContentContainer = styled.div`
	margin-left: 1rem;
	max-width: 400px;
`;

export const RegisterFormContainer = styled.div`
	width: 80%;
	margin-top: 6rem;
`;

export const RegisterContainer = styled.div`
	min-width: 100vw;
	min-height: 180vh;
	display: flex;
	justify-content: center;
	flex-direction: row;
	background-color: #fff;
`;

export const RegisterImageContainer = styled.div`
	width: 60%;
	height: 100vh;

	@media screen and (max-width: 1350px) {
		display: none;
	}
`;

export const BallLockContainer = styled.img`
	position: absolute;
	height: 80%;
	z-index: 1;
	animation: shimmy 3s infinite;
	animation-direction: alternate;

	@keyframes shimmy {
		0% {
			transform: translate(0, 0);
		}
		100% {
			transform: translate(0, 20px);
		}
	}
`;

export const Link = styled.span`
	color: #0061a8;
	cursor: pointer;
`;
