import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.5686 10.8008C28.2875 9.67542 27.6592 8.65479 26.769 7.89029C25.749 7.01445 24.4479 6.53215 23.105 6.53215C22.0976 6.53215 21.1326 6.79194 20.2809 7.28828C19.3789 6.4634 18.1962 6 16.9535 6C14.288 6 12.1094 8.12485 12.0224 10.7695C11.1009 11.0271 10.2666 11.5514 9.62878 12.2827C8.84534 13.1811 8.41382 14.3317 8.41382 15.5223C8.41382 18.2428 10.6271 20.456 13.3475 20.456H27.1353C29.8558 20.456 32.069 18.2428 32.069 15.5223C32.069 13.3411 30.6223 11.4249 28.5686 10.8008Z"
      fill="white"
    />
    <path
      d="M14.8047 23.3188V26.3285H11.9009C11.5429 25.0676 10.3819 24.1411 9.00781 24.1411C7.3493 24.1411 6 25.4904 6 27.1489C6 28.8074 7.3493 30.1567 9.00781 30.1567C10.382 30.1567 11.543 29.2301 11.9011 27.9691H15.625C16.0778 27.9691 16.4453 27.6021 16.4453 27.1488V23.3188H14.8047Z"
      fill="#23A4DE"
    />
    <path
      d="M20.8203 28.0841V21.8708H19.1797V28.0841C17.9188 28.4422 16.9922 29.6032 16.9922 30.9773C16.9922 32.6358 18.3415 33.9851 20 33.9851C21.6585 33.9851 23.0078 32.6358 23.0078 30.9773C23.0078 29.6032 22.0812 28.4422 20.8203 28.0841Z"
      fill="#23A4DE"
    />
    <path
      d="M30.9922 24.1411C29.6181 24.1411 28.4571 25.0676 28.0991 26.3285H25.1953V23.3188H23.5547V27.1488C23.5547 27.6021 23.9222 27.9691 24.375 27.9691H28.0989C28.457 29.2301 29.618 30.1567 30.9922 30.1567C32.6507 30.1567 34 28.8074 34 27.1489C34 25.4904 32.6507 24.1411 30.9922 24.1411Z"
      fill="#23A4DE"
    />
  </svg>
))
