import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M70.0474 11C66.799 11 64.1436 13.6554 64.1436 16.9038C64.1436 20.1522 66.799 22.8076 70.0474 22.8076C73.2958 22.8076 75.9512 20.1522 75.9512 16.9038C75.9512 13.6554 73.2958 11 70.0474 11Z"
      fill="#FFB71B"
    />
    <path
      d="M80.1795 27.5255C80.0248 27.1388 79.8186 26.7779 79.5865 26.4427C78.4006 24.6896 76.5702 23.5295 74.5077 23.2459C74.25 23.2201 73.9664 23.2717 73.7601 23.4264C72.6773 24.2256 71.3883 24.638 70.0477 24.638C68.707 24.638 67.418 24.2256 66.3352 23.4264C66.1289 23.2717 65.8453 23.1943 65.5876 23.2459C63.5251 23.5295 61.6689 24.6896 60.5088 26.4427C60.2768 26.7779 60.0705 27.1646 59.9158 27.5255C59.8385 27.6802 59.8643 27.8607 59.9416 28.0154C60.1479 28.3763 60.4056 28.7373 60.6377 29.0466C60.9986 29.5365 61.3853 29.9747 61.8236 30.3872C62.1845 30.7481 62.597 31.0833 63.0095 31.4184C65.0462 32.9395 67.4954 33.7387 70.0219 33.7387C72.5484 33.7387 74.9976 32.9395 77.0343 31.4184C77.4467 31.1091 77.8592 30.7481 78.2202 30.3872C78.6327 29.9747 79.0452 29.5364 79.4061 29.0466C79.6639 28.7115 79.896 28.3763 80.1022 28.0154C80.231 27.8607 80.2568 27.6802 80.1795 27.5255Z"
      fill="#FFB71B"
    />
    <path
      d="M29.2905 96.1567C26.0421 96.1567 23.3867 98.8121 23.3867 102.061C23.3867 105.309 26.0421 107.964 29.2905 107.964C32.5389 107.964 35.1943 105.309 35.1943 102.061C35.1943 98.8121 32.5389 96.1567 29.2905 96.1567Z"
      fill="#FFB71B"
    />
    <path
      d="M39.4197 112.682C39.265 112.296 39.0588 111.935 38.8268 111.599C37.6408 109.846 35.8104 108.686 33.748 108.403C33.4902 108.377 33.2066 108.428 33.0003 108.583C31.9175 109.382 30.6285 109.795 29.2879 109.795C27.9473 109.795 26.6582 109.382 25.5754 108.583C25.3692 108.428 25.0856 108.351 24.8278 108.403C22.7653 108.686 20.9091 109.846 19.749 111.599C19.517 111.935 19.3107 112.321 19.1561 112.682C19.0787 112.837 19.1045 113.017 19.1818 113.172C19.3881 113.533 19.6459 113.894 19.8779 114.203C20.2388 114.693 20.6255 115.131 21.0638 115.544C21.4248 115.905 21.8372 116.24 22.2498 116.575C24.2864 118.096 26.7356 118.895 29.2621 118.895C31.7886 118.895 34.2378 118.096 36.2745 116.575C36.687 116.266 37.0995 115.905 37.4604 115.544C37.8729 115.131 38.2854 114.693 38.6464 114.203C38.9041 113.868 39.1362 113.533 39.3424 113.172C39.4713 113.017 39.497 112.837 39.4197 112.682Z"
      fill="#FFB71B"
    />
    <path
      d="M87.7438 25.1227C86.2979 33.6146 78.9039 40.0795 70 40.0795C61.0961 40.0795 53.7021 33.6146 52.2562 25.1227C31.7114 32.4202 17 52.0319 17 75.0795C17 80.882 17.9325 86.4667 19.6557 91.6919C22.3795 90.0343 25.5783 89.0795 29 89.0795C38.9411 89.0795 47 97.1384 47 107.08C47 112.084 44.9574 116.612 41.6605 119.875C49.8569 125.071 59.5771 128.08 70 128.08C80.6841 128.08 90.6298 124.918 98.9527 119.48C95.8835 116.252 94 111.886 94 107.08C94 97.1384 102.059 89.0795 112 89.0795C115.075 89.0795 117.969 89.8504 120.501 91.2094C122.124 86.1234 123 80.7038 123 75.0795C123 52.0319 108.289 32.4202 87.7438 25.1227Z"
      fill="#262F69"
    />
    <path
      d="M87.7438 25.1228C86.2978 33.6146 78.9039 40.0795 70 40.0795C61.096 40.0795 53.7021 33.6145 52.2561 25.1226C51.07 25.5439 49.9033 26.0062 48.7578 26.5079L91.1642 123.685C93.887 122.498 96.4908 121.088 98.9525 119.48C95.8835 116.252 94 111.885 94 107.08C94 97.1384 102.059 89.0795 112 89.0795C115.074 89.0795 117.969 89.8503 120.501 91.2092C122.124 86.1233 123 80.7037 123 75.0795C123 52.0319 108.288 32.4204 87.7438 25.1228Z"
      fill="#0061A8"
    />
    <path
      d="M87.7439 25.1224C86.298 33.6144 78.904 40.0795 70 40.0795C61.0962 40.0795 53.7023 33.6147 52.2562 25.1229C37.4605 30.3786 25.6903 42.0209 20.2617 56.7338L107.177 89.733C108.712 89.3071 110.329 89.0795 112 89.0795C115.075 89.0795 117.97 89.8505 120.501 91.2097C122.125 86.1236 123.001 80.7039 123.001 75.0795C123.001 52.0316 108.289 32.4198 87.7439 25.1224Z"
      fill="#7BC9EB"
    />
    <path
      d="M87.7438 25.1228C86.2978 33.6146 78.9039 40.0795 70 40.0795C61.096 40.0795 53.7021 33.6145 52.2561 25.1226C51.07 25.5439 49.9033 26.0062 48.7578 26.5079L70.3665 76.0256L107.036 89.7728C108.612 89.3213 110.278 89.0795 112 89.0795C115.074 89.0795 117.969 89.8503 120.501 91.2092C122.124 86.1233 123 80.7037 123 75.0795C123 52.0319 108.288 32.4204 87.7438 25.1228Z"
      fill="#23A4DE"
    />
    <path
      d="M111.713 96.1567C108.465 96.1567 105.81 98.8121 105.81 102.061C105.81 105.309 108.465 107.964 111.713 107.964C114.962 107.964 117.617 105.309 117.617 102.061C117.617 98.8121 114.962 96.1567 111.713 96.1567Z"
      fill="#FFB71B"
    />
    <path
      d="M121.845 112.682C121.691 112.296 121.485 111.935 121.253 111.599C120.067 109.846 118.236 108.686 116.174 108.403C115.916 108.377 115.632 108.428 115.426 108.583C114.343 109.382 113.054 109.795 111.714 109.795C110.373 109.795 109.084 109.382 108.001 108.583C107.795 108.428 107.511 108.351 107.254 108.403C105.191 108.686 103.335 109.846 102.175 111.599C101.943 111.935 101.737 112.321 101.582 112.682C101.505 112.837 101.53 113.017 101.608 113.172C101.814 113.533 102.072 113.894 102.304 114.203C102.665 114.693 103.051 115.131 103.49 115.544C103.851 115.905 104.263 116.24 104.676 116.575C106.712 118.096 109.161 118.895 111.688 118.895C114.214 118.895 116.664 118.096 118.7 116.575C119.113 116.266 119.525 115.905 119.886 115.544C120.299 115.131 120.711 114.693 121.072 114.203C121.33 113.868 121.562 113.533 121.768 113.172C121.897 113.017 121.923 112.837 121.845 112.682Z"
      fill="#FFB71B"
    />
    <path
      d="M74.323 64.2138C67.602 64.3282 62.6511 70.7208 63.9224 77.0172C63.9642 77.2247 64.1465 77.3742 64.3582 77.3766C65.9332 77.3949 67.361 77.3149 68.9099 76.9461C69.1469 76.8897 69.2811 76.6432 69.2117 76.4096C68.9472 75.5198 68.9592 74.3592 69.2553 73.4166C69.6391 72.2581 70.3712 71.2616 71.469 70.5739C72.8479 69.6668 75.1388 69.8314 77.0594 69.7853C77.0581 69.7826 77.0569 69.78 77.0556 69.7774L84.151 69.7049C86.9786 69.6761 89.2922 71.9661 89.2922 74.7939C89.3211 77.6214 87.0313 79.9584 84.2038 79.9873L79.8896 80.0476C79.6899 80.0504 79.5103 80.1661 79.4229 80.3457C78.4925 82.2555 76.6105 84.3784 74.7694 85.5631C74.7179 85.5963 74.7424 85.6763 74.8036 85.6756L83.9507 85.5821C89.7941 85.5225 94.5749 80.6925 94.5749 74.8489C94.5749 68.8854 89.6959 64.056 83.7327 64.1168L74.3605 64.2125C74.3479 64.2128 74.3356 64.2136 74.323 64.2138Z"
      fill="white"
    />
    <path
      d="M70.5355 84.9297C70.5352 84.9298 70.535 84.9299 70.5348 84.93C70.5389 84.9317 70.5427 84.9332 70.5469 84.9349C73.16 84.1694 75.2794 82.4138 76.7351 80.0921L76.7311 80.0922L76.7299 80.0891C78.001 77.9958 78.5434 75.1973 78.0398 72.6367C77.9965 72.4165 77.7757 72.2162 77.5525 72.1926C76.1489 72.044 74.5847 72.0891 73.0566 72.6147C72.8167 72.6972 72.682 72.9498 72.7471 73.1949C72.991 74.1143 73.0219 75.1341 72.7173 76.1423L72.7182 76.1424C72.5286 76.8802 71.9189 78.0302 70.7985 78.8006C70.7984 78.8005 70.7982 78.8004 70.7981 78.8002C69.3815 79.8904 66.9661 79.7744 64.9239 79.8042L64.9239 79.8052C63.3748 79.8225 59.4429 79.8603 57.8668 79.8772C55.0393 79.9061 52.7022 77.6163 52.6734 74.7887C52.6445 71.9612 54.9343 69.6242 57.7618 69.5953L62.0889 69.5348C62.285 69.532 62.4624 69.4204 62.5512 69.2455C63.5309 67.3185 65.3584 65.2315 67.1686 64.0193C67.219 63.9855 67.1941 63.9067 67.1334 63.9073L58.1239 63.9994C52.2206 64.0597 47.3907 68.9392 47.3907 74.8427C47.451 80.7457 52.33 85.5261 58.233 85.4658C60.3831 85.3455 68.7499 85.6675 70.5355 84.9297Z"
      fill="white"
    />
  </svg>
))
