import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.29144 15.2438L5.93475 17.6005C4.95771 18.5776 3.37548 18.5776 2.39926 17.6007C1.42285 16.6243 1.42285 15.0419 2.39907 14.0657L7.11327 9.3515C8.08949 8.37524 9.67188 8.37524 10.6481 9.3515C10.9735 9.67693 11.5012 9.67693 11.8266 9.3515C12.1521 9.02607 12.1521 8.49841 11.8266 8.17298C10.1995 6.54586 7.56187 6.54586 5.93475 8.17298L1.22058 12.8871C-0.406535 14.5143 -0.406535 17.1519 1.22058 18.779C2.84751 20.407 5.48533 20.407 7.11331 18.779L9.47 16.4223C9.79544 16.0969 9.79544 15.5692 9.47 15.2438C9.14457 14.9184 8.61687 14.9184 8.29144 15.2438Z"
      fill="currentColor"
    />
    <path
      d="M18.7794 1.22034C17.1523 -0.40678 14.5138 -0.40678 12.8867 1.22034L10.0592 4.04786C9.73376 4.37329 9.73376 4.90095 10.0592 5.22638C10.3846 5.55181 10.9123 5.55181 11.2377 5.22638L14.0652 2.39886C15.0414 1.42261 16.6247 1.42261 17.6009 2.39886C18.5771 3.37508 18.5771 4.95747 17.6009 5.93369L12.4159 11.1188C11.4396 12.095 9.85728 12.095 8.88106 11.1188C8.55563 10.7933 8.02797 10.7933 7.70254 11.1188C7.37711 11.4442 7.37711 11.9718 7.70254 12.2973C9.32966 13.9244 11.9673 13.9244 13.5944 12.2973L18.7794 7.11225C20.4066 5.48513 20.4066 2.84746 18.7794 1.22034Z"
      fill="currentColor"
    />
  </svg>
))
