import { styled } from '@linaria/react';

export const TFLogo = styled.img`
	vertical-align: middle;
	width: 140px;
`;

export const VerticalDivider = styled.div`
	height: 30px;
	width: 1px;
	margin: 0 1rem;
	background-color: grey;
`;
