export const Jurisdictions = {
	AR: {
		name: 'Argentina',
		countryCode: 'AR',
		requiresIAV: true,
	},
	AT: {
		name: 'Austria',
		countryCode: 'AT',
		requiresIAV: true,
	},
	AU: {
		name: 'Australia',
		countryCode: 'AU',
		requiresIAV: true,
		requiresIAVConsent: true,
	},
	BE: {
		name: 'Belgium',
		countryCode: 'BE',
		requiresIAV: true,
	},
	BR: {
		name: 'Brazil',
		countryCode: 'BR',
		requiresIAV: true,
	},
	CA: {
		name: 'Canada',
		countryCode: 'CA',
		requiresIAV: true,
		requiresIAVConsent: true,
	},
	DK: {
		name: 'Denmark',
		countryCode: 'DK',
		requiresIAV: true,
	},
	FR: {
		name: 'France',
		countryCode: 'FR',
		requiresIAV: true,
	},
	DE: {
		name: 'Germany',
		countryCode: 'DE',
		requiresIAV: true,
	},
	IT: {
		name: 'Italy',
		countryCode: 'IT',
		requiresIAV: true,
	},
	MX: {
		name: 'Mexico',
		countryCode: 'MX',
		requiresIAV: true,
	},
	NL: {
		name: 'Netherlands',
		countryCode: 'NL',
		requiresIAV: true,
	},
	NZ: {
		name: 'New Zealand',
		countryCode: 'NZ',
		requiresIAV: true,
	},
	NO: {
		name: 'Norway',
		countryCode: 'NO',
		requiresIAV: true,
	},
	SG: {
		name: 'Singapore',
		countryCode: 'SG',
		requiresIAV: true,
	},
	ZA: {
		name: 'South Africa',
		countryCode: 'ZA',
		requiresIAV: true,
	},
	ES: {
		name: 'Spain',
		countryCode: 'ES',
		requiresIAV: true,
	},
	SE: {
		name: 'Sweden',
		countryCode: 'SE',
		requiresIAV: true,
	},
	CH: {
		name: 'Switzerland',
		countryCode: 'CH',
		requiresIAV: true,
	},
	TR: {
		name: 'Turkey',
		countryCode: 'TR',
		requiresIAV: true,
	},
	UK: {
		name: 'United Kingdom',
		countryCode: 'GB',
		requiresPostcodeValidation: true,
	},
	US: {
		name: 'United States',
		countryCode: 'US',
		requiresIAV: true,
	},
};
