import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M119.024 61.4515L69.9983 44.21L20.9727 61.4515L69.9983 78.9543L119.024 61.4515Z" fill="#23A4DE" />
      <path d="M119.022 61.4515L69.9961 44.21V78.9543L119.022 61.4515Z" fill="#0061A8" />
      <path
        d="M88.5528 8.83691H50.3176C46.6078 8.83691 43.5898 11.9163 43.5898 15.7005V97.8934C43.5898 101.678 46.6078 104.757 50.3176 104.757H88.5528C92.2626 104.757 95.2814 101.678 95.2814 97.8934V15.7005C95.2814 11.9163 92.2626 8.83691 88.5528 8.83691V8.83691Z"
        fill="#262F69"
      />
      <path
        d="M88.529 12.8105H82.8467C82.3106 12.8105 81.8764 13.2534 81.8764 13.7994C81.8764 15.1111 80.8249 16.1837 79.5399 16.1837H59.2847C57.9988 16.1837 56.9473 15.1111 56.9473 13.7994C56.9473 13.2534 56.5132 12.8105 55.977 12.8105H50.2947C48.7295 12.8105 47.4609 14.1046 47.4609 15.7003V80.5489H91.3628V15.7003C91.3628 14.1046 90.0942 12.8105 88.529 12.8105Z"
        fill="white"
      />
      <path d="M50.8828 45.4146L69.4588 27.1992L88.0348 45.4146V70.5692H50.8828V45.4146Z" fill="#262F69" />
      <path d="M50.8828 45.4146L69.4588 27.1992L88.0348 45.4146V59.7267L50.8828 45.4146Z" fill="#7BC9EB" />
      <path d="M88.036 70.5692H77.1623L61.7578 34.7519L69.46 27.1992L88.036 45.4146V70.5692Z" fill="#0061A8" />
      <path d="M88.036 45.4146V59.7267L69.46 52.6606L61.7578 34.7519L69.46 27.1992L88.036 45.4146Z" fill="#32BCFA" />
      <path d="M69.9983 79.0023L20.9727 61.4512V124.568L69.9983 140.345V79.0023Z" fill="#31448F" />
      <path d="M119.022 124.568V61.4512L69.9961 79.0023V140.345L119.022 124.568Z" fill="#262F69" />
      <path d="M20.9715 61.4521L4 81.8288L53.9483 100.246L69.9972 78.955L20.9715 61.4521Z" fill="#7BC9EB" />
      <path d="M119.022 61.4512L135.999 81.8278L86.0506 100.245L69.9961 78.954L119.022 61.4512Z" fill="#23A4DE" />
      <rect
        width="12.6821"
        height="12.724"
        rx="3"
        transform="matrix(0.38391 -0.923371 0.9164 0.400264 81 49.0547)"
        fill="#FFE5C7"
      />
      <rect
        width="25.2093"
        height="25.3778"
        rx="5"
        transform="matrix(0.89921 -0.437516 0.449099 0.893482 20.2227 18.0295)"
        fill="#FFB71B"
      />
      <rect
        width="9.83494"
        height="9.95171"
        rx="2"
        transform="matrix(0.950509 0.310698 -0.30262 0.953111 100.012 11.8572)"
        fill="#FFE5C7"
      />
      <path
        d="M49.1555 105.187C45.03 103.979 40.8757 106.968 40.5391 111.081C40.528 111.216 40.6129 111.343 40.7418 111.384C41.7006 111.695 42.5869 111.917 43.5982 111.985C43.7529 111.996 43.8784 111.87 43.8773 111.713C43.8731 111.116 44.0856 110.404 44.4331 109.881C44.8724 109.242 45.4956 108.768 46.2878 108.554C47.2904 108.259 48.6606 108.796 49.8419 109.133C49.8416 109.131 49.8413 109.129 49.8409 109.127L54.1877 110.432C55.92 110.952 56.9283 112.8 56.4284 114.538C55.9461 116.282 54.1343 117.284 52.4022 116.764L49.7564 115.98C49.6339 115.944 49.5037 115.981 49.4186 116.075C48.5126 117.072 46.9878 118.019 45.6538 118.398C45.6165 118.408 45.6173 118.462 45.6548 118.473L51.2585 120.155C54.8382 121.23 58.6123 119.17 59.6454 115.577C60.6997 111.91 58.5733 108.013 54.9201 106.917L49.1786 105.193C49.1708 105.191 49.1632 105.189 49.1555 105.187Z"
        fill="#F2F2F2"
      />
      <path
        d="M43.1797 117.203C43.1795 117.203 43.1793 117.203 43.1792 117.203C43.1814 117.205 43.1835 117.206 43.1857 117.208C44.9172 117.235 46.5221 116.558 47.8217 115.408L47.8193 115.407L47.8191 115.405C48.9655 114.36 49.7916 112.742 49.9366 111.072C49.9491 110.928 49.8497 110.763 49.7175 110.706C48.8865 110.348 47.923 110.078 46.8968 110.111C46.7356 110.116 46.6087 110.246 46.6051 110.409C46.5916 111.02 46.4302 111.653 46.0658 112.215L46.0664 112.215C45.8201 112.633 45.2444 113.224 44.4239 113.485C44.4238 113.485 44.4238 113.484 44.4237 113.484C43.3657 113.885 41.9108 113.355 40.6582 112.984L40.658 112.985C39.7087 112.701 37.3004 111.977 36.3347 111.687C34.6026 111.167 33.5799 109.315 34.0621 107.571C34.5444 105.827 36.3562 104.826 38.0883 105.346L40.742 106.131C40.8623 106.167 40.9904 106.132 41.0756 106.042C42.0146 105.043 43.4999 104.108 44.8199 103.706C44.8567 103.695 44.8553 103.642 44.8181 103.631L39.2988 101.974C35.6823 100.889 31.8695 102.97 30.8259 106.6C29.8191 110.24 31.9542 114.107 35.5704 115.193C36.905 115.528 41.9586 117.317 43.1797 117.203Z"
        fill="#F2F2F2"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="1em" height="1em" fill="white" />
      </clipPath>
    </defs>
  </svg>
))
