import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M19.9549 9.77553L17.1977 3.11879C17.1382 2.97524 17.0242 2.86114 16.8806 2.80172L10.2237 0.0443751C10.0801 -0.0151172 9.91884 -0.0151172 9.77529 0.0443751L3.11848 2.80172C2.97489 2.86114 2.86082 2.97524 2.80137 3.11879L0.044099 9.77545C-0.0307839 9.95627 -0.0104323 10.1625 0.0982788 10.3252C0.20699 10.4879 0.389764 10.5856 0.585467 10.5856H1.88231V19.4138C1.88231 19.7375 2.14465 19.9998 2.46824 19.9998C8.52833 19.9998 8.62953 19.9998 17.5308 19.9998C17.8544 19.9998 18.1167 19.7375 18.1167 19.4138V10.5857H19.4136C19.6093 10.5857 19.792 10.488 19.9008 10.3253C20.0095 10.1626 20.0298 9.95635 19.9549 9.77553Z"
        fill="#F2F2F2"
      />
      <path d="M6.82031 14.4692V19.1714L9.17138 16.8203L6.82031 14.4692Z" fill="#23A4DE" />
      <path d="M9.99998 17.649L7.64893 20H12.3511L9.99998 17.649Z" fill="#23A4DE" />
      <path d="M7.64893 13.6406L9.99998 15.9917L12.3511 13.6406H7.64893Z" fill="#23A4DE" />
      <path d="M10.8286 16.8203L13.1797 19.1714V14.4692L10.8286 16.8203Z" fill="#23A4DE" />
      <path
        d="M19.9549 9.77553L17.1977 3.11879C17.1382 2.97524 17.0242 2.86114 16.8806 2.80172L10.2237 0.0443751C10.0801 -0.0151172 9.91884 -0.0151172 9.77529 0.0443751L3.11848 2.80172C2.97489 2.86114 2.86082 2.97524 2.80137 3.11879L0.044099 9.77545C-0.0307839 9.95627 -0.0104323 10.1625 0.0982788 10.3252C0.20699 10.4879 0.389764 10.5856 0.585467 10.5856H1.88231V19.4138C1.88231 19.7375 2.14465 19.9998 2.46824 19.9998H5.64794V13.0545C5.64794 12.7309 5.91028 12.4685 6.23388 12.4685H13.7651C14.0887 12.4685 14.3511 12.7309 14.3511 13.0545V19.9998H17.5308C17.8544 19.9998 18.1167 19.7375 18.1167 19.4138V10.5857H19.4136C19.6093 10.5857 19.792 10.488 19.9008 10.3253C20.0095 10.1626 20.0298 9.95635 19.9549 9.77553ZM12.4682 8.11697C12.4682 8.44056 12.2059 8.70291 11.8823 8.70291H8.11673C7.79314 8.70291 7.53079 8.44056 7.53079 8.11697V4.35134C7.53079 4.02778 7.79314 3.7654 8.11673 3.7654H11.8823C12.2059 3.7654 12.4682 4.02778 12.4682 4.35134V8.11697Z"
        fill="#7BC9EB"
      />
      <path d="M11.2969 4.9375H8.70312V7.53125H11.2969V4.9375Z" fill="#0061A8" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="1em" height="1em" fill="white" />
      </clipPath>
    </defs>
  </svg>
))
