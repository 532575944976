import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M50.8388 11.125H14.1152V68.875H50.8388C52.0856 68.875 53.0964 67.8856 53.0964 66.6651V13.3348C53.0964 12.1144 52.0856 11.125 50.8388 11.125Z"
      fill="#23A4DE"
    />
    <path
      d="M58.6309 69.7671C58.6309 69.7671 59.3328 72.3622 60.5023 72.6675C61.6719 72.9729 68.144 70.1487 67.5982 69.1564C67.0524 68.1644 63.1535 67.8589 61.6719 68.088C60.1904 68.317 58.6309 69.7671 58.6309 69.7671Z"
      fill="#262F69"
    />
    <path
      d="M31.2071 56.5674C31.2071 56.5674 29.3287 54.6057 28.1518 54.8785C26.9746 55.151 22.6545 60.6365 23.6168 61.258C24.5791 61.8796 28.1566 60.343 29.3483 59.4553C30.5401 58.5675 31.2071 56.5674 31.2071 56.5674Z"
      fill="#262F69"
    />
    <path
      d="M39.3191 52.1957C39.3191 52.1957 39.6409 56.462 36.5843 58.0421C33.5277 59.6223 31.356 56.1459 30.7928 56.1459C30.2299 56.1459 28.772 59.2815 28.6112 60.1505C28.4502 61.0196 33.4473 65.6268 38.3538 64.4418C43.2605 63.2565 44.3866 57.647 44.8692 57.4101C45.3518 57.1731 48.1128 57.401 49.052 57.647C50.2585 57.9631 52.2927 58.7474 54.6825 62.3875C57.0722 66.0276 57.4977 69.8143 58.3825 69.9722C59.2674 70.1303 61.5679 68.6442 62.4527 68.0121C63.3374 67.3801 61.1173 55.356 53.6367 52.5117C46.1562 49.6674 39.3191 52.1957 39.3191 52.1957Z"
      fill="#262F69"
    />
    <path
      d="M52.4654 33.8682C52.4654 33.8682 54.987 40.6706 56.8584 41.7391C58.7299 42.8076 61.5167 44.5035 61.5167 44.5035L54.1089 45.1904L50.756 44.5035L49.8203 37.7106L52.4654 33.8682Z"
      fill="#0061A8"
    />
    <path
      d="M41.5543 31.5494C41.2616 33.013 38.5856 46.6255 38.9031 52.1642C38.9811 53.5245 53.9524 54.7802 55.278 52.4781C56.6036 50.1759 53.6405 36.1537 52.159 32.9097C50.6775 29.666 47.4805 28.9335 47.4805 28.9335C47.4805 28.9335 41.8469 30.086 41.5543 31.5494Z"
      fill="#0061A8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.75 30.8852L64.661 32.3606C65.4757 32.564 65.9621 33.3869 65.745 34.1947L62.5958 45.9167C62.3788 46.7245 61.5391 47.2164 60.7244 47.0131L54.8135 45.5377C53.9988 45.3343 53.5124 44.5114 53.7294 43.7036L56.8787 31.9816C57.0957 31.1738 57.9353 30.6819 58.75 30.8852Z"
      fill="#23A4DE"
    />
    <path
      d="M62.4115 33.0635L60.9057 32.767C60.6977 32.726 60.4934 32.8603 60.4491 33.0672C60.4048 33.274 60.5373 33.4747 60.7454 33.5156L62.2512 33.8121C62.4592 33.8531 62.6635 33.7188 62.7078 33.5119C62.7521 33.3051 62.6196 33.1045 62.4115 33.0635Z"
      fill="white"
    />
    <path
      d="M59.4858 33.2573C59.6928 33.2978 59.8965 33.1641 59.9408 32.9588C59.985 32.7535 59.8532 32.5542 59.6462 32.5137C59.4393 32.4732 59.2356 32.6069 59.1913 32.8122C59.147 33.0175 59.2789 33.2168 59.4858 33.2573Z"
      fill="white"
    />
    <path
      d="M42.2626 22.377C42.2626 23.883 41.0872 25.104 39.6373 25.104C38.1872 25.104 37.0117 23.883 37.0117 22.377C37.0117 20.8708 38.1872 19.6498 39.6373 19.6498C41.0872 19.6498 42.2626 20.8708 42.2626 22.377Z"
      fill="#FFE5C7"
    />
    <path
      d="M59.7685 22.2245C59.7685 23.7307 58.593 24.9517 57.1431 24.9517C55.693 24.9517 54.5176 23.7307 54.5176 22.2245C54.5176 20.7184 55.693 19.4973 57.1431 19.4973C58.593 19.4973 59.7685 20.7184 59.7685 22.2245Z"
      fill="#FFE5C7"
    />
    <path
      d="M39.152 21.0659C39.152 21.0659 39.5126 32.8662 48.2733 32.8662C57.0339 32.8662 58.1159 23.6883 57.9098 20.3317C57.7038 16.9751 56.1578 11.7829 56.1578 11.7829L48.7885 11.0487L42.2955 12.3075L39.255 14.0907L39.0488 16.2408L39.152 21.0659Z"
      fill="#FFE5C7"
    />
    <path
      d="M35.4766 47.6355C35.4766 47.6355 35.7557 50.6773 36.4085 51.0948C37.0612 51.5124 37.5857 51.1732 38.3012 51.3574C39.0167 51.5415 39.7495 50.8067 40.382 50.9196C41.0145 51.0326 41.7676 50.6025 41.795 49.8358C41.8225 49.0692 40.891 47.9809 40.891 47.9809C40.891 47.9809 42.1058 47.445 41.827 46.7744C41.5482 46.1036 39.2226 47.1974 38.3012 46.2615C37.3799 45.3256 35.4766 47.6355 35.4766 47.6355Z"
      fill="#FFE5C7"
    />
    <path
      d="M56.5194 47.9866C55.0285 47.2569 54.8819 45.2389 55.3497 44.6284C55.8176 44.0178 56.4413 43.9414 56.4413 43.9414C56.4413 43.9414 57.1432 43.4071 57.6891 43.7125C57.6891 43.7125 58.7807 42.9492 59.4824 43.6361C59.4824 43.6361 60.8861 43.2545 61.3538 44.2468C61.8218 45.2389 61.6658 47.4524 60.574 47.9866C59.4824 48.5211 57.7669 48.5973 56.5194 47.9866Z"
      fill="#FFE5C7"
    />
    <path
      d="M40.8711 8.45437C43.2211 5.47463 47.8905 4.9722 52.0434 5.92931C54.3942 6.47108 66.0979 10.8965 59.6074 13.7641C59.6714 15.0958 59.6783 16.4363 59.6134 17.768C59.572 18.6145 59.5433 19.5253 59.2864 20.3425C58.9644 21.3667 57.9348 21.0108 57.617 20.1749C57.0399 18.6568 57.738 17.0362 56.7615 15.5243C56.1795 14.6234 55.3999 14.489 54.496 14.0131C54.4433 13.9853 53.8928 13.7835 53.8859 13.7407C53.8859 13.7407 54.8034 15.2238 53.6396 15.421C52.4756 15.6184 48.3317 14.1404 48.3317 14.1404C48.3317 14.1404 48.9599 15.1792 47.3707 15.6833C45.7816 16.1873 41.7116 13.7277 41.7116 13.7277C41.7116 13.7277 39.8986 15.6778 39.9936 20.6982C40.0234 22.282 36.829 21.9927 37.249 18.5277C37.6689 15.0627 37.505 8.60964 40.8711 8.45437Z"
      fill="#262F69"
    />
    <path
      d="M41.7169 31.1826C41.7169 31.1826 32.6896 36.0859 32.2663 40.7176C31.8427 45.3492 34.6618 47.9334 35.1791 48.0088C35.6964 48.0843 38.1145 46.0895 38.1902 45.8245C38.2658 45.5594 36.9497 40.9869 38.1902 39.7876C39.4306 38.5881 41.9553 37.0585 41.9553 37.0585C43.3073 36.6562 43.781 33.8204 43.2123 32.1367C42.9751 31.4346 42.4685 31.1826 41.7169 31.1826Z"
      fill="#0061A8"
    />
    <rect
      width="6.77032"
      height="6.638"
      rx="3.319"
      transform="matrix(0.981332 0.192319 -0.200393 0.979716 61.3594 35.2222)"
      fill="#FFB71B"
    />
    <rect
      width="4.52018"
      height="4.45621"
      rx="2"
      transform="matrix(0.916 -0.401177 0.415735 0.909486 66.6895 30.1357)"
      fill="#FFB71B"
    />
    <rect
      width="2.95631"
      height="2.94909"
      rx="1.47454"
      transform="matrix(0.753468 0.657485 -0.673344 0.739329 73.7734 34.0752)"
      fill="#FFB71B"
    />
    <path
      d="M34.0966 2.70286C33.5079 2.28742 32.7512 2.17442 32.0626 2.39909L4.97148 11.2384C4.04963 11.5392 3.42773 12.3836 3.42773 13.3348V66.6651C3.42773 67.6163 4.04948 68.4606 4.97148 68.7615L32.0626 77.6007C32.7571 77.8275 33.5135 77.7084 34.0966 77.297C34.6852 76.8818 35.0341 76.2147 35.0341 75.5044V4.49547C35.0341 3.78523 34.6852 3.11816 34.0966 2.70286ZM23.7461 42.2097C22.4993 42.2097 21.4885 41.2203 21.4885 39.9999C21.4885 38.7795 22.4993 37.7901 23.7461 37.7901C24.9929 37.7901 26.0037 38.7795 26.0037 39.9999C26.0037 41.2203 24.9929 42.2097 23.7461 42.2097Z"
      fill="#262F69"
    />
  </svg>
))
