export const BASE_HEADERS = {
	'Content-Type': 'application/json',
	'api-version': '2020-01-31',
};

export const TF_BASE_HEADERS = {
	Accept: 'application/json',
	'Content-Type': 'application/json',
};

export const URLS = {
	CHECKS: '/checks',
	CONTACTS: '/contacts',
	DOCUMENTS: '/documents',
	TRANSACTIONS: '/transactions',
	USERS: '/users',
};

export const TF_API_URL = $configs.env.backend.url;
