import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.0002 7C12.8228 7 7 12.8155 7 19.9934C7 27.1782 12.8228 33.0004 20.0002 33.0004C27.1815 33.0004 32.9994 27.1781 32.9994 19.9934C32.9995 12.8155 27.1815 7 20.0002 7Z"
      fill="white"
    />
    <path
      d="M20.92 27.5779C20.6204 27.8588 20.2733 28 19.88 28C19.4731 28 19.1182 27.8613 18.8152 27.5835C18.5118 27.3062 18.3598 26.9179 18.3598 26.419C18.3598 25.9764 18.5069 25.6039 18.8002 25.3021C19.0935 25.0002 19.4532 24.8492 19.88 24.8492C20.3 24.8492 20.6535 25.0002 20.9404 25.3021C21.2269 25.6039 21.3706 25.9764 21.3706 26.419C21.3701 26.9107 21.22 27.2971 20.92 27.5779ZM24.6552 17.7745C24.425 18.2242 24.1517 18.612 23.8347 18.939C23.5186 19.2659 22.95 19.8154 22.1294 20.588C21.9032 20.806 21.7211 20.9973 21.5847 21.162C21.4483 21.3273 21.3463 21.4782 21.2798 21.6153C21.2127 21.7524 21.1613 21.8896 21.1249 22.0267C21.0884 22.1633 21.0336 22.4043 20.9593 22.7486C20.8331 23.4792 20.4364 23.8445 19.7698 23.8445C19.4231 23.8445 19.1318 23.7253 18.8943 23.4863C18.6579 23.2474 18.5399 22.8928 18.5399 22.4221C18.5399 21.8322 18.6268 21.3211 18.8001 20.8888C18.9725 20.4564 19.2031 20.0773 19.4896 19.7504C19.7766 19.4235 20.163 19.0356 20.65 18.5859C21.0768 18.1924 21.3851 17.8957 21.575 17.6956C21.7653 17.4951 21.925 17.272 22.0547 17.0264C22.1853 16.7803 22.2494 16.5137 22.2494 16.2257C22.2494 15.6634 22.0518 15.1897 21.6546 14.8034C21.258 14.4171 20.7462 14.2237 20.1194 14.2237C19.3857 14.2237 18.8458 14.4186 18.4991 14.8085C18.1525 15.1983 17.8597 15.7724 17.6194 16.5312C17.3921 17.3253 16.962 17.7222 16.3293 17.7222C15.9559 17.7222 15.6408 17.5836 15.384 17.3062C15.1276 17.0289 14.9994 16.7286 14.9994 16.4053C14.9994 15.7381 15.2029 15.0617 15.6093 14.3766C16.0162 13.6915 16.6095 13.1241 17.3897 12.6749C18.1694 12.2251 19.0799 12 20.1194 12C21.0861 12 21.9391 12.1883 22.6791 12.5643C23.419 12.9399 23.991 13.451 24.3945 14.0977C24.7975 14.7439 24.9994 15.4464 24.9994 16.2052C25.0004 16.8013 24.8853 17.3247 24.6552 17.7745Z"
      fill="#23A4DE"
    />
  </svg>
))
