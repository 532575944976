import { styled } from '@linaria/react';

import { SmallText as ReapitSmallText } from '@reapit/elements';

export const BodyText = styled(ReapitSmallText)`
	font-size: 16px !important;
	line-height: 1.4 !important;
`;

// TODO: change the following font-size to 14px
export const SmallText = styled(ReapitSmallText)`
	font-size: 16px !important;
	line-height: 1.4 !important;
`;

export const BodyTextNoMargin = styled(ReapitSmallText)`
	font-size: 16px !important;
	line-height: 1.4 !important;
	margin: 0px !important;
`;

export const SmallTextNoMargin = styled(ReapitSmallText)`
	font-size: 16px !important;
	line-height: 1.4 !important;
	margin: 0px !important;
`;

export const SmallImageTextNoMargin = styled(ReapitSmallText)`
	font-size: 16px !important;
	line-height: 1.4 !important;
	margin: 0px !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
	width: 70% !important;
`;

export const SmallTextLink = styled.a`
	font-size: 16px !important;
	line-height: 1.4 !important;
	text-decoration: underline;
	color: #0061a8;
`;
