import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 322 220" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M310.373 41.8766V188.24C310.373 194.49 305.318 199.553 299.08 199.553H63.5772C57.3389 199.553 52.2839 194.489 52.2839 188.24V41.8766C52.2839 35.6272 57.3393 30.5635 63.5772 30.5635H299.08C305.318 30.5635 310.373 35.6272 310.373 41.8766Z"
      fill="#262F69"
    />
    <path
      d="M295.129 52.0643V178.053C295.129 179.618 293.862 180.888 292.299 180.888H70.3592C68.7957 180.888 67.5288 179.618 67.5288 178.053V52.0643C67.5288 50.4985 68.7957 49.229 70.3592 49.229H292.299C293.862 49.229 295.129 50.4985 295.129 52.0643Z"
      fill="#7BC9EB"
    />
    <path
      d="M321.859 202.122L320.715 205.638C318.117 213.639 310.673 219.055 302.273 219.055H60.3902C51.9895 219.055 44.5457 213.639 41.9479 205.643L40.8042 202.122C40.2096 200.296 41.5685 198.419 43.4874 198.419H160.585L163.274 206.613C164.27 209.636 167.089 211.683 170.265 211.683H192.398C195.574 211.683 198.393 209.636 199.389 206.613L202.078 198.419H319.175C321.095 198.419 322.453 200.296 321.859 202.122Z"
      fill="#43559D"
    />
    <path
      d="M195.212 214.297H167.452C162.575 214.297 158.249 211.16 156.725 206.519L152.503 198.419H210.16L205.938 206.519C204.415 211.16 200.089 214.297 195.212 214.297Z"
      fill="#262F69"
    />
    <path
      d="M255.802 12.2128V34.0966C179.341 34.0966 102.879 34.096 26.4185 34.096V12.2128C26.4185 5.99205 31.4567 0.944824 37.6664 0.944824H244.554C250.764 0.944824 255.802 5.99205 255.802 12.2128Z"
      fill="#0061A8"
    />
    <path
      d="M255.804 28.4263V141.765C255.804 147.988 250.769 153.032 244.558 153.032H37.6676C31.4563 153.032 26.4214 147.988 26.4214 141.765V28.4263C102.882 28.4263 179.343 28.4263 255.804 28.4263Z"
      fill="white"
    />
    <path
      d="M214.838 18.9919C212.493 18.9919 210.593 17.0879 210.593 14.739V14.6802C210.593 12.3308 212.494 10.4272 214.838 10.4272C217.183 10.4272 219.083 12.3313 219.083 14.6802V14.739C219.083 17.0884 217.183 18.9919 214.838 18.9919Z"
      fill="white"
    />
    <path
      d="M228.396 18.9919C226.051 18.9919 224.151 17.0879 224.151 14.739V14.6802C224.151 12.3308 226.051 10.4272 228.396 10.4272C230.741 10.4272 232.641 12.3313 232.641 14.6802V14.739C232.641 17.0884 230.741 18.9919 228.396 18.9919Z"
      fill="white"
    />
    <path
      d="M241.954 18.9919C239.609 18.9919 237.708 17.0879 237.708 14.739V14.6802C237.708 12.3308 239.609 10.4272 241.954 10.4272C244.299 10.4272 246.199 12.3313 246.199 14.6802V14.739C246.199 17.0884 244.299 18.9919 241.954 18.9919Z"
      fill="white"
    />
    <path
      d="M117.566 67.6626H83.3287C80.9835 67.6626 79.0835 65.7586 79.0835 63.4098C79.0835 61.0607 80.9839 59.1567 83.3287 59.1567H117.566C119.911 59.1567 121.811 61.0607 121.811 63.4098C121.811 65.7586 119.911 67.6626 117.566 67.6626Z"
      fill="#7BC9EB"
    />
    <path
      d="M159.218 88.7133H46.7684C44.4232 88.7133 42.5232 86.8093 42.5232 84.4606C42.5232 82.1115 44.4239 80.2075 46.7684 80.2075H159.218C161.563 80.2075 163.463 82.1115 163.463 84.4606C163.463 86.8093 161.563 88.7133 159.218 88.7133Z"
      fill="#7BC9EB"
    />
    <path
      d="M235.455 104.819H46.7684C44.4232 104.819 42.5232 102.915 42.5232 100.566C42.5232 98.2174 44.4239 96.313 46.7684 96.313H235.455C237.801 96.313 239.701 98.2174 239.701 100.566C239.701 102.915 237.801 104.819 235.455 104.819Z"
      fill="#7BC9EB"
    />
    <path
      d="M259.038 99.1977L197.42 77.5716C193.699 76.2654 190.121 79.8501 191.424 83.5781L213.012 145.306C214.158 148.583 218.328 149.535 220.779 147.08L228.446 139.398C229.34 138.504 230.787 138.504 231.68 139.398L264.628 172.405C266.465 174.245 269.444 174.245 271.281 172.405L286.089 157.57C287.927 155.73 287.927 152.745 286.089 150.905L253.142 117.898C252.249 117.004 252.249 115.554 253.142 114.659L260.809 106.978C263.26 104.524 262.309 100.346 259.038 99.1977Z"
      fill="#FFB71B"
    />
    <path
      d="M183.494 46.4139L183.494 46.4139C181.937 46.928 181.091 48.6076 181.603 50.1655L186.437 64.8531C186.95 66.411 188.627 67.2571 190.184 66.743L190.184 66.743C191.74 66.2289 192.587 64.5492 192.074 62.9914L187.24 48.3037C186.728 46.7459 185.05 45.8998 183.494 46.4139Z"
      fill="#7BC9EB"
    />
    <path
      d="M207.626 46.2975L207.626 46.2975C206.194 45.7033 204.553 46.3839 203.96 47.8175L197.944 62.371C197.352 63.8046 198.033 65.4484 199.465 66.0426L199.465 66.0426C200.897 66.6367 202.539 65.9562 203.131 64.5226L209.147 49.9691C209.739 48.5355 209.059 46.8916 207.626 46.2975Z"
      fill="#7BC9EB"
    />
    <path
      d="M224.755 62.9776L224.755 62.9776C224.153 61.5464 222.506 60.8754 221.076 61.4789L206.595 67.591C205.165 68.1945 204.494 69.8439 205.096 71.2751L205.096 71.2751C205.698 72.7062 207.345 73.3772 208.775 72.7737L223.256 66.6617C224.686 66.0582 225.357 64.4088 224.755 62.9776Z"
      fill="#7BC9EB"
    />
    <path
      d="M167.751 62.9624C166.328 62.3413 164.673 62.9919 164.054 64.4155C163.434 65.8391 164.085 67.4967 165.507 68.1178L179.912 74.4085C181.335 75.0296 182.99 74.3791 183.609 72.9554C184.229 71.5318 183.578 69.8742 182.156 69.2531L167.751 62.9624Z"
      fill="#7BC9EB"
    />
    <path
      d="M84.7034 113.446L77.6899 112.554C77.1117 110.774 76.3994 109.057 75.5675 107.426L79.8985 101.846C81.6517 99.5875 81.4467 96.3999 79.4435 94.4588L73.5679 88.5803C71.6093 86.557 68.4235 86.3555 66.163 88.1061L60.5934 92.4395C58.9634 91.6072 57.2473 90.8945 55.4643 90.3159L54.5724 83.3097C54.237 80.4992 51.8532 78.3828 49.029 78.3828H40.673C37.8491 78.3828 35.4653 80.4992 35.1296 83.2988L34.238 90.3159C32.4549 90.8945 30.7389 91.6035 29.1087 92.4395L23.5354 88.1061C21.2822 86.3555 18.0964 86.557 16.1529 88.5614L10.2775 94.4366C8.25559 96.3999 8.0504 99.5875 9.8037 101.849L14.1347 107.426C13.2991 109.057 12.5903 110.774 12.0121 112.554L5.01011 113.446C2.20109 113.782 0.0859375 116.167 0.0859375 118.993V127.354C0.0859375 130.179 2.20109 132.564 4.99892 132.9L12.0121 133.792C12.5903 135.573 13.3028 137.29 14.1347 138.921L9.8037 144.501C8.0504 146.759 8.25559 149.947 10.2588 151.888L16.1342 157.766C18.0964 159.786 21.2785 159.987 23.5391 158.237L29.1124 153.903C30.7426 154.74 32.4586 155.452 34.238 156.027L35.1296 163.029C35.4653 165.848 37.8491 167.964 40.673 167.964H49.029C51.8532 167.964 54.237 165.848 54.5724 163.048L55.4643 156.031C57.2436 155.452 58.9594 154.739 60.5897 153.907L66.1666 158.241C68.4235 159.995 71.6093 159.789 73.5493 157.785L79.4246 151.906C81.4467 149.943 81.6517 146.759 79.8985 144.497L75.5675 138.921C76.403 137.29 77.1157 135.573 77.6899 133.792L84.6881 132.9C87.4975 132.564 89.6126 130.179 89.6126 127.354V118.993C89.6163 116.167 87.5011 113.782 84.7034 113.446ZM44.851 141.836C34.5663 141.836 26.1989 133.464 26.1989 123.173C26.1989 112.883 34.5663 104.511 44.851 104.511C55.1357 104.511 63.5034 112.883 63.5034 123.173C63.5034 133.464 55.1357 141.836 44.851 141.836Z"
      fill="#23A4DE"
    />
    <path
      d="M45.0375 110.353C41.0844 110.353 37.8528 113.586 37.8528 117.542C37.8528 121.497 41.0844 124.731 45.0375 124.731C48.9909 124.731 52.2225 121.497 52.2225 117.542C52.2225 113.586 48.9909 110.353 45.0375 110.353Z"
      fill="#0061A8"
    />
    <path
      d="M57.3679 130.476C57.1797 130.005 56.9286 129.565 56.6462 129.157C55.203 127.023 52.9755 125.61 50.4653 125.265C50.1517 125.233 49.8068 125.296 49.5556 125.484C48.2378 126.458 46.6693 126.96 45.0375 126.96C43.406 126.96 41.8374 126.458 40.5196 125.484C40.2685 125.296 39.9233 125.202 39.6096 125.265C37.0998 125.61 34.8407 127.023 33.4288 129.157C33.1465 129.565 32.8954 130.036 32.7072 130.476C32.6131 130.664 32.6445 130.884 32.7386 131.072C32.9896 131.512 33.3033 131.951 33.5857 132.328C34.0249 132.925 34.4956 133.458 35.029 133.961C35.4682 134.4 35.9702 134.808 36.4721 135.216C38.9507 137.068 41.9316 138.041 45.0062 138.041C48.0808 138.041 51.0617 137.068 53.5403 135.216C54.0422 134.839 54.5441 134.4 54.9835 133.961C55.4854 133.458 55.9873 132.925 56.4267 132.328C56.7403 131.92 57.0231 131.512 57.2738 131.072C57.4308 130.884 57.4621 130.664 57.3679 130.476Z"
      fill="#0061A8"
    />
    <path
      d="M90.1483 163.85C100.153 163.85 108.264 155.735 108.264 145.724C108.264 135.713 100.153 127.598 90.1483 127.598C80.1432 127.598 72.0325 135.713 72.0325 145.724C72.0325 155.735 80.1432 163.85 90.1483 163.85Z"
      fill="white"
    />
    <path
      d="M89.1133 124.491C77.4119 124.491 67.8918 134.016 67.8918 145.724C67.8918 157.433 77.4119 166.958 89.1133 166.958C100.815 166.958 110.334 157.433 110.334 145.724C110.334 134.016 100.815 124.491 89.1133 124.491ZM101.299 142.138L87.9667 155.478C87.3997 156.045 86.6463 156.357 85.8446 156.357C85.0429 156.357 84.2895 156.045 83.7225 155.478L76.9275 148.679C76.3609 148.112 76.0483 147.358 76.0483 146.556C76.0483 145.753 76.3609 144.999 76.9275 144.432C77.4944 143.865 78.2478 143.553 79.0495 143.553C79.8513 143.553 80.605 143.865 81.1716 144.432L85.8443 149.108L97.0543 137.891C97.6212 137.324 98.3746 137.012 99.1764 137.012C99.9781 137.012 100.731 137.324 101.298 137.891C102.469 139.062 102.469 140.967 101.299 142.138Z"
      fill="#A0C862"
    />
    <path
      d="M65.3706 92.3754L105.452 126.103L116.975 124.635L116.5 112.314L114.826 111.371L112.385 105.534L105.013 103.138L101.562 96.586L94.6966 95.6384L92.4938 88.9827L86.2145 87.6965L76.0064 78.2485L65.3706 92.3754Z"
      fill="#FFB71B"
    />
    <path
      opacity="0.5"
      d="M67.6625 89.1997L65.2737 92.3724L105.355 126.1L112.03 125.279C96.4099 114.34 81.6441 102.181 67.6625 89.1997Z"
      fill="#FFB71B"
    />
    <path
      opacity="0.5"
      d="M86.1176 87.6943L75.9099 78.2456L71.8733 83.6071C85.9203 96.8212 100.83 109.131 116.66 120.138L116.343 112.418L114.668 111.476L112.258 105.585L104.901 103.161L101.458 96.5962L94.5965 95.6416L92.3963 88.9823L86.1176 87.6943Z"
      fill="#E19A00"
    />
    <path
      d="M49.2312 67.5272C47.5896 70.2628 44.0428 71.1505 41.3087 69.5087C38.5732 67.8661 37.6875 64.3174 39.3284 61.5817C40.9693 58.8454 44.516 57.9585 47.2508 59.6003C49.9857 61.2421 50.8713 64.7908 49.2312 67.5272ZM33.8662 61.1007C27.0758 72.4243 30.7447 87.1112 42.0614 93.9057C53.379 100.701 68.0569 97.029 74.8476 85.7061C81.6387 74.3825 77.9699 59.6952 66.6526 52.9007C55.3354 46.1061 40.6571 49.7777 33.8662 61.1007Z"
      fill="#FFB71B"
    />
    <path d="M82.0347 80.1164L68.2207 98.3109L63.8271 94.9713L77.6412 76.7764L82.0347 80.1164Z" fill="#FFB71B" />
  </svg>
))
