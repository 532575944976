import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.99991 12.4622C11.36 12.4622 12.4627 11.3596 12.4627 9.99949C12.4627 8.63935 11.36 7.53674 9.99991 7.53674C8.63978 7.53674 7.53717 8.63935 7.53717 9.99949C7.53717 11.3596 8.63978 12.4622 9.99991 12.4622Z"
      fill="currentColor"
    />
    <path
      d="M19.876 9.68749C19.6956 9.49665 15.4049 4.99854 9.99872 4.99854C4.59315 4.99854 0.303011 9.49665 0.123115 9.68749C-0.0409569 9.86275 -0.0409569 10.136 0.123115 10.3105C0.303725 10.5013 4.59399 15.0015 9.99872 15.0015C15.4049 15.0015 19.6969 10.5013 19.876 10.3105C20.0413 10.136 20.0413 9.86132 19.876 9.68749ZM9.99991 13.8067C7.90124 13.8067 6.19389 12.099 6.19389 9.99863C6.19389 7.89996 7.90124 6.1913 9.99991 6.1913C12.0985 6.1913 13.8082 7.89936 13.8082 9.99863C13.8077 12.0978 12.0985 13.8067 9.99991 13.8067ZM6.98189 6.40451C5.95332 7.27008 5.28418 8.55137 5.28418 9.99863C5.28418 11.4453 5.95332 12.7267 6.98189 13.5938C4.13592 12.6785 1.90102 10.7526 1.10219 9.99863C1.90114 9.24549 4.13651 7.31934 6.98189 6.40451ZM13.0194 13.5924C14.0472 12.7262 14.7172 11.4446 14.7172 9.99863C14.7172 8.55267 14.0478 7.27198 13.0185 6.40582C15.8644 7.32112 18.0976 9.24466 18.8969 9.99863C18.0975 10.7519 15.8643 12.6758 13.0194 13.5924Z"
      fill="currentColor"
    />
  </svg>
))
