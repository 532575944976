import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.93787 20.9462H23.5727C24.3715 20.9462 25.019 20.2986 25.019 19.4999C25.019 18.7012 24.3715 18.0536 23.5727 18.0536H8.93787L10.8078 16.1838C11.3726 15.6191 11.3726 14.7033 10.8078 14.1385C10.2432 13.5736 9.3274 13.5736 8.76249 14.1385L4.42395 18.477C4.3904 18.5105 4.35877 18.5455 4.32869 18.5821C4.32136 18.5911 4.3151 18.6007 4.30796 18.6099C4.28588 18.6379 4.26419 18.6661 4.24432 18.6958C4.23854 18.7044 4.23381 18.7136 4.22813 18.7224C4.20826 18.7534 4.18879 18.7848 4.17124 18.8174C4.16786 18.8239 4.16507 18.8308 4.16169 18.8372C4.14347 18.8726 4.12592 18.9085 4.11059 18.9455C4.10866 18.95 4.10731 18.9548 4.10548 18.9595C4.08977 18.9984 4.07521 19.0379 4.06286 19.0785C4.06123 19.0836 4.06036 19.0889 4.05891 19.0941C4.04724 19.1339 4.03654 19.1741 4.02835 19.2153C4.02594 19.2275 4.02488 19.2401 4.02266 19.2525C4.01668 19.2866 4.0107 19.3208 4.00733 19.3556C4.00241 19.4033 4 19.4515 4 19.4999C4 19.5483 4.00241 19.5965 4.00733 19.6443C4.0107 19.6796 4.01687 19.714 4.02275 19.7485C4.02488 19.7604 4.02594 19.7727 4.02835 19.7845C4.03664 19.8261 4.04724 19.8667 4.05901 19.9069C4.06045 19.9116 4.06132 19.9166 4.06277 19.9213C4.07521 19.9621 4.08977 20.0019 4.10567 20.0413C4.10741 20.0455 4.10866 20.0501 4.1105 20.0543C4.12592 20.0916 4.14357 20.1277 4.16198 20.1633C4.16516 20.1695 4.16786 20.1761 4.17114 20.1823C4.18888 20.2152 4.20846 20.2468 4.22861 20.2782C4.23401 20.2867 4.23864 20.2956 4.24423 20.3039C4.26419 20.3337 4.28607 20.3621 4.30806 20.3902C4.3151 20.3993 4.32136 20.4088 4.32859 20.4177C4.35877 20.4542 4.3904 20.4893 4.42385 20.5228L8.76239 24.8613C9.0448 25.1438 9.41495 25.285 9.7851 25.285C10.1552 25.285 10.5254 25.1438 10.8077 24.8613C11.3725 24.2965 11.3725 23.3808 10.8077 22.816L8.93787 20.9462Z"
      fill="#23A4DE"
    />
    <path
      d="M23.0147 7C18.7424 7 15.3636 9.27272 13.5179 12.5351C13.0358 13.2355 12.9049 14.2591 13.4244 15.0177C14.2888 16.28 15.8979 16.1383 16.2939 16.1383C19.9152 16.1383 18.246 16.1383 21.8669 16.1383C25.4878 16.1383 26.6356 17.8192 26.6356 19.5001C26.6356 21.181 25.4878 22.8619 21.8669 22.8619C18.6484 22.8619 19.3282 22.8619 16.2939 22.8619C15.3109 22.8619 14.5722 23.4222 13.9983 23.9825C13.4244 24.5428 13.1859 25.7499 13.5352 26.4899C14.7954 29.159 18.7549 31.9999 23.0147 31.9999C30.0745 31.9999 35.8181 26.3925 35.8181 19.4999C35.8181 12.6074 30.0745 7 23.0147 7Z"
      fill="white"
    />
  </svg>
))
