import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.3899 9.32253C20.9601 4.89277 13.7521 4.89286 9.3223 9.32253C4.89245 13.7524 4.89245 20.9602 9.3223 25.39C13.0804 29.1481 18.6697 29.7173 23.0344 27.099C23.0344 27.099 23.3499 26.911 23.6037 27.165C25.0527 28.6141 29.4003 32.9616 29.4003 32.9616C30.5539 34.1153 32.1672 34.39 33.1905 33.3668L33.3665 33.1907C34.3898 32.1675 34.1153 30.5542 32.9615 29.4005C32.9615 29.4005 28.6231 25.0622 27.1771 23.6161C26.9109 23.3499 27.099 23.0345 27.099 23.0345C29.7172 18.67 29.1481 13.0807 25.3899 9.32253ZM23.2923 23.2925C20.0191 26.5657 14.6933 26.5656 11.4201 23.2924C8.14698 20.0192 8.14689 14.6934 11.4201 11.4202C14.6932 8.14704 20.0191 8.14704 23.2923 11.4202C26.5654 14.6935 26.5654 20.0192 23.2923 23.2925Z"
      fill="white"
    />
    <path
      d="M16.6821 22.5678C16.6821 22.7202 16.6522 22.875 16.5891 23.0243C16.3368 23.6206 15.6487 23.8996 15.0523 23.6472C11.3037 22.061 9.54447 17.7208 11.1307 13.9722C11.3831 13.3759 12.0712 13.0969 12.6676 13.3493C13.264 13.6018 13.5428 14.2896 13.2905 14.8862C12.2083 17.4438 13.4086 20.4052 15.9661 21.4874C16.4134 21.6767 16.6821 22.1109 16.6821 22.5678Z"
      fill="#23A4DE"
    />
  </svg>
))
