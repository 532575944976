import React from 'react';

import { NavResponsive } from '@reapit/elements';
import { useReapitConnect } from '@reapit/connect-session';

import { Routes } from '_constants';

import { reapitConnectBrowserSession } from 'core/connect-session';
import { useNavigate } from 'react-router-dom';

const getDefaultNavIndex = (pathname) => {
	switch (pathname) {
		case Routes.ACTIVITIES:
			return 1;

		default:
			return 0;
	}
};

const Nav = () => {
	const navigate = useNavigate();
	const { connectLogoutRedirect, connectIsDesktop } = useReapitConnect(
		reapitConnectBrowserSession
	);

	const options = [
		{
			itemIndex: 0,
			text: 'My Dashboard',
			iconId: 'searchMenu',
			callback: () => navigate(Routes.HOME),
		},
		{
			itemIndex: 1,
			text: 'New Activity',
			iconId: 'appsMenu',
			callback: () => navigate(Routes.ACTIVITIES),
		},
	];

	if (!connectIsDesktop) {
		options.push({
			itemIndex: 2,
			iconId: 'logoutMenu',
			text: 'Logout',
			isSecondary: true,
			callback: connectLogoutRedirect,
		});
	}

	return (
		<NavResponsive
			options={options}
			defaultNavIndex={getDefaultNavIndex(window.location.pathname)}
		/>
	);
};

export default Nav;
