import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M11.1719 0C10.848 0 10.5859 0.301133 10.5859 0.625V2.47625C10.7813 2.45758 10.9729 2.42188 11.1719 2.42188C11.371 2.42188 11.5625 2.4557 11.7578 2.47387V0.625C11.7578 0.301133 11.4957 0 11.1719 0Z"
        fill="#0061A8"
      />
      <path
        d="M15.4937 1.2558C15.2139 1.09272 14.8551 1.19057 14.6932 1.47037L13.8013 3.01526C14.1594 3.17826 14.4985 3.37151 14.8196 3.59561L15.7083 2.05631C15.8702 1.77592 15.7741 1.41772 15.4937 1.2558Z"
        fill="#0061A8"
      />
      <path
        d="M18.7107 4.47267C18.5476 4.19115 18.1888 4.09673 17.9101 4.2581L16.3816 5.1406C16.6081 5.45771 16.8057 5.79439 16.9731 6.15243L18.4961 5.27318C18.7765 5.11122 18.8726 4.75306 18.7107 4.47267Z"
        fill="#0061A8"
      />
      <path
        d="M7.57788 1.47026C7.41538 1.18874 7.05659 1.09261 6.77737 1.25569C6.49698 1.41764 6.40085 1.77581 6.5628 2.0562L7.48128 3.64706C7.79975 3.41846 8.13585 3.22042 8.4912 3.05225L7.57788 1.47026Z"
        fill="#0061A8"
      />
      <path
        d="M4.36087 4.25809C4.08048 4.09672 3.72173 4.19286 3.56036 4.47266C3.39841 4.75305 3.49454 5.11126 3.77493 5.27317L5.36837 6.19313C5.53501 5.83536 5.73044 5.4977 5.95653 5.17934L4.36087 4.25809Z"
        fill="#0061A8"
      />
      <path
        d="M0 10.926V17.5001C1.5 18.5001 1.5 18.5001 4.5 20H14.4098C9.7332 11.2459 0.764258 11.2168 0 10.926Z"
        fill="#23A4DE"
      />
      <path
        d="M17.6544 19.6201C15.5595 14.0331 10.6014 9.95209 4.71512 8.97076L0.682266 8.3051C0.512383 8.27678 0.338594 8.32463 0.207188 8.436C0.0758203 8.54729 0 8.71076 0 8.88303V9.73811C0.741719 10.0278 10.8357 10.0653 15.7155 20H17.7969L17.6544 19.6201Z"
        fill="#7BC9EB"
      />
      <path
        d="M17.2758 16.1899C17.8551 17.1399 18.3538 18.1471 18.7518 19.2082L19.0878 20.0001H19.414C19.7378 20.0001 19.9999 19.738 19.9999 19.4141V15.4458C19.0866 15.6113 18.1511 15.8647 17.2758 16.1899Z"
        fill="#23A4DE"
      />
      <path
        d="M14.9478 13.1544C15.5518 13.7884 16.9921 15.7806 17.5 16.5C18.5 16.5 18.8601 17.1918 19.9999 17V11.6912C19.0973 11.9091 17.3329 12.0477 14.9478 13.1544Z"
        fill="#23A4DE"
      />
      <path
        d="M19.7856 8.43709C19.6498 8.3258 19.4714 8.28127 19.2992 8.31572C18.7224 8.48873 16.0559 8.58959 12.5458 11.0436C13.0718 11.429 13.5753 11.8437 14.0566 12.2837C16.7889 10.9283 18.9507 10.7544 20.0001 10.4961V8.89022C20.0001 8.71471 19.9214 8.54838 19.7856 8.43709Z"
        fill="#7BC9EB"
      />
      <path
        d="M11.1718 3.59375C8.57426 3.59375 6.39859 5.53195 5.99023 8.03293C7.96707 8.49012 9.82965 9.27562 11.5104 10.3361C12.9706 9.25703 14.5973 8.40113 16.3233 7.83461C15.8394 5.40766 13.7182 3.59375 11.1718 3.59375Z"
        fill="#23A4DE"
      />
      <path
        d="M0 16.5784V19.4141C0 19.7379 0.26207 20 0.585938 20H6.04605C4.30801 18.4588 2.22652 17.2904 0 16.5784Z"
        fill="#23A4DE"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="1em" height="1em" fill="white" />
      </clipPath>
    </defs>
  </svg>
))
