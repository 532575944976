import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.09627 66.2785V71.8622C7.09627 72.4908 7.59386 73 8.20821 73H48.7938C49.4082 73 49.9058 72.4908 49.9058 71.8622V66.2785C49.9058 60.4203 45.2648 55.6742 39.5384 55.6728C37.5146 55.6714 35.7328 54.6061 34.707 52.9905C33.6765 51.37 33.7827 49.9608 33.7827 48.8219L33.7619 48.7693C38.7517 46.6558 42.2612 41.6253 42.2612 35.7557V35.3106C44.2425 35.3106 45.736 33.7084 45.736 31.8204V25.9096C45.7805 23.8516 45.6887 16.5939 40.5655 11.5407C34.6083 5.66538 25.168 6.1461 18.3296 9.28925C18.1072 9.39165 18.1142 9.71735 18.3393 9.81264L20.1226 10.5636C20.3895 10.6759 20.3325 11.0784 20.0461 11.1097L19.1802 11.205C14.5212 11.7142 10.6447 14.5601 8.71136 17.9322C8.69329 17.9649 8.69884 18.0076 8.72525 18.036C10.2653 19.6432 11.127 21.8064 11.127 24.0592V31.6142C11.127 33.6523 12.7407 35.3106 14.7408 35.3106V35.7557C14.7408 41.6253 18.2504 46.6558 23.2402 48.7693L23.2193 48.8219C23.2193 49.9285 23.3341 51.3565 22.295 52.9905C21.2693 54.6061 19.4874 55.6714 17.4637 55.6728C11.7372 55.6742 7.09627 60.4203 7.09627 66.2785Z"
      fill="#FFE5C7"
    />
    <path
      d="M8.7111 17.9322C8.69262 17.9645 8.69817 18.0078 8.72514 18.0359C10.2642 19.6436 11.1262 21.8071 11.1262 24.0594V31.6143C11.1262 33.6523 12.7395 35.3107 14.74 35.3107C14.74 31.0069 14.7377 28.7578 14.7385 27.5489C14.739 26.7855 15.4597 26.237 16.1726 26.4614C18.4134 27.1668 21.0273 27.5304 23.7743 26.9195C28.6085 25.8443 29.9776 23.9058 33.9208 22.9372C38.3268 21.8563 42.3228 25.3095 42.295 29.727C42.2867 31.1791 42.263 32.9939 42.2603 35.3107C44.2416 35.3107 45.7351 33.7086 45.7351 31.8206V25.9097C45.7795 23.8517 45.6878 16.594 40.5646 11.5408C34.6079 5.6654 25.1666 6.1464 18.3293 9.28984C18.107 9.3921 18.1128 9.71794 18.3384 9.81294L20.1218 10.5642C20.3884 10.6765 20.3322 11.0786 20.0456 11.11L19.1794 11.2052C14.52 11.7149 10.6438 14.5598 8.7111 17.9322Z"
      fill="#262F69"
    />
    <path
      d="M16.1727 26.4613C15.4598 26.2368 14.7392 26.7848 14.7386 27.548C14.7376 28.7988 14.7401 31.1619 14.7401 35.7557C14.7401 41.6253 18.2497 46.6558 23.2395 48.7693C32.2624 52.6018 42.2605 45.7934 42.2605 35.7557C42.2605 33.16 42.2861 31.3158 42.2953 29.7269C42.3231 25.3094 38.327 21.8562 33.921 22.9371C29.9778 23.9056 28.6087 25.8441 23.7746 26.9194C21.0274 27.5301 18.4135 27.1667 16.1727 26.4613Z"
      fill="#FFE5C7"
    />
    <path
      d="M7.09627 66.2785V71.8622C7.09627 72.4906 7.59414 73 8.20821 73H48.7938C49.4079 73 49.9058 72.4906 49.9058 71.8622V66.2785C49.9058 60.4203 45.2641 55.675 39.5391 55.6728C37.5148 55.6721 35.7325 54.6056 34.707 52.9905C31.1274 56.1197 25.8767 56.1215 22.295 52.9905C21.2697 54.6056 19.4873 55.6721 17.463 55.6728C11.7379 55.675 7.09627 60.4203 7.09627 66.2785Z"
      fill="#0061A8"
    />
    <path
      d="M62.2495 68.9999H43.8211C43.8207 68.9999 43.8202 68.9999 43.8197 68.9999C42.4072 68.9957 41.2619 67.8503 41.2578 66.4377V27.5622C41.2619 26.1495 42.4074 25.004 43.82 25H62.2506C63.6632 25.004 64.8086 26.1494 64.8128 27.5619V66.4384C64.8079 67.8515 63.6633 68.9957 62.2509 68.9999C62.2504 68.9999 62.25 68.9999 62.2495 68.9999Z"
      fill="#23A4DE"
      stroke="#262F69"
      strokeWidth="2"
    />
    <path
      d="M62.2519 70H43.8187C41.8548 69.9952 40.2626 68.403 40.2578 66.4391V61.929C51.9995 61.929 55.3641 61.929 65.8128 61.929V66.4403C65.8068 68.4042 64.2158 69.9952 62.2519 70Z"
      fill="#31448F"
    />
    <rect x="44.8585" y="28.3701" width="12.65" height="12.65" rx="1" fill="#7BC9EB" />
    <rect x="44.8585" y="53.6699" width="16.1" height="5.75001" rx="1" fill="#7BC9EB" />
    <rect x="44.8585" y="43.3201" width="11.5" height="2.3" rx="1" fill="#7BC9EB" />
    <rect x="44.8585" y="46.77" width="16.1" height="2.3" rx="1" fill="#7BC9EB" />
    <path
      d="M72.671 55.1578L68.1553 59.6735C67.7207 60.1096 67.0105 60.1123 66.5732 59.6735L63.1704 56.2699L61.3989 59.264C60.8963 60.1137 59.6115 59.9395 59.3559 58.9843L55.2229 43.5949C55.0001 42.7646 55.7615 42.0013 56.5938 42.2247L71.981 46.3577C72.9359 46.6132 73.1111 47.8968 72.2607 48.4014L69.2674 50.1715L72.671 53.5758C73.1073 54.0129 73.1073 54.7208 72.671 55.1578Z"
      fill="white"
    />
    <path
      d="M52.0165 42.6547C54.6477 42.0327 56.2765 39.3955 55.6546 36.7643C55.0326 34.1331 52.3954 32.5043 49.7642 33.1263C47.133 33.7482 45.5042 36.3854 46.1262 39.0166C46.7481 41.6478 49.3853 43.2766 52.0165 42.6547Z"
      fill="#FFB71B"
    />
  </svg>
))
