import React from 'react';

import { useNavigate } from 'react-router-dom';

import { FlexContainer, Icon as ReapitIcon } from '@reapit/elements';

import logo from 'assets/images/brand/tf-logo.svg';

import { TopNavContainer, TFLogo } from './__styles__';

import { SpacerHorizontal } from 'components/ui/sized-components';

import { SmallTextNoMargin } from 'components/ui/typography';

import { Routes, Support } from '_constants';

const TopNav = () => {
	const navigate = useNavigate();

	const handleLogoClick = () => {
		navigate(Routes.HOME);
	};

	return (
		<TopNavContainer>
			<TFLogo src={logo} alt="thirdfort logo" onClick={handleLogoClick} />
			<a href={Support.customerHub} rel="noreferrer">
				<FlexContainer isFlexAlignCenter>
					<SmallTextNoMargin>Need help?</SmallTextNoMargin>
					<SpacerHorizontal />
					<ReapitIcon
						icon="infoSolidSystem"
						iconSize="medium"
						intent="primary"
					/>
				</FlexContainer>
			</a>
		</TopNavContainer>
	);
};

export default TopNav;
