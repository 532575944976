import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.47059 10.0046L15.1617 1.31351C15.4622 1.01301 15.4622 0.525816 15.1617 0.225311C14.8611 -0.0751037 14.374 -0.0751037 14.0735 0.225311L4.83829 9.46052C4.53787 9.76103 4.53787 10.2482 4.83829 10.5487L14.0735 19.7839C14.3792 20.0792 14.8664 20.0707 15.1617 19.765C15.4497 19.4667 15.4497 18.9939 15.1617 18.6957L6.47059 10.0046Z"
      fill="currentColor"
    />
  </svg>
))
