import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M19.6001 14.5722L12.5933 2.4358C11.4488 0.430905 8.55468 0.430905 7.41431 2.4358L0.403358 14.5722C-0.74112 16.5771 0.683302 19.0678 2.99284 19.0678H16.9859C19.2955 19.0678 20.7446 16.5524 19.6001 14.5722ZM9.99968 16.3754C9.39039 16.3754 8.88402 15.869 8.88402 15.2597C8.88402 14.6504 9.39039 14.1441 9.99968 14.1441C10.609 14.1441 11.1153 14.6504 11.0906 15.2885C11.1195 15.869 10.5843 16.3754 9.99968 16.3754ZM11.0165 9.16269C10.9671 10.0272 10.9136 10.8876 10.8642 11.7522C10.8395 12.0321 10.8395 12.2874 10.8395 12.5632C10.8148 13.0202 10.4566 13.3742 9.99968 13.3742C9.54271 13.3742 9.18867 13.0449 9.15985 12.5879C9.08575 11.2417 9.00753 9.92019 8.93342 8.57399C8.90872 8.21994 8.88402 7.86178 8.8552 7.50773C8.8552 6.92314 9.18455 6.44147 9.71974 6.28915C10.2549 6.16153 10.786 6.41677 11.0165 6.92314C11.0948 7.10016 11.1195 7.27719 11.1195 7.48303C11.0948 8.04704 11.0412 8.60692 11.0165 9.16269Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="1em" height="1em" fill="white" />
      </clipPath>
    </defs>
  </svg>
))
