import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const AgencyCloudContext = createContext();

const ACContextProvider = ({ children }) => {
	const reapitGlobals = window.__REAPIT_MARKETPLACE_GLOBALS__ || {};

	return (
		<AgencyCloudContext.Provider value={reapitGlobals}>
			{children}
		</AgencyCloudContext.Provider>
	);
};

ACContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export const useAgencyCloud = () => {
	const acContext = useContext(AgencyCloudContext);

	if (!acContext) {
		throw new Error('Invalid use of useAgencyCloud hook detected');
	}

	return acContext;
};

export default ACContextProvider;
