import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M10 6.78137C8.22421 6.78137 6.78137 8.22421 6.78137 10C6.78137 11.7758 8.22421 13.2187 10 13.2187C11.7758 13.2187 13.2187 11.7758 13.2187 10C13.2187 8.22421 11.7758 6.78137 10 6.78137ZM9.75584 9.04552C9.35629 9.04552 9.02332 9.37849 9.02332 9.77804H7.95784C7.98004 8.77915 8.77915 7.98004 9.75584 7.98004V9.04552Z"
        fill="currentColor"
      />
      <path
        d="M19.7669 9.33398C18.6792 7.97993 14.7947 3.56262 10 3.56262C5.20533 3.56262 1.32075 7.97993 0.233074 9.33398C-0.0776914 9.71134 -0.0776914 10.2663 0.233074 10.6658C1.32075 12.0199 5.20533 16.4372 10 16.4372C14.7947 16.4372 18.6792 12.0199 19.7669 10.6658C20.0777 10.2885 20.0777 9.73354 19.7669 9.33398ZM10 14.6614C7.42508 14.6614 5.33851 12.5748 5.33851 9.99991C5.33851 7.425 7.42508 5.33843 10 5.33843C12.5749 5.33843 14.6615 7.425 14.6615 9.99991C14.6615 12.5748 12.5749 14.6614 10 14.6614Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="1em" height="1em" fill="white" />
      </clipPath>
    </defs>
  </svg>
))
