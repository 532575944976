import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.0373 10H9.96266C7.77789 10 6 11.7779 6 13.9632V26.5227C6 28.708 7.77789 30.4859 9.96266 30.4859H30.0373C32.2221 30.4859 34 28.708 34 26.5227V13.9632C34 11.7779 32.2221 10 30.0373 10ZM16.1615 16.3049L11.9927 20.4738L15.6999 24.181C16.0204 24.5015 16.0204 25.021 15.6999 25.3409C15.38 25.6614 14.8605 25.6614 14.54 25.3409L10.2525 21.0534C9.93203 20.733 9.93203 20.214 10.2525 19.8935L15.001 15.145C15.3215 14.8245 15.841 14.8245 16.1615 15.145C16.4814 15.4649 16.4814 15.9845 16.1615 16.3049ZM22.928 14.375L18.5902 26.6573C18.4392 27.0849 17.9705 27.3086 17.5434 27.1577C17.1163 27.0067 16.8921 26.538 17.043 26.1109L21.3809 13.8287C21.5318 13.4016 21.9999 13.1773 22.4276 13.3283C22.8547 13.4792 23.0789 13.9479 22.928 14.375ZM29.7475 20.5924L24.999 25.3409C24.6785 25.6614 24.159 25.6614 23.8385 25.3409C23.5186 25.021 23.5186 24.5015 23.8385 24.181L28.0073 20.0122L24.3001 16.3049C23.9796 15.9845 23.9796 15.4649 24.3001 15.145C24.62 14.8245 25.1395 14.8245 25.46 15.145L29.7475 19.4325C30.068 19.753 30.068 20.272 29.7475 20.5924Z"
      fill="#23A4DE"
    />
    <path
      d="M30.0373 10H22.3809C19.5 18 18 22.5 15 30.4859H30.0373C32.2221 30.4859 34 28.708 34 26.5227V13.9632C34 11.7779 32.2221 10 30.0373 10ZM22.928 14.375L18.5901 26.6573C18.4392 27.0849 17.9705 27.3086 17.5434 27.1577C17.1163 27.0067 16.8921 26.538 17.043 26.1109L21.3808 13.8287C21.5318 13.4016 21.9999 13.1773 22.4276 13.3283C22.8547 13.4792 23.0789 13.9479 22.928 14.375ZM29.7475 20.5924L24.999 25.3409C24.6785 25.6614 24.159 25.6614 23.8385 25.3409C23.5186 25.021 23.5186 24.5015 23.8385 24.181L28.0073 20.0122L24.3001 16.3049C23.9796 15.9845 23.9796 15.4649 24.3001 15.145C24.62 14.8245 25.1395 14.8245 25.46 15.145L29.7475 19.4325C30.068 19.753 30.068 20.272 29.7475 20.5924Z"
      fill="white"
    />
  </svg>
))
