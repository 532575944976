import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M1.4375 11.5L12.375 1L23.3125 11.5V26H1.4375V11.5Z" fill="#262F69" />
      <path d="M1.4375 11.5L12.375 1L23.3125 11.5V19.75L1.4375 11.5Z" fill="#7BC9EB" />
      <path d="M23.3124 26H16.91L7.83984 5.35366L12.3749 1L23.3124 11.5V26Z" fill="#0061A8" />
      <path d="M23.3124 11.5V19.75L12.3749 15.6768L7.83984 5.35366L12.3749 1L23.3124 11.5Z" fill="#23A4DE" />
      <path
        d="M27.7259 5.30862C26.4143 1.7405 22.7996 0.053403 19.6522 1.54042C17.5002 2.55715 16.037 4.86151 15.8693 7.4978C14.0507 7.78294 12.7802 9.68557 13.0317 11.7474C13.2593 13.6131 14.6668 15.0019 16.3281 15H27.7258C30.0864 15 32 12.8305 32 10.1543C32 7.47817 30.0865 5.30862 27.7259 5.30862Z"
        fill="#F2F2F2"
      />
      <path
        d="M31.2351 13.6096C31.2351 13.6096 30.5092 13.3042 30.2873 12.7562C30.0642 12.2074 30.362 11.4587 30.362 11.4587C30.4265 11.2958 30.3885 11.0534 30.2774 10.9204L29.5004 10.126C29.3708 10.0118 29.1363 9.97494 28.9796 10.0429C28.9796 10.0429 28.2587 10.3579 27.7262 10.1321C27.1929 9.90719 26.8921 9.16004 26.8921 9.16004C26.8267 8.99761 26.6342 8.85456 26.4645 8.84266L25.3715 8.84724C25.2021 8.8618 25.0107 9.00723 24.9466 9.17036C24.9466 9.17036 24.6522 9.91948 24.1216 10.1501C23.5909 10.3808 22.8671 10.0735 22.8671 10.0735C22.7095 10.0065 22.4756 10.046 22.3469 10.1609L21.5784 10.9645C21.4679 11.0985 21.432 11.3411 21.4979 11.5031C21.4979 11.5031 21.8025 12.248 21.5848 12.7986C21.3666 13.3493 20.6448 13.6612 20.6448 13.6612C20.4878 13.7291 20.3499 13.9284 20.338 14.104L20.3426 15.2336C20.3568 15.4091 20.4971 15.6068 20.6548 15.6731C20.6548 15.6731 21.3796 15.978 21.6016 16.5263C21.8246 17.0744 21.5268 17.8231 21.5268 17.8231C21.4623 17.9861 21.5003 18.228 21.6117 18.3614L22.3887 19.1554C22.518 19.27 22.7526 19.3071 22.9092 19.2389C22.9092 19.2389 23.6306 18.9251 24.163 19.1496C24.6954 19.3747 24.9968 20.122 24.9968 20.122C25.0625 20.2844 25.2549 20.4271 25.425 20.4396L26.5167 20.4343C26.6864 20.4197 26.8776 20.2746 26.9424 20.1114C26.9424 20.1114 27.2374 19.3628 27.7674 19.1322C28.2977 18.9016 29.022 19.2095 29.022 19.2095C29.1796 19.2765 29.4135 19.237 29.5422 19.1221L30.3105 18.3192C30.421 18.1855 30.457 17.9433 30.3912 17.7809C30.3912 17.7809 30.0871 17.0343 30.3041 16.4838C30.5223 15.9325 31.245 15.6211 31.245 15.6211C31.4018 15.5535 31.54 15.3541 31.5519 15.1786L31.5473 14.0493C31.5331 13.8736 31.3926 13.6759 31.2351 13.6096ZM27.98 15.5005C27.52 16.6623 26.2373 17.22 25.1136 16.7447C23.99 16.2699 23.4515 14.9424 23.9099 13.7808C24.3695 12.6185 25.6533 12.0618 26.7765 12.5359C27.9 13.0117 28.4386 14.3389 27.98 15.5005Z"
        fill="#7BC9EB"
      />
    </g>
    <defs>
      <rect width="1em" height="1em" fill="white" />
    </defs>
  </svg>
))
