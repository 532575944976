import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M119.024 61.1067L69.9983 43.8652L20.9727 61.1067L69.9983 78.6096L119.024 61.1067Z" fill="#23A4DE" />
    <path d="M119.022 61.1067L69.9961 43.8652V78.6096L119.022 61.1067Z" fill="#0061A8" />
    <path
      d="M88.5528 17.4922H50.3176C46.6078 17.4922 43.5898 20.5716 43.5898 24.3558V106.549C43.5898 110.333 46.6078 113.412 50.3176 113.412H88.5528C92.2626 113.412 95.2814 110.333 95.2814 106.549V24.3558C95.2814 20.5716 92.2626 17.4922 88.5528 17.4922V17.4922Z"
      fill="#262F69"
    />
    <path
      d="M88.529 21.4658H82.8467C82.3106 21.4658 81.8764 21.9086 81.8764 22.4547C81.8764 23.7663 80.8249 24.839 79.5399 24.839H59.2847C57.9988 24.839 56.9473 23.7663 56.9473 22.4547C56.9473 21.9086 56.5132 21.4658 55.977 21.4658H50.2947C48.7295 21.4658 47.4609 22.7599 47.4609 24.3556V89.2042H91.3628V24.3556C91.3628 22.7599 90.0942 21.4658 88.529 21.4658Z"
      fill="white"
    />
    <path d="M50.8828 54.0697L69.4588 35.8542L88.0348 54.0697V79.2243H50.8828V54.0697Z" fill="#262F69" />
    <path d="M50.8828 54.0697L69.4588 35.8542L88.0348 54.0697V68.3818L50.8828 54.0697Z" fill="#7BC9EB" />
    <path d="M88.036 79.2243H77.1623L61.7578 43.407L69.46 35.8542L88.036 54.0697V79.2243Z" fill="#0061A8" />
    <path d="M88.036 54.0697V68.3818L69.46 61.3156L61.7578 43.407L69.46 35.8542L88.036 54.0697Z" fill="#32BCFA" />
    <path d="M69.9983 78.6575L20.9727 61.1064V124.223L69.9983 140V78.6575Z" fill="#31448F" />
    <path d="M119.022 124.223V61.1064L69.9961 78.6575V140L119.022 124.223Z" fill="#262F69" />
    <path d="M20.9715 61.1074L4 79.4841L53.9483 97.9011L69.9972 78.6103L20.9715 61.1074Z" fill="#7BC9EB" />
    <path d="M119.022 61.1064L135.999 78.4831L86.0506 96.9002L69.9961 78.6093L119.022 61.1064Z" fill="#23A4DE" />
    <rect
      width="17.8142"
      height="17.8731"
      rx="4"
      transform="matrix(0.763205 -0.646157 0.632487 0.774571 77.7969 52.2031)"
      fill="#FFE5C7"
    />
    <rect
      width="33.4161"
      height="33.6394"
      rx="6"
      transform="matrix(0.850452 0.526053 -0.51502 0.857178 34.8359 8)"
      fill="#FFB71B"
    />
    <path
      d="M47.877 104.231C44.3955 103.212 40.8898 105.734 40.6057 109.205C40.5963 109.319 40.668 109.426 40.7768 109.461C41.5859 109.724 42.3338 109.911 43.1872 109.968C43.3178 109.977 43.4237 109.871 43.4228 109.739C43.4192 109.234 43.5986 108.634 43.8918 108.193C44.2625 107.653 44.7885 107.254 45.457 107.073C46.3031 106.824 47.4594 107.277 48.4562 107.561C48.456 107.559 48.4557 107.558 48.4554 107.556L52.1237 108.657C53.5855 109.096 54.4364 110.656 54.0145 112.123C53.6076 113.595 52.0786 114.44 50.6168 114.001L48.3841 113.34C48.2807 113.309 48.1709 113.34 48.099 113.419C47.3345 114.261 46.0477 115.06 44.9219 115.38C44.8904 115.389 44.8911 115.434 44.9228 115.444L49.6517 116.863C52.6726 117.77 55.8575 116.031 56.7293 112.999C57.619 109.905 55.8246 106.616 52.7417 105.691L47.8965 104.236C47.89 104.235 47.8835 104.233 47.877 104.231Z"
      fill="#F2F2F2"
    />
    <path
      d="M42.8304 114.371C42.8302 114.372 42.8301 114.372 42.8299 114.372C42.8318 114.373 42.8336 114.374 42.8355 114.376C44.2966 114.398 45.651 113.827 46.7477 112.857L46.7457 112.856L46.7455 112.854C47.713 111.972 48.4101 110.607 48.5325 109.198C48.543 109.076 48.4591 108.937 48.3476 108.889C47.6463 108.587 46.8333 108.359 45.9672 108.387C45.8312 108.391 45.7241 108.5 45.7211 108.638C45.7097 109.154 45.5734 109.688 45.266 110.162L45.2664 110.163C45.0586 110.515 44.5728 111.014 43.8803 111.234C43.8803 111.234 43.8802 111.233 43.8802 111.233C42.9873 111.572 41.7596 111.124 40.7025 110.812L40.7024 110.812C39.9013 110.572 37.8689 109.961 37.054 109.717C35.5922 109.278 34.7292 107.715 35.1361 106.243C35.5431 104.772 37.072 103.927 38.5338 104.365L40.7733 105.028C40.8748 105.058 40.9828 105.029 41.0547 104.953C41.8472 104.11 43.1006 103.32 44.2145 102.982C44.2456 102.972 44.2445 102.928 44.213 102.918L39.5553 101.52C36.5034 100.604 33.2858 102.36 32.4051 105.423C31.5555 108.496 33.3572 111.759 36.409 112.675C37.5352 112.958 41.7999 114.468 42.8304 114.371Z"
      fill="#F2F2F2"
    />
    <rect
      width="13.4768"
      height="13.6369"
      rx="3"
      transform="matrix(0.950509 0.310697 -0.30262 0.953111 102.711 9.05469)"
      fill="#FFE5C7"
    />
  </svg>
))
