import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_8061_91394" maskUnits="userSpaceOnUse" x="6" y="5" width="58" height="58">
      <ellipse
        rx="28.5101"
        ry="28.5047"
        transform="matrix(0.998843 -0.0480906 0.0481086 0.998842 35.3523 34.1573)"
        fill="#C4C4C4"
      />
    </mask>
    <g mask="url(#mask0_8061_91394)">
      <rect
        width="67.8919"
        height="62.1751"
        transform="matrix(0.998843 -0.0480906 0.0481086 0.998842 2.23828 4.44629)"
        fill="#F2F2F2"
      />
      <path
        d="M67.3827 63.3253C67.3827 63.3253 61.1073 63.6274 56.2983 60.3044C49.0707 58.2065 51.5984 43.3651 52.1275 42.796C52.6565 42.2268 57.6216 42.4831 60.4864 43.6714C64.4265 45.3058 64.4268 48.0425 65.5146 50.6618C67.0936 54.4636 67.0936 57.3239 67.0936 57.3239C68.1548 57.2728 66.406 60.7137 67.3827 63.3253Z"
        fill="#7BC9EB"
      />
      <path
        d="M40.092 46.3553C39.8609 47.956 34.6363 54.4311 34.2983 63.1964C40.9698 69.3269 50.906 69.9389 58.2866 65.3127C57.3162 58.0821 54.252 46.969 52.625 43.726C50.3634 39.2184 45.9119 38.3903 45.9119 38.3903C45.9119 38.3903 40.3949 44.2581 40.092 46.3553Z"
        fill="#7BC9EB"
      />
      <ellipse
        rx="4.92615"
        ry="3.89608"
        transform="matrix(0.998843 -0.0480906 0.0481086 0.998842 47.2036 42.7186)"
        fill="#0076AB"
      />
      <path
        d="M33.1117 72.9042C33.1117 72.9042 32.469 62.4664 37.567 58.2715C42.6651 54.077 42.6258 43.6087 42.2044 43.0222C41.7832 42.4356 33.8188 42.7928 31.5427 44.8974C27.3031 48.8174 27.2921 55.4803 25.6994 60.957C23.279 59.6312 33.84 70.3859 33.1117 72.9042Z"
        fill="#7BC9EB"
      />
      <path
        d="M34.7825 62.0232C35.0772 59.449 36.8904 53.4845 36.8904 53.4845C36.8904 53.4845 36.1044 60.3489 38.1733 63.5412C37.0479 63.4993 35.7534 62.6558 34.7825 62.0232Z"
        fill="#0076AB"
      />
      <path
        d="M59.5746 61.173C59.2701 58.5753 57.4237 52.5593 57.4237 52.5593C57.4237 52.5593 58.2356 59.4865 56.1558 62.7144C57.2918 62.6692 58.5962 61.8142 59.5746 61.173Z"
        fill="#0076AB"
      />
    </g>
    <mask id="mask1_8061_91394" maskUnits="userSpaceOnUse" x="0" y="7" width="43" height="29">
      <path
        d="M33.172 15.9559C30.2174 8.7236 22.0748 5.30397 14.985 8.31804C10.1374 10.3789 6.84127 15.0497 6.46356 20.3932C2.36681 20.9712 -0.495046 24.8277 0.0715076 29.007C0.584204 32.7886 3.75476 35.6034 7.49698 35.5996H33.1719C38.4893 35.5996 42.8 31.2022 42.8 25.7778C42.8 20.3534 38.4895 15.9559 33.172 15.9559Z"
        fill="#7BC9EB"
      />
    </mask>
    <g mask="url(#mask1_8061_91394)">
      <rect x="-6.09766" y="2.64941" width="51.1255" height="39.5167" fill="#0061A8" />
      <path d="M32.8367 51.16L23.2589 27.7486L0 18.0181V51.16H32.8367Z" fill="#262F69" />
      <path d="M46.5528 51.1595V38.2665V37.4324L23.2363 27.7598L32.8372 51.1595H46.5528Z" fill="#0061A8" />
      <path d="M0 18.0174L23.2358 27.7595L13.6464 4.39453L0 18.0174Z" fill="#7BC9EB" />
      <path
        d="M46.5522 18.0176L23.3048 -5.32422L19.6973 -1.71L13.6348 4.40637L23.2357 27.7598L46.5522 37.4324V18.0176Z"
        fill="#23A4DE"
      />
    </g>
    <path
      d="M38.2117 29.587C38.3178 31.788 36.738 33.6526 34.6834 33.7515C32.6286 33.8505 30.8769 32.1463 30.7709 29.9453C30.6649 27.744 32.2447 25.8795 34.2995 25.7805C36.3541 25.6816 38.1057 27.3858 38.2117 29.587Z"
      fill="#FFE5C7"
    />
    <path
      d="M63.0067 28.1693C63.1127 30.3706 61.5329 32.2351 59.4784 32.3341C57.4235 32.433 55.6719 30.7288 55.5658 28.5276C55.4598 26.3266 57.0396 24.4617 59.0944 24.3628C61.149 24.2639 62.9007 25.9683 63.0067 28.1693Z"
      fill="#FFE5C7"
    />
    <path
      d="M33.7101 27.8833C33.7101 27.8833 35.0517 45.1041 47.4661 44.5064C59.8804 43.9087 60.7677 30.4218 60.2393 25.5304C59.7112 20.639 57.1549 13.1565 57.1549 13.1565L46.6605 12.5862L37.5482 14.8689L33.3651 17.6823L33.2243 20.8387L33.7101 27.8833Z"
      fill="#FFE5C7"
    />
    <path
      d="M39.9253 21.8109C39.9052 22.1664 40.3516 22.0897 40.7207 22.0287C41.4886 21.902 41.2594 21.3359 42.8773 21.3209C43.4643 21.3155 44.4228 22.2458 44.6936 21.7432C45.4631 20.3151 40.0641 19.3684 39.9253 21.8109Z"
      fill="#262F69"
    />
    <path
      d="M56.6052 21.3953C56.2369 20.7056 55.0168 20.4094 54.1719 20.5029C53.4645 20.5812 52.28 20.9358 52.3309 21.66C52.3465 21.8817 52.6786 21.8417 52.9369 21.7826C53.3381 21.6908 53.4998 21.5272 53.8877 21.4918C54.2649 21.4572 54.6533 21.5218 54.985 21.6719C55.3867 21.8538 55.6722 22.1343 56.179 21.992C56.634 21.8641 56.7897 21.7408 56.6052 21.3953Z"
      fill="#262F69"
    />
    <path
      d="M54.6627 25.6805C54.6223 25.6575 54.5794 25.6374 54.5341 25.6208C53.0371 25.0713 52.5291 27.4431 53.6218 27.828C55.1994 28.3837 55.5997 26.2158 54.6627 25.6805Z"
      fill="#262F69"
    />
    <path
      d="M54.4768 25.9434C54.3108 25.786 53.995 25.9259 54.0401 26.1402C54.0631 26.2494 54.0849 26.3367 54.1859 26.4111C54.3397 26.5249 54.648 26.3917 54.6355 26.2104C54.6273 26.0924 54.5633 26.0254 54.4768 25.9434Z"
      fill="white"
    />
    <path
      d="M42.2294 36.3121C42.2294 36.3121 44.1635 39.1682 47.8118 37.9726C45.8439 39.5409 42.9696 40.1524 42.2294 36.3121Z"
      fill="#262F69"
    />
    <path
      d="M36.4496 9.94506C37.0627 4.67159 43.129 2.45243 49.0813 3.56784C60.7319 5.75108 64.6564 15.9543 59.9961 25.7654C59.2603 23.5579 60.2637 20.0191 58.0339 16.8128C54.6477 11.9441 51.8849 13.8558 46.138 14.6053C41.6404 15.1918 38.4117 11.7846 38.4117 11.7846C32.7704 16.4449 39.7608 19.3882 34.1194 26.9918C31.7893 23.0674 28.3554 11.2941 36.4496 9.94506Z"
      fill="#262F69"
    />
    <path
      d="M36.346 16.0724C35.5575 13.6593 36.7264 10.2912 39.853 9.82985C37.3019 12.5571 38.0002 15.7077 39.1598 19.0729C37.8742 18.4224 36.7936 17.4419 36.346 16.0724Z"
      fill="#262F69"
    />
    <path
      d="M33.2409 9.5763C34.5433 8.60308 36.8265 8.62174 37.7475 10.4242C35.3794 11.4548 33.3723 9.77726 31.9973 11.8987C32.1284 10.9853 32.5018 10.1286 33.2409 9.5763Z"
      fill="#262F69"
    />
    <path
      d="M35.8351 11.953C38.0329 9.28766 35.9055 5.46998 39.5558 4.44794C35.7908 4.69709 33.5204 9.98365 35.8351 11.953Z"
      fill="#262F69"
    />
    <path
      d="M44.1906 18.5289C44.0704 11.7632 39.6677 12.5872 39.1053 7.94505C31.3874 13.8397 41.4174 14.4882 44.1906 18.5289Z"
      fill="#262F69"
    />
    <path
      d="M42.8148 25.4694C42.771 25.4444 42.7245 25.4226 42.6754 25.4046C41.0528 24.8091 40.5022 27.3798 41.6866 27.7971C43.3965 28.3994 43.8304 26.0495 42.8148 25.4694Z"
      fill="#262F69"
    />
    <path
      d="M42.6141 25.754C42.4342 25.5834 42.0919 25.7351 42.1407 25.9673C42.1658 26.0856 42.1893 26.1803 42.2987 26.261C42.4655 26.3843 42.7997 26.2401 42.7861 26.0435C42.7772 25.9156 42.7078 25.8429 42.6141 25.754Z"
      fill="white"
    />
    <path
      d="M35.9902 23.5714L35.9902 30.903C35.9902 31.1792 36.2167 31.4051 36.4934 31.4051L45.5577 31.4051C45.8345 31.4051 46.0609 31.1791 46.0609 30.903L46.0611 27.6891L49.1814 27.6891L49.1814 30.903C49.1814 31.1792 49.4078 31.4051 49.6846 31.4051L58.7489 31.4051C59.0257 31.4051 59.2521 31.1791 59.2521 30.903L59.2521 23.5714C59.2521 23.2953 59.0257 23.0693 58.7489 23.0693L49.6846 23.0693C49.4078 23.0693 49.1814 23.2953 49.1814 23.5714L49.1814 26.6849L46.0358 26.6849L46.0358 23.5714C46.0358 23.2953 45.8094 23.0693 45.5326 23.0693L36.4935 23.0693C36.2167 23.0693 35.9902 23.2953 35.9902 23.5714ZM50.433 24.8096L58.0003 24.8096L58.0003 29.6651L50.433 29.6649L50.433 24.8096ZM37.242 24.8096L44.8092 24.8096L44.8092 29.6651L37.242 29.6649L37.242 24.8096Z"
      fill="#262F69"
    />
    <path
      d="M29.8621 39.1997H12.2728C10.3586 39.1997 8.80078 40.7083 8.80078 42.5626V53.2199C8.80078 55.0742 10.3586 56.5828 12.2728 56.5828H29.8621C31.7763 56.5828 33.3341 55.0742 33.3341 53.2199V42.5626C33.3341 40.7083 31.7763 39.1997 29.8621 39.1997Z"
      fill="white"
    />
    <path
      d="M29.8618 39.1997H24.8869C22.9726 39.1997 21.4148 40.7083 21.4148 42.5626L17.7871 53.2199C17.7871 55.0742 19.3449 56.5828 21.2592 56.5828H29.8618C31.7761 56.5828 33.3339 55.0742 33.3339 53.2199V42.5626C33.3339 40.7083 31.7761 39.1997 29.8618 39.1997Z"
      fill="white"
    />
    <path
      d="M29.8621 39.1997H12.2728C10.3586 39.1997 8.80078 40.7083 8.80078 42.5626V53.2199C8.80078 55.0742 10.3586 56.5828 12.2728 56.5828H29.8621C31.7763 56.5828 33.3341 55.0742 33.3341 53.2199V42.5626C33.3341 40.7083 31.7763 39.1997 29.8621 39.1997ZM17.7042 44.5497L14.0515 48.0871L17.2998 51.2328C17.5806 51.5047 17.5806 51.9456 17.2998 52.2171C17.0194 52.489 16.5642 52.489 16.2834 52.2171L12.5268 48.579C12.246 48.307 12.246 47.8666 12.5268 47.5947L16.6874 43.5654C16.9682 43.2935 17.4234 43.2935 17.7042 43.5654C17.9845 43.8369 17.9845 44.2777 17.7042 44.5497ZM23.9869 42.9121L20.1862 53.334C20.0539 53.6969 19.6433 53.8867 19.269 53.7586C18.8948 53.6305 18.6984 53.2328 18.8306 52.8704L22.6314 42.4485C22.7636 42.0861 23.1738 41.8958 23.5485 42.0239C23.9227 42.152 24.1192 42.5496 23.9869 42.9121ZM29.6081 48.1878L25.4475 52.2171C25.1667 52.489 24.7115 52.489 24.4307 52.2171C24.1504 51.9456 24.1504 51.5047 24.4307 51.2328L28.0834 47.6954L24.8351 44.5497C24.5543 44.2777 24.5543 43.8369 24.8351 43.5654C25.1154 43.2935 25.5707 43.2935 25.8514 43.5654L29.6081 47.2035C29.8889 47.4755 29.8889 47.9158 29.6081 48.1878Z"
      fill="#FFB71B"
    />
  </svg>
))
