import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M14.2979 10.6312L12.295 8.42181C12.1703 8.28427 12.0556 8.00086 12.0498 7.81505L11.9762 5.52257V5.45615C11.9762 5.1029 11.6887 4.81543 11.3354 4.81543H9.37485H8.59574C8.28008 4.81543 7.9279 5.03904 7.79356 5.32459L5.15424 10.9309C5.0812 11.0863 5.07394 11.26 5.13374 11.4204C5.19354 11.5805 5.31314 11.707 5.47011 11.7762L5.85903 11.9477C5.94189 11.9842 6.03031 12.0028 6.12193 12.0028C6.37331 12.0028 6.60739 11.8599 6.71866 11.6388L7.95503 9.17914V11.6515C7.9499 11.6658 7.94243 11.6788 7.93773 11.6933L5.84835 18.2367C5.76143 18.5093 5.78791 18.7961 5.9231 19.0443C6.05829 19.2924 6.28468 19.4701 6.56104 19.5447L6.70072 19.5825C6.79212 19.6072 6.88695 19.6198 6.9822 19.6198C7.46295 19.6198 7.89971 19.3035 8.04451 18.8503L9.99678 12.7362L10.6892 14.4322C10.8581 14.8459 11.0405 15.5703 11.0875 16.0145L11.4187 19.1372C11.4719 19.6371 11.8672 20 12.3589 20C12.4394 20 12.5212 19.9902 12.6017 19.9707L12.7425 19.9366C13.3029 19.8009 13.6988 19.2252 13.6252 18.6534L13.1346 14.8459C13.0722 14.3621 12.8636 13.6046 12.6694 13.1571L12.0069 11.6314C11.9984 11.6115 11.9862 11.5936 11.9764 11.5744V10.5404L13.1702 11.7869C13.289 11.9109 13.4479 11.9793 13.6175 11.9793C13.789 11.9793 13.9491 11.9097 14.0683 11.7832L14.2892 11.5487C14.5254 11.2978 14.5294 10.8864 14.2979 10.6312Z"
        fill="currentColor"
      />
      <path
        d="M10.1437 4.27251C11.3218 4.27251 12.2801 3.31421 12.2801 2.13615C12.2801 0.958299 11.3218 0 10.1437 0C8.96584 0 8.00775 0.958299 8.00775 2.13615C8.00754 3.31421 8.96584 4.27251 10.1437 4.27251Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="1em" height="1em" fill="white" />
      </clipPath>
    </defs>
  </svg>
))
