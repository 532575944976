import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.0759 12.7509L23.4409 9.92174L22.892 8.81225C22.5328 8.09108 21.6569 7.79327 20.9358 8.1524L11.6569 12.7509H13.0759Z"
      fill="#23A4DE"
    />
    <path
      d="M26.6584 10.0037C26.5299 10.0037 26.4014 10.0212 26.273 10.0562L23.838 10.7219L16.4014 12.7511H24.8423H28.5211L28.0657 11.081C27.8905 10.4299 27.3007 10.0037 26.6584 10.0037Z"
      fill="#23A4DE"
    />
    <path
      d="M30.0424 13.7729H29.7095H29.257H28.8044H25.3504H12.6584H10.9942H9.5927H9.33285H8.46278C8.00146 13.7729 7.58978 13.9861 7.32117 14.3219C7.19854 14.4766 7.10511 14.6547 7.05255 14.8503C7.02044 14.973 7 15.1014 7 15.2328V15.408V17.0722V31.4985C7 32.3044 7.65402 32.9584 8.45986 32.9584H30.0394C30.8453 32.9584 31.4993 32.3044 31.4993 31.4985V27.4255H22.8365C21.4672 27.4255 20.3548 26.3131 20.3548 24.9438V23.6065V23.154V22.7014V21.697C20.3548 21.0255 20.6233 20.4153 21.0584 19.9686C21.4438 19.5715 21.9606 19.3029 22.5387 19.2357C22.6351 19.2241 22.7343 19.2182 22.8336 19.2182H30.2847H30.7372H31.1898H31.4993V15.2328C31.5022 14.427 30.8482 13.7729 30.0424 13.7729Z"
      fill="white"
    />
    <path
      d="M32.5241 20.6134C32.3781 20.4791 32.2058 20.3769 32.0131 20.3098C31.8642 20.2602 31.7065 20.231 31.5401 20.231H31.5022H31.473H31.0204H29.3883H22.8364C22.0306 20.231 21.3766 20.8849 21.3766 21.6908V22.4178V22.8703V23.3229V24.9404C21.3766 25.7463 22.0306 26.4003 22.8364 26.4003H31.5022H31.5401C31.7065 26.4003 31.8642 26.3711 32.0131 26.3214C32.2058 26.2572 32.3781 26.1521 32.5241 26.0178C32.816 25.7521 33 25.3667 33 24.9405V21.6908C33 21.2645 32.816 20.8791 32.5241 20.6134ZM25.8846 23.6061C25.8846 24.009 25.5576 24.336 25.1547 24.336H24.67C24.2671 24.336 23.9401 24.009 23.9401 23.6061V23.1214C23.9401 22.8879 24.0481 22.6805 24.2204 22.5491C24.3459 22.4528 24.5007 22.3915 24.67 22.3915H24.7927H25.1547C25.5576 22.3915 25.8846 22.7185 25.8846 23.1214V23.6061Z"
      fill="#23A4DE"
    />
  </svg>
))
