import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 38 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.7684 16.5518H23.2632C16.4211 16.552 7.5 16.5516 2.73684 16.5518H1.23158C0.547369 16.5518 0 17.1035 0 17.7932V38.7587C0 39.4345 0.547369 40.0001 1.23158 40.0001H24.7684C25.4526 40.0001 26 39.4345 26 38.7587V17.7932C26 17.1035 25.4526 16.5518 24.7684 16.5518ZM9.57895 26.8966C9.57895 24.9932 11.1116 23.4483 13 23.4483C14.8884 23.4483 16.4211 24.9932 16.4211 26.8966C16.4211 28.0001 15.9147 29.007 15.0526 29.6552V32.4139C15.0526 33.5587 14.1358 34.4828 13 34.4828C11.8642 34.4828 10.9474 33.5587 10.9474 32.4139V29.6552C10.0853 29.007 9.57895 28.0001 9.57895 26.8966Z"
      fill="#23A4DE"
    />
    <path
      d="M23.2626 16.5517V10.3448C23.2626 4.63448 18.6647 0 12.9995 0C7.33422 0 2.73633 4.63448 2.73633 10.3448V16.5517C4.5 16.5517 21.5004 16.5517 23.2626 16.5517ZM6.84158 10.3448C6.84158 6.92414 9.6058 4.13793 12.9995 4.13793C16.3932 4.13793 19.1574 6.92414 19.1574 10.3448V16.5517H6.84158V10.3448Z"
      fill="#0061A8"
    />
    <path
      d="M32.0357 37.0047L31.9979 39.9254L37.9244 40L38 34.1585L28.4924 24.5449C29.2691 21.7795 28.568 18.7034 26.374 16.4849C23.156 13.231 17.8222 13.1639 14.521 16.3358C11.2198 19.5076 11.1518 24.7649 14.3697 28.0188C16.5638 30.2373 19.6657 31.0066 22.4903 30.3118L23.2216 31.0514L26.1849 31.0886L26.147 34.0094L29.1103 34.0467L29.0725 36.9674L32.0357 37.0047ZM15.9459 20.7355C15.2145 19.996 15.2334 18.5356 15.9837 17.8148C16.734 17.0939 18.2156 17.1125 18.9469 17.852C19.8246 18.7395 19.6594 20.0519 18.9091 20.7728C18.0088 21.6378 16.8235 21.6229 15.9459 20.7355Z"
      fill="#FFB71B"
    />
  </svg>
))
