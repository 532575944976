import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5294 10.0046L4.8383 1.31351C4.53784 1.01301 4.53784 0.525816 4.8383 0.225311C5.13885 -0.0751037 5.626 -0.0751037 5.9265 0.225311L15.1617 9.46052C15.4621 9.76103 15.4621 10.2482 15.1617 10.5487L5.9265 19.7839C5.62077 20.0792 5.13357 20.0707 4.8383 19.765C4.55029 19.4667 4.55029 18.9939 4.8383 18.6957L13.5294 10.0046Z"
      fill="currentColor"
    />
  </svg>
))
