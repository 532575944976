import React, { memo } from 'react'

export default memo(() => (
  <svg width="0.84em" height="1em" viewBox="0 0 5213 6185" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5216.83 -88.4541H3068.32L668.888 6184.67H5216.83V-88.4541Z" fill="#0061A8" />
    <path
      d="M1633.36 3659.74L5216.83 5188.81V5596.56L3970.06 6176.83L661.046 6184.67L1633.36 3659.74Z"
      fill="#7BC9EB"
    />
    <path d="M5216.83 4145.91L3507.43 -88.4541H3060.48L1633.36 3659.74L5216.83 5188.81V4145.91Z" fill="#23A4DE" />
    <path
      d="M4606.19 4788.34L2672.85 5540.82C2493.74 5610.54 2291.38 5522.14 2220.88 5343.39L769.077 1662.17C698.58 1483.42 786.634 1281.99 965.747 1212.28L2899.09 459.79C3078.2 390.077 3280.56 478.474 3351.06 657.226L4802.86 4338.44C4873.36 4517.19 4785.31 4718.62 4606.19 4788.34Z"
      fill="#0061A8"
    />
    <path
      d="M2221.09 5345.07L769.018 1664.61C698.508 1485.9 786.597 1284.52 965.764 1214.83L2899.7 462.629C2655.59 2085.58 2490.09 3185.89 2221.09 5345.07Z"
      fill="#23A4DE"
    />
    <path
      d="M4349.92 4208.16L2461.84 4943.54C2370.73 4979.03 2267.8 4934.04 2231.94 4843.07L979.211 1665.33C943.345 1574.35 988.137 1471.84 1079.24 1436.36L2967.33 700.971C3058.45 665.483 3161.38 710.472 3197.24 801.443L4449.96 3979.17C4485.83 4070.15 4441.04 4172.67 4349.92 4208.16Z"
      fill="white"
    />
    <path
      d="M4197.9 4116.53L2510.94 4773.32C2427.97 4805.63 2334.22 4764.67 2301.56 4681.84L1140.15 1736.43C1107.49 1653.6 1148.29 1560.26 1231.26 1527.96L2918.23 871.161C3001.2 838.855 3094.95 879.815 3127.61 962.643L4289.01 3908.05C4321.66 3990.88 4280.88 4084.22 4197.9 4116.53Z"
      fill="white"
    />
    <path
      d="M3607.12 5052.48C3718.91 5008.73 3773.84 4882.41 3729.82 4770.33C3685.8 4658.26 3559.5 4602.88 3447.71 4646.63C3335.92 4690.39 3280.99 4816.71 3325 4928.78C3369.02 5040.86 3495.33 5096.24 3607.12 5052.48Z"
      fill="#23A4DE"
    />
    <path
      d="M3316.6 2362.65C3168.93 1988.1 2745.56 1803.03 2370.98 1949.29C1996.39 2095.54 1812.44 2517.74 1960.12 2892.29C2064.66 3157.45 2307.4 3327.45 2573.17 3352.35L2696.21 3664.43C2762.43 3832.41 2952.3 3915.4 3120.29 3849.81C3288.27 3784.22 3370.77 3594.88 3304.54 3426.91L3181.5 3114.83C3359.34 2916.72 3421.15 2627.81 3316.6 2362.65Z"
      fill="#262F69"
    />
    <path
      d="M4017.31 1256.1C3932.62 1113.73 3754.84 1050.03 3597.35 1113.69C3538.1 1137.63 3487.8 1177.14 3450.79 1227.86C3324.57 1131.18 3157.52 1106.79 3007.27 1167.52C2775.16 1261.33 2662.76 1527.68 2756.71 1761.23C2757.29 1762.68 2757.89 1764.18 2758.53 1765.6C2619.53 1851.18 2554.69 2029.53 2617.9 2186.66C2688.36 2361.84 2890.81 2445.68 3064.9 2375.32L4222.5 1907.44C4396.6 1837.07 4484.4 1635.92 4413.93 1460.74C4350.18 1302.26 4177.83 1218.82 4017.31 1256.1Z"
      fill="#FFB71B"
    />
    <rect
      width="260.242"
      height="260.246"
      transform="matrix(0.928936 0.370241 -0.370229 0.92894 2791.13 2862.69)"
      fill="#FFB71B"
    />
    <path d="M2189.06 2395.22L2821.61 2564.73L2358.54 3027.79L2189.06 2395.22Z" fill="#FFB71B" />
    <g>
      <path
        d="M956.232 3531.27L960.392 3293.39C960.995 3258.91 933.498 3230.43 899.016 3229.83L792.574 3227.97C758.092 3227.36 729.617 3254.86 729.014 3289.34L724.854 3527.22C761.804 3515.37 800.893 3509.81 840.872 3510.51C880.851 3511.2 919.719 3518.13 956.232 3531.27Z"
        fill="#7BC9EB"
      />
      <path
        d="M712.371 4212.62C660.704 4194.22 613.495 4163.9 574.464 4123.23C568.327 4116.87 562.193 4110.26 556.564 4103.42C555.777 4105.4 382.925 4470.81 289.222 4669.38C204.873 4847.62 46.627 5178.04 15.2347 5243.73C11.6011 5251.41 9.45792 5259.62 9.0639 5267.86L0.981687 5444.19C-0.156803 5466.41 26.1197 5478.87 42.6278 5463.92L173.667 5345.73C180.014 5340.09 184.884 5333.18 188.514 5325.74C211.104 5277.4 300.687 5085.75 379.878 4916.67L569.676 4919.99L572.385 4765.07L452.051 4762.96C454.383 4758.26 642.548 4360.62 712.371 4212.62Z"
        fill="#7BC9EB"
      />
      <path
        d="M829.578 4156.18C860.063 4156.72 889.63 4152.48 917.273 4143.97C973.813 4126.71 1022.9 4092.58 1058.44 4047.21C1095.25 4000.11 1117.53 3941.26 1118.65 3877.04C1120.67 3761.35 1053.2 3660.44 954.776 3614.48C919.82 3598.12 880.743 3588.69 839.517 3587.97C798.288 3587.25 759.155 3595.31 723.398 3610.43C623.426 3652.93 552.469 3751.41 550.446 3867.11C549.323 3931.32 569.526 3990.92 604.673 4039.27C638.599 4085.86 686.46 4121.94 742.366 4140.91C769.692 4150.39 799.094 4155.65 829.578 4156.18Z"
        fill="#7BC9EB"
      />
      <path
        d="M1054.17 4928.21L1252.43 4931.68C1325.91 5103.43 1408.49 5298.09 1429.37 5347.19C1432.74 5355 1437.36 5361.83 1443.51 5367.69L1570.34 5490.38C1586.32 5505.91 1613.01 5494.38 1612.65 5472.12L1610.74 5295.62C1610.64 5287.12 1608.78 5278.84 1605.42 5271.29C1576.33 5204.79 1429.74 4869.04 1351.67 4687.71C1264.72 4485.98 1105 4114.76 1104.28 4112.75C1098.42 4119.39 1092.31 4125.79 1085.7 4131.92C1045.02 4171.2 997.031 4199.86 944.752 4216.44C1009.36 4366.54 1183.51 4770.76 1185.43 4775.29L1056.64 4773.04L1053.92 4928.21L1054.17 4928.21Z"
        fill="#7BC9EB"
      />
    </g>
    <g clipPath="url(#clip1)">
      <path
        d="M733.695 4820.5C717.777 4836.12 701.221 4856.95 684.151 4883.53C703.225 4906.68 728.184 4925.5 757.948 4937.4C796.665 4952.87 838.503 4954.77 877.904 4943.27C881.039 4906.35 879.622 4876.4 874.443 4852.79C824.48 4862.44 773.381 4850.01 733.695 4820.5Z"
        fill="#23A4DE"
      />
      <path
        d="M784.986 4550.09C715.14 4580.62 682.911 4661.83 713.006 4731.99C743.228 4802.44 824.841 4835.06 895.295 4804.84C965.436 4774.75 997.967 4693.4 968.438 4623.16C968.454 4623.2 968.467 4623.23 968.467 4623.23C968.467 4623.23 968.457 4623.21 968.43 4623.15C955.964 4594.08 951.824 4478.5 1007.07 4454.8L784.976 4550.07L784.986 4550.09Z"
        fill="#0061A8"
      />
      <path
        d="M496.054 5735.07L496.054 5735.07L496.552 5735.24L496.55 5735.24C535.954 5748.94 713.41 5496.86 811.026 5218.18C843.447 5124.2 863.23 5047.35 872.732 4985.8C829.69 4994.94 784.906 4991.19 743.123 4974.49C711.584 4961.89 684.478 4942.88 662.676 4919.57C632.923 4973.1 601.854 5043.86 570.126 5134.43C473.829 5413.57 456.649 5721.37 496.054 5735.07Z"
        fill="#23A4DE"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="1651.13" height="2525.92" fill="white" transform="translate(0.512695 3214.8)" />
      </clipPath>
      <clipPath id="clip1">
        <rect
          width="978.656"
          height="978.668"
          fill="white"
          transform="matrix(-0.919015 0.394222 -0.39421 -0.91902 1392.87 5354.21)"
        />
      </clipPath>
    </defs>
  </svg>
))
