import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M68.0839 32.5781L39.9172 22.6511L11.7505 32.5781L39.9172 42.6555L68.0839 32.5781Z" fill="#23A4DE" />
    <path d="M68.0842 32.5781L39.9175 22.6511V42.6555L68.0842 32.5781Z" fill="#0061A8" />
    <path
      d="M50.5783 7.46606H28.611C26.4797 7.46606 24.7457 9.23906 24.7457 11.4178V58.7411C24.7457 60.9199 26.4797 62.6929 28.611 62.6929H50.5783C52.7097 62.6929 54.4441 60.9199 54.4441 58.7411V11.4178C54.4441 9.23906 52.7097 7.46606 50.5783 7.46606V7.46606Z"
      fill="#262F69"
    />
    <path
      d="M32.189 10.3965C31.9798 10.0786 31.8576 9.69614 31.8576 9.28426C31.8576 8.47572 32.3286 7.78095 33.0042 7.46606H28.6113C26.4764 7.46606 24.7455 9.2355 24.7455 11.4178V58.7411C24.7455 60.9235 26.4764 62.6924 28.6113 62.6924H33.2815V13.8597C33.2815 12.6169 32.8676 11.428 32.189 10.3965Z"
      fill="#262F69"
    />
    <path
      d="M26.9832 32.426V58.7411C26.9832 59.6603 27.712 60.4054 28.6108 60.4054H50.578C51.4768 60.4054 52.2056 59.6603 52.2056 58.7411C52.2056 57.8381 52.2056 44.6557 52.2056 32.426H26.9832Z"
      fill="#262F69"
    />
    <path
      d="M50.5653 9.75391H47.3006C46.9926 9.75391 46.7432 10.0089 46.7432 10.3232C46.7432 11.0784 46.1391 11.696 45.4008 11.696H33.7636C33.0248 11.696 32.4207 11.0784 32.4207 10.3232C32.4207 10.0089 32.1712 9.75391 31.8632 9.75391H28.5986C27.6993 9.75391 26.9705 10.499 26.9705 11.4177V48.7548H52.1934V11.4177C52.1934 10.499 51.4645 9.75391 50.5653 9.75391Z"
      fill="white"
    />
    <path d="M28.9352 28.5258L39.6076 18.0381L50.2801 28.5258V43.0087H28.9352V28.5258Z" fill="#262F69" />
    <path d="M28.9352 28.5258L39.6076 18.0381L50.2801 28.5258V36.7661L28.9352 28.5258Z" fill="#7BC9EB" />
    <path d="M50.2801 43.0087H44.0328L35.1825 22.3866L39.6077 18.0381L50.2801 28.5258V43.0087Z" fill="#0061A8" />
    <path
      d="M50.2801 28.5258V36.7661L39.6077 32.6977L35.1825 22.3866L39.6077 18.0381L50.2801 28.5258Z"
      fill="#32BCFA"
    />
    <path d="M39.9172 42.6831L11.7505 32.5779V68.9179L39.9172 78.0015V42.6831Z" fill="#31448F" />
    <path d="M68.0842 68.9179V32.5779L39.9175 42.6831V78.0015L68.0842 68.9179Z" fill="#262F69" />
    <path d="M11.7504 32.5784L1.99976 44.3104L30.6966 54.9142L39.9171 42.6558L11.7504 32.5784Z" fill="#7BC9EB" />
    <path d="M68.0842 32.5781L77.8381 44.3101L49.1413 54.9139L39.9175 42.6555L68.0842 32.5781Z" fill="#FABE3D" />
    <path d="M68.0842 32.5779L77.8381 44.3099L49.1413 54.9137L39.9175 42.6553L68.0842 32.5779Z" fill="#23A4DE" />
    <rect x="41" y="12" width="25" height="14" rx="7" fill="#E3E3E3" />
    <g>
      <path
        d="M60.9063 28.5925C60.4698 26.9791 58.7332 26.0523 57.1214 26.7001C56.706 25.1646 55.0871 24.1903 53.4459 24.7737V20.8149C53.4445 17.0646 47.77 17.0588 47.7686 20.8149V35.4159C46.3325 33.9798 45.0477 32.6951 45.0446 32.692C43.9384 31.5844 42.1363 31.5844 41.0303 32.6921C39.9248 33.7957 39.9209 35.5975 41.0304 36.7062C41.3048 36.9803 48.2601 43.9358 48.5343 44.2101C50.3214 45.9972 52.7444 47 55.2714 46.9999V47C60.5347 46.9993 64.8001 42.7339 64.8006 37.4708C64.8006 35.5783 64.8006 35.8831 64.8006 31.2232C64.7998 29.2282 62.7849 27.8374 60.9063 28.5925Z"
        fill="#FFB71B"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="29" height="29" fill="white" transform="translate(38 18)" />
      </clipPath>
    </defs>
  </svg>
))
