import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Input, InputAddOn } from '@reapit/elements';

const INPUT_WIDTH = '300px';
const INPUT_WIDE_WIDTH = '630px';

// TODO: field prop can be replaced by the id prop
const InputField = ({
	disabled,
	error,
	field,
	onChange,
	id,
	loading,
	onValidate,
	pattern,
	placeholder,
	type,
	validator,
	value,
	wide = false,
}) => {
	const [textFieldError, setTextFieldError] = useState('');

	// Executes when error changes
	useEffect(() => {
		setTextFieldError(error);
	}, [error]);

	const validate = async (field, value) => {
		if (validator) {
			const error = await validator(field, value);
			setTextFieldError(error);
			onValidate(field, error);
		}

		return;
	};

	const handleChangeEvent = (event) => {
		if (!event.target.validity.valid) return;

		const value = event.target.value;
		validate(field, value);
		onChange(field, value);
	};

	return (
		<>
			<Input
				disabled={disabled || loading}
				id={id}
				onChange={handleChangeEvent}
				pattern={pattern}
				placeholder={placeholder}
				style={{ width: wide ? INPUT_WIDE_WIDTH : INPUT_WIDTH }}
				type={type}
				value={value}
			/>
			<InputAddOn intent="danger">{textFieldError}</InputAddOn>
		</>
	);
};

InputField.propTypes = {
	disabled: PropTypes.bool,
	error: PropTypes.any,
	field: PropTypes.string,
	id: PropTypes.any.isRequired,
	loading: PropTypes.bool,
	onChange: PropTypes.func,
	type: PropTypes.string.isRequired,
	onValidate: PropTypes.func,
	pattern: PropTypes.string,
	placeholder: PropTypes.string,
	validator: PropTypes.func,
	value: PropTypes.string,
	wide: PropTypes.bool,
};

export default InputField;
