import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M19.1276 19.2187V7.41978C19.1276 7.20404 18.9111 7.02916 18.6441 7.02916H15.4938V3.05533C15.4938 2.83958 15.2773 2.6647 15.0103 2.6647H13.98V1.26013C13.98 1.04439 13.7636 0.869507 13.4965 0.869507H5.89697C5.62991 0.869507 5.41344 1.04439 5.41344 1.26013V2.6647H4.38327C4.11622 2.6647 3.89974 2.83958 3.89974 3.05533V9.70377H1.24671C0.979657 9.70377 0.763181 9.87865 0.763181 10.0944V19.2187C0.763526 19.4155 0.762896 19.6913 0.763217 19.9999H19.1276C19.1276 19.7384 19.1276 19.5652 19.1276 19.2187ZM6.3805 1.65076H13.0129V2.6647H6.3805V1.65076ZM1.73024 10.485H3.89974V19.2187H1.73024V10.485ZM4.8668 3.44595H14.5267V19.2187H12.1197V16.6414C12.1197 16.4257 11.9032 16.2508 11.6362 16.2508H7.7573C7.49025 16.2508 7.27377 16.4257 7.27377 16.6414V19.2187H4.8668V3.44595ZM8.24088 19.2187V17.0321H11.1527V19.2187H8.24088ZM15.4938 19.2187V7.81041H18.1606V19.2187H15.4938Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.762984 19.2188V10.0945C0.762984 9.87874 0.97946 9.70385 1.24651 9.70385H3.89955V3.05541C3.89955 2.83967 4.11602 2.66479 4.38308 2.66479H5.41324V1.26022C5.41324 1.04447 5.62971 0.869592 5.89677 0.869592H13.4963C13.7634 0.869592 13.9798 1.04447 13.9798 1.26022V2.66479H15.0101C15.2771 2.66479 15.4936 2.83967 15.4936 3.05541V7.02924H18.6439C18.9109 7.02924 19.1274 7.20413 19.1274 7.41987V20H0.76302C0.762856 19.8425 0.76294 19.6935 0.763015 19.5592C0.763088 19.4304 0.763153 19.3151 0.762984 19.2188ZM15.6841 6.85533H18.6439C18.9936 6.85533 19.3179 7.08868 19.3179 7.41987V20.1739H0.572725L0.572544 20.0002C0.572379 19.8418 0.572463 19.693 0.572539 19.5589C0.572612 19.4305 0.572677 19.3157 0.572508 19.2191L0.572508 10.0945C0.572508 9.76329 0.896843 9.52994 1.24651 9.52994H3.70907V3.05541C3.70907 2.72423 4.03341 2.49087 4.38308 2.49087H5.22276V1.26022C5.22276 0.92903 5.5471 0.695679 5.89677 0.695679H13.4963C13.846 0.695679 14.1703 0.92903 14.1703 1.26022V2.49087H15.0101C15.3597 2.49087 15.6841 2.72422 15.6841 3.05541V6.85533ZM12.8223 1.82475H6.57077V2.49087H12.8223V1.82475ZM3.70907 10.659H1.92052V19.0449H3.70907V10.659ZM14.3361 3.61995H5.05708V19.0449H7.0831V16.6415C7.0831 16.3103 7.40743 16.077 7.7571 16.077H11.636C11.9857 16.077 12.31 16.3103 12.31 16.6415V19.0449H14.3361V3.61995ZM8.43116 17.206V19.0449H10.962V17.206H8.43116ZM15.6841 7.98441V19.0449H17.9699V7.98441H15.6841ZM6.3803 1.65084V2.66479H13.0127V1.65084H6.3803ZM1.73004 10.4851V19.2188H3.89955V10.4851H1.73004ZM4.86661 3.44604V19.2188H7.27357V16.6415C7.27357 16.4258 7.49005 16.2509 7.7571 16.2509H11.636C11.903 16.2509 12.1195 16.4258 12.1195 16.6415V19.2188H14.5265V3.44604H4.86661ZM8.24068 19.2188H11.1525V17.0321H8.24068V19.2188ZM15.4936 19.2188H18.1604V7.81049H15.4936V19.2188Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="1em" height="1em" fill="white" />
      </clipPath>
    </defs>
  </svg>
))
