import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.40176 11.5717C7.97737 11.4383 7.54834 11.7641 7.54801 12.2179C7.54743 12.9617 7.54892 14.3667 7.54892 17.0983C7.54892 20.5884 9.63822 23.5795 12.6087 24.8362C17.9803 27.115 23.9324 23.0667 23.9324 17.0983C23.9324 15.5548 23.9476 14.4582 23.953 13.5135C23.9696 10.8868 21.5907 8.8335 18.9677 9.47621C16.6202 10.0521 15.8052 11.2048 12.9273 11.8441C11.2919 12.2072 9.73577 11.9912 8.40176 11.5717Z"
      fill="#FFE5C7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.00006 38.5674V35.2473C3.00006 31.764 5.76291 28.942 9.17199 28.9411C10.3768 28.9403 11.4375 28.3069 12.0482 27.3462C12.6122 26.4604 12.6052 25.6764 12.5995 25.0463C12.599 24.9852 12.5984 24.9256 12.5984 24.8675L12.6109 24.8362C9.64032 23.5796 7.55102 20.5884 7.55102 17.0983V16.8336C6.36032 16.8336 5.39966 15.8476 5.39966 14.6357V10.1435C5.39966 8.80397 4.88664 7.5177 3.96983 6.56209C3.95411 6.54518 3.9508 6.51981 3.96155 6.50036C5.11253 4.49527 7.42028 2.80307 10.1939 2.50032L10.7094 2.44366C10.8798 2.42506 10.9138 2.18573 10.7549 2.11892L9.69328 1.67241C9.55923 1.61575 9.55509 1.42209 9.68749 1.3612C13.7585 -0.507735 19.3785 -0.793572 22.925 2.6999C25.9749 5.70458 26.0296 10.02 26.0031 11.2437V14.7584C26.0031 15.881 25.114 16.8336 23.9345 16.8336V17.0983C23.9345 20.5884 21.8452 23.5796 18.8746 24.8362L18.887 24.8675C18.887 24.9243 18.8866 24.9822 18.8861 25.0412C18.8812 25.6861 18.8752 26.4634 19.4373 27.3462C19.4686 27.3954 19.501 27.4437 19.5346 27.4912C18.9823 28.957 18.6801 30.5453 18.6801 32.2043C18.6801 34.7874 19.4128 37.1995 20.6816 39.244H3.66202C3.29629 39.244 3.00006 38.9412 3.00006 38.5674Z"
      fill="#F2F2F2"
    />
    <path
      d="M3.96333 6.50022C3.95232 6.51942 3.95563 6.54513 3.97169 6.56187C4.88792 7.51782 5.4011 8.80426 5.4011 10.1435V14.6357C5.4011 15.8474 6.36152 16.8336 7.55246 16.8336C7.55246 14.2745 7.55106 12.9371 7.55155 12.2183C7.55188 11.7645 7.98091 11.4383 8.40531 11.5717C9.73932 11.9912 11.2954 12.2073 12.9308 11.8441C15.8086 11.2048 16.6237 10.0521 18.9711 9.47623C21.5942 8.83352 23.9731 10.8868 23.9565 13.5135C23.9515 14.3769 23.9375 15.456 23.9358 16.8336C25.1153 16.8336 26.0044 15.8809 26.0044 14.7583V11.2437C26.0309 10.02 25.9763 5.70453 22.9263 2.69985C19.3802 -0.793705 13.7596 -0.507699 9.68926 1.36141C9.55687 1.42221 9.56034 1.61595 9.69464 1.67245L10.7563 2.11913C10.915 2.18594 10.8816 2.42501 10.711 2.4437L10.1953 2.50027C7.42148 2.80336 5.11389 4.49496 3.96333 6.50022Z"
      fill="#262F69"
    />
    <path
      d="M2.99805 32.8754V35.1986C2.99805 37.6674 11.2082 39 15.9265 39C20.6449 39 28.4834 37.6809 28.4834 35.1986V32.8754C28.4834 30.438 25.7201 28.4636 22.3119 28.4627C21.1068 28.4624 20.0458 28.0187 19.4353 27.3467C17.3043 28.6486 14.1784 28.6494 12.0462 27.3467C11.4358 28.0187 10.3747 28.4624 9.16956 28.4627C5.7613 28.4636 2.99805 30.438 2.99805 32.8754Z"
      fill="#0061A8"
    />
    <path
      d="M39.3868 23.8193L36.5707 21.0031V17.75C36.5707 17.1618 36.094 16.6851 35.505 16.6851C34.9173 16.6851 34.4406 17.1618 34.4406 17.75V18.8731L32.3438 16.7762C31.3071 15.74 29.5046 15.7419 28.4703 16.778L21.4289 23.8193C21.0133 24.2358 21.0133 24.9097 21.4289 25.3255C21.8448 25.7419 22.52 25.7419 22.9357 25.3255L29.9764 18.2841C30.2058 18.056 30.61 18.056 30.8382 18.2834L37.8801 25.3255C38.0889 25.5337 38.3611 25.6373 38.6333 25.6373C38.906 25.6373 39.1787 25.5336 39.3869 25.3255C39.8027 24.9097 39.8027 24.2358 39.3868 23.8193Z"
      fill="#23A4DE"
    />
    <path
      d="M30.778 20.3062C30.5734 20.1017 30.2422 20.1017 30.0382 20.3062L23.8446 26.4979C23.7468 26.5957 23.6914 26.7291 23.6914 26.8684V31.3844C23.6914 32.4442 24.5506 33.3034 25.6103 33.3034H28.6768V28.5544H32.1388V33.3034H35.2053C36.2649 33.3034 37.1241 32.4442 37.1241 31.3845V26.8684C37.1241 26.7291 37.0692 26.5957 36.9709 26.4979L30.778 20.3062Z"
      fill="#7BC9EB"
    />
  </svg>
))
