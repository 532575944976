import React, { Component } from 'react';
import PropTypes from 'prop-types';

import * as Sentry from '@sentry/browser';

import APIError from 'core/errors/api-error';

import ErrorPage from './error-page';

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			status: 0,
			hasFailed: false,
		};
	}

	static getDerivedStateFromError(err) {
		return {
			status: err?.status,
			hasFailed: true,
		};
	}

	componentDidCatch(error, info) {
		console.error('Error boundary hit', error.message, info);
		// Capture error on Sentry
		Sentry.withScope((scope) => {
			Object.keys(info).forEach((key) => {
				scope.setExtra(key, info[key]);
			});

			if (!(error instanceof APIError)) {
				Sentry.captureException(error);
			}
		});
	}

	render() {
		if (this.state.hasFailed) {
			return <ErrorPage status={this.state.status} />;
		}

		return this.props.children;
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.node,
};

export default ErrorBoundary;
