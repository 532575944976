import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.1408 0.63374L19.3658 1.85868C19.7747 2.26763 20 2.81173 20 3.39042C20 3.9691 19.7747 4.5132 19.3658 4.92199L17.8653 6.42248L13.577 2.13422L15.0775 0.63374C15.8952 -0.184157 17.3221 -0.184951 18.1408 0.63374Z"
      fill="currentColor"
    />
    <path
      d="M1.34679 14.3644L12.351 3.35931L16.6394 7.64776L5.63426 18.652C5.5789 18.7074 5.50958 18.7472 5.43328 18.7663L0.537614 19.9861C0.503033 19.9949 0.467499 19.9991 0.432759 19.9991C0.319338 19.9991 0.208455 19.9541 0.127077 19.8727C0.0195257 19.7652 -0.0237805 19.6084 0.0127046 19.4611L1.23242 14.5654C1.25145 14.49 1.29143 14.4199 1.34679 14.3644Z"
      fill="currentColor"
    />
  </svg>
))
