import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M36 8.47056V3.17646C36 1.42496 34.5806 0 32.8359 0H3.16406C1.4194 0 0 1.42496 0 3.17646V8.47056H36ZM24.3281 4.23528H30.6562C31.2387 4.23528 31.7109 4.70935 31.7109 5.2941C31.7109 5.87885 31.2387 6.35292 30.6562 6.35292H24.3281C23.7457 6.35292 23.2734 5.87885 23.2734 5.2941C23.2734 4.70935 23.7457 4.23528 24.3281 4.23528ZM13.7812 4.23528C14.3637 4.23528 14.8359 4.70935 14.8359 5.2941C14.8359 5.87885 14.3637 6.35292 13.7812 6.35292C13.1988 6.35292 12.7266 5.87885 12.7266 5.2941C12.7266 4.70935 13.1988 4.23528 13.7812 4.23528ZM9.5625 4.23528C10.145 4.23528 10.6172 4.70935 10.6172 5.2941C10.6172 5.87885 10.145 6.35292 9.5625 6.35292C8.98003 6.35292 8.50781 5.87885 8.50781 5.2941C8.50781 4.70935 8.98003 4.23528 9.5625 4.23528ZM5.34375 4.23528C5.92622 4.23528 6.39844 4.70935 6.39844 5.2941C6.39844 5.87885 5.92622 6.35292 5.34375 6.35292C4.76128 6.35292 4.28906 5.87885 4.28906 5.2941C4.28906 4.70935 4.76128 4.23528 5.34375 4.23528Z"
      fill="#262F69"
    />
    <path
      d="M0 10.5884V32.8236C0 34.5751 1.4194 36.0001 3.16406 36.0001H32.8359C34.5806 36.0001 36 34.5751 36 32.8236V10.5884H0Z"
      fill="#7BC9EB"
    />
    <path
      d="M25.7317 14.8569H10.1247C8.42616 14.8569 7.04395 16.2008 7.04395 17.8526V27.3458C7.04395 28.9976 8.42616 30.3414 10.1247 30.3414H25.7317C27.4302 30.3414 28.8125 28.9976 28.8125 27.3458V17.8526C28.8125 16.2008 27.4302 14.8569 25.7317 14.8569Z"
      fill="white"
    />
    <path
      d="M25.7315 14.8574H21.3172C19.6187 14.8574 18.2364 16.2013 18.2364 17.853L15.0176 27.3463C15.0176 28.9981 16.3998 30.3419 18.0983 30.3419H25.7315C27.4301 30.3419 28.8123 28.9981 28.8123 27.3463V17.853C28.8123 16.2013 27.4301 14.8574 25.7315 14.8574Z"
      fill="white"
    />
    <path
      d="M25.7317 14.8569H10.1247C8.42616 14.8569 7.04395 16.2008 7.04395 17.8526V27.3458C7.04395 28.9976 8.42616 30.3414 10.1247 30.3414H25.7317C27.4302 30.3414 28.8125 28.9976 28.8125 27.3458V17.8526C28.8125 16.2008 27.4302 14.8569 25.7317 14.8569ZM14.944 19.6226L11.7029 22.7736L14.5851 25.5758C14.8343 25.818 14.8343 26.2107 14.5851 26.4525C14.3364 26.6948 13.9325 26.6948 13.6833 26.4525L10.35 23.2118C10.1009 22.9696 10.1009 22.5773 10.35 22.335L14.0418 18.7458C14.2909 18.5036 14.6948 18.5036 14.944 18.7458C15.1927 18.9876 15.1927 19.3803 14.944 19.6226ZM20.5187 18.1638L17.1462 27.4475C17.0289 27.7707 16.6645 27.9398 16.3325 27.8257C16.0004 27.7116 15.8261 27.3574 15.9434 27.0345L19.3159 17.7509C19.4332 17.428 19.7972 17.2586 20.1296 17.3726C20.4617 17.4867 20.636 17.841 20.5187 18.1638ZM25.5064 22.8633L21.8146 26.4525C21.5655 26.6948 21.1616 26.6948 20.9124 26.4525C20.6637 26.2107 20.6637 25.818 20.9124 25.5758L24.1535 22.4247L21.2713 19.6226C21.0221 19.3803 21.0221 18.9876 21.2713 18.7458C21.52 18.5036 21.9239 18.5036 22.1731 18.7458L25.5064 21.9866C25.7555 22.2288 25.7555 22.6211 25.5064 22.8633Z"
      fill="#0061A8"
    />
    <path
      d="M25.7327 14.8574H19.7802C17.5405 20.9043 16.3743 24.3057 14.042 30.3419H25.7327C27.4313 30.3419 28.8135 28.9981 28.8135 27.3463V17.853C28.8135 16.2013 27.4313 14.8574 25.7327 14.8574ZM20.6043 18.1643L17.2318 27.448C17.1145 27.7712 16.7501 27.9403 16.4181 27.8262C16.086 27.7121 15.9117 27.3579 16.029 27.035L19.4015 17.7514C19.5188 17.4285 19.8827 17.259 20.2152 17.3731C20.5473 17.4872 20.7216 17.8415 20.6043 18.1643ZM25.5074 22.8638L21.8157 26.453C21.5665 26.6953 21.1626 26.6953 20.9135 26.453C20.6647 26.2112 20.6647 25.8185 20.9135 25.5763L24.1545 22.4252L21.2723 19.6231C21.0232 19.3808 21.0232 18.9881 21.2723 18.7463C21.521 18.5041 21.9249 18.5041 22.1741 18.7463L25.5074 21.9871C25.7565 22.2293 25.7565 22.6216 25.5074 22.8638Z"
      fill="#262F69"
    />
  </svg>
))
