import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M44.3202 11.4506C43.78 8.09117 40.7564 5.5127 37.1128 5.5127C35.7421 5.5127 34.4244 5.87747 33.2808 6.55784C31.5474 3.74831 28.4214 2 24.9454 2C19.5756 2 15.1999 6.2022 15.1914 11.3672C15.1914 11.3992 15.1913 11.4324 15.1925 11.4644C11.7405 12.0235 9.01784 14.9176 9.01213 18.3926C9.00577 22.2666 12.358 25.418 16.3857 25.418H43.1667C47.1943 25.418 50.5569 22.2666 50.5633 18.3926C50.5691 14.8879 47.812 11.9755 44.3202 11.4506Z"
      fill="white"
    />
    <path
      d="M69.2628 76.3422H40.55C38.0412 76.3361 36.0067 74.3021 36 71.7934V11.2266C36.0067 8.71826 38.0405 6.68447 40.5489 6.67773H69.2639C71.7722 6.68447 73.8059 8.7181 73.8128 11.2263C73.8128 11.2268 73.8128 11.2273 73.8128 11.2278V71.7934C73.8128 71.7939 73.8128 71.7943 73.8128 71.7947C73.8045 74.304 71.7715 76.3361 69.2628 76.3422Z"
      fill="#7BC9EB"
      stroke="#262F69"
      strokeWidth="2"
    />
    <path
      d="M69.2652 77.3427H40.5476C37.488 77.3352 35.0074 74.8547 35 71.795V64.7686C53.2926 64.7685 58.5345 64.7686 74.8128 64.7686V71.7969C74.8035 74.8566 72.3249 77.3352 69.2652 77.3427Z"
      fill="#31448F"
    />
    <rect x="42" y="15" width="22" height="22" rx="11" fill="white" />
    <rect x="42.168" y="48.9014" width="25.0827" height="8.95809" rx="1" fill="#F2F2F2" />
    <rect x="42.168" y="43" width="17.9162" height="3.58324" rx="1" fill="#F2F2F2" />
    <path
      d="M46.9214 67.8579C46.9214 66.7641 46.0351 65.8779 44.9421 65.8779H44.1065V46.7123C43.0793 46.7123 42.1071 46.3773 41.298 45.8125C40.754 46.196 40.1279 46.4794 39.4338 46.6171C39.1163 46.6806 38.7923 46.7123 38.4721 46.7123C38.2586 46.7123 38.0536 46.6837 37.8459 46.6566L28.6197 46.4821C28.1708 46.6165 27.7038 46.7116 27.2109 46.7116C26.7193 46.7116 26.253 46.6166 25.8034 46.4821L16.5773 46.6567C16.3704 46.6838 16.164 46.7124 15.9506 46.7124C15.6324 46.7124 15.3096 46.6806 14.9855 46.6166C14.2934 46.4788 13.6685 46.1961 13.124 45.8126C12.3148 46.3773 11.3446 46.7124 10.3182 46.7124L10.3175 65.878H9.48183C8.38803 65.878 7.50195 66.7642 7.50195 67.858C7.50195 68.9518 8.38803 69.8379 9.48183 69.8379H44.942C46.0351 69.8378 46.9214 68.9517 46.9214 67.8579Z"
      fill="#0061A8"
    />
    <path
      d="M3.71672 50.7332C4.07924 50.8486 4.4454 50.9035 4.80616 50.9035C6.44357 50.9035 7.956 49.7675 8.4488 48.0084L8.64878 47.296C8.78423 48.5895 9.49376 49.7293 10.5552 50.3672C10.8977 50.5702 11.2629 50.7378 11.6683 50.8235C11.9191 50.8777 12.169 50.9035 12.4145 50.9035C14.1925 50.9035 15.7819 49.5691 16.141 47.6395L16.2337 47.1451C16.3289 48.5205 17.0681 49.7321 18.1671 50.3867C18.6084 50.6503 19.1048 50.8244 19.6412 50.8823C19.7695 50.8971 19.8972 50.9028 20.0229 50.9028C21.9532 50.9028 23.6071 49.342 23.8035 47.2503L23.8316 46.9495C24.3398 53.036 31.7646 51.5605 31.7646 47.1451L31.8574 47.6395C32.2181 49.5699 33.8065 50.9035 35.5838 50.9035C35.8301 50.9035 36.0791 50.8776 36.3309 50.8235C36.7344 50.7378 37.1014 50.5702 37.4422 50.3672C38.5055 49.7292 39.215 48.5895 39.3504 47.296L39.5504 48.0084C40.0433 49.7675 41.5556 50.9035 43.1938 50.9035C43.5539 50.9035 43.92 50.8486 44.2825 50.7332C46.2965 50.0924 47.441 47.8333 46.84 45.6887L43.5871 34.0863C42.9075 31.6614 40.8148 30 38.441 30H9.55928C7.18437 30 5.09182 31.6614 4.41214 34.0863L1.16 45.6887C0.558945 47.8334 1.70367 50.0925 3.71672 50.7332Z"
      fill="#23A4DE"
    />
    <rect x="15" y="54" width="9" height="6" rx="1" fill="#F2F2F2" />
    <rect x="30" y="54" width="9" height="6" rx="1" fill="#F2F2F2" />
    <path
      d="M61.7871 35.3711C63.4043 36.4466 63.4043 38.5534 61.7871 39.6289L35.0072 57.4373C32.9621 58.7973 30 57.5379 30 55.3084L30 19.6916C30 17.4621 32.9621 16.2027 35.0072 17.5627L61.7871 35.3711Z"
      fill="#FFB71B"
    />
  </svg>
))
