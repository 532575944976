import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35 23.3919V24.9553C35 25.187 34.8294 25.4563 34.6177 25.5505C33.7972 25.9156 31.5593 26.6628 26.9863 26.6628C26.7672 26.6628 26.5584 26.6597 26.35 26.6563C26.1492 23.3445 23.6366 20.617 20.3162 20.1677C21.031 19.4516 21.9772 18.9661 23.0323 18.8454C23.1015 18.8375 23.204 18.8671 23.2604 18.9079C24.3098 19.6676 25.5945 20.1211 26.9863 20.1211C28.378 20.1211 29.6628 19.6676 30.7122 18.9079C30.7686 18.8671 30.8711 18.8375 30.9403 18.8454C33.2163 19.1057 35 21.0492 35 23.3919ZM26.9863 9C24.3669 9 22.2435 11.1234 22.2435 13.7428C22.2435 16.3622 24.3669 18.4855 26.9863 18.4855C29.6057 18.4855 31.7291 16.3621 31.7291 13.7428C31.7291 11.1234 29.6057 9 26.9863 9Z"
      fill="#23A4DE"
    />
    <path
      d="M15.0685 20.9178C18.3595 20.9178 21.0274 18.25 21.0274 14.9589C21.0274 11.6679 18.3595 9 15.0685 9C11.7775 9 9.10956 11.6679 9.10956 14.9589C9.10956 18.25 11.7775 20.9178 15.0685 20.9178ZM25.137 27.0822V29.0464C25.137 29.3375 24.9226 29.6759 24.6567 29.7943C23.6258 30.2528 20.8141 31.1917 15.0685 31.1917C9.32284 31.1917 6.51113 30.2529 5.48029 29.7943C5.21436 29.676 5 29.3375 5 29.0465V27.0823C5 24.1388 7.24108 21.6969 10.1007 21.3699C10.1875 21.3599 10.3164 21.3972 10.3873 21.4485C11.7057 22.4029 13.32 22.9727 15.0685 22.9727C16.817 22.9727 18.4312 22.4029 19.7498 21.4485C19.8206 21.3972 19.9495 21.3599 20.0363 21.3699C22.8959 21.697 25.137 24.1388 25.137 27.0822Z"
      fill="white"
    />
  </svg>
))
