import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 20H11V4H20V16.264V20Z" fill="#7BC9EB" />
    <path d="M13 2H11.3572L10 9.05535L11.3574 20H13V2Z" fill="#0061A8" />
    <path d="M1.27977 0V1.78715H0V20H12V0H1.27977Z" fill="#23A4DE" />
    <path d="M3.61719 4.50488H5.14902V6.38001H3.61719V4.50488Z" fill="#F3FFFB" />
    <path d="M7.43005 4.50488H8.96188V6.38001H7.43005V4.50488Z" fill="#F3FFFB" />
    <path d="M3.61719 8.25513H5.14902V10.1303H3.61719V8.25513Z" fill="#F3FFFB" />
    <path d="M7.43005 8.25513H8.96188V10.1303H7.43005V8.25513Z" fill="#F3FFFB" />
    <path d="M3.61719 12.0056H5.14902V13.8807H3.61719V12.0056Z" fill="#F3FFFB" />
    <path d="M7.43005 12.0056H8.96188V13.8807H7.43005V12.0056Z" fill="#F3FFFB" />
    <path d="M3.61719 15.7559H5.14902V17.631H3.61719V15.7559Z" fill="#F3FFFB" />
    <path d="M7.43005 15.7559H8.96188V17.631H7.43005V15.7559Z" fill="#F3FFFB" />
    <path d="M14.7491 8.25513H16.281V10.1303H14.7491V8.25513Z" fill="#23A4DE" />
    <path d="M14.7491 12.0056H16.281V13.8807H14.7491V12.0056Z" fill="#23A4DE" />
  </svg>
))
