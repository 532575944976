import { styled } from '@linaria/react';

export const TopNavContainer = styled.div`
	overflow: hidden;
	background-color: #fff;
	position: fixed;
	top: 0;
	width: 100%;
	height: 80px;
	z-index: 2;
	padding: 0 40px 0 120px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-shadow: 0 4px 2px -2px #e1e1e1;
`;

export const TFLogo = styled.img`
	vertical-align: middle;
	width: 140px;
	height: 140px;
	cursor: pointer;

	&:hover {
		transform: translate(0, -2px);
	}
`;
