import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 20H18V7.88218L10 2L2 7.88218V20Z" fill="#7BC9EB" />
    <path d="M1.22096 10L10 2.65656L18.779 10L20 8.36478L10 0L7.62939e-06 8.36478L1.22096 10Z" fill="#23A4DE" />
    <path d="M6 12H14V20H6V12Z" fill="#F2F2F2" />
    <path d="M8 14H12V20H8V14Z" fill="#0061A8" />
  </svg>
))
