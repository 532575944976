import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M60.3789 17.8717C60.3789 13.5405 63.9194 10 68.2506 10C72.5818 10 76.1224 13.5405 76.1224 17.8717C76.1224 22.2029 72.5818 25.7435 68.2506 25.7435C63.9194 25.7435 60.3789 22.2029 60.3789 17.8717Z"
      fill="#FFB71B"
    />
    <path
      d="M80.9695 30.5903C81.2789 31.0372 81.5539 31.5184 81.7601 32.034C81.8632 32.2402 81.8289 32.4809 81.6571 32.6872C81.3821 33.1684 81.0727 33.6153 80.729 34.0622C80.2477 34.7152 79.6977 35.2996 79.1478 35.8496C78.6665 36.3308 78.1165 36.8121 77.5665 37.2246C74.851 39.2526 71.5854 40.3182 68.2167 40.3182C64.848 40.3182 61.5824 39.2527 58.8669 37.2246C58.3168 36.7777 57.7669 36.3308 57.2856 35.8496C56.7012 35.2996 56.1856 34.7153 55.7044 34.0622C55.395 33.6497 55.0513 33.1684 54.7763 32.6872C54.6732 32.4809 54.6388 32.2403 54.7419 32.034C54.9481 31.5528 55.2232 31.0372 55.5325 30.5903C57.0794 28.2528 59.5543 26.706 62.3043 26.3279C62.648 26.2591 63.0261 26.3622 63.3011 26.5685C64.7448 27.6341 66.4635 28.1841 68.251 28.1841C70.0386 28.1841 71.7572 27.6341 73.201 26.5685C73.476 26.3622 73.8541 26.2935 74.1978 26.3279C76.9478 26.706 79.3883 28.2528 80.9695 30.5903Z"
      fill="#FFB71B"
    />
    <path
      d="M15.7031 101.081C15.7031 96.7495 19.2437 93.209 23.5749 93.209C27.9061 93.209 31.4466 96.7495 31.4466 101.081C31.4466 105.412 27.9061 108.952 23.5749 108.952C19.2437 108.952 15.7031 105.412 15.7031 101.081Z"
      fill="#FFB71B"
    />
    <path
      d="M36.2899 113.799C36.5992 114.246 36.8742 114.727 37.0804 115.243C37.1835 115.449 37.1492 115.69 36.9774 115.896C36.7025 116.377 36.393 116.824 36.0493 117.271C35.568 117.924 35.018 118.509 34.4681 119.059C33.9868 119.54 33.4368 120.021 32.8868 120.434C30.1713 122.462 26.9057 123.527 23.537 123.527C20.1683 123.527 16.9027 122.462 14.1872 120.434C13.6371 119.987 13.0872 119.54 12.6059 119.059C12.0216 118.509 11.5059 117.924 11.0247 117.271C10.7153 116.859 10.3716 116.377 10.0966 115.896C9.9935 115.69 9.95916 115.449 10.0623 115.243C10.2685 114.762 10.5435 114.246 10.8528 113.799C12.3997 111.462 14.8746 109.915 17.6246 109.537C17.9683 109.468 18.3464 109.571 18.6214 109.777C20.0652 110.843 21.7838 111.393 23.5714 111.393C25.3589 111.393 27.0775 110.843 28.5213 109.777C28.7963 109.571 29.1744 109.502 29.5181 109.537C32.2681 109.915 34.7086 111.462 36.2899 113.799Z"
      fill="#FFB71B"
    />
    <path
      d="M91.5719 30.1973C89.1132 40.8394 79.5768 48.7728 68.1875 48.7728C56.7982 48.7728 47.2618 40.8394 44.8031 30.1973C27.2591 38.8369 15.1875 56.8951 15.1875 77.7728C15.1875 80.2149 15.3527 82.6184 15.6725 84.9729C18.0364 84.1941 20.5628 83.7728 23.1875 83.7728C36.4423 83.7728 47.1875 94.5179 47.1875 107.773C47.1875 113.778 44.9818 119.268 41.3364 123.477C49.2108 128.114 58.3886 130.773 68.1875 130.773C79.0492 130.773 89.1478 127.505 97.554 121.9C94.1986 117.772 92.1875 112.507 92.1875 106.773C92.1875 93.5179 102.933 82.7728 116.188 82.7728C117.804 82.7728 119.383 82.9325 120.909 83.2371C121.093 81.4407 121.188 79.6177 121.188 77.7728C121.188 56.8951 109.116 38.8369 91.5719 30.1973Z"
      fill="#262F69"
    />
    <path
      d="M91.5718 30.1974C89.113 40.8394 79.5767 48.7727 68.1875 48.7727C62.5128 48.7727 57.2981 46.8033 53.1897 43.5107L89.3516 126.378C92.2276 125.124 94.9707 123.622 97.5539 121.9C94.1986 117.772 92.1875 112.507 92.1875 106.773C92.1875 93.5179 102.933 82.7727 116.188 82.7727C117.804 82.7727 119.382 82.9325 120.909 83.237C121.093 81.4406 121.187 79.6177 121.187 77.7727C121.187 56.8952 109.116 38.8371 91.5718 30.1974Z"
      fill="#0061A8"
    />
    <path
      d="M91.572 30.1969C89.1133 40.8392 79.5769 48.7727 68.1875 48.7727C56.7983 48.7727 47.262 40.8394 44.8032 30.1975C32.6787 36.1684 23.1681 46.6376 18.4492 59.427L99.0085 90.0131C103.367 85.5461 109.453 82.7727 116.188 82.7727C117.804 82.7727 119.383 82.9325 120.91 83.2371C121.094 81.4407 121.188 79.6177 121.188 77.7727C121.188 56.8948 109.116 38.8364 91.572 30.1969Z"
      fill="#7BC9EB"
    />
    <path
      d="M91.5718 30.1974C89.113 40.8394 79.5767 48.7727 68.1875 48.7727C62.5128 48.7727 57.2981 46.8033 53.1897 43.5107L68.5539 78.7188L98.9209 90.1034C103.285 85.5836 109.408 82.7727 116.188 82.7727C117.804 82.7727 119.382 82.9325 120.909 83.237C121.093 81.4406 121.187 79.6177 121.187 77.7727C121.187 56.8952 109.116 38.8371 91.5718 30.1974Z"
      fill="#23A4DE"
    />
    <path
      d="M115.805 92.209C111.474 92.209 107.934 95.7495 107.934 100.081C107.934 104.412 111.474 107.952 115.805 107.952C120.137 107.952 123.677 104.412 123.677 100.081C123.677 95.7495 120.137 92.209 115.805 92.209Z"
      fill="#FFB71B"
    />
    <path
      d="M129.315 114.243C129.109 113.727 128.834 113.246 128.524 112.799C126.943 110.462 124.502 108.915 121.753 108.537C121.409 108.502 121.031 108.571 120.756 108.777C119.312 109.843 117.593 110.393 115.806 110.393C114.018 110.393 112.3 109.843 110.856 108.777C110.581 108.571 110.203 108.468 109.859 108.537C107.109 108.915 104.634 110.462 103.087 112.799C102.778 113.246 102.503 113.762 102.297 114.243C102.194 114.449 102.228 114.69 102.331 114.896C102.606 115.377 102.95 115.859 103.259 116.271C103.74 116.924 104.256 117.509 104.84 118.059C105.322 118.54 105.872 118.987 106.422 119.434C109.137 121.462 112.403 122.527 115.771 122.527C119.14 122.527 122.406 121.462 125.121 119.434C125.671 119.021 126.221 118.54 126.702 118.059C127.252 117.509 127.802 116.924 128.284 116.271C128.627 115.824 128.937 115.377 129.212 114.896C129.384 114.69 129.418 114.449 129.315 114.243Z"
      fill="#FFB71B"
    />
    <path
      d="M71.5574 68.763C66.5428 68.8484 62.8489 73.6179 63.7975 78.3157C63.8287 78.4705 63.9647 78.582 64.1227 78.5838C65.2977 78.5974 66.363 78.5377 67.5187 78.2626C67.6955 78.2205 67.7956 78.0366 67.7438 77.8623C67.5465 77.1984 67.5554 76.3325 67.7763 75.6292C68.0627 74.7649 68.6089 74.0214 69.428 73.5083C70.4568 72.8315 72.166 72.9543 73.599 72.9199C73.598 72.9179 73.5971 72.916 73.5961 72.914L78.89 72.8599C80.9997 72.8384 82.7259 74.547 82.7259 76.6568C82.7474 78.7664 81.039 80.5101 78.9294 80.5316L75.7106 80.5766C75.5616 80.5787 75.4276 80.665 75.3624 80.799C74.6682 82.2239 73.264 83.8078 71.8904 84.6917C71.852 84.7165 71.8703 84.7761 71.916 84.7757L78.7406 84.7059C83.1003 84.6614 86.6673 81.0578 86.6673 76.6979C86.6673 72.2485 83.0271 68.6453 78.5779 68.6907L71.5854 68.7621C71.576 68.7623 71.5667 68.7629 71.5574 68.763Z"
      fill="white"
    />
    <path
      d="M68.7322 84.2191C68.732 84.2192 68.7318 84.2193 68.7316 84.2194C68.7347 84.2206 68.7376 84.2218 68.7407 84.223C70.6904 83.6519 72.2716 82.342 73.3577 80.6098L73.3547 80.6098L73.3538 80.6075C74.3022 79.0457 74.7069 76.9578 74.3311 75.0474C74.2988 74.883 74.1341 74.7336 73.9676 74.716C72.9204 74.6051 71.7533 74.6387 70.6132 75.0309C70.4342 75.0924 70.3337 75.2809 70.3822 75.4638C70.5643 76.1498 70.5873 76.9106 70.36 77.6628L70.3607 77.663C70.2192 78.2134 69.7643 79.0714 68.9284 79.6462C68.9283 79.6461 68.9282 79.646 68.9281 79.6459C67.8712 80.4593 66.069 80.3727 64.5454 80.395V80.3957C63.3896 80.4086 60.456 80.4369 59.2801 80.4495C57.1705 80.471 55.4268 78.7626 55.4053 76.653C55.3837 74.5433 57.0922 72.7997 59.2018 72.7782L62.4302 72.733C62.5765 72.7309 62.7088 72.6477 62.7751 72.5172C63.5061 71.0795 64.8696 69.5223 66.2201 68.6179C66.2578 68.5926 66.2392 68.5339 66.1939 68.5344L59.4719 68.603C55.0675 68.648 51.4639 72.2886 51.4639 76.6933C51.5088 81.0975 55.1491 84.6641 59.5533 84.6191C61.1575 84.5293 67.4 84.7696 68.7322 84.2191Z"
      fill="white"
    />
  </svg>
))
