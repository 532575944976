import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M19.7556 18.5783L14.0682 12.8909C15.1699 11.5301 15.8332 9.80089 15.8332 7.91758C15.8332 3.55262 12.2815 0.000976562 7.91656 0.000976562C3.55161 0.000976562 0 3.55258 0 7.91754C0 12.2825 3.55165 15.8342 7.9166 15.8342C9.7999 15.8342 11.5291 15.1708 12.8899 14.0692L18.5773 19.7566C18.7398 19.9191 18.9531 20.0008 19.1665 20.0008C19.3798 20.0008 19.5932 19.9191 19.7557 19.7566C20.0815 19.4308 20.0815 18.9041 19.7556 18.5783ZM7.9166 14.1675C4.46996 14.1675 1.66666 11.3642 1.66666 7.91754C1.66666 4.4709 4.46996 1.6676 7.9166 1.6676C11.3632 1.6676 14.1665 4.4709 14.1665 7.91754C14.1665 11.3642 11.3632 14.1675 7.9166 14.1675Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="20" height="20.0003" fill="white" />
      </clipPath>
    </defs>
  </svg>
))
