import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M12.203 7.79552C11.9661 7.55721 11.7034 7.34595 11.4198 7.16563C10.6687 6.68467 9.79548 6.42909 8.90357 6.42909C7.66563 6.42678 6.47799 6.91865 5.60416 7.79552L1.36492 12.0381C0.49258 12.9121 0.00227744 14.0963 0.00149633 15.3312C-0.000144003 17.9081 2.08745 19.9984 4.66433 20C5.90036 20.0043 7.0869 19.5148 7.96042 18.6402L11.4598 15.1409C11.5234 15.0778 11.5589 14.9918 11.5584 14.9023C11.5573 14.7182 11.4072 14.5699 11.2232 14.571H11.0899C10.3587 14.5735 9.63414 14.4331 8.95692 14.1577C8.83214 14.1064 8.68873 14.1353 8.59366 14.231L6.07744 16.7506C5.29609 17.5319 4.02929 17.5319 3.24794 16.7506C2.4666 15.9692 2.4666 14.7024 3.24794 13.9211L7.50386 9.66851C8.28458 8.88877 9.54931 8.88877 10.33 9.66851C10.8562 10.1637 11.6769 10.1637 12.203 9.66851C12.4294 9.44195 12.5666 9.14146 12.5896 8.82198C12.614 8.44021 12.4732 8.06633 12.203 7.79552Z"
        fill="currentColor"
      />
      <path
        d="M18.6318 1.36666C16.8096 -0.455553 13.8552 -0.455553 12.033 1.36666L8.53697 4.85935C8.44097 4.95582 8.41328 5.10091 8.46698 5.22597C8.52006 5.35137 8.6441 5.43187 8.78024 5.42925H8.90354C9.6338 5.42792 10.3572 5.56942 11.0332 5.84585C11.1579 5.89721 11.3014 5.86823 11.3964 5.77255L13.906 3.26632C14.6873 2.48497 15.9541 2.48497 16.7355 3.26632C17.5168 4.04766 17.5168 5.31447 16.7355 6.09581L13.6093 9.21857L13.5827 9.24856L12.4895 10.335C11.7088 11.1148 10.4441 11.1148 9.66337 10.335C9.13721 9.83986 8.3165 9.83986 7.79038 10.335C7.56257 10.5633 7.42521 10.8664 7.40377 11.1882C7.3794 11.57 7.5202 11.9439 7.79038 12.2147C8.17613 12.6021 8.62746 12.918 9.12346 13.1479C9.19345 13.1812 9.26344 13.2079 9.33343 13.2378C9.40341 13.2678 9.47672 13.2912 9.54671 13.3178C9.6167 13.3445 9.69 13.3678 9.75999 13.3878L9.95663 13.4411C10.0899 13.4744 10.2233 13.5011 10.3599 13.5244C10.5245 13.5489 10.6902 13.5645 10.8565 13.5711H11.0898H11.1097L11.3097 13.5478C11.383 13.5445 11.4597 13.5278 11.5463 13.5278H11.6596L11.8896 13.4945L11.9963 13.4745L12.1896 13.4345H12.2262C13.0449 13.2289 13.7924 12.805 14.3892 12.208L18.6317 7.96547C20.454 6.14326 20.454 3.18887 18.6318 1.36666Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="1em" height="1em" fill="white" />
      </clipPath>
    </defs>
  </svg>
))
