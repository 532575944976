import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.92891 12.9289C4.01809 11.8398 5.31449 11.0335 6.72894 10.5454C5.21402 9.50199 4.21875 7.75578 4.21875 5.78125C4.21875 2.59348 6.81223 0 10 0C13.1878 0 15.7812 2.59348 15.7812 5.78125C15.7812 7.75578 14.786 9.50199 13.271 10.5454C14.6855 11.0335 15.9819 11.8398 17.0711 12.9289C18.9598 14.8177 20 17.3289 20 20H18.4375C18.4375 15.3475 14.6525 11.5625 10 11.5625C5.34754 11.5625 1.5625 15.3475 1.5625 20H0C0 17.3289 1.0402 14.8177 2.92891 12.9289ZM10 10C12.3262 10 14.2188 8.1075 14.2188 5.78125C14.2188 3.455 12.3262 1.5625 10 1.5625C7.67379 1.5625 5.78125 3.455 5.78125 5.78125C5.78125 8.1075 7.67379 10 10 10Z"
      fill="currentColor"
    />
  </svg>
))
