export const redactHeaders = (headers) => {
	if (!headers.authorization) {
		return headers;
	}

	// Drop the signature so these tokens can't be reused
	const result = {
		...headers,
		authorization: headers.authorization.split('.').slice(0, 2).join('.'),
	};

	return result;
};

// Redact PII of request body
export const redactRequestBody = (body) => {
	if (!body) return null;

	const result = {
		...body,
		name: body?.name?.split(' ')?.slice(0, 2)?.join(' '),
	};

	if (body?.request?.actor) {
		result.request.actor = {
			...body.request.actor,
			name: 'X',
			phone: body.request.actor?.phone.substring(0, 5),
		};
	}

	if (body?.request?.expectations) {
		if (body.request.expectations?.address) {
			result.request.expectations.address = {
				data: {
					country: body.request.expectations.address.country,
				},
			};
		}

		if (body.request.expectations?.dob) {
			result.request.expectations.dob = {
				data: new Date().toISOString(),
			};
		}

		if (body.request.expectations?.name) {
			result.request.expectations.name = {
				data: {
					first: 'X',
					last: 'Y',
					other: 'Z',
				},
			};
		}
	}

	return result;
};
