import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M65 35.4285L38 26L11 35.4285L38 45L65 35.4285Z" fill="#23A4DE" />
    <path d="M65 35.4285L38 26V45L65 35.4285Z" fill="#0061A8" />
    <path
      d="M50.564 12.2517C49.9575 5.9453 44.676 1 38.2717 1C31.8676 1 26.5864 5.94539 25.9794 12.2517C21.8788 12.8404 18.7161 16.4077 18.7161 20.7066C18.7161 25.4157 22.5117 29.2468 27.177 29.2468C53.1412 29.2468 25.0325 29.2469 49.3663 29.2469C54.0317 29.2469 57.8272 25.4157 57.8272 20.7067C57.8272 16.4077 54.6644 12.8404 50.564 12.2517Z"
      fill="#FFB71B"
    />
    <path d="M24.6628 33.0206L25.8848 33.7261L39.4975 10.1488L38.2756 9.4433L24.6628 33.0206Z" fill="white" />
    <path d="M51.6084 31.137H24.3829V32.548H51.6084V31.137Z" fill="white" />
    <path d="M24.9217 31.9295L38.5396 39.792L39.245 38.5701L25.6272 30.7076L24.9217 31.9295Z" fill="white" />
    <path d="M36.7514 11.438L50.3693 19.3005L51.0748 18.0786L37.4569 10.2161L36.7514 11.438Z" fill="white" />
    <path d="M24.9256 18.0732L25.6311 19.2952L39.2489 11.4327L38.5434 10.2107L24.9256 18.0732Z" fill="white" />
    <path d="M36.5054 10.149L50.1182 33.7263L51.3401 33.0208L37.7273 9.44353L36.5054 10.149Z" fill="white" />
    <path d="M26.8734 17.1375H25.4624V32.8624H26.8734V17.1375Z" fill="white" />
    <path d="M50.5375 17.1375H49.1265V32.8624H50.5375V17.1375Z" fill="white" />
    <path d="M37.6832 38.1275L38.2994 39.1948L50.1433 32.3566L49.527 31.2892L37.6832 38.1275Z" fill="white" />
    <path
      d="M52.4075 33.3215C51.5915 34.741 49.7725 35.2255 48.353 34.4095C46.9335 33.5935 46.449 31.7745 47.265 30.355C48.081 28.9355 49.9 28.451 51.3195 29.267C52.7475 30.0915 53.232 31.902 52.4075 33.3215Z"
      fill="white"
    />
    <path
      d="M28.7265 19.6449C27.9105 21.0644 26.0915 21.5489 24.672 20.7329C23.2525 19.9169 22.768 18.0979 23.584 16.6784C24.4 15.2589 26.219 14.7744 27.6385 15.5904C29.058 16.4149 29.5425 18.2254 28.7265 19.6449Z"
      fill="white"
    />
    <path
      d="M23.5925 33.3215C22.7765 31.902 23.261 30.0915 24.6805 29.267C26.1 28.451 27.9105 28.9355 28.735 30.355C29.551 31.7745 29.0665 33.585 27.647 34.4095C26.219 35.2255 24.4085 34.741 23.5925 33.3215Z"
      fill="white"
    />
    <path
      d="M47.2735 19.6449C46.4575 18.2254 46.942 16.4149 48.3615 15.5904C49.781 14.7744 51.5915 15.2589 52.416 16.6784C53.232 18.0979 52.7475 19.9084 51.328 20.7329C49.9085 21.5489 48.0895 21.0644 47.2735 19.6449Z"
      fill="white"
    />
    <path
      d="M37.9999 41.643C36.3594 41.643 35.0334 40.317 35.0334 38.6765C35.0334 37.036 36.3594 35.71 37.9999 35.71C39.6404 35.71 40.9664 37.036 40.9664 38.6765C40.9664 40.3085 39.6404 41.643 37.9999 41.643Z"
      fill="white"
    />
    <path
      d="M37.9999 14.2899C36.3594 14.2899 35.0334 12.9639 35.0334 11.3234C35.0334 9.68293 36.3594 8.35693 37.9999 8.35693C39.6404 8.35693 40.9664 9.68293 40.9664 11.3234C40.9664 12.9639 39.6404 14.2899 37.9999 14.2899Z"
      fill="white"
    />
    <path d="M38 44.566L11 35V69.401L38 78V44.566Z" fill="#31448F" />
    <path d="M65 69.401V35L38 44.566V78L65 69.401Z" fill="#262F69" />
    <path d="M11.2576 35L2 46.0304L29.2457 56L38 44.4747L11.2576 35Z" fill="#7BC9EB" />
    <path d="M64.7401 35L74 46.0304L46.7566 56L38 44.4747L64.7401 35Z" fill="#23A4DE" />
  </svg>
))
