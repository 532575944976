import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_8061_91340" maskUnits="userSpaceOnUse" x="4" y="6" width="58" height="58">
      <ellipse cx="33.0004" cy="35.3998" rx="28.6" ry="28.6" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0_8061_91340)">
      <path d="M50.9559 92.7112L31.7759 45.8291L-14.8008 26.3433V92.7112H50.9559Z" fill="#262F69" />
      <path d="M78.4226 92.7109V66.8921V65.2219L31.7305 45.8521L50.9566 92.7109H78.4226Z" fill="#0061A8" />
      <path d="M-14.8008 26.3427L31.7298 45.8518L12.5267 -0.9375L-14.8008 26.3427Z" fill="#7BC9EB" />
      <path
        d="M78.4222 26.343L31.8686 -20.3999L24.6443 -13.1623L12.5039 -0.914034L31.7301 45.852L78.4222 65.2219V26.343Z"
        fill="#23A4DE"
      />
      <path
        d="M31.2401 32.5909L18.5614 45.7775C17.0577 47.3413 18.348 49.9885 20.4952 49.6786C20.8613 49.6258 21.2666 49.505 21.7151 49.298C21.7151 49.298 22.1102 52.5938 25.579 52.3339C25.579 52.3339 26.0511 55.6679 29.8914 54.8522C29.8914 54.8522 31.755 58.2977 37.022 53.438C39.6208 51.0401 48.2174 44.0814 48.2174 44.0814L31.2401 32.5909Z"
        fill="#DF9856"
      />
      <path
        d="M61.0952 26.9163L48.6002 30.7891C47.8474 31.0202 47.0314 30.9135 46.3634 30.4968L39.0486 25.9347C38.061 25.3187 36.8476 25.183 35.7484 25.5656L27.5743 28.4105L31.8749 31.8604L19.1961 45.0471C17.6925 46.6109 18.9828 49.2581 21.1301 48.9482C21.496 48.8953 21.9014 48.7746 22.3499 48.5676C22.3499 48.5676 22.7451 51.8633 26.2138 51.6036C26.2138 51.6036 26.6859 54.9374 30.5262 54.1217C30.5262 54.1217 32.3898 57.5673 37.6568 52.7075C40.2556 50.3097 48.8522 43.3509 48.8522 43.3509L64.4183 38.4133L61.0952 26.9163Z"
        fill="#FFC086"
      />
      <path
        d="M41.6876 36.7392C43.4281 35.9282 43.1321 34.4288 42.4264 33.8801L37.1125 29.6492C36.5423 29.1952 35.8746 28.8794 35.1619 28.7267L29.8578 27.5902L27.707 28.3387L32.0076 31.7886L28.5194 35.4166C29.0339 35.3888 31.6041 35.204 33.7225 34.2154C33.7225 34.2154 37.9462 38.4826 41.6876 36.7392Z"
        fill="#E8974C"
      />
      <path
        d="M43.3364 33.0265L38.0225 28.7956C37.4523 28.3416 36.7847 28.0259 36.0719 27.8731L28.9814 26.3538C28.1233 26.1699 27.2276 26.3632 26.5217 26.8845L20.1114 31.6193C19.6407 31.967 19.0276 32.0569 18.477 31.8589L8.83107 28.3907L7.88477 40.2627L19.2637 44.9546L29.3356 34.5676C29.3356 34.5676 32.2668 34.4658 34.6325 33.3619C34.6325 33.3619 38.8562 37.6291 42.5977 35.8857C44.3382 35.0746 44.0421 33.5752 43.3364 33.0265"
        fill="#FFD9B7"
      />
      <path
        d="M41.5036 35.7244C38.5043 35.7544 34.943 32.1586 34.8564 32.1377C34.8564 32.1377 32.2669 34.5829 27.5635 33.4582C27.5635 33.4582 26.7993 36.2309 22.4257 41.2219C21.6066 42.1568 20.2864 42.4734 19.1298 42.0182C18.6952 41.8471 18.2079 41.6703 17.6868 41.4878C16.4419 41.0517 15.6244 39.8568 15.6704 38.5385L15.936 30.9453C14.2952 30.3751 12.3779 32.0515 10.8611 33.7278C9.27938 35.476 8.13332 37.224 8.19059 36.4246L7.88477 40.2627L18.9795 44.8375L18.9763 44.8405L19.2528 44.9546L19.2559 44.9514L19.2636 44.9546L21.0989 43.0509L29.3248 34.5676C29.3248 34.5676 32.256 34.4658 34.6216 33.3619C34.6136 33.3596 35.334 34.0949 36.4511 34.8303C38.0199 35.8628 40.3711 36.8954 42.5869 35.8857C43.2487 35.5841 43.6156 35.179 43.778 34.7628C43.5458 35.2055 42.7495 35.7119 41.5036 35.7244"
        fill="#F1AF72"
      />
      <path
        d="M33.9491 54.1217C30.7509 51.0095 31.4483 49.0596 31.4483 49.0596L48.3842 41.0407L48.4075 41.0355C49.7432 40.7398 50.6689 39.5214 50.5962 38.1554L50.178 30.3053L64.5652 37.7733L64.6756 38.1554L48.8533 43.3509C48.8533 43.3509 40.2567 50.3097 37.658 52.7075C32.3909 57.5673 30.5273 54.1217 30.5273 54.1217C30.5273 54.1217 31.9951 55.6956 33.9491 54.1217Z"
        fill="#DF9856"
      />
      <path
        d="M22.2291 49.2338C24.909 46.7248 27.589 44.2158 30.2689 41.7068C30.5009 41.4896 30.1523 41.1424 29.921 41.359C27.241 43.8679 24.5611 46.3769 21.8811 48.8859C21.6491 49.1032 21.9977 49.4503 22.2291 49.2338Z"
        fill="#DF9856"
      />
      <path
        d="M26.0943 52.2698C28.6724 49.7743 31.2504 47.2789 33.8285 44.7834C34.0568 44.5624 33.7086 44.2149 33.4806 44.4355C30.9025 46.9309 28.3244 49.4264 25.7464 51.9219C25.5181 52.1429 25.8663 52.4904 26.0943 52.2698"
        fill="#DF9856"
      />
      <path
        d="M30.4049 54.7875C32.6861 52.5834 34.9673 50.3794 37.2484 48.1753C37.4769 47.9545 37.1287 47.6071 36.9005 47.8274C34.6193 50.0314 32.3382 52.2355 30.057 54.4395C29.8285 54.6603 30.1768 55.0079 30.4049 54.7875"
        fill="#DF9856"
      />
      <path
        d="M32.0649 47.7427L32.0979 47.7087C33.0029 46.7792 34.5375 46.8313 35.5257 47.8251L35.3432 47.6415C34.4559 46.749 34.4481 45.2946 35.3257 44.3933L35.723 43.9851C36.2483 43.4456 36.9714 43.229 37.6579 43.3312C37.5878 42.5886 37.8299 41.8211 38.3865 41.2495L38.4229 41.2121C39.4185 40.1894 41.0414 40.1811 42.0479 41.1937L42.5266 41.6752C42.436 40.9934 42.6442 40.2783 43.157 39.7516C44.0346 38.8502 45.4652 38.8429 46.3525 39.7355L48.4275 41.8228C49.3147 42.7153 49.3225 44.1696 48.4448 45.0712C47.8815 45.6499 47.0905 45.8579 46.3613 45.698C46.8743 46.6786 46.7284 47.9229 45.9197 48.7536L45.8833 48.791C45.3269 49.3626 44.5743 49.6166 43.843 49.5532C43.9507 50.2498 43.7452 50.9871 43.2199 51.5266L42.8227 51.9347C41.9451 52.8362 40.5143 52.8435 39.6271 51.951L37.4851 49.7962L39.4443 51.7672C40.4326 52.7612 40.4999 54.3205 39.5948 55.2502L39.5616 55.2843C38.6567 56.2139 37.1219 56.1617 36.1337 55.1676L32.2153 51.2259C31.2271 50.2318 31.1597 48.6724 32.0649 47.7427"
        fill="#F1AF72"
      />
      <path d="M59.6549 41.7588L59.6543 41.7531L59.8792 41.695L59.8796 41.7008L59.6549 41.7588" fill="#5C7D8E" />
      <path d="M58.9707 41.9355V41.9297L59.6554 41.7531L59.656 41.7588L58.9707 41.9355Z" fill="#A1A79C" />
      <path d="M53.5607 43.3311L53.5605 43.3253L58.9708 41.9298V41.9357L53.5607 43.3311Z" fill="#AAB2AA" />
      <path d="M0.900819 37.591L10.8627 43.053L17.9796 30.5061L8.01776 25.0442L0.900819 37.591Z" fill="white" />
      <path
        d="M10.8515 43.0467L4.25909 39.3074L6.08375 37.4425L3.10611 32.1093L3.14431 32.042L15.1197 28.9007L10.8515 43.0467Z"
        fill="#D1DBD7"
      />
      <path d="M4.37885 21.158L-0.615115 39.6663L6.91234 43.6703L15.9374 27.3977L4.37885 21.158Z" fill="#473D5A" />
      <path d="M0.407984 35.7378L-0.648352 39.6527L6.8063 43.8544L8.71528 40.4124L0.407984 35.7378Z" fill="#2C2837" />
      <path d="M62.5685 38.6394L54.3577 42.3397L48.5363 29.1418L61.5623 23.3176L62.5685 38.6394Z" fill="white" />
      <path
        d="M54.3796 42.3306L51.5503 27.813L51.5504 27.813L60.0241 31.8035L63.8275 37.3926L63.8302 37.433L60.3513 39.6966L60.1387 39.7904L59.4918 40.0758L54.3796 42.3306Z"
        fill="#D1DBD7"
      />
      <path d="M63.6734 20.8L50.8798 26.2541L58.2256 43.3506L66.7841 39.7161L63.6734 20.8Z" fill="#4F6483" />
      <path d="M66.1549 35.885L56.7609 39.9284L58.3146 43.5447L66.8128 39.8862L66.1549 35.885Z" fill="#343F56" />
      <path
        d="M32.1866 50.6709C33.3772 51.8266 34.5679 52.9823 35.7585 54.138C36.5904 54.9453 38.0725 55.0281 38.8896 54.138C39.6909 53.265 39.7774 51.8687 38.8896 51.0069C37.6989 49.8512 36.5083 48.6955 35.3177 47.5399C34.4858 46.7324 33.0037 46.6498 32.1866 47.5399C31.3852 48.4128 31.2987 49.8091 32.1866 50.6709Z"
        fill="#FFD9B7"
      />
      <path
        d="M35.2678 46.9883C36.4977 48.1022 37.7275 49.216 38.9574 50.33C39.7871 51.0813 41.1757 51.2104 41.9841 50.33C42.7364 49.5104 42.8703 48.1059 41.9841 47.3032C40.7542 46.1894 39.5244 45.0755 38.2945 43.9617C37.4648 43.2102 36.0762 43.0811 35.2678 43.9617C34.5155 44.7812 34.3816 46.1857 35.2678 46.9883Z"
        fill="#FFD9B7"
      />
      <path
        d="M38.7968 43.9135C40.0266 45.0274 41.2565 46.1412 42.4863 47.2552C43.3447 48.0324 44.7812 48.1659 45.6174 47.2552C46.3957 46.4072 46.5342 44.9544 45.6174 44.1241C44.3876 43.0102 43.1577 41.8963 41.9279 40.7824C41.0696 40.0051 39.633 39.8716 38.7968 40.7824C38.0186 41.6303 37.8801 43.0832 38.7968 43.9135Z"
        fill="#FFD9B7"
      />
      <path
        d="M43.5921 41.6071C44.4966 42.4209 45.4012 43.2346 46.3057 44.0484C46.9369 44.6164 47.9872 44.7178 48.6017 44.0484C49.1709 43.4285 49.2761 42.359 48.6017 41.7523C47.6973 40.9386 46.7928 40.1247 45.8883 39.311C45.257 38.743 44.2066 38.6415 43.5921 39.311C43.023 39.9309 42.9178 41.0004 43.5921 41.6071"
        fill="#FFD9B7"
      />
    </g>
    <g clipPath="url(#clip0_8061_91340)">
      <path d="M32.6012 22.0002H50.2012V8.67059L41.4012 2.2002L32.6012 8.67059V22.0002Z" fill="#7BC9EB" />
      <path
        d="M31.7434 11L41.4004 2.92222L51.0573 11L52.4004 9.20126L41.4004 0L30.4004 9.20126L31.7434 11Z"
        fill="#23A4DE"
      />
      <path d="M37.0008 13.2002H45.8008V22.0002H37.0008V13.2002Z" fill="#F2F2F2" />
      <path d="M39.1996 15.3999H43.5996V21.9999H39.1996V15.3999Z" fill="#0061A8" />
    </g>
    <path
      d="M28.7537 10.8183C28.2329 10.7332 27.7278 10.6136 27.2523 10.4629C23.7667 9.35826 21.7584 6.56173 21.0503 2.92888C20.983 2.58472 20.4849 2.59337 20.4298 2.93971C19.7546 7.18722 17.3139 10.2252 13.028 11.0982C12.6871 11.1676 12.6958 11.6603 13.039 11.7176C17.3129 12.4311 19.9017 15.3757 20.7215 19.5633C20.7731 19.8271 21.0526 19.8236 21.0366 19.8178C21.1894 19.8149 21.3182 19.7027 21.342 19.5518C21.6842 17.379 22.6999 14.4173 25.5953 12.648C26.5203 12.0832 27.5868 11.6762 28.7652 11.4381C28.9143 11.408 29.0207 11.2756 29.0178 11.1234C29.015 10.9713 28.9038 10.8429 28.7537 10.8183Z"
      fill="#FFB71B"
    />
    <path
      d="M30.2566 3.28799C30.0514 3.25444 29.8524 3.20732 29.6651 3.14794C28.2916 2.71269 27.5003 1.6108 27.2213 0.179379C27.1948 0.0437735 26.9986 0.0471807 26.9768 0.183646C26.7108 1.85726 25.7491 3.0543 24.0604 3.39827C23.9261 3.42562 23.9295 3.61975 24.0647 3.64234C25.7487 3.92347 26.7687 5.08369 27.0918 6.7337C27.1121 6.83766 27.2222 6.83627 27.2159 6.83396C27.2761 6.83284 27.3269 6.78863 27.3362 6.72918C27.4711 5.87303 27.8713 4.70608 29.0122 4.00891C29.3766 3.78639 29.7969 3.62602 30.2612 3.53219C30.3199 3.52032 30.3618 3.46815 30.3607 3.40821C30.3596 3.34826 30.3158 3.29767 30.2566 3.28799Z"
      fill="#FFB71B"
    />
    <defs>
      <clipPath id="clip0_8061_91340">
        <rect width="22" height="22" fill="white" transform="translate(30.4004)" />
      </clipPath>
    </defs>
  </svg>
))
