import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.05582 19.5H1.18758L7.54482 18.1165C7.66865 18.0959 7.78339 18.0385 7.87418 17.9518L18.6452 7.1808C19.432 6.39567 19.871 5.32777 19.8639 4.21632C19.8654 3.09622 19.4278 2.02019 18.6452 1.21886C17.8601 0.432032 16.7922 -0.00696545 15.6807 0.000158881C14.5621 -0.00973953 13.4892 0.443255 12.7162 1.25184L1.97807 12.0228C1.88337 12.099 1.81434 12.2025 1.78042 12.3193L0.397035 18.6765C0.35927 18.9047 0.432657 19.1373 0.594688 19.3024C0.716306 19.4265 0.882058 19.4975 1.05582 19.5ZM15.6807 1.31772C17.2634 1.31759 18.5464 2.60054 18.5466 4.18322C18.5466 4.19425 18.5465 4.20528 18.5464 4.21632C18.5572 4.9703 18.2597 5.69603 17.7229 6.22557L13.6714 2.14112C14.2052 1.6107 14.9282 1.31438 15.6807 1.31772ZM12.7491 3.09641L16.8006 7.14788L7.41299 16.5025L3.36152 12.4839L12.7491 3.09641ZM2.8345 13.8016L6.09543 17.0625L1.91219 17.9848L2.8345 13.8016Z"
      fill="currentColor"
    />
  </svg>
))
