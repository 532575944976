import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.75 1H18.75C19.0815 1 19.3994 1.1317 19.6338 1.36611C19.8682 1.60053 19.9999 1.91847 19.9999 2.24999V5.99996H12.5V2.24999C12.5 1.91847 12.6317 1.60053 12.8661 1.36611C13.1005 1.1317 13.4185 1 13.75 1V1Z"
      fill="#262F69"
    />
    <path
      d="M26.2498 3.5H21.2499V5.99998C21.2499 6.3315 21.1182 6.64944 20.8838 6.88386C20.6493 7.11828 20.3314 7.24997 19.9999 7.24997H12.4999C12.1684 7.24997 11.8505 7.11828 11.6161 6.88386C11.3816 6.64944 11.25 6.3315 11.25 5.99998V3.5H6.24999C5.91847 3.5 5.60053 3.6317 5.36611 3.86611C5.1317 4.10053 5 4.41847 5 4.74999V29.7498C5 30.0813 5.1317 30.3993 5.36611 30.6337C5.60053 30.8681 5.91847 30.9998 6.24999 30.9998H26.2498C26.5814 30.9998 26.8993 30.8681 27.1337 30.6337C27.3681 30.3993 27.4998 30.0813 27.4998 29.7498V4.74999C27.4998 4.41847 27.3681 4.10053 27.1337 3.86611C26.8993 3.6317 26.5814 3.5 26.2498 3.5ZM16.8749 9.79042C17.556 9.87601 18.2122 10.101 18.8026 10.4513C19.3929 10.8017 19.9048 11.2699 20.3062 11.8267C20.7076 12.3836 20.9901 13.0172 21.1359 13.688C21.2817 14.3588 21.2877 15.0524 21.1536 15.7257L16.8749 14.2995V9.79042ZM15.6249 9.79042V14.7499C15.6247 14.8812 15.6659 15.0093 15.7426 15.1158C15.8194 15.2224 15.9277 15.3021 16.0523 15.3437L20.758 16.9122C20.3831 17.6944 19.8112 18.3657 19.0984 18.86C18.3856 19.3543 17.5565 19.6546 16.6925 19.7315C15.8286 19.8085 14.9594 19.6593 14.1705 19.2987C13.3816 18.9381 12.7001 18.3785 12.193 17.6748C11.6858 16.9711 11.3704 16.1476 11.2778 15.2852C11.1852 14.4227 11.3185 13.551 11.6647 12.7557C12.0109 11.9604 12.558 11.2688 13.2523 10.7489C13.9467 10.229 14.7643 9.89871 15.6249 9.79042ZM23.7499 28.4998H8.74997V27.2498H11.25V24.7498H13.7499V27.2498H14.9999V23.4999H17.4999V27.2498H18.7499V22.2499H21.2499V27.2498H23.7499V28.4998Z"
      fill="#0061A8"
    />
    <path
      d="M16.8748 9.79053C17.5559 9.87611 18.2121 10.1011 18.8024 10.4514C19.3927 10.8018 19.9046 11.27 20.306 11.8268C20.7075 12.3837 20.9899 13.0173 21.1357 13.6881C21.2815 14.3589 21.2875 15.0525 21.1535 15.7258L16.8748 14.2996V9.79053ZM15.6248 9.79053V14.75C15.6246 14.8813 15.6658 15.0094 15.7425 15.1159C15.8192 15.2225 15.9275 15.3022 16.0521 15.3438L20.7578 16.9123C20.383 17.6946 19.811 18.3658 19.0982 18.8601C18.3855 19.3544 17.5564 19.6547 16.6924 19.7316C15.8284 19.8086 14.9593 19.6594 14.1704 19.2988C13.3815 18.9382 12.7 18.3786 12.1928 17.6749C11.6856 16.9712 11.3702 16.1477 11.2776 15.2853C11.185 14.4228 11.3183 13.5511 11.6645 12.7558C12.0107 11.9605 12.5578 11.2689 13.2522 10.749C13.9465 10.2291 14.7641 9.89882 15.6248 9.79053Z"
      fill="#7BC9EB"
    />
    <path
      d="M23.7499 28.5H8.75V27.25H11.25V24.75H13.75V27.25H15V23.5H17.4999V27.25H18.7499V22.25H21.2499V27.25H23.7499V28.5Z"
      fill="#F2F2F2"
    />
  </svg>
))
