import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.21594 10.1156L10.0532 8.89966H29.9142L32.3461 10.1156V24.7074L30.3195 26.3287L9.24257 26.734L7.21594 24.7074V10.1156Z"
      fill="#23A4DE"
    />
    <path
      d="M9.22994 27.3796C7.44895 27.3796 6 25.9306 6 24.1497H7.77573H31.7864H33.5621C33.5621 25.9306 32.1132 27.3796 30.3322 27.3796H20.8577V29.5329H26.4563V31.3086H13.1059V29.5329H18.7044V27.3796H9.22994Z"
      fill="#23A4DE"
    />
    <path
      d="M9.22994 8H30.3322C32.1132 8 33.5621 9.44895 33.5621 11.2299V24.1497H31.7864H7.77573H6V11.2299C6 9.44895 7.44895 8 9.22994 8Z"
      fill="white"
    />
  </svg>
))
