import React, { Suspense, lazy } from 'react';

import { BrowserRouter, Routes as RouteWrapper, Route } from 'react-router-dom';

import { Routes } from '_constants';

import PrivateRouteWrapper from './private-route-wrapper';

const catchChunkError = (fn, retriesLeft = 3, interval = 500) => {
	return new Promise((resolve, reject) => {
		fn()
			.then(resolve)
			.catch(() => {
				setTimeout(() => {
					if (retriesLeft === 1) {
						window.location.reload();
						return;
					}

					catchChunkError(fn, retriesLeft - 1, interval).then(resolve, reject);
				}, interval);
			});
	});
};

const LoginPage = lazy(() =>
	catchChunkError(() => import('../components/pages/auth/login/login-page'))
);
const HomePage = lazy(() =>
	catchChunkError(() => import('../components/pages/home/home-page'))
);
const ActivitiesPage = lazy(() =>
	catchChunkError(
		() => import('../components/pages/activities/activities-page')
	)
);
const ActivityPage = lazy(() =>
	catchChunkError(() => import('../components/pages/activity/activity-page'))
);

const Router = () => (
	<BrowserRouter>
		<Suspense fallback={null}>
			<RouteWrapper>
				<Route path={Routes.LOGIN} element={<LoginPage />} />
				<Route path={Routes.HOME} element={<PrivateRouteWrapper />}>
					{/* Private routes */}
					<Route path={Routes.HOME} element={<HomePage />} />
					<Route path={Routes.ACTIVITIES} element={<ActivitiesPage />} />
					<Route path={`${Routes.ACTIVITIES}/:id`} element={<ActivityPage />} />
				</Route>
			</RouteWrapper>
		</Suspense>
	</BrowserRouter>
);

export default Router;
