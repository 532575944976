import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 0C6.14023 0 3 3.11865 3 6.9519C3 11.7699 10.0069 20 10.0069 20C10.0069 20 17 11.533 17 6.9519C17 3.11865 13.8599 0 10 0ZM12.112 8.98743C11.5297 9.56567 10.7649 9.85486 10 9.85486C9.23522 9.85486 8.4702 9.56567 7.88808 8.98743C6.72346 7.83093 6.72346 5.9491 7.88808 4.79248C8.45201 4.23218 9.20216 3.92358 10 3.92358C10.7978 3.92358 11.5479 4.2323 12.112 4.79248C13.2767 5.9491 13.2767 7.83093 12.112 8.98743Z"
      fill="currentColor"
    />
  </svg>
))
