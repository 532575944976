import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40.3202 12.4506C39.78 9.09117 36.7564 6.5127 33.1128 6.5127C31.7421 6.5127 30.4244 6.87747 29.2808 7.55784C27.5474 4.74831 24.4214 3 20.9454 3C15.5756 3 11.1999 7.2022 11.1914 12.3672C11.1914 12.3992 11.1913 12.4324 11.1925 12.4644C7.74046 13.0235 5.01784 15.9176 5.01213 19.3926C5.00577 23.2666 8.35804 26.418 12.3857 26.418H39.1667C43.1943 26.418 46.5569 23.2666 46.5633 19.3926C46.5691 15.8879 43.812 12.9755 40.3202 12.4506Z"
      fill="white"
    />
    <path
      d="M52.9194 71.8579C52.9194 70.7641 52.0332 69.8779 50.9401 69.8779H50.1045V50.7123C49.0773 50.7123 48.1052 50.3773 47.296 49.8125C46.752 50.196 46.1259 50.4794 45.4318 50.6171C45.1143 50.6806 44.7903 50.7123 44.4701 50.7123C44.2566 50.7123 44.0517 50.6837 43.8439 50.6566L34.6178 50.4821C34.1688 50.6165 33.7019 50.7116 33.209 50.7116C32.7174 50.7116 32.251 50.6166 31.8015 50.4821L22.5753 50.6567C22.3684 50.6838 22.162 50.7124 21.9487 50.7124C21.6305 50.7124 21.3076 50.6806 20.9836 50.6166C20.2915 50.4788 19.6666 50.1961 19.122 49.8126C18.3129 50.3773 17.3427 50.7124 16.3162 50.7124L16.3156 69.878H15.4799C14.3861 69.878 13.5 70.7642 13.5 71.858C13.5 72.9518 14.3861 73.8379 15.4799 73.8379H50.94C52.0332 73.8378 52.9194 72.9517 52.9194 71.8579Z"
      fill="#0061A8"
    />
    <path
      d="M10.2472 55.1588C10.6472 55.2761 11.0512 55.3319 11.4492 55.3319C13.2556 55.3319 14.9242 54.1771 15.4679 52.3889L15.6886 51.6646C15.838 52.9796 16.6208 54.1383 17.7919 54.7868C18.1697 54.9931 18.5726 55.1635 19.0199 55.2506C19.2965 55.3057 19.5722 55.3319 19.8431 55.3319C21.8047 55.3319 23.5582 53.9754 23.9544 52.0138L24.0567 51.5113C24.1616 52.9094 24.9772 54.1411 26.1897 54.8066C26.6765 55.0746 27.2242 55.2515 27.816 55.3103C27.9576 55.3254 28.0984 55.3312 28.2371 55.3312C30.3667 55.3312 32.1914 53.7445 32.4081 51.6182L32.4391 51.3124C32.4775 52.8137 33.3335 54.1022 34.5867 54.7913C35.192 55.1247 35.8811 55.3311 36.6235 55.3311C37.3685 55.3311 38.0568 55.1247 38.6621 54.7913C39.9143 54.1022 40.7712 52.8137 40.8108 51.3124L40.8406 51.6182C41.0576 53.7444 42.8813 55.3312 45.0116 55.3312C45.1513 55.3312 45.2912 55.3254 45.4329 55.3103C46.0238 55.2515 46.5715 55.0746 47.06 54.8066C48.2718 54.1411 49.0883 52.9094 49.1912 51.5113L49.2935 52.0138C49.6915 53.9762 51.444 55.3319 53.4047 55.3319C53.6765 55.3319 53.9512 55.3056 54.2289 55.2506C54.6741 55.1635 55.079 54.9931 55.4551 54.7868C56.6281 54.1382 57.4109 52.9796 57.5603 51.6646L57.7809 52.3889C58.3247 54.1771 59.9932 55.3319 61.8005 55.3319C62.1978 55.3319 62.6017 55.2761 63.0016 55.1588C65.2236 54.5073 66.4863 52.2108 65.8232 50.0307L62.2344 38.2361C61.4847 35.771 59.1759 34.082 56.557 34.082H16.6931C14.0729 34.082 11.7643 35.771 11.0145 38.2361L7.42652 50.0307C6.7634 52.211 8.02633 54.5074 10.2472 55.1588Z"
      fill="#7BC9EB"
    />
    <path
      d="M69.2628 76.3422H40.55C38.0412 76.3361 36.0067 74.3021 36 71.7934V11.2266C36.0067 8.71826 38.0405 6.68447 40.5489 6.67773H69.2639C71.7722 6.68447 73.8059 8.7181 73.8128 11.2263C73.8128 11.2268 73.8128 11.2273 73.8128 11.2278V71.7934C73.8128 71.7939 73.8128 71.7943 73.8128 71.7947C73.8045 74.304 71.7715 76.3361 69.2628 76.3422Z"
      fill="#23A4DE"
      stroke="#262F69"
      strokeWidth="2"
    />
    <path
      d="M69.2652 77.3427H40.5476C37.488 77.3352 35.0074 74.8547 35 71.795V64.7686C53.2926 64.7685 58.5345 64.7686 74.8128 64.7686V71.7969C74.8035 74.8566 72.3249 77.3352 69.2652 77.3427Z"
      fill="#31448F"
    />
    <rect x="42" y="15" width="22" height="22" rx="11" fill="white" />
    <rect x="42.168" y="48.9014" width="25.0827" height="8.95809" rx="1" fill="#F2F2F2" />
    <rect x="42.168" y="43" width="17.9162" height="3.58324" rx="1" fill="#F2F2F2" />
    <rect x="22" y="58" width="9" height="6" rx="1" fill="#F2F2F2" />
    <g clipPath="url(#clip0_7573_98455)">
      <path d="M53.5 24.5L54.5 20.5L49 14L44.5 15L21.5 38L20.5 40.5L27 49L31 48L53.5 24.5Z" fill="white" />
      <path
        d="M21.0508 38.117L43.8641 15.287C44.0991 15.0503 44.4174 14.917 44.7491 14.917H44.7508C45.0824 14.917 45.4008 15.0487 45.6341 15.2837L53.7175 23.367C53.9525 23.602 54.0841 23.9203 54.0841 24.2503C54.0841 24.582 53.9525 24.9003 53.7175 25.1353L30.8841 47.952L21.0508 38.117Z"
        fill="#FFB71B"
      />
      <path
        d="M54.6137 21.905L55.4354 21.0834C56.4437 20.0734 57.0004 18.7417 57.0004 17.3334C57.0004 15.8934 56.4404 14.5534 55.4387 13.57C53.4354 11.5467 49.9137 11.5484 47.9154 13.5667L47.0938 14.3884L54.6137 21.905Z"
        fill="#FFB71B"
      />
      <path
        d="M20.3735 39.7969L17.0568 50.3769C16.9185 50.8202 17.0368 51.3052 17.3668 51.6335C17.6035 51.8719 17.9235 52.0002 18.2502 52.0002C18.3752 52.0002 18.5002 51.9819 18.6252 51.9435L29.2035 48.6269L20.3735 39.7969Z"
        fill="#FFB71B"
      />
      <path
        d="M28.6165 26.6469C28.9365 26.6469 29.2565 26.5253 29.4998 26.2803L37.5698 18.2103C38.0015 17.7803 38.5781 17.6103 39.1432 17.6519L41.1165 15.6769C39.4098 14.7636 37.2398 15.0053 35.8015 16.4419L27.7315 24.5119C27.2432 25.0003 27.2432 25.7919 27.7315 26.2803C27.9765 26.5236 28.2948 26.6469 28.6165 26.6469Z"
        fill="#FFB71B"
      />
    </g>
    <defs>
      <clipPath id="clip0_7573_98455">
        <rect width="40" height="40" fill="white" transform="translate(17 12)" />
      </clipPath>
    </defs>
  </svg>
))
