import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 292 269" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M81.9111 100.259C99.1176 100.259 113.066 86.3105 113.066 69.1039C113.066 51.8974 99.1176 37.9487 81.9111 37.9487C64.7045 37.9487 50.7559 51.8974 50.7559 69.1039C50.7559 86.3105 64.7045 100.259 81.9111 100.259Z"
      fill="white"
    />
    <path
      d="M137.746 54.9051L127.838 53.6456C127.021 51.1318 126.015 48.7076 124.839 46.4046L130.958 38.5259C133.435 35.3375 133.145 30.8369 130.315 28.0965L122.015 19.7962C119.248 16.9398 114.747 16.6552 111.554 19.1269L103.686 25.2454C101.383 24.0702 98.9583 23.0636 96.4392 22.2467L95.1797 12.3549C94.7054 8.38655 91.3378 5.39844 87.3484 5.39844H75.5435C71.5541 5.39844 68.1866 8.38655 67.7123 12.3391L66.4527 22.2467C63.9337 23.0636 61.5094 24.0649 59.2064 25.2454L51.333 19.1269C48.1499 16.6552 43.6493 16.9398 40.9036 19.7698L32.6033 28.0649C29.7469 30.8369 29.4571 35.3375 31.934 38.5311L38.0525 46.4046C36.872 48.7076 35.8707 51.1318 35.0539 53.6456L25.162 54.9051C21.1937 55.3794 18.2056 58.747 18.2056 62.7364V74.5413C18.2056 78.5307 21.1937 81.8983 25.1462 82.3726L35.0539 83.6321C35.8707 86.1459 36.8773 88.5701 38.0525 90.8731L31.934 98.7518C29.4571 101.94 29.7469 106.441 32.577 109.181L40.8773 117.482C43.6493 120.333 48.1446 120.617 51.3383 118.146L59.2117 112.027C61.5147 113.208 63.9389 114.214 66.4527 115.026L67.7123 124.912C68.1866 128.891 71.5541 131.879 75.5435 131.879H87.3484C91.3378 131.879 94.7054 128.891 95.1797 124.939L96.4392 115.031C98.953 114.214 101.377 113.208 103.68 112.032L111.559 118.151C114.747 120.628 119.248 120.338 121.988 117.508L130.289 109.208C133.145 106.436 133.435 101.94 130.958 98.7466L124.839 90.8731C126.02 88.5701 127.027 86.1459 127.838 83.6321L137.725 82.3726C141.693 81.8983 144.681 78.5307 144.681 74.5413V62.7364C144.686 58.747 141.698 55.3794 137.746 54.9051ZM81.446 94.989C66.9165 94.989 55.0958 83.1683 55.0958 68.6389C55.0958 54.1094 66.9165 42.2887 81.446 42.2887C95.9755 42.2887 107.796 54.1094 107.796 68.6389C107.796 83.1683 95.9755 94.989 81.446 94.989Z"
      fill="#23A4DE"
    />
    <path
      d="M81.7093 50.5376C76.1244 50.5376 71.5591 55.103 71.5591 60.6878C71.5591 66.2727 76.1244 70.838 81.7093 70.838C87.2941 70.838 91.8595 66.2727 91.8595 60.6878C91.8595 55.103 87.2941 50.5376 81.7093 50.5376Z"
      fill="#0061A8"
    />
    <path
      d="M99.1285 78.9496C98.8625 78.2847 98.508 77.6642 98.1091 77.088C96.0702 74.0739 92.9232 72.0794 89.3773 71.5918C88.9341 71.5475 88.4466 71.6361 88.0919 71.9021C86.2303 73.2761 84.0142 73.9853 81.7093 73.9853C79.4044 73.9853 77.1882 73.2761 75.3266 71.9021C74.972 71.6361 74.4844 71.5032 74.0412 71.5918C70.4953 72.0794 67.304 74.0739 65.3094 77.088C64.9105 77.6642 64.5559 78.3291 64.29 78.9496C64.1571 79.2156 64.2013 79.5258 64.3343 79.7918C64.6889 80.4123 65.1321 81.0329 65.531 81.5648C66.1515 82.407 66.8164 83.1604 67.5699 83.8696C68.1905 84.4901 68.8996 85.0663 69.6089 85.6426C73.1104 88.2577 77.3212 89.6317 81.665 89.6317C86.0087 89.6317 90.2195 88.2576 93.7211 85.6426C94.4302 85.1107 95.1394 84.4901 95.76 83.8696C96.4692 83.1604 97.1783 82.4069 97.7989 81.5648C98.2421 80.9886 98.6411 80.4123 98.9957 79.7918C99.2172 79.5258 99.2615 79.2155 99.1285 78.9496Z"
      fill="#0061A8"
    />
    <path
      d="M217.664 113.832C200.069 70.8516 151.579 50.5291 109.359 68.4414C80.4909 80.6887 60.8622 108.447 58.6129 140.203C34.2164 143.638 17.1738 166.556 20.5477 191.393C23.6008 213.867 42.4817 230.595 64.767 230.573H217.664C249.329 230.573 275 204.439 275 172.203C275 139.966 249.331 113.832 217.664 113.832Z"
      fill="white"
    />
    <path
      d="M249.028 28.4646C244.127 16.486 230.62 10.8221 218.858 15.8143C210.817 19.2276 205.349 26.9636 204.722 35.8141C197.926 36.7713 193.179 43.1587 194.119 50.0808C194.969 56.3441 200.229 61.0063 206.436 61H249.028C257.849 61 265 53.7167 265 44.7325C265 35.7481 257.849 28.4646 249.028 28.4646Z"
      fill="white"
    />
    <path
      d="M206.008 141.981V161.6C137.339 161.6 68.6695 161.599 0 161.599V141.981C0 136.404 4.52482 131.879 10.1018 131.879H195.907C201.484 131.879 206.008 136.404 206.008 141.981Z"
      fill="#0061A8"
    />
    <path
      d="M206.011 156.516V258.125C206.011 263.703 201.489 268.225 195.911 268.225H10.1032C4.52479 268.225 0.00292969 263.703 0.00292969 258.125V156.516C68.6722 156.516 137.342 156.516 206.011 156.516Z"
      fill="#7BC9EB"
    />
    <path
      d="M169.219 148.058C167.113 148.058 165.406 146.351 165.406 144.246V144.193C165.406 142.087 167.113 140.38 169.219 140.38C171.325 140.38 173.032 142.087 173.032 144.193V144.246C173.032 146.352 171.325 148.058 169.219 148.058Z"
      fill="white"
    />
    <path
      d="M181.396 148.058C179.289 148.058 177.583 146.351 177.583 144.246V144.193C177.583 142.087 179.29 140.38 181.396 140.38C183.501 140.38 185.208 142.087 185.208 144.193V144.246C185.208 146.352 183.502 148.058 181.396 148.058Z"
      fill="white"
    />
    <path
      d="M193.572 148.058C191.466 148.058 189.76 146.351 189.76 144.246V144.193C189.76 142.087 191.467 140.38 193.572 140.38C195.679 140.38 197.385 142.087 197.385 144.193V144.246C197.385 146.352 195.679 148.058 193.572 148.058Z"
      fill="white"
    />
    <path
      d="M119.267 207.366H18.2771C16.1708 207.366 14.4644 205.659 14.4644 203.553C14.4644 201.447 16.1713 199.74 18.2771 199.74H119.267C121.373 199.74 123.08 201.447 123.08 203.553C123.08 205.659 121.373 207.366 119.267 207.366Z"
      fill="white"
    />
    <path
      d="M67.5622 166.214H20.9263C17.6126 166.214 14.9263 168.9 14.9263 172.214V186.867C14.9263 190.18 17.6126 192.867 20.9263 192.867H67.5622C70.8759 192.867 73.5622 190.18 73.5622 186.867V172.214C73.5622 168.9 70.8759 166.214 67.5622 166.214Z"
      fill="#23A4DE"
    />
    <path
      d="M187.9 166.214H163.917C161.708 166.214 159.917 168.005 159.917 170.214V178.205C159.917 180.415 161.708 182.205 163.917 182.205H187.9C190.109 182.205 191.9 180.415 191.9 178.205V170.214C191.9 168.005 190.109 166.214 187.9 166.214Z"
      fill="#23A4DE"
    />
    <path
      d="M150.586 166.214H126.603C124.394 166.214 122.603 168.005 122.603 170.214V178.205C122.603 180.415 124.394 182.205 126.603 182.205H150.586C152.795 182.205 154.586 180.415 154.586 178.205V170.214C154.586 168.005 152.795 166.214 150.586 166.214Z"
      fill="#23A4DE"
    />
    <path
      d="M185.768 230.181H18.9263C16.7171 230.181 14.9263 231.972 14.9263 234.181V252.834C14.9263 255.043 16.7171 256.834 18.9263 256.834H185.768C187.977 256.834 189.768 255.043 189.768 252.834V234.181C189.768 231.972 187.977 230.181 185.768 230.181Z"
      fill="#EAF5FC"
    />
    <path
      d="M187.736 220.738H18.2771C16.1708 220.738 14.4644 219.031 14.4644 216.926C14.4644 214.82 16.1713 213.113 18.2771 213.113H187.736C189.842 213.113 191.549 214.82 191.549 216.926C191.549 219.031 189.842 220.738 187.736 220.738Z"
      fill="white"
    />
  </svg>
))
