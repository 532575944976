import { Tasks } from './tasks';

export const ReportColWidths = {
	address: 1,
	document: 3,
	facial_similarity_video: 3,
	'report:peps': 1,
};

export const ReportKeys = {
	fillInText: {
		'Face Comparison':
			'Face in the document matches the face in the live photo or video',
		'Visual Authenticity':
			'Photo or video is not a spoof (photos of printed photos or digital screens)',
		image_integrity:
			'Quality of files and their content were sufficient to perform face comparison',
		police_record:
			'Document has not been recorded as lost, stolen or compromised',
		compromised_document:
			'Document has not been flagged as stolen, lost or part of identity fraud',
		'Address matches': '2 or more address matches on Experian database',
		'Address match quality score':
			'The quality of address match sources from Experian is sufficient',
		'PEPs & Sanctions':
			'Identity checked against global PEPs and sanctions lists. Please review PEP, Sanctions and Adverse Media report if necessary',
		face_comparison_biometrics:
			'Face in the document matches the face in the video',
		image_integrity_biometrics:
			'Quality of files and their content were sufficient to perform face comparison',
		visual_authenticity_biometrics:
			'The live video is not a spoof (such as videos of digital screens)',
	},
	address: {
		// Following keys are arrays or strings, not objects
		ignore: ['documents', 'rules', 'service_name'],
		status: {
			clear: {
				data_count: '2 or more address matches on Experian database',
				data_quality:
					'The quality of address match sources from Experian is sufficient',
			},
			consider: {
				data_count: '2 or more address matches on Experian database',
				data_quality:
					'The quality of address match sources from Experian is not sufficient',
			},
		},
		data_count: {
			text: 'Address matches',
			order: 1,
		},
		data_quality: {
			text: 'Address match quality score',
			order: 2,
		},
		rules: {
			text: 'Further Information',
			order: 3,
		},
	},
	document: {
		chip_and_document_validation: {
			text: 'Chip & Document Validation',
			order: 1,
		},
		data_comparison: {
			text: 'Data Comparison',
			order: 2,
		},
		clone_detection: {
			text: 'Chip Cloning Detection',
			order: 3,
		},
		data_consistency: {
			text: 'Data Consistency',
			order: 4,
		},
		data_validation: {
			text: 'Data Validation',
			order: 5,
		},
		visual_authenticity: {
			text: 'Visual Authenticity',
			order: 6,
		},
		image_integrity: {
			text: 'Image Integrity',
			order: 7,
		},
		police_record: {
			text: 'Police Record',
			order: 8,
		},
		compromised_document: {
			text: 'Compromised Document',
			order: 9,
		},
		age_validation: {
			text: 'Age Validation',
			order: 10,
		},
	},
	facial_similarity_video: {
		face_comparison: {
			text: 'Face Comparison',
			order: 1,
		},
		visual_authenticity: {
			text: 'Visual Authenticity',
			order: 2,
		},
		image_integrity: {
			text: 'Image Integrity',
			order: 3,
		},
		face_comparison_biometrics: {
			text: 'Face Comparison',
			order: 4,
		},
		visual_authenticity_biometrics: {
			text: 'Visual Authenticity',
			order: 5,
		},
		image_integrity_biometrics: {
			text: 'Image Integrity',
			order: 6,
		},
	},
	'report:peps': {
		status: {
			clear:
				'Identity checked against global PEPs and sanctions lists. No matches identified.',
			other:
				'Identity checked against global PEPs and sanctions lists. One or more matches identified, please view below for information on the match(es).',
		},
		// in the order they should appear
		generalKeys: [
			'roles',
			'aka',
			'countries',
			'spouses',
			'institutions',
			'political_positions',
			'match_types',
			'flag_types',
		],
		keysMap: {
			aka: 'AKA',
			countries: 'Countries',
			dob: 'Date of Birth',
			roles: 'Roles',
			spouses: 'Spouses',
			institutions: 'Institutions',
			political_positions: 'Political Positions',
			match_types: {
				key: 'Match Types',
				renderType: 'chip',
			},
			flag_types: {
				key: 'Flag Types',
				renderType: 'chip',
			},
		},
		_keys: ['match_types', 'flag_types'],
	},
	'company:sanctions': {
		status: {
			clear:
				'Company checked against global sanctions and adverse media lists. No matches identified.',
			other:
				'Company checked against global sanctions and adverse media lists. One or more matches identified, please view below for information on the match(es).',
		},
		notFound: 'Information not available on file',
		keysMap: {
			countries: 'Countries',
			date: 'Sanction Date',
			snippet: 'Snippet',
		},
	},
};

export const ReportSections = {
	sof: 'sourceOfFunds',
	idCheck: 'identityCheck',
};

export const TaskToGroup = {
	[Tasks.documentsBankStatement]: ReportSections.sof,
	[Tasks.reportBankStatement]: ReportSections.sof,
	[Tasks.reportBankSummary]: ReportSections.sof,
	[Tasks.reportSofV1]: ReportSections.sof,
	[Tasks.documentsOther]: ReportSections.idCheck,
	[Tasks.reportPeps]: ReportSections.idCheck,
	[Tasks.documentsPoa]: ReportSections.idCheck,
	[Tasks.reportFootprint]: ReportSections.idCheck,
	[Tasks.reportIdentity]: ReportSections.idCheck,
};
