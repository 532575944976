import { css } from '@linaria/core'

export const elIntentPrimary = css``

export const elIntentSecondary = css``

export const elIntentCritical = css``

export const elIntentSuccess = css``

export const elIntentDanger = css``

export const elIntentNeutral = css``

export const elIntentLow = css``
