import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.4444 22.1082C18.2439 22.1082 16.2973 23.2761 15.2647 25.1381C16.6358 26.4246 18.4471 27.1863 20.4444 27.1863C22.4417 27.1863 24.253 26.4246 25.6242 25.1381C24.5916 23.2761 22.645 22.1082 20.4444 22.1082Z"
      fill="#23A4DE"
    />
    <path
      d="M24.6762 17.8763C24.6762 15.5431 22.7776 13.6445 20.4444 13.6445C18.1112 13.6445 16.2126 15.5431 16.2126 17.8763C16.2126 20.2095 18.1112 22.1081 20.4444 22.1081C22.7776 22.1081 24.6762 20.2095 24.6762 17.8763Z"
      fill="#23A4DE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.2081 13.7256C31.9538 14.9167 32.4893 16.2102 32.8058 17.5838L34.8889 18.0905V22.7984L32.8058 23.3051C32.4893 24.6786 31.9538 25.9722 31.2081 27.1633L32.2819 28.9543L28.9543 32.2819L27.1633 31.2081C25.9722 31.9538 24.6786 32.4893 23.3051 32.8058L22.7984 34.8889H18.0905L17.5838 32.8058C16.2102 32.4893 14.9167 31.9538 13.7256 31.2081L11.9346 32.2819L8.60695 28.9543L9.68076 27.1633C8.93513 25.9722 8.39955 24.6786 8.08305 23.3051L6 22.7984V18.0905L8.08305 17.5838C8.39955 16.2102 8.93513 14.9167 9.68076 13.7256L8.60695 11.9346L11.9346 8.60695L13.7256 9.68076C14.9167 8.93513 16.2102 8.39955 17.5838 8.08305L18.0905 6H22.7984L23.3051 8.08305C24.6786 8.39955 25.9722 8.93513 27.1633 9.68076L28.9543 8.60695L32.2819 11.9346L31.2081 13.7256ZM20.4445 30.5118C26.0045 30.5118 30.5118 26.0045 30.5118 20.4444C30.5118 14.8844 26.0045 10.3771 20.4445 10.3771C14.8844 10.3771 10.3771 14.8844 10.3771 20.4444C10.3771 26.0045 14.8844 30.5118 20.4445 30.5118Z"
      fill="white"
    />
  </svg>
))
