import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3749 10.3316C19.215 10.3316 19.055 10.2665 18.933 10.1363L18.4375 9.60771V18.0831C18.4375 19.14 17.6314 19.9999 16.6406 19.9999H12.5087C12.5058 20 12.5029 20 12.5 20H7.49997C7.49705 20 7.49413 20 7.49121 19.9999H3.35938C2.3686 19.9999 1.5625 19.1401 1.5625 18.0831V9.6077L1.06694 10.1363C0.822878 10.3967 0.427137 10.3967 0.183075 10.1363C-0.0610252 9.87599 -0.0610252 9.45384 0.183075 9.19349L7.95602 0.901689C9.08304 -0.300563 10.9169 -0.300563 12.0439 0.901689L19.8168 9.19345C20.0609 9.45384 20.0609 9.87595 19.8168 10.1363C19.6948 10.2665 19.5349 10.3316 19.3749 10.3316ZM11.875 18.6665H8.12497V13.4162C8.12497 13.0026 8.4404 12.6662 8.8281 12.6662H11.1719C11.5595 12.6662 11.875 13.0026 11.875 13.4162V18.6665ZM13.125 18.6665V13.4162C13.125 12.2674 12.2488 11.3327 11.1719 11.3327H8.8281C7.75115 11.3327 6.87497 12.2674 6.87497 13.4162V18.6665H3.35938C3.05781 18.6665 2.8125 18.4048 2.8125 18.0831V8.27427L8.83988 1.84459C9.47956 1.16221 10.5204 1.16221 11.16 1.84459L17.1875 8.27429V18.0831C17.1875 18.4048 16.9422 18.6665 16.6406 18.6665H13.125Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="1em" height="1em" fill="white" />
      </clipPath>
    </defs>
  </svg>
))
