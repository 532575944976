import React, { Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Loader, MainContainer, useSnack } from '@reapit/elements';

import * as Sentry from '@sentry/browser';

import APIError from 'core/errors/api-error';

import { useReapitConnect } from '@reapit/connect-session';

import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { reapitConnectBrowserSession } from './connect-session';

import Nav from 'components/ui/nav';
import TopNav from 'components/ui/top-nav';

import { OutletContainer } from './__styles__/styles';

import { useAPI } from 'components/hocs/api-provider';

import { fetchUserStatus } from 'platform-api/auth-api';

import RegisterTenantPage from 'components/pages/auth/register/register-tenant-page';

import { SnackDuration } from '_constants';
import OfficeGroupChangeNotifyModal from 'components/ui/common-modals/office-group-change-notify-modal';

const PrivateRoute = ({ children }) => {
	const fetcher = useAPI();

	const [loading, setLoading] = useState();
	const [userStatus, setUserStatus] = useState();

	const { error } = useSnack();

	useEffect(() => {
		const _checkStatus = async () => {
			setLoading(true);
			try {
				setUserStatus(await fetchUserStatus(fetcher));
			} catch (err) {
				if (!(err instanceof APIError)) {
					Sentry.captureException(err);
				}

				error('Error while checking the user status', SnackDuration.LONG);
			} finally {
				setLoading(false);
			}
		};

		if (fetcher) {
			_checkStatus();
		}
	}, [fetcher]);

	if (!fetcher || !userStatus || loading) {
		return (
			<MainContainer>
				<Loader label="Loading" fullPage />
			</MainContainer>
		);
	}

	const { tenant_registered, user_registered, user_changes } = userStatus;

	if (!tenant_registered || !user_registered) {
		return <RegisterTenantPage userStatus={userStatus} />;
	}

	if (user_changes && user_changes.user_detail_mismatch_reapit) {
		return <OfficeGroupChangeNotifyModal />;
	}

	return (
		<>
			<TopNav />
			<Nav />
			<Suspense fallback={<Loader label="Loading" fullPage />}>
				{children ? (
					children
				) : (
					<OutletContainer>
						<Outlet />
					</OutletContainer>
				)}
			</Suspense>
		</>
	);
};

PrivateRoute.propTypes = {
	children: PropTypes.node,
};

const PrivateRouteWrapper = ({ children }) => {
	const { connectSession, connectInternalRedirect } = useReapitConnect(
		reapitConnectBrowserSession
	);
	const location = useLocation();
	const currentUri = `${location?.pathname}${location?.search}`;

	if (!connectSession) {
		return (
			<MainContainer>
				<Loader label="Loading" fullPage />
			</MainContainer>
		);
	}

	if (connectInternalRedirect && currentUri !== connectInternalRedirect) {
		return <Navigate to={connectInternalRedirect} />;
	}

	return (
		<MainContainer data-cy="main-container">
			<PrivateRoute>{children}</PrivateRoute>
		</MainContainer>
	);
};

PrivateRouteWrapper.propTypes = {
	children: PropTypes.node,
};

export default PrivateRouteWrapper;
