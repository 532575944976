export const ChecksSummaryStatusMap = {
	address: {
		defaultScenario: {
			name: 'error',
			result: {
				action: 'Please contact support',
				reason: ['Error processing checks'],
				status: 'consider',
			},
		},
		name: 'Address',
		scenario: {
			footprint: [
				{
					conditions: {
						isFootprintReportEmpty: true,
						transactionStatus: 'closed',
					},
					name: 'waiting-closed',
					order: 1,
					result: {
						closedByLawyer: true,
						reason: ['Transaction closed', 'Task incomplete'],
						status: 'waiting',
					},
				},
				{
					conditions: {
						isFootprintReportEmpty: true,
					},
					name: 'waiting',
					order: 2,
					result: {
						reason: ['Waiting for client'],
						status: 'waiting',
					},
				},
				{
					conditions: {
						isLiteId: true,
						footprintReportStatus: 'error',
					},
					name: 'error.lite-id',
					order: 3,
					result: {
						action: 'Please contact support',
						reason: ['Error processing checks'],
						status: 'consider',
					},
				},
				{
					conditions: {
						footprintReportStatus: 'error',
						isPoaReportEmpty: false,
					},
					name: 'error.poa-avail',
					order: 4,
					result: {
						action: 'Please contact support',
						reason: ['Error processing checks'],
						status: 'consider',
					},
				},
				{
					conditions: {
						footprintReportStatus: 'error',
						isPoaReportEmpty: true,
						isPoaRequested: true,
					},
					name: 'error.poa-requested',
					order: 5,
					result: {
						action: 'Please contact support',
						reason: ['Error processing checks'],
						status: 'consider',
					},
				},
				{
					conditions: {
						footprintReportStatus: 'error',
						isPoaRequested: false,
					},
					name: 'error',
					order: 6,
					result: {
						action: 'Please contact support',
						reason: ['Error processing checks'],
						status: 'consider',
					},
				},
				{
					conditions: {
						footprintReportStatus: 'unobtainable',
						poaReportResult: 'clear',
					},
					name: 'experian-unobtainable.poa-avail',
					order: 7,
					result: {
						action: 'Please review',
						closed: true,
						reason: ['Non-UK address', 'Proof of address provided'],
						status: 'consider',
					},
				},
				{
					conditions: {
						footprintReportStatus: 'closed',
						footprintReportResult: 'consider',
						hasFootprintRecomendations: false,
					},
					name: 'experian-consider',
					order: 8,
					result: {
						action: 'Please review',
						reason: ['High risk indicator found'],
						status: 'consider',
					},
				},
				{
					conditions: {
						footprintReportStatus: 'unobtainable',
						notPoaReportResult: 'clear',
					},
					name: 'experian-unobtainable.poa-not-avail',
					order: 9,
					result: {
						action: 'Proof of address required',
						reason: ['Non-UK address'],
						status: 'consider',
					},
				},
				{
					conditions: {
						footprintReportStatus: 'closed',
						notFootprintReportResult: 'clear',
						documentsAvailable: true,
					},
					name: 'experian-fail.poa-avail',
					order: 10,
					result: {
						action: 'Please review',
						closed: true,
						reason: ['Address not verified', 'Proof of address provided'],
						status: 'consider',
					},
				},
				{
					conditions: {
						footprintReportStatus: 'closed',
						footprintReportResult: 'clear',
						poaReportResult: 'clear',
					},
					name: 'experian-pass.poa-avail',
					order: 11,
					result: {
						action: 'Please review',
						reason: ['Address verified', 'Proof of Address provided'],
						status: 'pass',
					},
				},
				{
					conditions: {
						footprintReportStatus: 'closed',
						footprintReportResult: 'clear',
					},
					name: 'experian-pass',
					order: 12,
					result: {
						reason: ['Address verified'],
						status: 'pass',
					},
				},
				{
					conditions: {
						footprintReportStatus: 'closed',
						notFootprintReportResult: 'clear',
						notTransactionStatus: 'closed',
					},
					name: 'waiting',
					order: 13,
					result: {
						closed: false,
						reason: ['Waiting for client'],
						status: 'waiting',
					},
				},
				{
					conditions: {
						footprintReportStatus: 'closed',
						notFootprintReportResult: 'clear',
						transactionStatus: 'closed',
						isLiteId: true,
					},
					name: 'lite-id.experian-fail',
					order: 14,
					result: {
						action: 'Proof of address required',
						reason: ['Address not verified'],
						status: 'consider',
					},
				},
				{
					conditions: {
						footprintReportStatus: 'closed',
						notFootprintReportResult: 'clear',
						transactionStatus: 'closed',
					},
					name: 'experian-fail.poa-rejected',
					order: 15,
					result: {
						reason: ['Address not verified', 'Declined to complete activity'],
						status: 'consider',
					},
				},
				{
					conditions: {
						footprintReportStatus: 'complete',
						footprintReportResult: 'fail',
					},
					name: 'experian-fail.poa-requested',
					order: 16,
					result: {
						closed: false,
						reason: ['Waiting for client'],
						status: 'waiting',
					},
				},
			],
			poa: [
				{
					conditions: {
						isPoaReportEmpty: true,
						transactionStatus: 'closed',
					},
					name: 'waiting-closed',
					order: 1,
					result: {
						closedByLawyer: true,
						reason: ['Transaction closed', 'Task incomplete'],
						status: 'waiting',
					},
				},
				{
					conditions: {
						isPoaReportEmpty: true,
					},
					name: 'waiting',
					order: 2,
					result: {
						reason: ['Waiting for client'],
						status: 'waiting',
					},
				},
				{
					conditions: {
						poaReportStatus: 'error',
					},
					name: 'error',
					order: 3,
					result: {
						action: 'Please contact support',
						reason: ['Error processing checks'],
						status: 'consider',
					},
				},
				{
					conditions: {
						poaReportStatus: 'rejected',
					},
					name: 'poa-rejected',
					order: 4,
					result: {
						reason: ['Declined to complete activity'],
						status: 'consider',
					},
				},
				{
					conditions: {
						poaReportStatus: 'closed',
						notPoaReportResult: 'clear',
					},
					name: 'poa-error',
					order: 5,
					result: {
						action: 'Please contact support',
						reason: ['Error processing checks'],
						status: 'consider',
					},
				},
				{
					conditions: {
						poaReportStatus: 'closed',
						poaReportResult: 'clear',
					},
					name: 'poa-avail',
					order: 6,
					result: {
						action: 'Please review',
						reason: ['Proof of address provided'],
						status: 'pass',
					},
				},
			],
		},
	},
	bankStatementReport: {
		defaultScenario: {
			name: 'error',
			result: {
				action: 'Please contact support',
				reason: ['Error gathering data'],
				status: 'consider',
			},
		},
		scenario: [
			{
				conditions: {
					isBankStatementReportEmpty: true,
					transactionStatus: 'closed',
				},
				name: 'waiting-closed',
				order: 1,
				result: {
					closedByLawyer: true,
					reason: ['Transaction closed', 'Task incomplete'],
					status: 'waiting',
				},
			},
			{
				conditions: {
					isBankStatementReportEmpty: true,
				},
				name: 'noData',
				order: 2,
				result: {
					reason: ['Waiting for client'],
					status: 'waiting',
				},
			},
			{
				conditions: {
					bankStatementReportStatus: 'error',
				},
				name: 'error',
				order: 3,
				result: {
					closed: true,
					action: 'Please contact support',
					reason: ['Error gathering data'],
					status: 'consider',
				},
			},
			{
				conditions: {
					bankStatementReportStatus: 'unobtainable',
				},
				name: 'unobtainable',
				order: 4,
				result: {
					closed: true,
					action: 'Please contact support',
					reason: ['Error gathering data'],
					status: 'consider',
				},
			},
			{
				conditions: {
					bankStatementReportStatus: 'rejected',
				},
				name: 'bank-link-rejected',
				order: 5,
				result: {
					closed: true,
					reason: ['Declined to complete activity'],
					status: 'consider',
				},
			},
			{
				conditions: {
					bankStatementReportResult: 'clear',
				},
				name: 'report-bank-link-aval',
				order: 6,
				result: {
					action: 'Please review',
					closed: true,
					reason: ['Data gathered from client'],
					status: 'pass',
				},
			},
			{
				conditions: {
					notBankStatementReportResult: 'clear',
				},
				name: 'consider',
				order: 7,
				result: {
					action: 'Please contact support',
					reason: ['Error gathering data'],
					status: 'consider',
				},
			},
		],
	},
	bankStatementDocument: {
		defaultScenario: {
			name: 'error',
			result: {
				action: 'Please contact support',
				reason: ['Error gathering data'],
				status: 'consider',
			},
		},
		scenario: [
			{
				conditions: {
					isBankStatementDocumentEmpty: true,
					transactionStatus: 'closed',
				},
				name: 'waiting-closed',
				order: 1,
				result: {
					closedByLawyer: true,
					reason: ['Transaction closed', 'Task incomplete'],
					status: 'waiting',
				},
			},
			{
				conditions: {
					isBankStatementDocumentEmpty: true,
				},
				name: 'noData',
				order: 2,
				result: {
					reason: ['Waiting for client'],
					status: 'waiting',
				},
			},
			{
				conditions: {
					bankStatementDocumentStatus: 'error',
				},
				name: 'error',
				order: 3,
				result: {
					action: 'Please contact support',
					closed: true,
					reason: ['Error gathering data'],
					status: 'consider',
				},
			},
			{
				conditions: {
					bankStatementDocumentStatus: 'unobtainable',
				},
				name: 'unobtainable',
				order: 4,
				result: {
					action: 'Please contact support',
					closed: true,
					reason: ['Error gathering data'],
					status: 'consider',
				},
			},
			{
				conditions: {
					bankStatementDocumentStatus: 'rejected',
				},
				name: 'rejected',
				order: 5,
				result: {
					reason: ['Declined to complete activity'],
					status: 'consider',
				},
			},
			{
				conditions: {
					bankStatementDocumentStatus: 'closed',
					bankStatementDocumentResult: 'clear',
				},
				name: 'pass',
				order: 6,
				result: {
					action: 'Please review',
					closed: true,
					reason: ['Data gathered from client'],
					status: 'pass',
				},
			},
			{
				conditions: {
					notBankStatementDocumentResult: 'clear',
				},
				name: 'consider',
				order: 7,
				result: {
					action: 'Please contact support',
					closed: true,
					reason: ['Error gathering data'],
					status: 'consider',
				},
			},
		],
	},
	bankSummaryReport: {
		defaultScenario: {
			name: 'error',
			result: {
				reason: ['Error gathering data'],
				status: 'consider',
			},
		},
		scenario: [
			{
				conditions: {
					isBankSummaryReportEmpty: true,
					transactionStatus: 'closed',
				},
				name: 'waiting-closed',
				order: 1,
				result: {
					closedByLawyer: true,
					reason: ['Transaction closed', 'Task incomplete'],
					status: 'waiting',
				},
			},
			{
				conditions: {
					isBankSummaryReportEmpty: true,
				},
				name: 'noData',
				order: 2,
				result: {
					reason: ['Waiting for client'],
					status: 'waiting',
				},
			},
			{
				conditions: {
					bankSummaryReportStatus: 'error',
				},
				name: 'error',
				order: 3,
				result: {
					closed: true,
					reason: ['Error gathering data'],
					status: 'consider',
				},
			},
			{
				conditions: {
					bankSummaryReportStatus: 'unobtainable',
				},
				name: 'unobtainable',
				order: 4,
				result: {
					closed: true,
					reason: ['Error gathering data'],
					status: 'consider',
				},
			},
			{
				conditions: {
					bankSummaryReportStatus: 'rejected',
				},
				name: 'bank-link-rejected',
				order: 5,
				result: {
					closed: true,
					reason: ['Declined to complete activity'],
					status: 'consider',
				},
			},
			{
				conditions: {
					bankSummaryReportResult: 'clear',
				},
				name: 'report-bank-link-aval',
				order: 6,
				result: {
					action: 'Please review',
					closed: true,
					reason: ['Data gathered from client'],
					status: 'pass',
				},
			},
			{
				conditions: {
					notBankSummaryReportResult: 'clear',
				},
				name: 'consider',
				order: 7,
				result: {
					action: 'Please contact support',
					reason: ['Error gathering data'],
					status: 'consider',
				},
			},
		],
	},
	documentsPoo: {
		defaultScenario: {
			name: 'error',
			result: {
				action: 'Please contact support',
				reason: ['Error processing checks'],
				status: 'consider',
			},
		},
		scenario: [
			{
				conditions: {
					isDocumentsPooEmpty: true,
					transactionStatus: 'closed',
				},
				name: 'waiting-closed',
				order: 1,
				result: {
					closedByLawyer: true,
					reason: ['Transaction closed', 'Task incomplete'],
					status: 'waiting',
				},
			},
			{
				conditions: {
					isDocumentsPooEmpty: true,
				},
				name: 'noData.no-identity',
				order: 2,
				result: {
					reason: ['Waiting for client'],
					status: 'waiting',
				},
			},
			{
				conditions: {
					documentsPooStatus: 'error',
				},
				name: 'error',
				order: 3,
				result: {
					action: 'Please contact support',
					reason: ['Error processing checks'],
					status: 'consider',
				},
			},
			{
				conditions: {
					documentsPooStatus: 'rejected',
				},
				name: 'rejected',
				order: 3,
				result: {
					reason: ['Declined to complete activity'],
					status: 'consider',
				},
			},
			{
				conditions: {
					documentsPooResult: 'clear',
					documentsPooStatus: 'closed',
					hasTwoDocuments: true,
				},
				name: 'pass',
				order: 6,
				result: {
					action: 'Please review',
					reason: ['Two documents available'],
					status: 'pass',
				},
			},
			{
				conditions: {
					documentsPooStatus: 'closed',
					notHasTwoDocuments: true,
				},
				name: 'no two documents',
				order: 6,
				result: {
					reason: ['Client did not provide two documents'],
					status: 'consider',
				},
			},
		],
	},
	documentReport: {
		defaultScenario: {
			name: 'error',
			result: {
				action: 'Please contact support',
				reason: ['Error processing checks'],
				status: 'consider',
			},
		},
		scenario: [
			{
				conditions: {
					isIdentityReportEmpty: true,
					transactionStatus: 'closed',
				},
				name: 'waiting-closed',
				order: 1,
				result: {
					closedByLawyer: true,
					reason: ['Transaction closed', 'Task incomplete'],
					status: 'waiting',
				},
			},
			{
				conditions: {
					isIdentityReportEmpty: true,
				},
				name: 'noData.no-identity',
				order: 2,
				result: {
					reason: ['Waiting for client'],
					status: 'waiting',
				},
			},
			{
				conditions: {
					identityReportStatus: 'error',
				},
				name: 'error',
				order: 3,
				result: {
					action: 'Please contact support',
					reason: ['Error processing checks'],
					status: 'consider',
				},
			},
			{
				conditions: {
					isDocumentReportEmpty: true,
				},
				name: 'noData.no-document',
				order: 4,
				result: {
					reason: ['Waiting for client'],
					status: 'waiting',
				},
			},
			{
				conditions: {
					documentReportStatus: 'error',
				},
				name: 'error',
				order: 5,
				result: {
					action: 'Please contact support',
					reason: ['Error processing checks'],
					status: 'consider',
				},
			},
			{
				conditions: {
					documentReportResult: 'clear',
				},
				name: 'pass',
				order: 6,
				result: {
					reason: ['ID document verified'],
					status: 'pass',
				},
			},
			{
				conditions: {
					notDocumentReportResult: 'clear',
				},
				name: 'consider',
				order: 7,
				result: {
					action: 'Please review',
					reason: ['ID document not verified'],
					status: 'consider',
				},
			},
		],
	},
	facialRecognitionReport: {
		defaultScenario: {
			name: 'error',
			result: {
				action: 'Please contact support',
				reason: ['Error processing checks'],
				status: 'consider',
			},
		},
		scenario: [
			{
				conditions: {
					isIdentityReportEmpty: true,
					transactionStatus: 'closed',
				},
				name: 'waiting-closed',
				order: 1,
				result: {
					closedByLawyer: true,
					reason: ['Transaction closed', 'Task incomplete'],
					status: 'waiting',
				},
			},
			{
				conditions: {
					isFacialRecongitionReportEmpty: true,
					transactionStatus: 'closed',
				},
				name: 'waiting-closed',
				order: 2,
				result: {
					closedByLawyer: true,
					reason: ['Transaction closed', 'Task incomplete'],
					status: 'waiting',
				},
			},
			{
				conditions: {
					isIdentityReportEmpty: true,
				},
				name: 'noData.no-identity-report',
				order: 3,
				result: {
					reason: ['Waiting for client'],
					status: 'waiting',
				},
			},
			{
				conditions: {
					identityReportStatus: 'error',
				},
				name: 'error.identity-report',
				order: 4,
				result: {
					action: 'Please contact support',
					reason: ['Error processing checks'],
					status: 'consider',
				},
			},
			{
				conditions: {
					identityReportStatus: 'closed',
					notIdentityReportResult: 'clear',
					isFacialRecongitionReportEmpty: true,
				},
				name: 'document-report-failed',
				order: 5,
				result: {
					closed: true,
					reason: ['Waiting for client'],
					status: 'waiting',
				},
			},
			{
				conditions: {
					isFacialRecongitionReportEmpty: true,
				},
				name: 'noData.no-facial-recognition-report',
				order: 6,
				result: {
					reason: ['Waiting for client'],
					status: 'waiting',
				},
			},
			{
				conditions: {
					facialRecognitionReportStatus: 'error',
				},
				name: 'error.facial-recognition-report',
				order: 7,
				result: {
					action: 'Please contact support',
					reason: ['Error processing checks'],
					status: 'consider',
				},
			},
			{
				conditions: {
					facialRecognitionReportResult: 'clear',
				},
				name: 'pass',
				order: 8,
				result: {
					reason: ['Facial similarity pass'],
					status: 'pass',
				},
			},
			{
				conditions: {
					facialRecognitionReportSubResult: 'rejected',
				},
				name: 'facial-similaraty-rejected',
				order: 9,
				result: {
					reason: ['Declined to complete activity'],
					status: 'consider',
				},
			},
			{
				conditions: {
					notFacialRecognitionReportResult: 'clear',
				},
				name: 'consider',
				order: 10,
				result: {
					action: 'Please review',
					reason: ['Facial similarity not confirmed'],
					status: 'consider',
				},
			},
		],
	},
	facialRecognitionReportBiometric: {
		defaultScenario: {
			name: 'error',
			result: {
				action: 'Please contact support',
				reason: ['Error processing checks'],
				status: 'consider',
			},
		},
		scenario: [
			{
				conditions: {
					isIdentityReportEmpty: true,
					transactionStatus: 'closed',
				},
				name: 'waiting-closed',
				order: 1,
				result: {
					closedByLawyer: true,
					reason: ['Transaction closed', 'Task incomplete'],
					status: 'waiting',
				},
			},
			{
				conditions: {
					isIdentityReportEmpty: true,
				},
				name: 'noData.no-identity-report',
				order: 2,
				result: {
					reason: ['Waiting for client'],
					status: 'waiting',
				},
			},
			{
				conditions: {
					identityReportStatus: 'error',
				},
				name: 'error.identity-report',
				order: 3,
				result: {
					action: 'Please contact support',
					reason: ['Error processing checks'],
					status: 'consider',
				},
			},
			{
				conditions: {
					identityReportStatus: 'closed',
					facialRecognitionReportBiometricStatus: 'complete',
					facialRecognitionReportBiometricResult: 'clear',
				},
				name: 'pass',
				order: 4,
				result: {
					action: '',
					reason: ['Facial similarity confirmed'],
					status: 'pass',
				},
			},
			{
				conditions: {
					notfacialRecognitionReportBiometricResult: 'clear',
				},
				name: 'consider',
				order: 5,
				result: {
					action: 'Please review',
					reason: ['Facial similarity not confirmed'],
					status: 'consider',
				},
			},
		],
	},
	pepsReport: {
		defaultScenario: {
			name: 'error',
			result: {
				action: 'Please contact support',
				reason: ['Error processing checks'],
				status: 'consider',
			},
		},
		scenario: [
			{
				conditions: {
					isPepsReportEmpty: true,
					transactionStatus: 'closed',
				},
				name: 'waiting-closed',
				order: 1,
				result: {
					closedByLawyer: true,
					reason: ['Transaction closed', 'Task incomplete'],
					status: 'waiting',
				},
			},
			{
				conditions: {
					isPepsReportEmpty: true,
				},
				name: 'noData',
				order: 2,
				result: {
					reason: ['Waiting for client'],
					status: 'waiting',
				},
			},
			{
				conditions: {
					pepsReportStatus: 'error',
				},
				name: 'error',
				order: 3,
				result: {
					action: 'Please contact support',
					reason: ['Error procesing checks'],
					status: 'consider',
				},
			},
			{
				conditions: {
					pepsReportResult: 'clear',
				},
				name: 'pass',
				order: 4,
				result: {
					reason: ['No match'],
					status: 'pass',
				},
			},
			{
				conditions: {
					notPepsReportResult: 'clear',
				},
				name: 'consider',
				order: 5,
				result: {
					action: 'Please review',
					reason: ['Match(es) found - download report for more information.'],
					status: 'consider',
				},
			},
		],
	},
	sofv1Report: {
		defaultScenario: {
			name: 'error',
			result: {
				action: 'Please contact support',
				reason: ['Error gathering data'],
				status: 'consider',
			},
		},
		giftorEligibilityStatements: [
			'They do not require the money to be repaid.',
			'They are giving the gift to assist with the purchase of a property.',
			'They do not expect to have any legal or beneficial interest in the property.',
			'They will not be living in the property.',
		],
		scenario: [
			{
				conditions: {
					isSofReportEmpty: true,
				},
				name: 'noData',
				order: 1,
				result: {
					reason: ['Waiting for client'],
					status: 'waiting',
				},
			},
			{
				conditions: {
					sofReportStatus: 'error',
				},
				name: 'error',
				order: 2,
				result: {
					action: 'Please contact support',
					reason: ['Error gathering data'],
					status: 'consider',
				},
			},
			{
				conditions: {
					sofReportStatus: 'rejected',
				},
				name: 'rejected',
				order: 3,
				result: {
					reason: ['Declined to complete activity'],
					status: 'consider',
				},
			},
			{
				conditions: {
					sofReportResult: 'clear',
				},
				name: 'pass',
				order: 4,
				result: {
					action: 'Please review',
					reason: ['Data gathered from client'],
					status: 'pass',
				},
			},
			{
				conditions: {
					notSofReportResult: 'clear',
				},
				name: 'noData.result-not-clear',
				order: 5,
				result: {
					reason: ['Waiting for client'],
					status: 'waiting',
				},
			},
		],
	},
};
