export const Status = {
	aborted: 'Aborted',
	closed: 'Closed',
	deleted: 'Deleted',
	open: 'Open',
};

export const ActivityResult = {
	clear: 'Clear',
	consider: 'Consider',
	fail: 'Fail',
};
