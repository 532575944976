import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6903 0.0656733C13.1939 -0.0669626 12.6742 0.0028151 12.23 0.261338L3.44771 5.37422H1.93911C0.86957 5.37422 0 6.24374 0 7.31333V18.0612C0 19.1307 0.869524 20.0003 1.93911 20.0003H17.1653C18.2348 20.0003 19.1044 19.1308 19.1044 18.0612V15.392C19.629 15.1621 20 14.642 20 14.0307V11.3438C20 10.7325 19.629 10.2124 19.1044 9.98254V7.31333C19.1044 6.32232 18.3562 5.51312 17.3983 5.39706L14.8641 0.970641C14.6063 0.52126 14.1891 0.199222 13.6903 0.0656733ZM12.8298 1.29066C12.9981 1.19218 13.194 1.16604 13.3822 1.21625C13.5254 1.25457 13.6506 1.33351 13.7461 1.44409L6.9958 5.37422H5.81531L12.8298 1.29066ZM10.5439 5.37422H9.36323L14.3499 2.47087L14.6452 2.98649L10.5439 5.37422ZM16.0122 5.37422H12.9114L15.237 4.02025L16.0122 5.37422ZM1.19125 7.31333C1.19125 6.90122 1.527 6.56547 1.93911 6.56547H17.1653C17.5774 6.56547 17.9131 6.90122 17.9131 7.31333V9.85251H15.3739C13.8106 9.85251 12.5392 11.1239 12.5392 12.6873C12.5392 14.2506 13.8106 15.522 15.3739 15.522H17.9131V18.0612C17.9131 18.4733 17.5774 18.809 17.1653 18.809H1.93911C1.527 18.809 1.19125 18.4733 1.19125 18.0612V7.31333ZM13.7304 12.6873C13.7304 11.781 14.4676 11.0437 15.3739 11.0437H18.5087C18.6742 11.0437 18.8088 11.1783 18.8088 11.3438V14.0307C18.8088 14.1962 18.6742 14.3308 18.5087 14.3308H15.3739C14.4676 14.3308 13.7304 13.5936 13.7304 12.6873Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="20" height="20.0003" fill="white" />
      </clipPath>
    </defs>
  </svg>
))
