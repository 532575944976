import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M47.2972 63.9165L36.6778 67.7785L36.2752 69.2139C35.7034 71.2569 33.5313 72.9278 31.4489 72.9278V73.8933H53.4728L47.2972 63.9165Z"
      fill="#31448F"
    />
    <path
      d="M51.4835 69.2141L49.9992 63.9167L43.8779 60.0547L37.7567 63.9167L36.675 67.7787H47.2945L47.6971 69.2141C48.2688 71.2571 50.4409 72.928 52.5234 72.928L54.4166 73.8935L56.3098 72.928C54.2273 72.928 52.0552 71.2571 51.4835 69.2141Z"
      fill="#262F69"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2981 65.6986C14.2156 65.6986 12.5117 63.9607 12.5117 61.8366V54.1126L71.4525 38.6646V50.2527L75.2368 54.1126V61.8366C75.2368 63.9607 73.5329 65.6986 71.4504 65.6986H67.6715C67.6697 65.6986 67.6679 65.6986 67.6662 65.6986L67.6641 65.6986L16.2981 65.6986Z"
      fill="#31448F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.4525 54.1124H71.4504H12.5117V19.3544C12.5117 17.2303 14.2156 15.4924 16.2981 15.4924H67.6641H67.6662H71.4504C73.5329 15.4924 75.2368 17.2303 75.2368 19.3544V54.1124H71.4525Z"
      fill="#23A4DE"
    />
    <path
      d="M43.8768 61.8366C44.9224 61.8366 45.77 60.9721 45.77 59.9056C45.77 58.8392 44.9224 57.9746 43.8768 57.9746C42.8312 57.9746 41.9836 58.8392 41.9836 59.9056C41.9836 60.9721 42.8312 61.8366 43.8768 61.8366Z"
      fill="white"
    />
    <path
      d="M63.443 74.8587H24.3174C23.7947 74.8587 23.3708 74.4266 23.3708 73.8932C23.3708 73.3599 23.7947 72.9277 24.3174 72.9277H63.443C63.9658 72.9277 64.3896 73.3599 64.3896 73.8932C64.3896 74.4266 63.9658 74.8587 63.443 74.8587Z"
      fill="#31448F"
    />
    <rect x="18.7184" y="36.1143" width="12.4076" height="12.7961" rx="3" fill="#7BC9EB" />
    <rect x="38.0156" y="36.1143" width="12.4076" height="12.7961" rx="3" fill="#7BC9EB" />
    <rect x="64.3984" y="31.0569" width="5" height="18" rx="2.5" fill="#7BC9EB" />
    <path
      d="M60.2485 31.7139C60.5068 32.166 60.3513 32.7472 59.9018 33.0099L50.3302 38.3174C50.0908 38.4501 49.7893 38.3657 49.6536 38.1281L49.2136 37.358C49.0752 37.1157 49.1618 36.807 49.4061 36.6722L57.7058 32.092C57.9501 31.9572 58.0368 31.6485 57.8984 31.4062L55.969 28.0289C55.7107 27.5768 55.8662 26.9956 56.3157 26.7329L63.6883 22.4243C64.0718 22.2001 63.9944 21.6244 63.5652 21.5096L61.0441 20.835C60.7773 20.7636 60.619 20.4895 60.6903 20.2228L60.9223 19.3556C60.9937 19.0889 61.2678 18.9305 61.5346 19.0019L67.4007 20.5714C67.4029 20.572 67.4042 20.5743 67.4036 20.5765C67.403 20.5788 67.4044 20.5812 67.4067 20.5817C67.8953 20.7002 68.1961 21.1952 68.0783 21.6897C68.0746 21.7034 68.071 21.7171 68.067 21.7318L66.4796 27.6648C66.4083 27.9315 66.1342 28.0899 65.8674 28.0185L65.0182 27.7913C64.7515 27.72 64.5931 27.4458 64.6644 27.1791L65.3456 24.6331C65.4611 24.2016 64.996 23.8468 64.6104 24.0721L58.4948 27.6461C58.2581 27.7845 58.1769 28.0877 58.313 28.3258L60.2485 31.7139Z"
      fill="#7BC9EB"
    />
    <rect x="58.3984" y="36.0569" width="5" height="13" rx="2.5" fill="#7BC9EB" />
    <rect x="52.3984" y="41.0569" width="5" height="8" rx="2.5" fill="#7BC9EB" />
    <path
      d="M43.6186 15.6022C40.0898 6.82214 30.3648 2.67072 21.8972 6.32979C16.1075 8.83164 12.1708 14.502 11.7197 20.989C6.82677 21.6907 3.40875 26.3724 4.0854 31.4461C4.69774 36.0369 8.48445 39.4542 12.9539 39.4496H43.6185C49.9692 39.4496 55.1177 34.1111 55.1177 27.5259C55.1177 20.9407 49.9695 15.6022 43.6186 15.6022Z"
      fill="white"
    />
    <path
      d="M53.0168 31.267C53.0168 31.267 51.5531 30.6511 51.1054 29.5459C50.6556 28.4392 51.2562 26.9294 51.2562 26.9294C51.3862 26.6008 51.3096 26.1122 51.0856 25.8439L49.5187 24.2419C49.2573 24.0115 48.7844 23.9372 48.4684 24.0742C48.4684 24.0742 47.0145 24.7094 45.9406 24.2541C44.8652 23.8005 44.2585 22.2938 44.2585 22.2938C44.1266 21.9662 43.7385 21.6777 43.3962 21.6537L41.1921 21.663C40.8504 21.6923 40.4644 21.9856 40.3351 22.3146C40.3351 22.3146 39.7415 23.8253 38.6714 24.2905C37.6012 24.7557 36.1414 24.1358 36.1414 24.1358C35.8236 24.0008 35.3519 24.0805 35.0923 24.3121L33.5425 25.9328C33.3197 26.2031 33.2473 26.6922 33.3804 27.019C33.3804 27.019 33.9944 28.5212 33.5555 29.6315C33.1154 30.742 31.6599 31.371 31.6599 31.371C31.3432 31.5081 31.0651 31.91 31.0411 32.264L31.0505 34.542C31.079 34.8961 31.3619 35.2947 31.68 35.4284C31.68 35.4284 33.1417 36.0434 33.5895 37.149C34.0391 38.2544 33.4385 39.7643 33.4385 39.7643C33.3085 40.0929 33.385 40.5808 33.6098 40.8497L35.1767 42.451C35.4375 42.6821 35.9105 42.757 36.2265 42.6194C36.2265 42.6194 37.6813 41.9867 38.7549 42.4393C39.8286 42.8932 40.4364 44.4004 40.4364 44.4004C40.569 44.7279 40.957 45.0156 41.3 45.0409L43.5014 45.0302C43.8438 45.0007 44.2292 44.7082 44.3599 44.379C44.3599 44.379 44.9548 42.8693 46.0237 42.4043C47.0932 41.9393 48.5539 42.5602 48.5539 42.5602C48.8717 42.6953 49.3434 42.6156 49.603 42.384L51.1523 40.7647C51.3752 40.4951 51.4477 40.0066 51.3151 39.6791C51.3151 39.6791 50.7018 38.1735 51.1395 37.0633C51.5794 35.9516 53.0369 35.3235 53.0369 35.3235C53.3531 35.1872 53.6318 34.7852 53.6558 34.4312L53.6465 32.1538C53.6178 31.7994 53.3345 31.4008 53.0168 31.267ZM46.4526 35.0803C45.5249 37.4233 42.9381 38.5481 40.672 37.5894C38.4061 36.632 37.3199 33.9548 38.2445 31.6124C39.1714 29.2683 41.7603 28.1457 44.0254 29.1018C46.2912 30.0613 47.3772 32.7378 46.4526 35.0803Z"
      fill="#FFB71B"
    />
  </svg>
))
