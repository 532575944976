import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.6692 22.6382H3.58075C3.26022 22.6382 3 22.8984 3 23.2189V27.5118C3 28.5386 3.83619 29.3749 4.86296 29.3749H27.387C28.4138 29.3749 29.25 28.5386 29.25 27.5118V23.2189C29.25 22.8984 28.9897 22.6382 28.6692 22.6382ZM6.36836 27.0519C5.79216 27.0519 5.323 26.5826 5.323 26.0065C5.323 25.4304 5.79216 24.9612 6.36836 24.9612C6.94456 24.9612 7.41371 25.4304 7.41371 26.0065C7.41371 26.5826 6.94456 27.0519 6.36836 27.0519ZM10.0852 27.0519C9.50897 27.0519 9.03981 26.5826 9.03981 26.0065C9.03981 25.4304 9.50897 24.9612 10.0852 24.9612C10.6614 24.9612 11.1305 25.4304 11.1305 26.0065C11.1305 26.5826 10.6614 27.0519 10.0852 27.0519ZM26.8108 26.5873H15.6604C15.3398 26.5873 15.0796 26.3271 15.0796 26.0065C15.0796 25.686 15.3398 25.4258 15.6604 25.4258H26.8108C27.1313 25.4258 27.3916 25.686 27.3916 26.0065C27.3916 26.3271 27.1313 26.5873 26.8108 26.5873Z"
      fill="#0061A8"
    />
    <path
      d="M28.6692 15.2046H3.58075C3.3439 15.2046 3.13929 15.3487 3.05106 15.5484C3.01863 15.6273 3 15.7064 3 15.79V21.3606C3 21.6811 3.26022 21.9413 3.58075 21.9413H28.6692C28.9897 21.9413 29.25 21.6811 29.25 21.3606V15.79C29.25 15.7064 29.2314 15.6273 29.1989 15.5484C29.1107 15.3487 28.9061 15.2046 28.6692 15.2046ZM6.36836 19.6183C5.79216 19.6183 5.323 19.1491 5.323 18.5729C5.323 17.9968 5.79216 17.5276 6.36836 17.5276C6.94456 17.5276 7.41371 17.9968 7.41371 18.5729C7.41371 19.1491 6.94456 19.6183 6.36836 19.6183ZM10.0852 19.6183C9.50897 19.6183 9.03981 19.1491 9.03981 18.5729C9.03981 17.9968 9.50897 17.5276 10.0852 17.5276C10.6614 17.5276 11.1305 17.9968 11.1305 18.5729C11.1305 19.1491 10.6614 19.6183 10.0852 19.6183ZM26.8108 19.1537H15.6604C15.3398 19.1537 15.0796 18.8935 15.0796 18.5729C15.0796 18.2524 15.3398 17.9922 15.6604 17.9922H26.8108C27.1313 17.9922 27.3916 18.2524 27.3916 18.5729C27.3916 18.8935 27.1313 19.1537 26.8108 19.1537Z"
      fill="#7BC9EB"
    />
    <path
      d="M3.58075 14.3915H28.6692C28.8643 14.3915 29.0501 14.2939 29.1569 14.1266C29.2175 14.0337 29.25 13.9223 29.25 13.8154C29.25 13.7318 29.2314 13.6527 29.1989 13.5738L25.8212 5.97289C25.5239 5.30387 24.8548 4.86719 24.1162 4.86719H8.13375C7.39513 4.86719 6.7261 5.30387 6.42871 5.97289L3.05106 13.5738C3.01863 13.6527 3 13.7318 3 13.8154C3 13.9223 3.03243 14.0338 3.09301 14.1266C3.19987 14.2939 3.38567 14.3915 3.58075 14.3915Z"
      fill="#7BC9EB"
    />
  </svg>
))
