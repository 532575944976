import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 9.99995C0 4.48591 4.48601 0 9.99995 0C15.514 0 19.9999 4.48591 19.9999 9.99995C19.9999 15.514 15.5139 20 9.99995 20C4.48601 20 0 15.514 0 9.99995ZM14.5467 14.5764C13.9855 15.1412 13.0756 15.1412 12.5144 14.5764L9.99068 12.0361L7.4438 14.5528C6.87745 15.1125 5.9676 15.104 5.41159 14.534C4.85558 13.964 4.86396 13.0482 5.4303 12.4886L7.9583 9.99053L5.45326 7.46918C4.89206 6.90432 4.89206 5.9885 5.45326 5.42364C6.01446 4.85879 6.92435 4.85879 7.48555 5.42364L10.0093 7.96384L12.5562 5.44713C13.1225 4.88749 14.0324 4.89593 14.5884 5.46596C15.1444 6.036 15.136 6.95177 14.5697 7.51141L12.0417 10.0095L14.5467 12.5308C15.1079 13.0957 15.1079 14.0115 14.5467 14.5764Z"
      fill="currentColor"
    />
  </svg>
))
