import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 0C4.4775 0 0 4.4775 0 10C0 15.5225 4.4775 20 10 20C15.5225 20 20 15.5225 20 10C20 4.4775 15.5225 0 10 0ZM10 16.3833C9.21833 16.3833 8.68333 15.7808 8.68333 15C8.68333 14.1967 9.24083 13.6167 10 13.6167C10.8025 13.6167 11.3167 14.1967 11.3167 15C11.3167 15.7817 10.8025 16.3833 10 16.3833ZM10.5183 11.1008C10.3192 11.78 9.69167 11.7917 9.4825 11.1008C9.24167 10.3042 8.38583 7.28 8.38583 5.31583C8.38583 2.72417 11.6283 2.71167 11.6283 5.31583C11.6275 7.29167 10.7258 10.3942 10.5183 11.1008Z"
      fill="currentColor"
    />
  </svg>
))
