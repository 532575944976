import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.57244 16.4417H12.4667C12.7662 17.3464 13.6076 18 14.5969 18C15.5861 18 16.4275 17.3464 16.727 16.4417H18.4276C18.8226 16.4417 19.1428 16.116 19.1428 15.7143C19.1428 15.3126 18.8226 14.9869 18.4276 14.9869H16.727C16.4275 14.0822 15.5861 13.4286 14.5968 13.4286C13.6075 13.4286 12.7661 14.0822 12.4666 14.9869H1.5724C1.17737 14.9869 0.857117 15.3126 0.857117 15.7143C0.857117 16.116 1.17737 16.4417 1.57244 16.4417ZM14.5969 14.8834C15.0473 14.8834 15.4138 15.2562 15.4138 15.7143C15.4138 16.1724 15.0473 16.5452 14.5969 16.5452C14.1464 16.5452 13.7799 16.1724 13.7799 15.7143C13.7799 15.2562 14.1464 14.8834 14.5969 14.8834ZM1.57244 10.7274H3.27299C3.5725 11.6321 4.41396 12.2857 5.40319 12.2857C6.39242 12.2857 7.23382 11.6321 7.53334 10.7274H18.4276C18.8226 10.7274 19.1428 10.4017 19.1428 9.99996C19.1428 9.59823 18.8226 9.27254 18.4276 9.27254L7.53334 9.27254C7.23382 8.36786 6.39237 7.71425 5.40314 7.71425C4.41391 7.71425 3.57246 8.36786 3.27295 9.27254H1.5724C1.17737 9.27254 0.857117 9.59823 0.857117 9.99996C0.857117 10.4017 1.17737 10.7274 1.57244 10.7274ZM5.40314 9.1691C5.85362 9.1691 6.22013 9.54183 6.22013 9.99996C6.22013 10.4581 5.85362 10.8308 5.40314 10.8308C4.95266 10.8308 4.58615 10.4581 4.58615 9.99996C4.58615 9.54183 4.95266 9.1691 5.40314 9.1691ZM9.40207 5.0132H1.57244C1.17737 5.0132 0.857117 4.6875 0.857117 4.28577C0.857117 3.88404 1.17737 3.55835 1.5724 3.55835H9.40207C9.70158 2.65367 10.543 2.00006 11.5323 2.00006C12.5215 2.00006 13.363 2.65367 13.6625 3.55835L18.4276 3.55835C18.8226 3.55835 19.1428 3.88404 19.1428 4.28577C19.1428 4.6875 18.8226 5.0132 18.4276 5.0132L13.6625 5.0132C13.363 5.91787 12.5215 6.57148 11.5323 6.57148C10.543 6.57148 9.70158 5.91787 9.40207 5.0132ZM12.3493 4.28572C12.3493 3.82759 11.9827 3.45486 11.5323 3.45486C11.0818 3.45486 10.7153 3.82764 10.7153 4.28577C10.7153 4.7439 11.0818 5.11659 11.5323 5.11659C11.9827 5.11659 12.3493 4.74385 12.3493 4.28572Z"
      fill="currentColor"
    />
  </svg>
))
