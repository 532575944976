export const DocumentCheck = {
	mimetypes: {
		'image/jpeg': ['.jpg', '.jpeg'],
		'image/png': ['.png'],
		'application/pdf': ['.pdf'],
	},
	resolution: {
		max: 64 * 1000 * 1000, // 64mpx
	},
	size: {
		max: 10 * 1000 * 1000, // 10mb
		min: 32 * 1000, // 32kb
	},
	types: {
		passport: {
			text: 'Passport',
		},
		driving_licence: {
			text: 'Driving licence',
			pages: ['front', 'back'],
		},
		national_identity_card: {
			text: 'National identity card',
			pages: ['front', 'back'],
		},
		residence_permit: {
			text: 'Resident permit',
			pages: ['front', 'back'],
		},
		unknown: {
			supportPageLink:
				'https://thirdfortapp.force.com/client/s/article/Lite-Document-Check-International',
			text: 'Other (Visa, Voter ID, Service ID card etc.)',
			pages: ['front', 'back'],
		},
	},
};
