import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M88.9667 19.4688H24.7004V120.531H88.9667C91.1486 120.531 92.9175 118.8 92.9175 116.664V23.3359C92.9175 21.2002 91.1486 19.4688 88.9667 19.4688Z"
      fill="#23A4DE"
    />
    <path
      d="M102.604 122.092C102.604 122.092 103.832 126.634 105.879 127.168C107.926 127.702 119.252 122.76 118.297 121.024C117.342 119.287 110.519 118.753 107.926 119.154C105.333 119.554 102.604 122.092 102.604 122.092Z"
      fill="#262F69"
    />
    <path
      d="M68.807 91.3423C68.807 91.3423 69.3701 98.8083 64.0211 101.574C58.672 104.339 54.8715 98.2553 53.886 98.2553C52.9008 98.2553 50.3496 103.742 50.0682 105.263C49.7864 106.784 58.5313 114.847 67.1177 112.773C75.7044 110.699 77.6752 100.882 78.5197 100.467C79.3642 100.053 84.196 100.452 85.8395 100.882C87.9509 101.435 91.5108 102.808 95.6928 109.178C99.8749 115.548 100.62 122.175 102.168 122.451C103.716 122.728 107.742 120.127 109.291 119.021C110.839 117.915 106.954 96.8728 93.8628 91.8953C80.7718 86.9178 68.807 91.3423 68.807 91.3423Z"
      fill="#262F69"
    />
    <path
      d="M91.8135 59.2691C91.8135 59.2691 96.2263 71.1733 99.5013 73.0432C102.776 74.9131 107.653 77.881 107.653 77.881L94.6897 79.083L88.8221 77.881L87.1846 65.9933L91.8135 59.2691Z"
      fill="#0061A8"
    />
    <path
      d="M72.72 55.2115C72.2077 57.7728 67.5248 81.5946 68.0804 91.2874C68.217 93.6679 94.4167 95.8653 96.7365 91.8367C99.0563 87.8078 93.8709 63.269 91.2783 57.592C88.6856 51.9154 83.0909 50.6335 83.0909 50.6335C83.0909 50.6335 73.2322 52.6505 72.72 55.2115Z"
      fill="#0061A8"
    />
    <rect
      width="13.8535"
      height="24.6557"
      transform="matrix(0.980867 0.194782 -0.207794 0.978194 100.305 54.7529)"
      fill="#23A4DE"
    />
    <path
      d="M112.558 56.2097L102.096 54.156C100.654 53.8729 99.2302 54.8073 98.9226 56.2384L94.4594 77.0051C94.1518 78.4362 95.075 79.8313 96.517 80.1143L106.979 82.168C108.421 82.4511 109.845 81.5168 110.152 80.0856L114.615 59.319C114.923 57.8878 114 56.4928 112.558 56.2097ZM108.844 79.8289C108.69 80.5458 107.98 81.0119 107.258 80.8701L96.7959 78.8164C96.0736 78.6746 95.613 77.9787 95.7671 77.2618L100.23 56.4951C100.384 55.7782 101.095 55.3121 101.817 55.4539L112.279 57.5076C113.001 57.6494 113.462 58.3454 113.308 59.0623L108.844 79.8289Z"
      fill="#23A4DE"
    />
    <path
      d="M109.221 57.8615L106.586 57.3426C106.222 57.2709 105.864 57.5059 105.787 57.8679C105.709 58.2299 105.941 58.581 106.305 58.6527L108.94 59.1716C109.304 59.2433 109.662 59.0083 109.739 58.6463C109.817 58.2842 109.585 57.9332 109.221 57.8615Z"
      fill="white"
    />
    <path
      d="M104.101 58.2C104.463 58.2708 104.82 58.037 104.897 57.6776C104.975 57.3183 104.744 56.9696 104.382 56.8988C104.02 56.8279 103.663 57.0618 103.586 57.4211C103.508 57.7805 103.739 58.1292 104.101 58.2Z"
      fill="white"
    />
    <path
      d="M73.9603 39.1594C73.9603 41.7949 71.9033 43.9316 69.3659 43.9316C66.8283 43.9316 64.7712 41.7949 64.7712 39.1594C64.7712 36.5235 66.8283 34.3868 69.3659 34.3868C71.9033 34.3868 73.9603 36.5235 73.9603 39.1594Z"
      fill="#FFE5C7"
    />
    <path
      d="M104.596 38.8925C104.596 41.5283 102.539 43.665 100.001 43.665C97.4635 43.665 95.4065 41.5283 95.4065 38.8925C95.4065 36.2569 97.4635 34.1199 100.001 34.1199C102.539 34.1199 104.596 36.2569 104.596 38.8925Z"
      fill="#FFE5C7"
    />
    <path
      d="M68.515 36.8652C68.515 36.8652 69.1461 57.5156 84.4772 57.5156C99.8083 57.5156 101.702 41.4542 101.341 35.5802C100.981 29.7061 98.2752 20.6199 98.2752 20.6199L85.379 19.3349L74.0162 21.5378L68.6952 24.6584L68.3345 28.4211L68.515 36.8652Z"
      fill="#FFE5C7"
    />
    <path
      d="M62.0842 83.3622C62.0842 83.3622 62.5727 88.6854 63.7151 89.4161C64.8574 90.1468 65.7753 89.5532 67.0273 89.8757C68.2794 90.1978 69.5618 88.9119 70.6688 89.1094C71.7757 89.3071 73.0935 88.5545 73.1416 87.2128C73.1896 85.8712 71.5594 83.9666 71.5594 83.9666C71.5594 83.9666 73.6854 83.0289 73.1975 81.8553C72.7097 80.6814 68.6398 82.5956 67.0273 80.9578C65.4152 79.32 62.0842 83.3622 62.0842 83.3622Z"
      fill="#FFE5C7"
    />
    <path
      d="M98.9092 83.9762C96.3001 82.6993 96.0436 79.1677 96.8622 78.0994C97.6811 77.0307 98.7726 76.897 98.7726 76.897C98.7726 76.897 100.001 75.9621 100.956 76.4965C100.956 76.4965 102.866 75.1608 104.094 76.3628C104.094 76.3628 106.551 75.695 107.369 77.4315C108.188 79.1677 107.915 83.0413 106.005 83.9762C104.094 84.9115 101.092 85.0449 98.9092 83.9762Z"
      fill="#FFE5C7"
    />
    <path
      d="M71.525 14.7951C75.6375 9.58061 83.8088 8.70135 91.0764 10.3763C95.1903 11.3244 115.672 19.0688 104.314 24.0872C104.425 26.4176 104.437 28.7635 104.324 31.0939C104.252 32.5754 104.201 34.1693 103.752 35.5994C103.188 37.3917 101.386 36.769 100.83 35.306C99.8203 32.6494 101.042 29.8133 99.3331 27.1675C98.3147 25.591 96.9504 25.3558 95.3685 24.5229C95.2762 24.4743 94.3128 24.1212 94.3008 24.0463C94.3008 24.0463 95.9064 26.6417 93.8699 26.9867C91.8327 27.3321 84.5809 24.7458 84.5809 24.7458C84.5809 24.7458 85.6802 26.5637 82.8991 27.4457C80.1184 28.3277 72.9958 24.0234 72.9958 24.0234C72.9958 24.0234 69.823 27.4361 69.9893 36.2218C70.0415 38.9936 64.4512 38.4873 65.1863 32.4235C65.9211 26.3597 65.6343 15.0669 71.525 14.7951Z"
      fill="#262F69"
    />
    <path
      d="M73.0046 54.5697C73.0046 54.5697 57.2068 63.1505 56.466 71.256C55.7247 79.3613 60.6581 83.8836 61.5634 84.0155C62.4688 84.1477 66.7005 80.6567 66.8328 80.1931C66.9651 79.7291 64.662 71.7272 66.8328 69.6284C69.0035 67.5293 73.4219 64.8526 73.4219 64.8526C75.7877 64.1485 76.6167 59.1859 75.6215 56.2394C75.2065 55.0107 74.3198 54.5697 73.0046 54.5697Z"
      fill="#0061A8"
    />
    <rect
      width="11.8481"
      height="11.6165"
      rx="4"
      transform="matrix(0.981332 0.192319 -0.200394 0.979715 107.377 61.6387)"
      fill="#FFB71B"
    />
    <rect
      width="7.91029"
      height="7.79836"
      rx="2"
      transform="matrix(0.916 -0.401178 0.415734 0.909486 116.707 52.7373)"
      fill="#FFB71B"
    />
    <rect
      width="5.17353"
      height="5.16089"
      rx="2"
      transform="matrix(0.753467 0.657486 -0.673343 0.73933 129.102 59.6318)"
      fill="#FFB71B"
    />
    <path
      d="M59.6705 4.73014C58.6404 4.0031 57.3161 3.80536 56.1111 4.19853L8.70155 19.6673C7.08831 20.1937 6 21.6715 6 23.3359V116.664C6 118.329 7.08805 119.806 8.70155 120.333L56.1111 135.801C57.3263 136.198 58.6501 135.99 59.6705 135.27C60.7006 134.543 61.3111 133.376 61.3111 132.133V7.8672C61.3111 6.62428 60.7006 5.45691 59.6705 4.73014ZM41.5571 73.8672C39.3752 73.8672 37.6063 72.1357 37.6063 70C37.6063 67.8643 39.3752 66.1328 41.5571 66.1328C43.739 66.1328 45.5079 67.8643 45.5079 70C45.5079 72.1357 43.739 73.8672 41.5571 73.8672Z"
      fill="#262F69"
    />
  </svg>
))
