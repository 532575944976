import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <mask id="mask0_7486_89243" maskUnits="userSpaceOnUse" x="5" y="7" width="72" height="72">
        <circle cx="41.0144" cy="42.6966" r="35.6309" transform="rotate(-2.75697 41.0144 42.6966)" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_7486_89243)">
        <rect
          x="-2.50684"
          y="3.1748"
          width="88.3349"
          height="80.9118"
          transform="rotate(-2.75697 -2.50684 3.1748)"
          fill="white"
        />
        <path
          d="M70.3574 81.0422C70.3574 81.0422 61.8307 81.4528 55.2964 76.9368C45.476 74.0857 48.9105 53.9161 49.6294 53.1428C50.3481 52.3692 52.0831 52.8454 55.9758 54.4604C61.3294 56.6814 62.6238 61.2989 64.5359 64.6455C67.2517 69.3986 69.9646 72.8862 69.9646 72.8862C71.4065 72.8168 69.0303 77.493 70.3574 81.0422Z"
          fill="#23A4DE"
        />
        <path
          d="M33.2762 57.9805C32.9622 60.1558 25.8632 68.9556 25.404 80.8675C34.4689 89.199 47.9697 90.0308 57.9981 83.7437C56.6795 73.9172 52.516 58.8145 50.3053 54.4072C47.2325 48.2814 41.1839 47.1559 41.1839 47.1559C41.1839 47.1559 33.6878 55.1303 33.2762 57.9805Z"
          fill="#23A4DE"
        />
        <ellipse
          cx="42.9394"
          cy="53.038"
          rx="6.69342"
          ry="5.29479"
          transform="rotate(-2.75697 42.9394 53.038)"
          fill="#0076AB"
        />
        <path
          d="M23.7949 94.0608C23.7949 94.0608 22.9216 79.8757 29.8486 74.1749C36.7756 68.4745 36.7222 54.2479 36.1497 53.451C35.5773 52.6537 28.8097 53.9496 25.717 56.8097C19.9565 62.137 15.8876 70.3815 13.7234 77.8244C10.4348 76.0227 24.7845 90.6383 23.7949 94.0608Z"
          fill="#23A4DE"
        />
        <path
          d="M25.3927 78.941C26.3442 75.5509 30.0652 67.94 30.0652 67.94C30.0652 67.94 27.5278 76.9802 29.6135 81.7104C28.1128 81.4111 26.5585 79.9996 25.3927 78.941Z"
          fill="#0076AB"
        />
        <path
          d="M60.7921 77.5352C59.5073 74.2212 55.0303 66.9349 55.0303 66.9349C55.0303 66.9349 58.4567 75.7722 56.8198 80.7273C58.2987 80.2809 59.7235 78.712 60.7921 77.5352Z"
          fill="#0076AB"
        />
      </g>
      <path
        d="M30.7234 35.1932C30.8675 38.1844 28.721 40.7183 25.9293 40.8528C23.1373 40.9872 20.7572 38.6712 20.6132 35.68C20.4691 32.6885 22.6156 30.1546 25.4077 30.0201C28.1993 29.8857 30.5794 32.2017 30.7234 35.1932Z"
        fill="#FFE5C7"
      />
      <path
        d="M64.4138 33.2671C64.5579 36.2586 62.4114 38.7925 59.6197 38.927C56.8277 39.0614 54.4477 36.7454 54.3036 33.7539C54.1596 30.7627 56.306 28.2284 59.0981 28.094C61.8898 27.9596 64.2698 30.2759 64.4138 33.2671Z"
        fill="#FFE5C7"
      />
      <path
        d="M24.6052 32.8781C24.6052 32.8781 26.4281 56.2812 43.2961 55.4689C60.1641 54.6567 61.3697 36.3279 60.6518 29.6805C59.9342 23.033 56.4608 12.8643 56.4608 12.8643L42.2016 12.0892L29.8202 15.1914L24.1364 19.0149L23.9451 23.3044L24.6052 32.8781Z"
        fill="#FFE5C7"
      />
      <path
        d="M32.718 25.6259C32.6906 26.1091 33.2972 26.0049 33.7987 25.922C34.8421 25.7497 34.5307 24.9804 36.729 24.9601C37.5266 24.9527 38.8289 26.2169 39.1968 25.5339C40.2424 23.5932 32.9065 22.3065 32.718 25.6259Z"
        fill="#262F69"
      />
      <path
        d="M55.3818 25.0609C54.8814 24.1237 53.2235 23.7212 52.0755 23.8482C51.1143 23.9547 49.5049 24.4366 49.574 25.4207C49.5953 25.722 50.0465 25.6677 50.3974 25.5874C50.9425 25.4626 51.1623 25.2402 51.6894 25.1921C52.2019 25.1451 52.7297 25.2329 53.1803 25.4368C53.7261 25.6842 54.1141 26.0653 54.8027 25.8719C55.4209 25.6981 55.6325 25.5305 55.3818 25.0609Z"
        fill="#262F69"
      />
      <path
        d="M53.7607 28.1333C53.6777 28.086 53.5896 28.0447 53.4965 28.0106C50.4208 26.8814 49.3772 31.7553 51.6222 32.5464C54.8634 33.6884 55.6859 29.2332 53.7607 28.1333Z"
        fill="#262F69"
      />
      <path
        d="M53.3789 28.6731C53.0378 28.3496 52.3889 28.6373 52.4815 29.0775C52.5287 29.302 52.5736 29.4815 52.781 29.6344C53.0972 29.8681 53.7307 29.5944 53.705 29.2219C53.6881 28.9794 53.5566 28.8417 53.3789 28.6731Z"
        fill="white"
      />
      <path
        d="M36.059 43.5956C36.059 43.5956 43.6312 48.1135 49.4032 44.7856C46.2724 49.3031 37.818 48.611 36.059 43.5956Z"
        fill="#262F69"
      />
      <path
        d="M26.7115 7.6701C30.9513 1.53414 39.8937 0.103319 47.9814 1.61918C52.5595 2.47723 67.5939 7.66878 63.2948 16.4786C63.5453 19.1175 63.6867 21.7793 63.6892 24.4301C63.6905 26.1153 63.7223 27.9269 63.3058 29.5738C62.7838 31.6377 60.7673 31.0265 60.0754 29.3956C58.8191 26.4341 60.0083 23.1506 57.9834 20.2383C53.3824 13.6217 49.9396 17.1144 42.131 18.1329C36.0199 18.93 29.9088 15.7577 29.9088 15.7577C29.9088 15.7577 25.5298 22.1067 26.1929 32.069C26.4018 35.2119 20.9435 34.2216 21.421 27.3008C21.898 20.38 20.2452 8.2906 26.7115 7.6701Z"
        fill="#262F69"
      />
      <path
        d="M36.7842 28.1338C36.7011 28.0865 36.613 28.0452 36.5199 28.011C33.4443 26.8819 32.4006 31.7558 34.6456 32.5469C37.8868 33.6889 38.7093 29.2337 36.7842 28.1338Z"
        fill="#262F69"
      />
      <path
        d="M36.4031 28.6735C36.062 28.3501 35.4131 28.6378 35.5057 29.078C35.5533 29.3023 35.5978 29.482 35.8052 29.6349C36.1214 29.8686 36.755 29.5952 36.7292 29.2224C36.7123 28.9799 36.5808 28.8421 36.4031 28.6735Z"
        fill="white"
      />
      <path
        d="M53.8877 78H70.1123C70.1127 78 70.1132 78 70.1136 78C71.2577 77.9964 72.2456 77.0247 72.25 75.7481C72.25 75.7477 72.25 75.7473 72.25 75.7469L73.25 75.7498L53.8877 78ZM53.8877 78C53.8873 78 53.8868 78 53.8864 78C52.7422 77.9964 51.7535 77.0235 51.75 75.7475V40.2525C51.7535 38.9764 52.7423 38.0035 53.8866 38H70.1134C71.2578 38.0035 72.2466 38.9765 72.25 40.2528M53.8877 78L72.25 40.2528M72.25 40.2528C72.25 40.2531 72.25 40.2533 72.25 40.2536L72.25 40.2528Z"
        fill="#23A4DE"
        stroke="#262F69"
        strokeWidth="2"
      />
      <path
        d="M70.1148 79H53.8852C52.1561 78.9956 50.7542 77.516 50.75 75.691V71.5C61.088 71.5 64.0504 71.5 73.25 71.5V75.6922C73.2447 77.5171 71.8439 78.9956 70.1148 79Z"
        fill="#31448F"
      />
      <rect x="54.5" y="40.75" width="11.25" height="12" rx="1" fill="#7BC9EB" />
      <rect x="54.5" y="64" width="15" height="5.25" rx="1" fill="#7BC9EB" />
      <rect x="54.5" y="54.25" width="10.5" height="2.25" rx="1" fill="#7BC9EB" />
      <rect x="54.5" y="57.25" width="15" height="3" rx="1" fill="#7BC9EB" />
      <path
        d="M79.6969 66.5141L75.5144 70.6962C75.112 71.1 74.4541 71.1026 74.0491 70.6962L70.8974 67.544L69.2566 70.3169C68.7911 71.1038 67.6011 70.9425 67.3644 70.0578L63.5364 55.8054C63.33 55.0365 64.0352 54.3296 64.8061 54.5365L79.0578 58.3641C79.9422 58.6008 80.1045 59.7895 79.3169 60.2569L76.5445 61.8961L79.6969 65.0489C80.101 65.4538 80.101 66.1093 79.6969 66.5141Z"
        fill="white"
      />
      <path
        d="M67.7933 48.6015C67.3156 48.5234 66.8523 48.4137 66.4161 48.2754C63.2187 47.2619 61.3764 44.6961 60.7268 41.363C60.6652 41.0473 60.2083 41.0552 60.1577 41.373C59.5383 45.27 57.2994 48.0573 53.3679 48.8583C53.0552 48.9219 53.0632 49.374 53.378 49.4266C57.2985 50.0812 59.6733 52.7828 60.4253 56.6249C60.4726 56.867 60.7291 56.8637 60.7143 56.8584C60.8545 56.8557 60.9727 56.7528 60.9945 56.6144C61.3084 54.6208 62.2401 51.9035 64.8961 50.2802C65.7446 49.7621 66.723 49.3886 67.8039 49.1701C67.9407 49.1425 68.0382 49.021 68.0356 48.8815C68.033 48.7418 67.931 48.6241 67.7933 48.6015Z"
        fill="#FFB71B"
      />
    </g>
    <defs>
      <rect width="1em" height="1em" fill="white" />
    </defs>
  </svg>
))
