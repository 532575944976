import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.99997 0C2.69161 0 0 2.69154 0 5.99997C0 9.30839 2.69161 12 5.99997 12C9.30833 12 11.9999 9.30839 11.9999 5.99997C11.9999 2.69154 9.30839 0 5.99997 0ZM9.44526 4.98652L5.67578 8.756C5.5155 8.91628 5.30245 9.00449 5.0758 9.00449C4.84916 9.00449 4.6361 8.91628 4.47582 8.756L2.55468 6.83485C2.3944 6.67458 2.30612 6.46152 2.30612 6.23488C2.30612 6.00817 2.3944 5.79511 2.55468 5.63484C2.7149 5.47456 2.92795 5.38628 3.15466 5.38628C3.3813 5.38628 3.59442 5.47456 3.75464 5.6349L5.07574 6.95594L8.24517 3.7865C8.40545 3.62623 8.61851 3.53801 8.84515 3.53801C9.07179 3.53801 9.28485 3.62623 9.44513 3.7865C9.77608 4.11745 9.77608 4.6557 9.44526 4.98652Z"
      fill="currentColor"
    />
  </svg>
))
