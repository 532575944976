import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M33.8498 10.3508C33.6927 10.0881 33.4337 9.90389 33.1348 9.84378L17.2744 6.02015C16.7517 5.91883 16.236 6.20758 16.0551 6.70702L10.0096 22.0653L12.0279 22.8008L17.7571 8.19753L31.5378 11.7141C30.6772 14.3822 28.6292 19.9511 26.666 25.1363C24.9545 29.6604 23.9695 31.2096 22.0976 31.526C22.0954 31.5237 22.0933 31.5216 22.0933 31.5183C18.2675 32.0546 18.3361 27.1772 18.3361 27.1772L6.1175 22.2416C5.24921 27.7452 9.47808 29.0689 9.47808 29.0689L18.4238 33.1304C18.426 33.1316 19.8225 33.7967 21.3403 33.7284C25.4343 33.632 26.873 30.6592 28.6757 25.8967C31.28 19.0136 33.9234 11.3013 33.9494 11.2195C34.0426 10.9277 34.0058 10.6129 33.8498 10.3508Z"
      fill="white"
    />
    <path
      d="M26.6203 16.5446C26.7134 16.5701 26.8099 16.5836 26.9052 16.5836C27.3763 16.5836 27.8075 16.2694 27.9396 15.7927C28.0966 15.2202 27.7575 14.6287 27.1858 14.4727L22.5308 13.0359C21.9654 12.8794 21.3673 13.2158 21.2112 13.7878C21.0552 14.3603 21.3911 14.9519 21.9651 15.1084L26.6203 16.5446Z"
      fill="#23A4DE"
    />
    <path
      d="M19.347 18.0902C19.1604 18.6542 19.4633 19.2614 20.0256 19.4496L24.6562 21.1044C24.7686 21.1418 24.8791 21.1586 24.9906 21.1592C25.4422 21.1616 25.8653 20.8767 26.0161 20.4256C26.2032 19.8632 25.9009 19.255 25.3363 19.0669L20.7075 17.4133C20.1418 17.2261 19.5358 17.5289 19.347 18.0902Z"
      fill="#23A4DE"
    />
    <path
      d="M18.9396 21.8968C18.3762 21.7083 17.768 22.0159 17.5838 22.5815C17.3985 23.1427 17.7073 23.7516 18.2706 23.9357L22.9109 25.5836C23.0227 25.6215 23.134 25.6377 23.2469 25.6377C23.6975 25.6377 24.1179 25.3507 24.2664 24.8999C24.4515 24.3355 24.1439 23.7278 23.5806 23.5436L18.9396 21.8968Z"
      fill="#23A4DE"
    />
    <path
      d="M18.7678 11.783C19.4254 11.783 19.959 12.3019 19.959 12.9411C19.959 13.5808 19.4254 14.0991 18.7678 14.0991C18.1097 14.0991 17.5761 13.5807 17.5761 12.9411C17.5762 12.3019 18.1097 11.783 18.7678 11.783Z"
      fill="#23A4DE"
    />
    <path
      d="M17.0827 16.0881C17.7408 16.0881 18.2739 16.6065 18.2739 17.2457C18.2739 17.8843 17.7408 18.4027 17.0827 18.4027C16.4246 18.4027 15.891 17.8843 15.891 17.2457C15.891 16.6066 16.4246 16.0881 17.0827 16.0881Z"
      fill="#23A4DE"
    />
    <path
      d="M15.2854 20.53C15.9435 20.53 16.4771 21.049 16.4771 21.6893C16.4771 22.3284 15.9435 22.8462 15.2854 22.8462C14.6273 22.8462 14.0937 22.3285 14.0937 21.6893C14.0937 21.049 14.6273 20.53 15.2854 20.53Z"
      fill="#23A4DE"
    />
  </svg>
))
