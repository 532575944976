import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.6627 7.48678H23.4494C23.4494 5.56109 21.905 4 20 4C18.095 4 16.5506 5.56109 16.5506 7.48678H14.3373V11.9565H25.6627V7.48678H25.6627Z"
      fill="#23A4DE"
    />
    <path
      d="M27.434 7.48682V13.747H12.566V7.48682H9V31.557C9 33.2139 10.3431 34.557 12 34.557H28C29.6569 34.557 31 33.2139 31 31.557V7.48682H27.434ZM20 31.9021C15.9497 31.9021 12.6546 28.5712 12.6546 24.477C12.6546 20.3829 15.9498 17.052 20 17.052C24.0502 17.052 27.3454 20.3829 27.3454 24.477C27.3454 28.5712 24.0503 31.9021 20 31.9021Z"
      fill="white"
    />
    <path
      d="M25.1131 23.2122L24.2133 23.0966C24.1391 22.8658 24.0477 22.6433 23.941 22.4318L24.4967 21.7085C24.7216 21.4158 24.6953 21.0026 24.4383 20.751L23.6844 19.989C23.4332 19.7268 23.0244 19.7007 22.7344 19.9276L22.0198 20.4893C21.8106 20.3814 21.5904 20.289 21.3617 20.214L21.2473 19.3059C21.2042 18.9416 20.8983 18.6672 20.536 18.6672H19.4639C19.1016 18.6672 18.7957 18.9416 18.7526 19.3044L18.6383 20.214C18.4095 20.289 18.1893 20.3809 17.9801 20.4893L17.2651 19.9276C16.976 19.7007 16.5672 19.7268 16.3179 19.9866L15.564 20.7481C15.3046 21.0026 15.2783 21.4158 15.5032 21.709L16.0589 22.4318C15.9517 22.6433 15.8608 22.8658 15.7866 23.0966L14.8882 23.2122C14.5278 23.2558 14.2564 23.5649 14.2564 23.9312V25.0149C14.2564 25.3812 14.5278 25.6903 14.8868 25.7339L15.7866 25.8495C15.8608 26.0803 15.9522 26.3029 16.0589 26.5143L15.5032 27.2376C15.2783 27.5303 15.3046 27.9435 15.5616 28.1951L16.3155 28.9571C16.5672 29.2188 16.9755 29.245 17.2655 29.0181L17.9806 28.4563C18.1898 28.5647 18.4099 28.6571 18.6383 28.7316L18.7526 29.6393C18.7957 30.0046 19.1016 30.2789 19.4639 30.2789H20.536C20.8983 30.2789 21.2042 30.0046 21.2473 29.6417L21.3617 28.7321C21.59 28.6571 21.8101 28.5647 22.0193 28.4568L22.7348 29.0185C23.0244 29.2459 23.4332 29.2193 23.682 28.9595L24.4359 28.1975C24.6953 27.943 24.7216 27.5303 24.4967 27.2371L23.941 26.5143C24.0482 26.3029 24.1396 26.0803 24.2133 25.8495L25.1112 25.7339C25.4716 25.6903 25.743 25.3812 25.743 25.0149V23.9312C25.7435 23.5649 25.4721 23.2558 25.1131 23.2122ZM20 26.8922C18.6804 26.8922 17.6068 25.8069 17.6068 24.4731C17.6068 23.1392 18.6804 22.054 20 22.054C21.3195 22.054 22.3931 23.1392 22.3931 24.4731C22.3931 25.8069 21.3195 26.8922 20 26.8922Z"
      fill="#23A4DE"
    />
  </svg>
))
