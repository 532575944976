import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M65 35.4285L38 26L11 35.4285L38 45L65 35.4285Z" fill="#23A4DE" />
    <path d="M65 35.4285L38 26V45L65 35.4285Z" fill="#0061A8" />
    <path
      d="M50.564 12.2517C49.9575 5.9453 44.676 1 38.2717 1C31.8676 1 26.5864 5.94539 25.9794 12.2517C21.8788 12.8404 18.7161 16.4077 18.7161 20.7066C18.7161 25.4157 22.5117 29.2468 27.177 29.2468C53.1412 29.2468 25.0325 29.2469 49.3663 29.2469C54.0317 29.2469 57.8272 25.4157 57.8272 20.7067C57.8272 16.4077 54.6644 12.8404 50.564 12.2517Z"
      fill="#FFB71B"
    />
    <path
      d="M30.6945 19.7756C30.751 19.9138 30.883 20.004 31.0294 20.004H35.0016L35.0016 29.247C37.0041 29.247 38.7786 29.247 41.5266 29.247L41.5266 20.004H45.514C45.6604 20.004 45.7924 19.9138 45.8489 19.7762C45.9048 19.638 45.8743 19.479 45.7706 19.3732L38.5389 11.973C38.4707 11.9035 38.3787 11.8643 38.2822 11.8643C38.1858 11.8643 38.0937 11.9035 38.0256 11.9722L30.7728 19.3725C30.6691 19.4783 30.638 19.6373 30.6945 19.7756Z"
      fill="white"
    />
    <path
      d="M41.5308 29.2468H35.0123C35.0274 31.0466 36.4834 32.5061 38.2715 32.5061C40.0598 32.5061 41.5158 31.0466 41.5308 29.2468Z"
      fill="white"
    />
    <path
      d="M38.2718 34.4135C35.4971 34.4135 33.233 32.1083 33.1608 29.2468H25.2346C25.2823 29.6934 25.3476 30.1374 25.4337 30.5757C25.5389 31.1109 25.9924 31.4954 26.5188 31.4954H28.5214C28.5794 31.6502 28.6407 31.8036 28.7055 31.9555L27.289 33.4253C26.9167 33.8115 26.858 34.4164 27.1486 34.872C27.6825 35.709 28.3013 36.4947 28.9882 37.2074C29.6758 37.9209 30.4331 38.5631 31.2392 39.1163C31.678 39.4178 32.2608 39.3566 32.6331 38.9704L34.0494 37.5008C34.1958 37.568 34.3437 37.6317 34.493 37.6918V39.7697C34.493 40.3159 34.8636 40.7866 35.3793 40.8957C36.3263 41.0959 37.2994 41.1974 38.2718 41.1974C39.2441 41.1974 40.2173 41.0959 41.1643 40.8957C41.6802 40.7866 42.0507 40.3159 42.0507 39.7697L42.0506 37.6918C42.1999 37.6317 42.3478 37.568 42.4942 37.5008L43.9102 38.9702C44.2824 39.3563 44.8651 39.4174 45.3041 39.1162C46.1099 38.5633 46.8673 37.9209 47.5553 37.2071C48.2429 36.4936 48.8617 35.7079 49.3948 34.8716C49.6853 34.4161 49.6265 33.8114 49.2543 33.4251L47.8381 31.9557C47.9029 31.8038 47.9643 31.6503 48.0223 31.4954H50.0244C50.5508 31.4954 51.0044 31.1109 51.1095 30.5756C51.1956 30.1373 51.261 29.6934 51.3087 29.2468H43.3829C43.3108 32.1083 41.0466 34.4135 38.2718 34.4135Z"
      fill="white"
    />
    <path d="M38 44.566L11 35V69.401L38 78V44.566Z" fill="#31448F" />
    <path d="M65 69.401V35L38 44.566V78L65 69.401Z" fill="#262F69" />
    <path d="M11.2576 35L2 46.0304L29.2457 56L38 44.4747L11.2576 35Z" fill="#7BC9EB" />
    <path d="M64.7401 35L74 46.0304L46.7566 56L38 44.4747L64.7401 35Z" fill="#23A4DE" />
  </svg>
))
