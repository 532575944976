import { TF_API_URL, URLS } from '_constants';

export const fetchUserStatus = async (fetcher) => {
	return await fetcher.fetch(`${TF_API_URL}${URLS.USERS}/tenant-status`);
};

export const registerUser = async (fetcher, body) => {
	return await fetcher.fetch(`${TF_API_URL}${URLS.USERS}`, {
		method: 'POST',
		body,
	});
};
