import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M33 30.6117V32.4117C33 33.2873 32.2874 33.9999 31.4118 33.9999H7.58824C6.71259 33.9999 6 33.2873 6 32.4117V30.6117C6 29.736 6.71259 29.0234 7.58824 29.0234H31.4118C32.2874 29.0234 33 29.736 33 30.6117Z"
      fill="white"
    />
    <path
      d="M8.5412 17.3765V27.4353H30.4588C30.4588 26.9813 30.4588 17.8724 30.4588 17.3765H25.4824V25.6353H21.9883V17.3765H17.0118V25.6353H13.5177V17.3765H8.5412Z"
      fill="#23A4DE"
    />
    <path
      d="M6.79442 15.7882H32.2062C33.0182 15.7882 33.3058 14.7045 32.5977 14.3032L19.8918 7.10324C19.649 6.96559 19.3516 6.96559 19.1088 7.10324L6.40292 14.3032C5.69647 14.7035 5.98045 15.7882 6.79442 15.7882Z"
      fill="white"
    />
  </svg>
))
