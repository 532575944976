import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.78053 12.4279C2.8631 12.4279 2.11938 13.1716 2.11938 14.089C2.11938 15.0064 2.8631 15.7501 3.78053 15.7501C4.69752 15.7491 5.44066 15.006 5.44168 14.089C5.44168 13.1716 4.69796 12.4279 3.78053 12.4279Z"
      fill="currentColor"
    />
    <path
      d="M19.3425 10.295L17.491 9.44992C17.4279 9.42191 17.3745 9.3758 17.3375 9.31748L15.7331 6.75997C15.4343 6.28294 14.9093 5.99524 14.3464 6.00006H7.82651C7.31748 5.99851 6.83677 6.23404 6.52607 6.63723L4.46276 9.34031C4.4166 9.40208 4.35125 9.44677 4.27692 9.46731L0.828519 10.4109C0.336043 10.5481 -0.00342524 10.9981 2.60761e-05 11.5093V12.7704C2.60761e-05 13.3982 0.490688 13.9203 1.11856 13.9203H1.41896C1.5075 12.7537 2.43466 11.8265 3.6013 11.738C4.90554 11.639 6.0431 12.616 6.14209 13.9203H13.6091C13.6977 12.7537 14.6248 11.8265 15.7915 11.738C17.0957 11.639 18.2333 12.616 18.3323 13.9203H18.8814C19.5093 13.9203 20 13.3982 20 12.7704V11.3308C20.0035 10.8863 19.7462 10.481 19.3425 10.295Z"
      fill="currentColor"
    />
    <path
      d="M15.9707 12.4279C15.0533 12.4279 14.3096 13.1716 14.3096 14.089C14.3096 15.0064 15.0533 15.7501 15.9707 15.7501C16.8877 15.7491 17.6308 15.006 17.6319 14.089C17.6319 13.1716 16.8881 12.4279 15.9707 12.4279Z"
      fill="currentColor"
    />
  </svg>
))
