import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M31.5375 16.2002L27.7039 12.3665V7.93803C27.7039 7.1373 27.055 6.48836 26.2531 6.48836C25.453 6.48836 24.8041 7.1373 24.8041 7.93803V9.46685L21.9497 6.61229C20.5384 5.20178 18.0847 5.20428 16.6766 6.61484L7.09105 16.2002C6.52519 16.7672 6.52519 17.6846 7.09105 18.2507C7.65716 18.8175 8.57636 18.8175 9.14227 18.2507L18.7269 8.66509C19.0391 8.35449 19.5895 8.35449 19.9001 8.66417L29.4864 18.2507C29.7706 18.5341 30.1412 18.6751 30.5117 18.6751C30.883 18.6751 31.2542 18.534 31.5376 18.2507C32.1036 17.6846 32.1036 16.7672 31.5375 16.2002Z"
      fill="white"
    />
    <path
      d="M19.8179 11.417C19.5394 11.1387 19.0885 11.1387 18.8108 11.417L10.3794 19.846C10.2462 19.979 10.1708 20.1607 10.1708 20.3503V26.4981C10.1708 27.9407 11.3405 29.1104 12.7831 29.1104H16.9575V22.6456H21.6704V29.1104H25.8448C27.2874 29.1104 28.4571 27.9407 28.4571 26.4981V20.3503C28.4571 20.1607 28.3823 19.979 28.2485 19.846L19.8179 11.417Z"
      fill="white"
    />
    <path
      d="M32.9524 31.5684L28.8021 27.4162L31.83 25.6663C32.0073 25.5637 32.1106 25.3689 32.0958 25.1645C32.081 24.9601 31.9508 24.7821 31.7606 24.7063L18.9691 19.6031C18.7704 19.5238 18.5439 19.5704 18.3926 19.7216C18.2414 19.8727 18.1948 20.0994 18.274 20.2979L23.3748 33.0952C23.4507 33.2856 23.6286 33.4159 23.8331 33.4306C24.0375 33.4457 24.2325 33.3422 24.3349 33.1647L26.0836 30.1362L30.2337 34.2879C30.334 34.3883 30.4701 34.4446 30.612 34.4446C30.7539 34.4446 30.89 34.3882 30.9904 34.2879L32.9524 32.3246C33.1612 32.1158 33.1612 31.7772 32.9524 31.5684Z"
      fill="#23A4DE"
    />
  </svg>
))
