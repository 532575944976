import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2" y="18" width="16" height="2" rx="1" fill="#7BC9EB" />
    <rect x="2" y="15" width="16" height="2" rx="1" fill="#7BC9EB" />
    <rect x="2" y="12" width="16" height="2" rx="1" fill="#7BC9EB" />
    <rect x="2" y="9" width="16" height="2" rx="1" fill="#7BC9EB" />
    <rect x="3" y="6" width="14" height="2" rx="1" fill="#7BC9EB" />
    <path d="M6 4.66667L10 2L14 4.66667V6H6V4.66667Z" fill="#0061A8" />
    <path d="M1.22096 10L10 2.65656L18.779 10L20 8.36478L10 0L7.62939e-06 8.36478L1.22096 10Z" fill="#23A4DE" />
    <path d="M6 12H14V20H6V12Z" fill="#F2F2F2" />
  </svg>
))
