import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M89.9667 19.4688H25.7004V120.531H89.9667C92.1486 120.531 93.9175 118.8 93.9175 116.664V23.3359C93.9175 21.2002 92.1486 19.4688 89.9667 19.4688Z"
      fill="#23A4DE"
    />
    <path
      d="M109.767 123.582C109.767 123.582 110.673 128.199 112.677 128.876C114.681 129.553 126.327 125.421 125.496 123.621C124.666 121.822 117.897 120.809 115.283 121.026C112.668 121.243 109.767 123.582 109.767 123.582Z"
      fill="#262F69"
    />
    <path
      d="M62.6114 96.9926C62.6114 96.9926 59.3243 93.5597 57.2646 94.037C55.2046 94.5139 47.6445 104.114 49.3284 105.201C51.0124 106.289 57.273 103.6 59.3586 102.046C61.4442 100.493 62.6114 96.9926 62.6114 96.9926Z"
      fill="#262F69"
    />
    <path
      d="M78.2189 90.5294C78.2189 90.5294 78.2549 98.0166 72.7244 100.398C67.1939 102.78 63.8312 96.4441 62.8481 96.3747C61.8654 96.3053 58.9341 101.599 58.5463 103.096C58.1583 104.594 66.3137 113.252 75.0248 111.788C83.7363 110.323 86.3933 100.67 87.2649 100.316C88.1365 99.9616 92.9283 100.7 94.5374 101.245C96.6046 101.945 100.059 103.565 103.782 110.214C107.505 116.863 107.782 123.525 109.307 123.91C110.832 124.295 115.031 121.984 116.653 120.99C118.276 119.996 115.882 98.732 103.174 92.8452C90.4655 86.9584 78.2189 90.5294 78.2189 90.5294Z"
      fill="#262F69"
    />
    <path
      d="M97.8135 59.2691C97.8135 59.2691 102.226 71.1733 105.501 73.0432C108.776 74.9131 113.653 77.881 113.653 77.881L100.69 79.083L94.8221 77.881L93.1846 65.9933L97.8135 59.2691Z"
      fill="#0061A8"
    />
    <path
      d="M84.6659 54.7638C83.9746 57.2827 77.6261 80.7156 77.4978 90.4235C77.4664 92.8077 103.446 96.8443 106.044 92.9891C108.642 89.1335 105.197 64.2904 103.011 58.4451C100.824 52.6 95.3334 50.9274 95.3334 50.9274C95.3334 50.9274 85.3571 52.2452 84.6659 54.7638Z"
      fill="#0061A8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M113.81 54.049L124.154 56.6309C125.58 56.9868 126.431 58.4269 126.051 59.8406L120.54 80.354C120.16 81.7677 118.691 82.6286 117.265 82.2727L106.921 79.6908C105.495 79.335 104.644 77.8949 105.024 76.4812L110.535 55.9677C110.915 54.554 112.384 53.6932 113.81 54.049Z"
      fill="#23A4DE"
    />
    <path
      d="M121.226 57.9967L118.622 57.34C118.262 57.2492 117.893 57.4651 117.797 57.8225C117.7 58.18 117.913 58.5427 118.273 58.6335L120.877 59.2902C121.237 59.381 121.606 59.1651 121.703 58.8077C121.799 58.4502 121.586 58.0875 121.226 57.9967Z"
      fill="white"
    />
    <path
      d="M116.096 58.0654C116.454 58.1552 116.822 57.9404 116.919 57.5856C117.015 57.2309 116.803 56.8705 116.445 56.7807C116.087 56.691 115.719 56.9058 115.622 57.2605C115.526 57.6153 115.738 57.9756 116.096 58.0654Z"
      fill="white"
    />
    <path
      d="M87.0335 38.8387C86.848 41.4677 84.6456 43.4543 82.1145 43.2757C79.5832 43.097 77.6817 40.8208 77.8673 38.1917C78.0528 35.5624 80.2552 33.5758 82.7866 33.7545C85.3176 33.9331 87.2191 36.2093 87.0335 38.8387Z"
      fill="#FFE5C7"
    />
    <path
      d="M117.611 40.729C117.426 43.3584 115.223 45.3449 112.692 45.1663C110.161 44.9876 108.26 42.7114 108.445 40.082C108.631 37.453 110.833 35.4661 113.364 35.6448C115.896 35.8234 117.797 38.1 117.611 40.729Z"
      fill="#FFE5C7"
    />
    <path
      d="M81.7632 36.1664C81.7632 36.1664 80.9388 56.8101 96.2318 57.8895C111.525 58.9689 114.545 43.0807 114.598 37.1958C114.652 31.311 112.593 22.0568 112.593 22.0568L99.8196 19.867L88.3299 21.2645L82.8024 24.0026L82.1776 27.7306L81.7632 36.1664Z"
      fill="#FFE5C7"
    />
    <path
      d="M72.0746 82.0953C72.0746 82.0953 72.1871 87.4396 73.2751 88.249C74.3632 89.0583 75.3206 88.5308 76.5468 88.9406C77.7731 89.3501 79.1429 88.1577 80.2332 88.4326C81.3234 88.7078 82.691 88.0498 82.8334 86.7149C82.9758 85.38 81.4837 83.3653 81.4837 83.3653C81.4837 83.3653 83.6704 82.5796 83.2664 81.3746C82.8624 80.1693 78.6679 81.7922 77.1747 80.0449C75.6818 78.2976 72.0746 82.0953 72.0746 82.0953Z"
      fill="#FFE5C7"
    />
    <path
      d="M106.8 84.3643C104.696 82.361 105.515 78.9162 106.617 78.1438C107.72 77.3713 108.801 77.5724 108.801 77.5724C108.801 77.5724 110.253 77.0507 111.003 77.8479C111.003 77.8479 113.227 77.1493 114.036 78.6653C114.036 78.6653 116.58 78.768 116.838 80.6704C117.096 82.5726 115.669 86.1842 113.566 86.5006C111.463 86.8173 108.56 86.0406 106.8 84.3643Z"
      fill="#FFE5C7"
    />
    <path
      d="M86.3196 14.3639C90.7891 9.45182 99.002 9.15006 106.134 11.3325C110.171 12.5679 130.056 21.7352 118.373 25.9414C118.32 28.2739 118.167 30.6148 117.89 32.9315C117.713 34.4042 117.551 35.9906 117.002 37.3855C116.313 39.1337 114.56 38.3856 114.108 36.8872C113.288 34.166 114.706 31.423 113.188 28.6634C112.283 27.0191 110.938 26.6884 109.419 25.7463C109.33 25.6913 108.394 25.2712 108.388 25.1957C108.388 25.1957 109.806 27.8976 107.751 28.0985C105.694 28.2996 98.6426 25.209 98.6426 25.209C98.6426 25.209 99.6112 27.0998 96.7749 27.7839C93.9389 28.4679 87.1371 23.6728 87.1371 23.6728C87.1371 23.6728 83.7319 26.8536 83.2792 35.6292C83.1361 38.3978 77.5953 37.4991 78.7555 31.5022C79.9154 25.5052 80.4244 14.2202 86.3196 14.3639Z"
      fill="#262F69"
    />
    <path
      d="M84.9953 54.1433C84.9953 54.1433 68.6325 61.5905 67.3228 69.6237C66.0127 77.6567 70.6154 82.5152 71.5092 82.7105C72.403 82.9061 76.87 79.7218 77.0347 79.2686C77.1993 78.8151 75.4653 70.6709 77.7785 68.7301C80.0916 66.7891 84.6875 64.4301 84.6875 64.4301C87.097 63.8943 88.2734 59.0024 87.4881 55.9931C87.1606 54.7383 86.3072 54.2359 84.9953 54.1433Z"
      fill="#0061A8"
    />
    <rect
      width="4.99387"
      height="4.89625"
      rx="2"
      transform="matrix(0.968237 -0.250033 0.242051 0.970264 115.98 69.3496)"
      fill="#FFB71B"
    />
    <rect
      width="10.2221"
      height="10.0774"
      rx="2"
      transform="matrix(0.916 -0.401179 0.415733 0.909486 119.935 57.0283)"
      fill="#FFB71B"
    />
    <rect
      width="3.08219"
      height="3.07466"
      rx="1"
      transform="matrix(0.753467 0.657486 -0.673343 0.73933 129.07 69)"
      fill="#FFB71B"
    />
    <path
      d="M55.5169 4.73014C54.5857 4.0031 53.3886 3.80536 52.2993 4.19853L9.44214 19.6673C7.98381 20.1937 7 21.6715 7 23.3359V116.664C7 118.329 7.98357 119.806 9.44214 120.333L52.2993 135.801C53.3979 136.198 54.5945 135.99 55.5169 135.27C56.4481 134.543 57 133.376 57 132.133V7.8672C57 6.62428 56.4481 5.45691 55.5169 4.73014ZM39.1429 73.8672C37.1705 73.8672 35.5714 72.1357 35.5714 70C35.5714 67.8643 37.1705 66.1328 39.1429 66.1328C41.1152 66.1328 42.7143 67.8643 42.7143 70C42.7143 72.1357 41.1152 73.8672 39.1429 73.8672Z"
      fill="#262F69"
    />
  </svg>
))
