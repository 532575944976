import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <rect x="19.1089" y="4.56079" width="19.3482" height="34.1736" fill="#0061A8" />
      <path
        d="M32.2112 6.354H28.4706C27.9537 6.354 27.5354 6.7645 27.5354 7.27163C27.5354 7.77876 27.9537 8.18925 28.4706 8.18925H32.2112C32.728 8.18925 33.1464 7.77876 33.1464 7.27163C33.1464 6.7645 32.728 6.354 32.2112 6.354Z"
        fill="#23A4DE"
      />
      <path
        d="M36.2628 3.29517H21.3002C19.2379 3.29517 17.5596 4.94201 17.5596 6.96566V36.3296C17.5596 38.3533 19.2379 40.0001 21.3002 40.0001H36.2628C38.3251 40.0001 40.0034 38.3533 40.0034 36.3296V6.96566C40.0034 4.94201 38.3251 3.29517 36.2628 3.29517ZM38.1331 36.3296C38.1331 37.3433 37.2959 38.1649 36.2628 38.1649H21.3002C20.2672 38.1649 19.4299 37.3433 19.4299 36.3296V6.96566C19.4299 5.95197 20.2672 5.13041 21.3002 5.13041H36.2628C37.2959 5.13041 38.1331 5.95197 38.1331 6.96566V36.3296Z"
        fill="#0061A8"
      />
      <path
        d="M28.78 36.9412C29.8129 36.9412 30.6503 36.1196 30.6503 35.106C30.6503 34.0924 29.8129 33.2708 28.78 33.2708C27.747 33.2708 26.9097 34.0924 26.9097 35.106C26.9097 36.1196 27.747 36.9412 28.78 36.9412Z"
        fill="#23A4DE"
      />
      <path
        d="M25.3509 8.18925C25.8674 8.18925 26.2861 7.77842 26.2861 7.27163C26.2861 6.76484 25.8674 6.354 25.3509 6.354C24.8345 6.354 24.4158 6.76484 24.4158 7.27163C24.4158 7.77842 24.8345 8.18925 25.3509 8.18925Z"
        fill="#23A4DE"
      />
      <g clipPath="url(#clip1)">
        <path
          d="M25.7577 5.48413H17.5542V17.2034C17.5542 17.527 17.8166 17.7894 18.1402 17.7894H25.7577H33.4143C33.7379 17.7894 34.0002 17.527 34.0002 17.2034V5.48413H25.7577Z"
          fill="#7BC9EB"
        />
        <path
          d="M33.4143 1.38232H25.7577H18.1402C17.8166 1.38232 17.5542 1.64468 17.5542 1.96829V4.89811L18.7261 6.07003H25.7577H32.8283L34.0002 4.89811V1.96829C34.0002 1.64472 33.7379 1.38232 33.4143 1.38232Z"
          fill="#EC631B"
        />
        <path
          d="M25.7574 4.89819H17.554V5.48416C17.554 11.5492 14.2417 14.3829 14.2085 14.4104C14.0203 14.5694 13.951 14.8293 14.0357 15.0604C14.1198 15.291 14.339 15.4455 14.5851 15.4455H25.7574H29.8592C29.9965 15.4455 30.1293 15.3975 30.2346 15.3099C30.3862 15.1829 34 12.1277 34 5.48416V4.89819H25.7574Z"
          fill="white"
        />
        <rect x="20.0869" width="1.73913" height="3.47826" rx="0.869565" fill="#0061A8" />
        <rect x="29.6521" width="1.73913" height="3.47826" rx="0.869565" fill="#0061A8" />
      </g>
      <path
        d="M17.9036 19.7988L32.8017 19.7988C33.5648 19.7988 34.183 20.4054 34.183 21.1542L34.183 29.9974C34.183 30.7463 33.5648 31.3528 32.8017 31.3528L17.9036 31.3528L17.9036 19.7988Z"
        fill="#23A4DE"
      />
      <path
        d="M19.285 13.381L19.285 37.7687C19.285 38.8862 17.9838 39.524 17.0736 38.8517L0.551091 26.6585C-0.183763 26.1163 -0.183763 25.0341 0.551091 24.4919L17.0736 12.298C17.9838 11.6258 19.285 12.2635 19.285 13.381Z"
        fill="#7BC9EB"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="1em" height="1em" fill="white" />
      </clipPath>
      <clipPath id="clip1">
        <rect width="1em" height="1em" fill="white" transform="translate(14 -1)" />
      </clipPath>
    </defs>
  </svg>
))
