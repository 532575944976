import React from 'react';

import Router from './router';

import ErrorBoundary from 'components/hocs/error-boundary';

import {
	NavStateProvider,
	MediaStateProvider,
	SnackProvider,
} from '@reapit/elements';

import ACContextProvider from 'components/hocs/ac-context-provider';

import '@reapit/elements/dist/index.css';

const App = () => (
	<ErrorBoundary>
		<ACContextProvider>
			<NavStateProvider>
				<MediaStateProvider>
					<SnackProvider>
						<Router />
					</SnackProvider>
				</MediaStateProvider>
			</NavStateProvider>
		</ACContextProvider>
	</ErrorBoundary>
);

export default App;
