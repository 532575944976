import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.1458 27.5183H28.0989V31.4897H26.1458V27.5183Z" fill="white" />
    <path
      d="M19.3099 11.9063V31.4896H24.1927V25.5651H30.0521V31.4896H35V11.9063L27.1224 8L19.3099 11.9063ZM28.0989 16.3724H30.0521V18.3255H28.0989V16.3724ZM28.0989 20.2786H30.0521V22.2318H28.0989V20.2786ZM26.1458 22.2318H24.1927V20.2786H26.1458V22.2318ZM26.1458 18.3255H24.1927V16.3724H26.1458V18.3255Z"
      fill="white"
    />
    <path d="M10.2344 27.5183H12.1875V31.4897H10.2344V27.5183Z" fill="#23A4DE" />
    <path
      d="M8.28125 25.5652H14.1406V31.4897H17.3568V21.3464L11.2109 17.4402L5 21.3464V31.4897H8.28125V25.5652Z"
      fill="#23A4DE"
    />
  </svg>
))
