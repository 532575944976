import React, { memo } from 'react'

export default memo(() => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M2.95847 16.8421L19.9797 0.39209L37.001 16.8421V39.5588H2.95847V16.8421Z" fill="#262F69" />
      <path d="M2.95847 16.8421L19.9797 0.39209L37.001 16.8421V29.7671L2.95847 16.8421Z" fill="#7BC9EB" />
      <path d="M37.001 39.5588H27.0374L12.9221 7.21282L19.9798 0.39209L37.001 16.8421V39.5588Z" fill="#0061A8" />
      <path
        d="M37.001 16.8421V29.7671L19.9798 23.3858L12.9221 7.21282L19.9798 0.39209L37.001 16.8421Z"
        fill="#23A4DE"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="1em" height="1em" fill="white" />
      </clipPath>
    </defs>
  </svg>
))
